import React from 'react';
import { ReleoxBaseInputProps } from './ReleoxBaseInputProps';

export interface ReleoxFormElementProps extends ReleoxBaseInputProps {
  type: 'element';
  Element(): JSX.Element;
}

export const ReleoxFormElement = (props: ReleoxFormElementProps) => {
  return <props.Element />;
};

export interface GeneratableReleoxFormElementOptions {
  type: 'element';
  props: ReleoxFormElementProps;
}

export const GeneratableReleoxFormElement = {
  type: 'element',
  Element: ReleoxFormElement,
};
