export const DocumentLink = {
  fi: {
    DocumentLink: {
      name: 'Nimi',
      url: 'URL',
    },
  },
  en: {
    DocumentLink: {
      name: 'Name',
      url: 'URL',
    },
  },
};
