import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { Steps } from '../../components/Steps';
import { setupQueryConfig } from '../../config/query.config';
import { getRoute } from '../../config/routes.config';
import {
  InviteTeamStep2,
  useInviteTeamStep2InitialValues,
  useInviteTeamStep2ValidationSchema,
} from '../../forms/use-invite-team-step-2.form';
import { TeamFormBody } from '../../forms/use-team.form';
import { useTeamEditSceneQuery, useUpdateTeamMutation } from '../../generated/graphql';

export const TeamInviteStep2Scene = (): JSX.Element => {
  const initialValues = useInviteTeamStep2InitialValues();
  const validateSchema = useInviteTeamStep2ValidationSchema();
  const { t } = useTranslation('TeamInviteScene');
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');
  const query = useTeamEditSceneQuery(setupQueryConfig({ id }));

  const [updateUser, { loading }] = useUpdateTeamMutation({
    onCompleted: (data) => {
      if (data.updateTeam) {
        toast.success(t('Common:updated'));
        navigate(getRoute('TEAM_INDEX'));
      }
    },
  });

  const handleUpdate = useCallback(
    (body: TeamFormBody) => {
      let formBody = {
        ..._.cloneDeep(body),
        groups: body.groups.map((g) => g.value),
        defaultGroups: body.defaultGroups.map((g) => g.value),
      };
      formBody = _.omit(formBody, '__typename', 'isCompanyOwner');
      updateUser({ variables: { id: id, body: formBody } });
    },
    [updateUser, id]
  );

  if (query.loading || !query.data) return <Loading />;

  return (
    <FormLayout>
      <Card>
        <div className="mb-5">
          <Steps
            steps={[
              { id: '1', name: t('basicInfo'), status: 'complete' },
              { id: '2', name: t('preveledges'), status: 'current' },
            ]}
          />
        </div>
        <CardTitle>{t('preveledges')}</CardTitle>
        <Formik
          validationSchema={validateSchema}
          onSubmit={handleUpdate}
          initialValues={{
            ...initialValues,
            ...query.data.user,
            groups: query.data.user.groups.map((g) => ({ value: g.id, label: g.name })),
            defaultGroups: query.data.user.defaultGroups.map((g) => ({
              value: g.id,
              label: g.name,
            })),
          }}
        >
          <Form>
            <InviteTeamStep2
              acls={query.data.acls.list}
              groups={query.data.groups.list}
              approvers={query.data.allDocumentApprovers}
            />
            <div className="mb-4">
              <Button type="submit" className="float-right" loading={loading}>
                {t('Common:save')}
              </Button>
              <ButtonLink to={getRoute('TEAM_INDEX')}>{t('toTeam')}</ButtonLink>
            </div>
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
