import { useParams } from 'react-router-dom';

export const useParamId = (): string => {
  const id = useParams<{ id: string }>().id;

  if (!id) {
    throw new Error('id is not defined');
  }

  return id;
};
