export const SendReminderSceneTranslation = {
  fi: {
    SendReminderScene: {
      reminderSent: 'Muistutus lähetetty',
      message: 'Viesti',
      title: 'Lähetä muistutus',
    },
  },
  en: {
    SendReminderScene: {
      reminderSent: 'Reminder sent',
      message: 'Message',
      title: 'Send reminder',
    },
  },
};
