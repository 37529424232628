import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  columns: unknown[];
  data?: unknown[] | undefined;
  loading: boolean;
};

export const EmptyTable: FC<Props> = ({ columns, data, loading }) => {
  const { t } = useTranslation();

  if (loading) return <></>;

  if (data?.length === 0) {
    return (
      <tr>
        <td colSpan={columns.length} className="text-center py-4">
          {t('EmptyTable:noData')}
        </td>
      </tr>
    );
  }

  return <></>;
};
