export const customReportShowTranslation = {
  fi: {
    CustomReportShow: {
      title: 'Raportti: {{name}}',
      confirm: 'Haluatko varmasti poistaa raportin?',
      errorMessage: 'Raportissa on virhe.',
    },
  },
  en: {
    CustomReportShow: {
      title: 'Report: {{name}}',
      confirm: 'Are you sure you want to delete the report?',
      errorMessage: 'There is an error in the report.',
    },
  },
};
