import { FC } from 'react';
import { FieldRenderer } from './FieldRenderer';
import { FormInput } from './form-input';
import { InputElementType } from './input-element-type';

interface InputRendererProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputs: (FormInput<any, any, any, string> | InputElementType)[];
}

/**
 * @example
 * const formConfig = [...]
 * <InputRenderer inputs={formConfig} />
 * // Rendered:
 * <>
 *   <input type="text" name="name" id="name" />
 *   <input type="text" name="email" id="email" />
 *   <input type="password" name="password" id="password" />
 * </>
 */
export const InputRenderer: FC<InputRendererProps> = ({ inputs }) => {
  const elements = inputs.map((input) => {
    if (!('type' in input)) {
      return <FieldRenderer input={input} key={input.name} />;
    }
    return <FieldRenderer input={input} key={input.props.name} />;
  });

  return <>{elements}</>;
};
