import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { Button } from '../../../components/Button';
import { getRoute } from '../../../config/routes.config';
import { ForgotFormBody, useForgotForm } from '../../../forms/use-forgot.form';
import { useForgotPasswordMutation } from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react-components/app-form-generator/AppFormGenerator';

export const ForgotScene = () => {
  const form = useForgotForm();
  const { t } = useTranslation('ForgotScene');
  const navigate = useNavigate();

  const [forgot, { loading }] = useForgotPasswordMutation({
    onCompleted: (data) => {
      if (data.forgotPassword) {
        navigate(getRoute('FORGOT_SUCCESS'));
      }
    },
  });

  const handleForgot = useCallback(
    (body: ForgotFormBody) => {
      forgot({ variables: { body } });
    },
    [forgot]
  );

  const FooterElement = (
    <div className="grid grid-cols-3 pt-3">
      <div className="col-span-2">
        <Link to={getRoute('LOGIN')} className="text-primary hover:text-secondary block text-sm ">
          {t('backToLogin')}
        </Link>
      </div>
      <div className="text-right">
        <Button
          type="submit"
          loading={loading}
          className="inline-flex justify-center w-sm py-2 px-6 shadow-sm text-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
        >
          {t('resetPasswordBtn')}
        </Button>
      </div>
    </div>
  );
  return (
    <AuthCard>
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-900">{t('resetPassword')}</h2>
        <p className="mt-1 text-sm text-gray-600">{t('fillYourEmail')}</p>
      </div>
      <AppFormGenerator
        form={form}
        onSubmit={handleForgot}
        wrapperClassNames="space-y-3"
        FooterElement={FooterElement}
      />
    </AuthCard>
  );
};
