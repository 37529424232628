import { Form, Formik } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button';
import { useYup } from '../config/use-yup.config';
import { useReportInput } from '../inputs/use-report.input';
import { TextInput } from '../releox-engine/react-components/form-inputs/TextInput';

export type ReportFormBody = {
  start: string;
  end: string;
};

type ReportFormProps = {
  onSubmit(body: ReportFormBody): void;
};

export const useReportInitialValues = () => {
  const inputs = useReportInput();

  return useMemo(() => {
    return {
      start: inputs.start.initValue,
      end: inputs.end.initValue,
    };
  }, [inputs.end.initValue, inputs.start.initValue]);
};

export const ReportForm = (props: ReportFormProps) => {
  const inputs = useReportInput();
  const initialValues = useReportInitialValues();
  const Yup = useYup();
  const { t } = useTranslation('Common');

  const schema = useMemo(() => {
    return Yup.object().shape({
      start: inputs.start.validation,
      end: inputs.end.validation,
    });
  }, [Yup, inputs.end.validation, inputs.start.validation]);

  return (
    <Formik initialValues={initialValues} onSubmit={props.onSubmit} validationSchema={schema}>
      <Form>
        <div className="md:flex md:space-x-10 space-y-4 md:space-y-0">
          <div className="md:w-48">
            <TextInput {...inputs.start.props} />
          </div>
          <div className="md:w-48">
            <TextInput {...inputs.end.props} />
          </div>
          <div className="md:float-left md:pt-6">
            <Button type="submit">{t('submit')}</Button>
          </div>
        </div>
      </Form>
    </Formik>
  );
};
