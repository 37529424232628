import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardTitle } from '../../components/CardTitle';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import { DocumentLinkFormBody, useDocumentLinkForm } from '../../forms/use-document-link.form';
import { useCreateDocumentLinkMutation } from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { BackButton } from '../../releox-engine/react-components/app-form-generator/BackButton';
import { SubmitButton } from '../../releox-engine/react-components/app-form-generator/SubmitButton';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const DocumentLinkCreate: FC<Props> = ({ type }) => {
  const form = useDocumentLinkForm();
  const { t } = useTranslation('DocumentLinkCreate');
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) throw new Error('[MiroScene] Missing id');

  const SHOW = getDocShowRoute(type);

  const [updateMiroLink, { loading }] = useCreateDocumentLinkMutation({
    onCompleted: useOnComplete(null, 'saved', () => {
      navigate(getRoute(SHOW, { id }));
    }),
  });

  const handleSubmit = (body: DocumentLinkFormBody) => {
    updateMiroLink({
      variables: {
        body: {
          documentId: id,
          name: body.name,
          url: body.url,
        },
      },
    });
  };

  const Header = <CardTitle>{t('title')}</CardTitle>;

  const Footer = (
    <>
      <SubmitButton text={t('Common:save')} loading={loading} />
      <BackButton to={getRoute(SHOW, { id })} />
      <div className="clear-both" />
    </>
  );

  return (
    <AppFormScene
      type="create"
      title={t('title')}
      form={form}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute(SHOW, { id })}
      HeaderElement={Header}
      FooterElement={Footer}
    />
  );
};
