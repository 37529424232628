export const MessageFormTranslation = {
  fi: {
    MessageForm: {
      message: 'Kirjoita viesti ja paina enter',
    },
  },
  en: {
    MessageForm: {
      message: 'Write a message and press enter',
    },
  },
};
