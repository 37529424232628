import { FC } from 'react';
import { BackgroundClass } from '../components/BackgroundClass';
import { ButtonLink } from '../components/ButtonLink';
import { CardTitle } from '../components/CardTitle';
import { AuthCard } from '../components/auth/AuthCard';
import { getRoute } from '../config/routes.config';
import Logo from '../images/logo.png';

export const NoMatch: FC = () => {
  return (
    <div className={`w-full h-screen justify-center py-64 px-6 lg:px-8 items-center`}>
      <BackgroundClass className="BlueBackground" />

      <div className="mb-4">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Laatupankki logo" />
      </div>
      <AuthCard>
        <CardTitle>Sivua ei löytynyt </CardTitle>
        <p>
          Mikäli tämä on virhe, ota yhteyttä ylläpitoon. Muussa tapauksessa voit palata takaisin
          etusivulle.
        </p>
        <div className="mt-8">
          <ButtonLink to={getRoute('DASHBOARD')}>Takaisin etusivulle</ButtonLink>
        </div>
      </AuthCard>
    </div>
  );
};
