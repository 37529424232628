import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDocSendReminderRoute, getRoute } from '../config/routes.config';
import {
  useDeleteDocumentApproverMutation,
  useSendDocumentApproveRequestMutation,
} from '../generated/graphql';
import { DocumentTypeField } from '../utils/DocumentType';

type Props = {
  status: string;
  id: string;
  daId: string;
  type: DocumentTypeField;
  refetch: () => void;
};

export const ReminderButton: FC<Props> = ({ status, type, daId, id }) => {
  const { t } = useTranslation('DocumentShowScene');
  const navigation = useNavigate();

  const route = getDocSendReminderRoute(type);

  if (status !== 'request-sent') return <Fragment />;

  return (
    <button
      type="button"
      onClick={() =>
        navigation(
          getRoute(route, {
            id: id,
            documentApproverId: daId,
          })
        )
      }
      className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      {t('reminder')}
    </button>
  );
};

export const RequestButton: FC<Props> = ({ status, daId, refetch }) => {
  const { t } = useTranslation('DocumentShowScene');
  const [sendRequest, { loading }] = useSendDocumentApproveRequestMutation({
    variables: {
      documentApproverId: daId,
    },
    onCompleted: (d) => {
      if (d.sendDocumentApproveRequest) {
        toast.success(t('requestSent'));
        refetch();
      }
    },
  });

  const text = loading ? t('sending') : t('request');

  if (['approved', 'request-sent'].includes(status)) return <Fragment />;

  return (
    <button
      type="button"
      onClick={() => sendRequest()}
      disabled={loading}
      className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      {text}
    </button>
  );
};

type RemoveProps = {
  daId: string;
  refetch: () => void;
};

export const RemoveButton: FC<RemoveProps> = ({ daId, refetch }) => {
  const { t } = useTranslation('DocumentShowScene');
  const [sendRequest, { loading }] = useDeleteDocumentApproverMutation({
    variables: {
      id: daId,
    },
    onCompleted: (d) => {
      if (d.deleteDocumentApprover) {
        toast.success(t('Common:deleted'));
        refetch();
      }
    },
  });

  const text = loading ? t('sending') : t('remove');

  return (
    <button
      type="button"
      onClick={() => sendRequest()}
      disabled={loading}
      className="relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      {text}
    </button>
  );
};
