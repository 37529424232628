import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDocumentColumn } from '../columns/document.column';
import { DocumentShowSceneQuery } from '../generated/graphql';
import { RiskBadge } from './RiskBadge';

export type DetailsColumn = {
  field: string;
  text: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  formatter?(data: any): string | number | JSX.Element;
};

type Props = {
  document: DocumentShowSceneQuery['document'];
  columns: DetailsColumn[];
};

const thClassNames = 'text-left w-1/2 pr-4 pb-1';

export const DocumentDetails = (props: Props) => {
  const documentColumn = useDocumentColumn();
  const { t } = useTranslation();
  const { document } = props;
  const { documentModel, documentFields } = document;

  const qncFields = [documentColumn.category, documentColumn.costs];

  return (
    <table>
      <tbody className="text-sm">
        {/* Standard fields */}
        {props.columns.map((col) => {
          let value: string | number | JSX.Element = _.get(props.document, col.field, '-');
          if (col.formatter) {
            value = col.formatter(props.document);
          }
          return (
            <tr key={col.field}>
              <th className={thClassNames}>{t(col.text)}</th>
              <td>{value}</td>
            </tr>
          );
        })}

        {/* QNC Specific fields */}
        {document.type === 'qnc'
          ? qncFields.map((col) => {
              let value: string | number | JSX.Element = _.get(props.document, col.field, '-');
              if (col.formatter) {
                value = col.formatter(props.document);
              }
              return (
                <tr key={col.field}>
                  <th className={thClassNames}>{t(col.text)}</th>
                  <td>{value}</td>
                </tr>
              );
            })
          : null}

        {/* DocumentModel fields */}
        {document.documentProperties.map((field) => {
          return (
            <tr key={field.label}>
              <th className={thClassNames}>{t(field.label)}</th>
              <td>
                <RiskBadge tKey={field.value} text={t(field.value)} />
              </td>
            </tr>
          );
        })}

        {/* DocumentModel fields */}
        {documentModel.documentModelFields.map((field) => {
          const customField = documentFields.find((df) => df.key === field.key);
          let value = '-';
          if (customField) value = customField.value;
          return (
            <tr key={field.key}>
              <th className={thClassNames}>{field.label}</th>
              <td>{value}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
