export const FileDropzoneTranslation = {
  fi: {
    FileDropzone: {
      info: 'Lisää valokuva tai liitetiedosto klikkaamalla tästä.',
      dropFiles: 'Pudota tiedosto tähän.',
      loading: 'Lataa...',
    },
  },
  en: {
    FileDropzone: {
      info: 'Drag and drop some files here, or click to select files.',
      dropFiles: 'Drop the files here.',
      loading: 'Uploading...',
    },
  },
};
