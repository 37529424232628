import { useMemo } from 'react';
import { useAccountDeleteInput } from '../inputs/use-account-delete.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useAccountDeleteForm = () => {
  const inputs = useAccountDeleteInput();

  const form = useMemo(() => {
    return createForm([inputs.email, inputs.name, inputs.company]);
  }, [inputs.company, inputs.email, inputs.name]);

  return form;
};

export type AccountDeleteFormBody = {
  email: string;
  name: string;
  company: string;
};
