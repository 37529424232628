export const PublicThanksSceneTranslation = {
  fi: {
    PublicThanksScene: {
      createNew: 'Lisää uusi',
      title: 'Kiitos',
      thanks: 'Lomake on tallennettu.',
    },
  },
  en: {
    PublicThanksScene: {
      createNew: 'Create new',
      title: 'Thank you',
      thanks: 'The document has been saved.',
    },
  },
};
