export const TagIndexSceneTranslation = {
  fi: {
    TagIndexScene: {
      title: 'Tagit',
    },
  },
  en: {
    TagIndexScene: {
      title: 'Tags',
    },
  },
};
