export const ChooseCustomDocumentModelSceneTranslation = {
  fi: {
    ChooseCustomDocumentModelScene: {
      title: 'Valitse pohja',
      noCustomDocuments: 'Ei mukautettuja pohjia',
    },
  },
  en: {
    ChooseCustomDocumentModelScene: {
      title: 'Choose document base',
      noCustomDocuments: 'No custom documents',
    },
  },
};
