import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import { ACLFormBody, useACLForm } from '../../forms/use-acl.form';
import { useCreateAclMutation } from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const ACLCreateScene = () => {
  const form = useACLForm();
  const { t } = useTranslation('ACLCreateScene');
  const onComplete = useOnComplete('ACL_SHOW');
  const [create, { loading }] = useCreateAclMutation({
    onCompleted: onComplete,
  });

  const submit = (body: ACLFormBody) => {
    create({
      variables: {
        body: {
          name: body.name,
        },
      },
    });
  };

  return (
    <AppFormScene
      type="create"
      form={form}
      onSubmit={submit}
      title={t('title')}
      isSubmitLoading={loading}
      backLink={getRoute('ACL_INDEX')}
    />
  );
};
