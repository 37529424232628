import _ from 'lodash';
import { EmptyTableTranslation } from './components/table/EmptyTable.translation';
import { LoadingTableTranslation } from './components/table/LoadingTable.translation';
import { YupTranslations } from './config/use-yup.config';
import documentFilterFormTranslation from './forms/filters/document-filter/document-filter-form.translation';
import filterTranslation from './forms/filters/filter.translation';
import { ACLCreateSceneTranslation } from './scenes/acl/ACLCreateScene.translation';
import { ACLEditSceneTranslation } from './scenes/acl/ACLEditScene.translation';
import { ACLIndexSceneTranslation } from './scenes/acl/ACLIndexScene.translation';
import { ACLShowSceneTranslation } from './scenes/acl/ACLShowScene.translation';
import { AcceptInvitationSceneTranslation } from './scenes/auth/accept-invitation/AcceptInvitationScene.translation';
import ConfirmSceneTranslation from './scenes/auth/confirm/ConfirmScene.translation';
import ForgotSceneTranslation from './scenes/auth/forgot/ForgotScene.translation';
import ForgotSuccessSceneTranslation from './scenes/auth/forgot/ForgotSuccessScene.translation';
import LoginSceneTranslation from './scenes/auth/login/LoginScene.translation';
import RegisterSceneTranslation from './scenes/auth/register/RegisterScene.translation';
import ResetPasswordSceneTranslation from './scenes/auth/reset/ResetPasswordScene.translation';
import ResetPasswordSuccessSceneTranslation from './scenes/auth/reset/ResetPasswordSuccessScene.translation';
import { CompanyEditSceneTranslation } from './scenes/company/CompanyEditScene.translation';
import { ChooseCustomDocumentModelSceneTranslation } from './scenes/custom-document/ChooseCustomDocumentModelScene.translation';
import { CustomReportCreateSceneTranslation } from './scenes/custom-reports/CustomReportCreateScene.translation';
import { customReportShowTranslation } from './scenes/custom-reports/CustomReportShow.translation';
import { HomeSceneTranslation } from './scenes/dashbaord/HomeScene.translation';
import { DocumentLinkCreateTranslation } from './scenes/document-link/DocumentLinkCreate.translation';
import { DocumentLinkEditTranslation } from './scenes/document-link/DocumentLinkEdit.translation';
import { DocumentModelCreateSceneTranslation } from './scenes/document-model/DocumentModelCreateScene.translation';
import { DocumentModelEditSceneTranslation } from './scenes/document-model/DocumentModelEditScene.translation';
import { DocumentModelIndexSceneTranslation } from './scenes/document-model/DocumentModelIndexScene.translation';
import { DocumentCreateSceneTranslation } from './scenes/document/DocumentCreateScene.translation';
import { DocumentDriveFileEditSceneTranslation } from './scenes/document/DocumentDriveFileEditScene.translation';
import { DocumentEditSceneTranslation } from './scenes/document/DocumentEditScene.translation';
import { DocumentIndexSceneTranslation } from './scenes/document/DocumentIndexScene.translation';
import { DocumentShowSceneTranslation } from './scenes/document/DocumentShowScene.translation';
import { RejectSceneTranslation } from './scenes/document/RejectScene.translation';
import { SendReminderSceneTranslation } from './scenes/document/SendReminderScene.translation';
import { EditorSceneTranslation } from './scenes/editor/EditorScene.translation';
import ExportIndexSceneTranslation from './scenes/export/ExportIndexScene.translation';
import { GroupCreateSceneTranslation } from './scenes/group/GroupCreateScene.translation';
import { GroupEditSceneTranslation } from './scenes/group/GroupEditScene.translation';
import { GroupIndexSceneTranslation } from './scenes/group/GroupIndexScene.translation';
import { GroupShowSceneTranslation } from './scenes/group/GroupShowScene.translation';
import { MiroSceneTranslation } from './scenes/miro/MiroScene.translation';
import { MSLinkSceneTranslation } from './scenes/ms/MSLinkScene.translation';
import { PublicDocumentCreateTranslation } from './scenes/public-document-create/PublicDocumentCreate.translation';
import { PublicThanksSceneTranslation } from './scenes/public-document-create/PublicThanksScene.translation';
import { ReportIndexSceneTranslation } from './scenes/report/ReportIndexScene.translation';
import { ReportQncCostSceneTranslation } from './scenes/report/ReportQncCostScene.translation';
import { ReportQncCountSceneTranslation } from './scenes/report/ReportQncCountScene.translation';
import { ReportQncTagSceneTranslation } from './scenes/report/ReportQncTagScene.translation';
import { ReportQncTypeSceneTranslation } from './scenes/report/ReportQncTypeScene.translation';
import { ReportRiskMatrixSceneTranslation } from './scenes/report/ReportRiskMatrixScene.translation';
import UserEditSceneTranslation from './scenes/settings/UserEditScene.translation';
import { TagCreateSceneTranslation } from './scenes/tag/TagCreateScene.translation';
import { TagEditSceneTranslation } from './scenes/tag/TagEditScene.translation';
import { TagIndexSceneTranslation } from './scenes/tag/TagIndexScene.translation';
import { TeamEditSceneTranslation } from './scenes/team/TeamEditScene.translation';
import { TeamIndexSceneTranslation } from './scenes/team/TeamIndexScene.translation';
import { TeamInviteSceneTranslation } from './scenes/team/TeamInviteScene.translation';
import ACLTranslation from './translations/ACL.translation';
import AccountDeleteSceneTranslation from './translations/AccountDeleteScene.translation';
import { ApprovalStatusBadgeTranslation } from './translations/ApprovalStatusBadge.translation';
import { ArrayInputTranslation } from './translations/ArrayInput.translation';
import AuthErrorViewTranslation from './translations/AuthErrorView.translation';
import CommonTranslation from './translations/Common.translation';
import CompanyInputTranslation from './translations/Company.translation';
import { ContentEditorTranslation } from './translations/ContentEditor.translation';
import { CustomReportTranslation } from './translations/CustomReport.translation';
import DocumentTranslation from './translations/Document.translation';
import { DocumentApprovalActionTranslation } from './translations/DocumentApprovalAction.translation';
import { DocumentLink } from './translations/DocumentLink.translation';
import DocumentModelTranslation from './translations/DocumentModel.translation';
import DocumentTypeTranslations from './translations/DocumentType.translations';
import DriveFileTranslation from './translations/DriveFile.translation';
import { FileDropzoneTranslation } from './translations/FileDropzone.translation';
import GroupTranslation from './translations/Group.translation';
import { LogEventTranslations } from './translations/LogEvent';
import MSLinkTranslation from './translations/MSLink.translation';
import { MessageFormTranslation } from './translations/MessageForm.translation';
import MiroTranslation from './translations/Miro.translation';
import { MonthTranslation } from './translations/Month.translation';
import PaginationTranslation from './translations/Pagination.translation';
import { QNCTypeTranslation } from './translations/QNCType.translation';
import ReactSelectInputTranslation from './translations/ReactSelectInput.translation';
import { RejectTranslation } from './translations/Reject.translation';
import { ReportTranslation } from './translations/Report.translation';
import { ReportMetaDataTranslation } from './translations/ReportMetaData.translation';
import RiskProbabilityTranslation from './translations/RiskProbabilityTranslation';
import { ServerErrorsTranslation } from './translations/ServerErrors.translation';
import SideBarTranslation from './translations/SideBar.translation';
import TagTranslation from './translations/Tag.translation';
import UserInputTranslation from './translations/User.translation';

export default _.merge(
  LoginSceneTranslation,
  RegisterSceneTranslation,
  UserInputTranslation,
  ForgotSuccessSceneTranslation,
  ForgotSceneTranslation,
  ConfirmSceneTranslation,
  ResetPasswordSceneTranslation,
  ResetPasswordSuccessSceneTranslation,
  AuthErrorViewTranslation,
  CommonTranslation,
  SideBarTranslation,
  UserEditSceneTranslation,
  CompanyInputTranslation,
  CompanyEditSceneTranslation,
  GroupIndexSceneTranslation,
  GroupTranslation,
  DocumentModelTranslation,
  GroupCreateSceneTranslation,
  GroupEditSceneTranslation,
  TagIndexSceneTranslation,
  TagTranslation,
  TagCreateSceneTranslation,
  TagEditSceneTranslation,
  ArrayInputTranslation,
  DocumentModelEditSceneTranslation,
  MessageFormTranslation,
  PaginationTranslation,
  SendReminderSceneTranslation,
  DocumentModelIndexSceneTranslation,
  ApprovalStatusBadgeTranslation,
  DocumentIndexSceneTranslation,
  DocumentTranslation,
  HomeSceneTranslation,
  DocumentApprovalActionTranslation,
  LogEventTranslations,
  DocumentShowSceneTranslation,
  TeamIndexSceneTranslation,
  TeamEditSceneTranslation,
  ReportIndexSceneTranslation,
  ReportQncCountSceneTranslation,
  ReportQncTypeSceneTranslation,
  ReportQncCostSceneTranslation,
  ReportQncTagSceneTranslation,
  DocumentCreateSceneTranslation,
  DocumentEditSceneTranslation,
  TeamInviteSceneTranslation,
  ContentEditorTranslation,
  FileDropzoneTranslation,
  ReactSelectInputTranslation,
  ReportTranslation,
  QNCTypeTranslation,
  ExportIndexSceneTranslation,
  MonthTranslation,
  ReportMetaDataTranslation,
  DocumentDriveFileEditSceneTranslation,
  DriveFileTranslation,
  RejectSceneTranslation,
  RejectTranslation,
  AcceptInvitationSceneTranslation,
  EditorSceneTranslation,
  MiroSceneTranslation,
  MiroTranslation,
  MSLinkSceneTranslation,
  MSLinkTranslation,
  DocumentModelCreateSceneTranslation,
  DocumentLinkCreateTranslation,
  DocumentLink,
  DocumentLinkEditTranslation,
  ChooseCustomDocumentModelSceneTranslation,
  PublicDocumentCreateTranslation,
  PublicThanksSceneTranslation,
  DocumentTypeTranslations,
  customReportShowTranslation,
  CustomReportCreateSceneTranslation,
  CustomReportTranslation,
  ACLIndexSceneTranslation,
  ACLTranslation,
  ACLShowSceneTranslation,
  GroupShowSceneTranslation,
  ACLCreateSceneTranslation,
  RiskProbabilityTranslation,
  ACLEditSceneTranslation,
  AccountDeleteSceneTranslation,
  AccountDeleteSceneTranslation,
  filterTranslation,
  documentFilterFormTranslation,
  LoadingTableTranslation,
  ReportRiskMatrixSceneTranslation,
  EmptyTableTranslation,
  YupTranslations,
  ServerErrorsTranslation
);
