import moment from 'moment';
import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useReportInput = () => {
  const Yup = useYup();

  const start: FormInput = {
    type: 'text',
    initValue: moment().subtract(11, 'months').format('YYYY-MM-DD'),
    validation: Yup.string().required(),
    props: {
      name: 'start',
      label: 'Report:start',
      type: 'date',
    },
  };

  const end: FormInput = {
    type: 'text',
    initValue: moment().format('YYYY-MM-DD'),
    validation: Yup.string().required(),
    props: {
      name: 'end',
      label: 'Report:end',
      type: 'date',
    },
  };

  return {
    start,
    end,
  };
};
