import React from 'react';
import { Button } from '../../../components/Button';

type Props = {
  loading: boolean;
  text: string;
};

export const SubmitButton = ({ loading, text }: Props) => {
  return (
    <Button type="submit" loading={loading} className="float-right">
      {text}
    </Button>
  );
};
