import { Suspense } from 'react';
import { Await, useLoaderData, useOutlet } from 'react-router-dom';
import { Loading } from './components/Loading';
import { AuthProvider } from './hooks/auth/use-auth';

export const AuthMiddleware = () => {
  const outlet = useOutlet();

  const { userPromise } = useLoaderData() as { userPromise: Promise<string | null> };

  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={userPromise}>
        {(token: string | null) => <AuthProvider token={token}>{outlet}</AuthProvider>}
      </Await>
    </Suspense>
  );
};
