import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: {
    isApproved: boolean;
    isApproveRequired: boolean;
    isApproveRequestSend: boolean;
  };
}

const baseClasses = 'text-xs font-semibold mr-2 px-2.5 py-0.5 rounded';
const greenClasses = `bg-green-100 text-green-800 ${baseClasses}`;
const redClasses = `bg-red-100 text-red-800 ${baseClasses}`;
const grayClasses = `bg-gray-100 text-gray-800 ${baseClasses}`;
const yellowClasses = `bg-yellow-100 text-yellow-800 ${baseClasses}`;

export type DocumentStatus = 'no-action' | 'approved' | 'unapproved' | 'request-sent';

export const getDocumentStatus = (x: Props['data']): DocumentStatus => {
  if (!x.isApproveRequired) return 'no-action';
  if (x.isApproved) return 'approved';
  if (x.isApproveRequestSend) return 'request-sent';
  return 'unapproved';
};

export const ApprovalStatusBadge = ({ data }: Props): JSX.Element => {
  const { t } = useTranslation('Status');

  const statusObject = {
    'no-action': <span className={grayClasses}>{t('noAction')}</span>,
    approved: <span className={greenClasses}>{t('approved')}</span>,
    'request-sent': <span className={yellowClasses}>{t('sent')}</span>,
    unapproved: <span className={redClasses}>{t('unapproved')}</span>,
  };

  const status = getDocumentStatus(data);
  return <>{_.get(statusObject, status, 'MissingBadge')}</>;
};
