export default {
  fi: {
    ReactSelectInput: {
      noOptionsMessage: 'Ei vaihtoehtoja',
    },
  },
  en: {
    ReactSelectInput: {
      noOptionsMessage: 'No options',
    },
  },
};
