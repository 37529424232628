import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { UseFileUploadInput } from '../../../utils/use-image-input';
import { ReleoxBaseInputProps } from '../../form/ReleoxBaseInputProps';

export interface FileInputProps extends ReleoxBaseInputProps {
  label: string;
}

const FileInput = (props: FileInputProps) => {
  const { t } = useTranslation('FileInput');
  const { getInputProps, getRootProps, preview } = UseFileUploadInput(props.name);
  return (
    <div className="FileInput">
      <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
        {t(props.label)}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <div className="flex items-center">
          <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
            <img className="h-full w-full text-gray-300" src={preview} alt="" />
          </span>
          <div {...getRootProps()}>
            <input type="file" {...getInputProps()} multiple={false} />
            <button
              type="button"
              className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary"
            >
              {t('changeImage')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export interface GeneratableFileInputOptions {
  type: 'file';
  initValue: string | null;
  validation?: Yup.StringSchema | Yup.NumberSchema;
  props: FileInputProps;
}

export const GeneratableFileInput = {
  Element: FileInput,
  type: 'file',
};
