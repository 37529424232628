import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { getRoute } from '../../config/routes.config';
import { MatrixDocs, useReportRiskMatrixQuery } from '../../generated/graphql';

type Matrix = {
  '1-1': number;
  '1-2': number;
  '1-3': number;
  '2-1': number;
  '2-2': number;
  '2-3': number;
  '3-1': number;
  '3-2': number;
  '3-3': number;
};

const MLink = ({ doc }: { doc: MatrixDocs }) => {
  return (
    <li key={doc.documentId}>
      <Link
        className="text-primary hover:underline"
        to={getRoute('RISK_SHOW', { id: doc.documentId })}
      >
        {doc.documentName}
      </Link>
    </li>
  );
};

export const ReportRiskMatrixScene = () => {
  const [matrix, setMatrix] = useState<Matrix | null>(null);
  const { t } = useTranslation('ReportRiskMatrixScene');

  const { data } = useReportRiskMatrixQuery();

  useEffect(() => {
    if (data?.reportRiskMatrix) {
      setMatrix(JSON.parse(data.reportRiskMatrix.report));
    }
  }, [data]);

  const Cal = ({ label, mKey }: { label: string; mKey: keyof Matrix }) => {
    return (
      <>
        <strong>{label}</strong>
        <br />
        {get(matrix, mKey, 0)}
      </>
    );
  };

  return (
    <FormLayout>
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <table className="matrix">
          <tbody>
            <tr>
              <td>-</td>
              <td>{t('RiskProperties:minor')}</td>
              <td>{t('RiskProperties:harmful')}</td>
              <td>{t('RiskProperties:severe')}</td>
            </tr>
            <tr>
              <td>{t('RiskProperties:unlikely')}</td>
              <td className="risk-2">
                <Cal label={t('RiskProperties:insignificantRisk')} mKey="1-1" />
              </td>
              <td className="risk-3">
                <Cal label={t('RiskProperties:minorRisk')} mKey="1-2" />
              </td>
              <td className="risk-4">
                <Cal label={t('RiskProperties:moderateRisk')} mKey="1-3" />
              </td>
            </tr>
            <tr>
              <td>{t('RiskProperties:possible')}</td>
              <td className="risk-3">
                <Cal label={t('RiskProperties:minorRisk')} mKey="2-1" />
              </td>
              <td className="risk-4">
                <Cal label={t('RiskProperties:moderateRisk')} mKey="2-2" />
              </td>
              <td className="risk-5">
                <Cal label={t('RiskProperties:significantRisk')} mKey="2-3" />
              </td>
            </tr>
            <tr>
              <td>{t('RiskProperties:likely')}</td>
              <td className="risk-4">
                <Cal label={t('RiskProperties:moderateRisk')} mKey="3-1" />
              </td>
              <td className="risk-5">
                <Cal label={t('RiskProperties:significantRisk')} mKey="3-2" />
              </td>
              <td className="risk-6">
                <Cal label={t('RiskProperties:unacceptableRisk')} mKey="3-3" />
              </td>
            </tr>
          </tbody>
        </table>
        {data && data.reportRiskMatrix.documents.length > 0 && (
          <>
            <div className="mt-20"></div>
            <CardTitle>{t('documents')}</CardTitle>
            <div className="ml-4">
              {/* If there is documents with riskIndex 2, show title and list docs */}
              {data?.reportRiskMatrix.documents.some((doc) => doc.riskIndex === 2) && (
                <>
                  <h3 className="mt-3 font-bold">{t('RiskProperties:insignificantRisk')}</h3>
                  <ul className="list-disc ml-6">
                    {data?.reportRiskMatrix.documents
                      .filter((doc) => doc.riskIndex === 2)
                      .map((doc) => <MLink key={doc.documentId} doc={doc} />)}
                  </ul>
                </>
              )}

              {/* If there is documents with riskIndex 3, show title and list docs */}
              {data?.reportRiskMatrix.documents.some((doc) => doc.riskIndex === 3) && (
                <>
                  <h3 className="mt-3 font-bold">{t('RiskProperties:minorRisk')}</h3>
                  <ul className="list-disc ml-6">
                    {data?.reportRiskMatrix.documents
                      .filter((doc) => doc.riskIndex === 3)
                      .map((doc) => <MLink key={doc.documentId} doc={doc} />)}
                  </ul>
                </>
              )}

              {/* If there is documents with riskIndex 4, show title and list docs */}
              {data?.reportRiskMatrix.documents.some((doc) => doc.riskIndex === 4) && (
                <>
                  <h3 className="mt-3 font-bold">{t('RiskProperties:moderateRisk')}</h3>
                  <ul className="list-disc ml-6">
                    {data?.reportRiskMatrix.documents
                      .filter((doc) => doc.riskIndex === 4)
                      .map((doc) => <MLink key={doc.documentId} doc={doc} />)}
                  </ul>
                </>
              )}

              {/* If there is documents with riskIndex 5, show title and list docs */}
              {data?.reportRiskMatrix.documents.some((doc) => doc.riskIndex === 5) && (
                <>
                  <h3 className="mt-3 font-bold">{t('RiskProperties:significantRisk')}</h3>
                  <ul className="list-disc ml-6">
                    {data?.reportRiskMatrix.documents
                      .filter((doc) => doc.riskIndex === 5)
                      .map((doc) => <MLink key={doc.documentId} doc={doc} />)}
                  </ul>
                </>
              )}

              {/* If there is documents with riskIndex 6, show title and list docs */}
              {data?.reportRiskMatrix.documents.some((doc) => doc.riskIndex === 6) && (
                <>
                  <h3 className="mt-3 font-bold">{t('RiskProperties:unacceptableRisk')}</h3>
                  <ul className="list-disc ml-6">
                    {data?.reportRiskMatrix.documents
                      .filter((doc) => doc.riskIndex === 6)
                      .map((doc) => <MLink key={doc.documentId} doc={doc} />)}
                  </ul>
                </>
              )}
            </div>
          </>
        )}
      </Card>
    </FormLayout>
  );
};
