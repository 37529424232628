import { Link } from 'react-router-dom';

interface Props {
  to: string;
  icon: string;
  title: string;
  text: string;
  secondaryColor?: boolean;
}

export const DashButton = (props: Props) => {
  const { to, icon, title, text, secondaryColor = false } = props;

  const color = secondaryColor ? 'bg-secondary' : 'bg-primary';
  return (
    <Link to={to}>
      <div className="bg-white shadow rounded-lg mb-5">
        <div className="flex grid-cols-1">
          <div className={`${color} rounded-l-lg p-4`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={`h-8 w-8 ${color} text-white text-center items-center`}
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d={icon} />
            </svg>
          </div>
          <div className="ml-3 mt-3">
            <p className="text-md text-gray-400 font-bold">{title}</p>
            <p className="text-xs uppercase text-gray-500 font-bold">{text}</p>
          </div>
        </div>
      </div>
    </Link>
  );
};
