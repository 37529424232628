export const GroupShowSceneTranslation = {
  fi: {
    GroupShowScene: {
      name: 'Nimi',
      actions: 'Toiminnot',
      edit: 'Muokkaa',
    },
  },
  en: {
    GroupShowScene: {
      name: 'Name',
      actions: 'Actions',
      edit: 'Edit',
    },
  },
};
