import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { authConfig } from './config/auth.config';
import { createLogger } from './utils/create-logger';
import { parseGraphqlErrors } from './utils/get-gql-error';
import { isI18nString } from './utils/is-i18n-string';

const logger = createLogger('UseApolloClient');

export const useApolloClient = () => {
  const { t } = useTranslation();

  return useMemo(() => {
    logger.log('Setup API Client');

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
          // eslint-disable-next-line no-console
          console.log(error);

          if (isI18nString(message)) {
            toast.error(t(message));
          } else {
            toast.error(parseGraphqlErrors(graphQLErrors));
          }
        });
      if (networkError) {
        const error = `[Network error]: ${networkError}`;
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });

    const host = process.env.REACT_APP_API_URL;

    const uri = `${host}/graphql`;

    const httpLink = createHttpLink({
      uri,
    });

    const authLink = setContext((_, { headers }) => {
      const token = localStorage.getItem(authConfig.tokenStorageKey);
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink.concat(httpLink)]),
      cache: new InMemoryCache(),
      defaultOptions: {
        mutate: {
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
