import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getRoute } from '../../config/routes.config';
import { ACLFormBody, useACLForm } from '../../forms/use-acl.form';
import {
  useDeleteAclMutation,
  useEditAclSceneQuery,
  useUpdateAclMutation,
} from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const ACLEditScene = () => {
  const form = useACLForm();
  const params = useParams();
  const { t } = useTranslation('ACLEditScene');
  const onComplete = useOnComplete('ACL_SHOW');
  const onDeleteComplete = useOnComplete('ACL_INDEX');

  const query = useEditAclSceneQuery({
    variables: {
      id: params?.id || 'UNKNOWN',
    },
  });

  const [update, { loading }] = useUpdateAclMutation({
    onCompleted: onComplete,
  });

  const [deleteACL, { loading: deleteLoading }] = useDeleteAclMutation({
    variables: {
      id: params.id || 'UNKNOWN',
    },
    onCompleted: onDeleteComplete,
  });

  const submit = (body: ACLFormBody) => {
    update({
      variables: {
        id: params?.id || 'UNKNOWN',
        body: {
          name: body.name,
        },
      },
    });
  };

  const onDelete = () => {
    if (confirm(t('deleteConfirm'))) {
      deleteACL();
    }
  };

  return (
    <AppFormScene
      type="edit"
      form={form}
      query={query}
      queryDataPath="acl"
      onSubmit={submit}
      onDelete={onDelete}
      title={t('title')}
      isSubmitLoading={loading || deleteLoading}
      backLink={getRoute('ACL_SHOW', { id: params.id })}
    />
  );
};
