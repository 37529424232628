import { TagIndexSceneQuery } from '../generated/graphql';
import { formatDate } from '../utils/format-date';
import { TableColumn } from './table-column';

type ColumnData = TagIndexSceneQuery['tags']['list'][0];

const id: TableColumn = {
  field: 'id',
  text: 'Tag:id',
};

const name: TableColumn = {
  field: 'name',
  sort: true,
  text: 'Tag:name',
};

const createdAt: TableColumn = {
  field: 'createdAt',
  sort: true,
  text: 'Tag:createdAt',
  formatter: (data: ColumnData) => {
    return formatDate(data.createdAt);
  },
};

export const tagColumn = {
  id,
  name,
  createdAt,
};
