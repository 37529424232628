import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { Button } from '../../../components/Button';
import { getRoute } from '../../../config/routes.config';
import { RegisterFormBody, useRegisterForm } from '../../../forms/use-register.form';
import { useRegisterMutation } from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react-components/app-form-generator/AppFormGenerator';
import { loginUser } from '../../../utils/login-user';

export const RegisterScene = () => {
  const form = useRegisterForm();
  const { t } = useTranslation('RegisterScene');
  const navigate = useNavigate();

  const [register, { loading }] = useRegisterMutation({
    onCompleted: (data) => {
      if (data.register) {
        loginUser(data.register.token);
        navigate(getRoute('DASHBOARD'));
      }
    },
  });

  const handleRegister = useCallback(
    (body: RegisterFormBody) => {
      register({ variables: { body } });
    },
    [register]
  );

  const FooterElement = (
    <>
      <div className="py-2">
        <a
          href="https://www.termsfeed.com/terms-service/45ac79ff63b79f1e6c2f8acf20f0ef91"
          target="_blank"
          rel="noopener noreferrer"
          className="text-primary hover:text-secondary block text-sm "
        >
          {t('termsOfUse')}
        </a>
      </div>

      <hr />
      <div className="grid grid-cols-3 pt-3">
        <div className="col-span-2">
          <Link to={getRoute('LOGIN')} className="text-primary hover:text-secondary block text-sm">
            {t('back')}
          </Link>
        </div>
        <div className="text-right">
          <Button
            loading={loading}
            type="submit"
            className="inline-flex justify-center w-sm py-2 px-6 shadow-sm text-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            {t('register')}
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <AuthCard>
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-900">{t('register')}</h2>
        <p className="mt-1 text-sm text-gray-600">{t('fillInInformations')}</p>
      </div>
      <AppFormGenerator
        form={form}
        onSubmit={handleRegister}
        wrapperClassNames="space-y-3"
        FooterElement={FooterElement}
      />
    </AuthCard>
  );
};
