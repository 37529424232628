export default {
  fi: {
    AccountDeleteScene: {
      title: 'Pyydä käyttäjätunnuksen poistoa',
      info: 'Täytä alla oleva lomake pyytääksesi käyttäjätunnuksen poistoa. Olemme sinuun yhteydessä poiston vahvistamiseksi.',
      button: 'Pyydä käyttäjätunnuksen poistoa',
      'ty-info':
        'Käyttäjätunnuksen poistopyyntösi on vastaanotettu. Olemme sinuun yhteydessä poiston vahvistamiseksi.',
      'ty-title': 'Kiitos!',
    },
  },
  en: {
    AccountDeleteScene: {
      title: 'Request account deletion',
      info: 'Please fill in the form below to request account deletion. We will contact you to confirm the deletion.',
      button: 'Request account deletion',
      'ty-info':
        'Your account deletion request has been received. We will contact you to confirm the deletion.',
      'ty-title': 'Thank you!',
    },
  },
};
