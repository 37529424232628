import { Form, Formik } from 'formik';
import { FC, useMemo } from 'react';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { SelectInput } from '../../components/inputs/select-input';
import { useAccountManagementQuery, useUpdateFeatureMutation } from '../../generated/graphql';

type FormValues = {
  paperipankki: string;
};

export const AccountManagementScene: FC = () => {
  const { data } = useAccountManagementQuery();

  const [updateFeature, { loading }] = useUpdateFeatureMutation();

  const onSubmit = (values: FormValues) => {
    updateFeature({
      variables: {
        key: 'paperipankki',
        value: values.paperipankki,
      },
    });
  };

  const initialValues = useMemo(() => {
    // find the feature with key 'paperipankki' and return its value
    const paperipankki = data?.accountFeatures.find((f) => f.key === 'paperipankki');

    return {
      paperipankki: paperipankki?.value || 'isNotClient',
    };
  }, [data?.accountFeatures]);

  return (
    <div key={JSON.stringify(initialValues)}>
      <Card>
        <CardTitle>Account Management</CardTitle>

        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          <Form>
            <div className="space-y-5">
              <SelectInput
                name="paperipankki"
                options={[
                  {
                    label: 'Ei ole paperipankin asiakas',
                    value: 'isNotClient',
                  },
                  {
                    label: 'On paperipankin asiakas',
                    value: 'isClient',
                  },
                  {
                    label: 'Piilota paperipankki linkki',
                    value: 'hide',
                  },
                ]}
                label="Paperipankki"
              />
              <Button type="submit" loading={loading}>
                Tallenna
              </Button>
            </div>
          </Form>
        </Formik>
      </Card>
      <div className="text-gray-500 text-sm pt-8">
        <h2 className="">Current account fetures:</h2>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </div>
  );
};
