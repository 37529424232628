import { CSSProperties, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Type = 'submit' | 'reset' | 'button';

interface Props {
  type: Type;
  children: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  loading?: boolean;
  className?: string;
  size?: 'sm' | 'xs';
  style?: CSSProperties;
}

export const buttonClassNames = '';

export const Button = (props: Props) => {
  const { t } = useTranslation('Common');
  const { type, children, loading, className, onClick, style } = props;

  let text = children;

  if (loading) text = t('loading');

  const size = `text-${props.size || 'sm'}`;
  // xs: px-2.5 py-1.5
  // sm: px-3 py-2
  let padding = 'px-3 py-2';

  if (props.size === 'xs') {
    padding = 'px-2.5 py-1.5';
  }

  return (
    <button
      type={type}
      style={style}
      onClick={onClick}
      className={`w-sm ${padding} shadow-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-gray-300 ${size} ${className}`}
      disabled={loading}
    >
      {text}
    </button>
  );
};
