import { useMemo } from 'react';
import { useYup } from '../../config/use-yup.config';
import { RiskCreateFormQuery } from '../../generated/graphql';

type Fields = RiskCreateFormQuery['riskCreateForm']['fields'];

export const useSchemaFromForm = (type: 'create' | 'edit', fields: Fields) => {
  const Yup = useYup();

  return useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const shape: any = {};

    for (const field of fields) {
      if (field.type === 'text') {
        shape[field.name] = Yup.string();
      }

      if (field.type === 'select') {
        shape[field.name] = Yup.string();
      }

      if (field.type === 'multi-select') {
        shape[field.name] = Yup.array().of(
          Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
          })
        );
      }

      if (field.type === 'react-select') {
        shape[field.name] = Yup.string();
      }

      if (field.type === 'checkbox') {
        shape[field.name] = Yup.boolean();
      }

      if (field.type === 'number') {
        shape[field.name] = Yup.number();
      }

      if (field.type === 'date') {
        shape[field.name] = Yup.string();
      }

      if (field.type === 'inline-select') {
        shape[field.name] = Yup.string();
      }

      if (field.isRequired) {
        shape[field.name] = shape[field.name].required();
      }
    }

    shape['isApproveRequired'] = Yup.boolean().required();
    shape['approverId'] = Yup.string().when('isApproveRequired', ([value], schema) => {
      if (value) {
        return schema.required();
      }
      return schema.optional();
    });

    if (type === 'edit') {
      delete shape['approverId'];
    }

    return Yup.object().shape(shape);
  }, [Yup, type, fields]);
};
