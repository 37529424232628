import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const Yup = yup;

export const useYup = () => {
  const { t } = useTranslation('Validation');

  yup.setLocale({
    mixed: {
      required: t('require'),
    },
    string: {
      email: t('email'),
      min: ({ min }) => t('min', { min }),
    },
  });

  return yup;
};

export const YupTranslations = {
  fi: {
    Validation: {
      require: 'Pakollinen kenttä',
      email: 'Virheellinen sähköpostiosoite',
      min: 'Vähintään {{min}} merkkiä',
    },
  },
  en: {
    Validation: {
      require: 'Required field',
      email: 'Invalid email',
      min: 'At least {{min}} characters',
    },
  },
};
