import { ErrorMessage, useField } from 'formik';
import { List } from 'immutable';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../releox-engine/form/ReleoxBaseInputProps';
import { InputWrapper } from '../releox-engine/react-components/form-inputs/InputWrapper';
import { Label } from '../releox-engine/react-components/form-inputs/Label';

export interface ReactSelectInputProps extends ReleoxBaseInputProps {
  label: string;
}

export type ReactSelectOption = {
  label: string;
  value: string;
};

interface Props extends ReactSelectInputProps {
  options: {
    value: string;
    label: string;
  }[];
}

export const ReactSelectInput = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<Props['options'] | null>(null);
  const [defaultValue, setDefaultValue] = useState<Props['options'][0] | undefined>();
  const [field, meta, helpers] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const handleDefaultValue = useCallback(
    (list: Props['options']) => {
      setDefaultValue(list.find((o) => o.value === _.get(meta.initialValue, field.name)));
    },
    [field.name, meta.initialValue]
  );

  useEffect(() => {
    let list = List<Props['options'][0]>([]);
    props.options.forEach((opt) => {
      list = list.push({
        value: opt.value,
        label: i18n.exists(opt.label) ? t(opt.label) : opt.label,
      });
    });
    const listArray = list.toArray();
    setOptions(list.toArray());
    handleDefaultValue(listArray);
  }, [props.options, props.placeholder, t, i18n, handleDefaultValue]);

  if (!options) return <span />;

  return (
    <div key={JSON.stringify(defaultValue)}>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <Select
          className="text-sm"
          placeholder={<>{props.placeholder ? t(props.placeholder) : undefined}</>}
          classNamePrefix="select"
          defaultValue={options.find((o) => o.value === field.value)}
          isClearable
          isSearchable
          name={field.name}
          noOptionsMessage={() => t('ReactSelectInput:noOptionsMessage')}
          options={options}
          onChange={(value) => helpers.setValue(value ? value.value : '')}
        />
        <ErrorMessage component="span" className="text-red-color text-sm" name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableReactSelectInputOptions {
  type: 'react-select';
  initValue: string;
  validation: Yup.StringSchema;
  props: ReactSelectInputProps;
}

export const GeneratableReactSelectInput = {
  Element: ReactSelectInput,
  type: 'react-select',
};
