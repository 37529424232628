import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  token: Scalars['String'];
  user: UserType;
};

export type AuthStatusResponse = {
  __typename?: 'AuthStatusResponse';
  status: Scalars['String'];
};

export type AuthStatusType = {
  __typename?: 'AuthStatusType';
  status: Scalars['Boolean'];
};

export type AvailableDocumentModel = {
  __typename?: 'AvailableDocumentModel';
  id: Scalars['String'];
  type: Scalars['String'];
};

export type Bars = {
  __typename?: 'Bars';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type BuiltinResourcePrivilege = {
  __typename?: 'BuiltinResourcePrivilege';
  doc: BuiltinResourcePrivilegeEnum;
  qnc: BuiltinResourcePrivilegeEnum;
  risk: BuiltinResourcePrivilegeEnum;
};

export enum BuiltinResourcePrivilegeEnum {
  All = 'ALL',
  None = 'NONE',
  ReadOnly = 'READ_ONLY'
}

export type ChartType = {
  __typename?: 'ChartType';
  data: Array<Data>;
  dataKeys: Array<Scalars['String']>;
  reportName: Scalars['String'];
};

export type CompanyType = {
  __typename?: 'CompanyType';
  features: Array<FeatureType>;
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CompleteInvitationDto = {
  invitationToken: Scalars['String'];
  password: Scalars['String'];
};

export type ConfirmDto = {
  verificationToken: Scalars['String'];
};

export type CreateDocumentDto = {
  approverId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  costs?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  documentModelId: Scalars['String'];
  fields: Array<DocumentFieldDto>;
  groups: Array<Scalars['String']>;
  isApproveRequired: Scalars['Boolean'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
  type: Scalars['String'];
};

export type CreateDocumentLinkDto = {
  documentId: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type CreateDocumentModelDto = {
  documentModelFields: Array<UpdateDocumentModelField>;
  privilege: Scalars['String'];
  type: Scalars['String'];
};

export type CreateGroupDto = {
  name: Scalars['String'];
};

export type CreateRiskDto = {
  approverId: Scalars['String'];
  category?: InputMaybe<Scalars['String']>;
  costs?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  documentModelId: Scalars['String'];
  fields: Array<DocumentFieldDto>;
  groups: Array<Scalars['String']>;
  isApproveRequired: Scalars['Boolean'];
  name: Scalars['String'];
  riskConsequence: Scalars['Int'];
  riskProbability: Scalars['Int'];
  tags: Array<Scalars['String']>;
};

export type CreateTagDto = {
  name: Scalars['String'];
};

export type CustomReportType = {
  __typename?: 'CustomReportType';
  fieldKey: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Data = {
  __typename?: 'Data';
  bars: Array<Bars>;
  name: Scalars['String'];
};

export type DocumentApproverType = {
  __typename?: 'DocumentApproverType';
  document: DocumentType;
  id: Scalars['String'];
  status: Scalars['String'];
  user: UserType;
};

export type DocumentContentType = {
  __typename?: 'DocumentContentType';
  content: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
};

export type DocumentCreateFormType = {
  __typename?: 'DocumentCreateFormType';
  fields: Array<DocumentFormField>;
  initialValues: Scalars['String'];
  redirect: Scalars['String'];
};

export type DocumentEditFormType = {
  __typename?: 'DocumentEditFormType';
  fields: Array<DocumentFormField>;
  initialValues: Scalars['String'];
};

export type DocumentFieldDto = {
  id?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentFieldType = {
  __typename?: 'DocumentFieldType';
  id: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentFormField = {
  __typename?: 'DocumentFormField';
  isRequired: Scalars['Boolean'];
  label: Scalars['String'];
  name: Scalars['String'];
  options: Array<DocumentFormOptions>;
  type: Scalars['String'];
};

export type DocumentFormOptions = {
  __typename?: 'DocumentFormOptions';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentLinkType = {
  __typename?: 'DocumentLinkType';
  id: Scalars['String'];
  name: Scalars['String'];
  url: Scalars['String'];
};

export type DocumentList = {
  __typename?: 'DocumentList';
  list: Array<DocumentType>;
  totalCount: Scalars['Int'];
};

export type DocumentModelFieldType = {
  __typename?: 'DocumentModelFieldType';
  id: Scalars['String'];
  key: Scalars['String'];
  label: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  type: Scalars['String'];
};

export type DocumentModelIdObject = {
  __typename?: 'DocumentModelIDObject';
  anonymous?: Maybe<Scalars['String']>;
  document: Scalars['String'];
  qnc: Scalars['String'];
  risk: Scalars['String'];
};

export type DocumentModelList = {
  __typename?: 'DocumentModelList';
  list: Array<DocumentModelType>;
  totalCount: Scalars['Int'];
};

export type DocumentModelPrivilege = {
  __typename?: 'DocumentModelPrivilege';
  model?: Maybe<DocumentModelType>;
  privilege: Scalars['String'];
};

export type DocumentModelSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type DocumentModelType = {
  __typename?: 'DocumentModelType';
  documentModelFields: Array<DocumentModelFieldType>;
  id: Scalars['String'];
  privilege: Scalars['String'];
  type: Scalars['String'];
};

export type DocumentModelWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type DocumentPropertyType = {
  __typename?: 'DocumentPropertyType';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type DocumentType = {
  __typename?: 'DocumentType';
  approveDate?: Maybe<Scalars['DateTime']>;
  approverId?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  costs: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<UserType>;
  description: Scalars['String'];
  documentApprovers: Array<DocumentApproverType>;
  documentContent: DocumentContentType;
  documentFields: Array<DocumentFieldType>;
  documentIdentifier?: Maybe<Scalars['Int']>;
  documentLinks: Array<DocumentLinkType>;
  documentModel: DocumentModelType;
  documentProperties: Array<DocumentPropertyType>;
  driveFiles: Array<DriveFileType>;
  groups: Array<GroupType>;
  hasAttachment: Scalars['Boolean'];
  id: Scalars['String'];
  isApproveRequestSend: Scalars['Boolean'];
  isApproveRequired: Scalars['Boolean'];
  isApproved: Scalars['Boolean'];
  miroLink?: Maybe<Scalars['String']>;
  msLink?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** Status of document for current user. Available values: `no-action`, `approved`, and `not-approved` */
  status: Scalars['String'];
  tags: Array<TagType>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
  userStatus: Scalars['String'];
};

export type DocumentV2Sort = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type DocumentV2Where = {
  approverId?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  createdAtGte?: InputMaybe<Scalars['DateTime']>;
  createdAtLte?: InputMaybe<Scalars['DateTime']>;
  documentIdentifier?: InputMaybe<Scalars['Int']>;
  groups?: InputMaybe<StringNullableListFilterV2>;
  id?: InputMaybe<Scalars['String']>;
  includeModels?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<StringNullableListFilterV2>;
  userId?: InputMaybe<Scalars['String']>;
};

export type DriveFileType = {
  __typename?: 'DriveFileType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  storageName: Scalars['String'];
  storagePath: Scalars['String'];
  type: Scalars['String'];
};

export type FeatureType = {
  __typename?: 'FeatureType';
  id: Scalars['String'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ForgotPasswordDto = {
  email: Scalars['String'];
};

export type ForgotPasswordResponse = {
  __typename?: 'ForgotPasswordResponse';
  status: Scalars['String'];
};

export type GetFieldNameType = {
  __typename?: 'GetFieldNameType';
  key: Scalars['String'];
  label: Scalars['String'];
};

export type GroupList = {
  __typename?: 'GroupList';
  list: Array<GroupType>;
  totalCount: Scalars['Int'];
};

export type GroupSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type GroupType = {
  __typename?: 'GroupType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  users: Array<UserType>;
};

export type GroupWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InvitationTokenDto = {
  invitationToken: Scalars['String'];
};

export type InviteUserDto = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type LogType = {
  __typename?: 'LogType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  user?: Maybe<UserType>;
};

export type LoginDto = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MatrixDocs = {
  __typename?: 'MatrixDocs';
  documentId: Scalars['String'];
  documentName: Scalars['String'];
  riskIndex: Scalars['Float'];
};

export type MessageDto = {
  documentId: Scalars['String'];
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  approveDocument: DocumentApproverType;
  completeInvitation: AuthStatusType;
  confirm: AuthStatusResponse;
  createACL: ResourceAclType;
  createACLRule: ResourceAclRuleType;
  createCustomReport: CustomReportType;
  createDocument: DocumentType;
  createDocumentApprover: DocumentApproverType;
  createDocumentLink: DocumentLinkType;
  createDocumentModel: DocumentModelType;
  createGroup: GroupType;
  createMessage: LogType;
  createPublicDocument: DocumentType;
  createRisk: DocumentType;
  createRiskFromForm: DocumentType;
  createTag: TagType;
  deleteACL: ResourceAclType;
  deleteACLRule: ResourceAclRuleType;
  deleteCustomReport: CustomReportType;
  deleteDocument: DocumentType;
  deleteDocumentApprover: DocumentApproverType;
  deleteDocumentLink: DocumentLinkType;
  deleteDocumentModel: DocumentModelType;
  deleteDriveFile: DriveFileType;
  deleteGroup: GroupType;
  deleteMSLink: DocumentType;
  deleteMiroLink: DocumentType;
  deleteTag: TagType;
  deleteUser: UserType;
  forgotPassword: ForgotPasswordResponse;
  inviteUser: UserType;
  login: AuthResponse;
  register: AuthResponse;
  rejectDocument: DocumentApproverType;
  requestPaperipankkiContact: Scalars['String'];
  resetPassword: ResetPasswordResponse;
  sendAllDocumentApproveRequest: Array<Scalars['String']>;
  sendDocumentApproveRequest: DocumentApproverType;
  sendReminder?: Maybe<DocumentApproverType>;
  updateACL: ResourceAclType;
  updateCompany: CompanyType;
  updateDocument: DocumentType;
  updateDocumentContent: DocumentType;
  updateDocumentLink: DocumentLinkType;
  updateDocumentModel: DocumentModelType;
  updateDriveFile: DriveFileType;
  updateFeature: FeatureType;
  updateGroup: GroupType;
  updateMSLink: DocumentType;
  updateMiroLink: DocumentType;
  updateRiskFromForm: DocumentType;
  updateTag: TagType;
  updateTeam: UserType;
  updateUser: UserType;
};


export type MutationApproveDocumentArgs = {
  documentApproverId: Scalars['String'];
};


export type MutationCompleteInvitationArgs = {
  body: CompleteInvitationDto;
};


export type MutationConfirmArgs = {
  body: ConfirmDto;
};


export type MutationCreateAclArgs = {
  body: ResourceAcldto;
};


export type MutationCreateAclRuleArgs = {
  body: ResourceAclRuleDto;
};


export type MutationCreateCustomReportArgs = {
  fieldKey: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateDocumentArgs = {
  body: CreateDocumentDto;
};


export type MutationCreateDocumentApproverArgs = {
  id: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateDocumentLinkArgs = {
  body: CreateDocumentLinkDto;
};


export type MutationCreateDocumentModelArgs = {
  body: CreateDocumentModelDto;
};


export type MutationCreateGroupArgs = {
  body: CreateGroupDto;
};


export type MutationCreateMessageArgs = {
  body: MessageDto;
};


export type MutationCreatePublicDocumentArgs = {
  body: CreateDocumentDto;
};


export type MutationCreateRiskArgs = {
  body: CreateRiskDto;
};


export type MutationCreateRiskFromFormArgs = {
  body: Scalars['String'];
};


export type MutationCreateTagArgs = {
  body: CreateTagDto;
};


export type MutationDeleteAclArgs = {
  id: Scalars['String'];
};


export type MutationDeleteAclRuleArgs = {
  id: Scalars['String'];
};


export type MutationDeleteCustomReportArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDocumentApproverArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDocumentLinkArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDocumentModelArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDriveFileArgs = {
  id: Scalars['String'];
};


export type MutationDeleteGroupArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMsLinkArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMiroLinkArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTagArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  body: ForgotPasswordDto;
};


export type MutationInviteUserArgs = {
  body: InviteUserDto;
};


export type MutationLoginArgs = {
  body: LoginDto;
};


export type MutationRegisterArgs = {
  body: RegisterDto;
};


export type MutationRejectDocumentArgs = {
  body: RejectDocumentDto;
  documentApproverId: Scalars['String'];
};


export type MutationRequestPaperipankkiContactArgs = {
  body: PaperipankkiRequestDto;
};


export type MutationResetPasswordArgs = {
  body: ResetPasswordDto;
};


export type MutationSendAllDocumentApproveRequestArgs = {
  documentId: Scalars['String'];
};


export type MutationSendDocumentApproveRequestArgs = {
  documentApproverId: Scalars['String'];
};


export type MutationSendReminderArgs = {
  documentApproverId: Scalars['String'];
  message: Scalars['String'];
};


export type MutationUpdateAclArgs = {
  body: ResourceAcldto;
  id: Scalars['String'];
};


export type MutationUpdateCompanyArgs = {
  body: UpdateCompanyDto;
  id: Scalars['String'];
};


export type MutationUpdateDocumentArgs = {
  body: UpdateDocumentDto;
  id: Scalars['String'];
};


export type MutationUpdateDocumentContentArgs = {
  body: UpdateDocumentContentDto;
  documentContentId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateDocumentLinkArgs = {
  body: UpdateDocumentLinkDto;
  id: Scalars['String'];
};


export type MutationUpdateDocumentModelArgs = {
  body: UpdateDocumentModelDto;
  id: Scalars['String'];
};


export type MutationUpdateDriveFileArgs = {
  body: UpdateDriveFileDto;
  id: Scalars['String'];
};


export type MutationUpdateFeatureArgs = {
  key: Scalars['String'];
  value: Scalars['String'];
};


export type MutationUpdateGroupArgs = {
  body: UpdateGroupDto;
  id: Scalars['String'];
};


export type MutationUpdateMsLinkArgs = {
  body: UpdateMsLinkDto;
  id: Scalars['String'];
};


export type MutationUpdateMiroLinkArgs = {
  body: UpdateMiroLinkDto;
  id: Scalars['String'];
};


export type MutationUpdateRiskFromFormArgs = {
  body: Scalars['String'];
  id: Scalars['String'];
};


export type MutationUpdateTagArgs = {
  body: UpdateTagDto;
  id: Scalars['String'];
};


export type MutationUpdateTeamArgs = {
  body: UpdateTeamDto;
  id: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  body: UpdateUserDto;
  id: Scalars['String'];
};

export type MyCustomDocuments = {
  __typename?: 'MyCustomDocuments';
  create: Array<Scalars['String']>;
  delete: Array<Scalars['String']>;
  edit: Array<Scalars['String']>;
  read: Array<Scalars['String']>;
};

export type PaperipankkiRequestDto = {
  cta: Scalars['String'];
  section: Scalars['String'];
  source: Scalars['String'];
};

export type PendingApprovalsForCurrentUserType = {
  __typename?: 'PendingApprovalsForCurrentUserType';
  approverCount: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  identifier: Scalars['String'];
  modelName: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  userName: Scalars['String'];
};

export type QncCostsReportType = {
  __typename?: 'QNCCostsReportType';
  end: Scalars['DateTime'];
  result: Array<QncCostsReportTypeData>;
  start: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type QncCostsReportTypeData = {
  __typename?: 'QNCCostsReportTypeData';
  costs: Scalars['Float'];
  name: Scalars['String'];
};

export type QncCountReportType = {
  __typename?: 'QNCCountReportType';
  end: Scalars['DateTime'];
  result: Array<QncCountReportTypeData>;
  start: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type QncCountReportTypeData = {
  __typename?: 'QNCCountReportTypeData';
  approved: Scalars['Int'];
  count: Scalars['Int'];
  name: Scalars['String'];
};

export type QncTagReport = {
  __typename?: 'QNCTagReport';
  end: Scalars['DateTime'];
  result: Array<QncTagReportData>;
  start: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type QncTagReportData = {
  __typename?: 'QNCTagReportData';
  data?: Maybe<Array<QncTagReportDataField>>;
  name: Scalars['String'];
};

export type QncTagReportDataField = {
  __typename?: 'QNCTagReportDataField';
  count: Scalars['Float'];
  name: Scalars['String'];
};

export type QncTypeReport = {
  __typename?: 'QNCTypeReport';
  end: Scalars['DateTime'];
  result: Array<QncTypeReportData>;
  start: Scalars['DateTime'];
  total: Scalars['Int'];
};

export type QncTypeReportData = {
  __typename?: 'QNCTypeReportData';
  data: QncTypeReportDataField;
  name: Scalars['String'];
};

export type QncTypeReportDataField = {
  __typename?: 'QNCTypeReportDataField';
  customerReclamation?: Maybe<Scalars['Float']>;
  environmentObservation?: Maybe<Scalars['Float']>;
  externalAudit?: Maybe<Scalars['Float']>;
  initiative?: Maybe<Scalars['Float']>;
  internalAudit?: Maybe<Scalars['Float']>;
  internalNonConformity?: Maybe<Scalars['Float']>;
  other?: Maybe<Scalars['Float']>;
  safetyAccident?: Maybe<Scalars['Float']>;
  safetyObservation?: Maybe<Scalars['Float']>;
  supplierReclamation?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  accountFeatures: Array<FeatureType>;
  acl: ResourceAclType;
  acls: ResourceAclList;
  /** Get all document approvers */
  allDocumentApprovers: Array<UserType>;
  /** Get available document approvers for a document. If approver is already assigned, it will not be returned */
  availableDocumentApprovers: Array<UserType>;
  availableDocumentModels: Array<AvailableDocumentModel>;
  builtinResourceACLs: BuiltinResourcePrivilege;
  company: CompanyType;
  customDocumentModels: Array<DocumentModelType>;
  document: DocumentType;
  documentCount: Scalars['Int'];
  documentLink: DocumentLinkType;
  documentModelById: DocumentModelType;
  documentModelByType: DocumentModelType;
  documentModelIdObject: DocumentModelIdObject;
  documentModelPrivilege: DocumentModelPrivilege;
  documentModels: DocumentModelList;
  documentsV2: DocumentList;
  driveFile: DriveFileType;
  getCustomReport: ChartType;
  getCustomReports: Array<CustomReportType>;
  getFieldNames: Array<GetFieldNameType>;
  getModelTypeNames: Array<Scalars['String']>;
  getPendingApprovalsForCurrentUser: Array<PendingApprovalsForCurrentUserType>;
  group: GroupType;
  groups: GroupList;
  logs: Array<LogType>;
  myGroups: GroupList;
  qncCategoryReport: QncTypeReport;
  qncCostsReport: QncCostsReportType;
  qncCountReport: QncCountReportType;
  qncTagReport: QncTagReport;
  reportRiskMatrix: ReportRiskMatrixType;
  riskCreateForm: DocumentCreateFormType;
  riskEditForm: DocumentEditFormType;
  serverTime: Scalars['String'];
  tag: TagType;
  tags: TagList;
  user: UserType;
  users: UserList;
  validateInvitationToken: AuthStatusType;
  validateResetToken: AuthStatusType;
  viewPrivilege: ViewPrivilegeType;
  viewPrivilegeV2: Array<ViewPrivilegeTypeV2>;
  whoAmI: UserType;
};


export type QueryAclArgs = {
  id: Scalars['String'];
};


export type QueryAclsArgs = {
  orderBy?: InputMaybe<Array<ResourceAClSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResourceAClWhere>;
};


export type QueryAvailableDocumentApproversArgs = {
  documentId: Scalars['String'];
};


export type QueryCompanyArgs = {
  id: Scalars['String'];
};


export type QueryCustomDocumentModelsArgs = {
  modelIds: Array<Scalars['String']>;
};


export type QueryDocumentArgs = {
  id: Scalars['String'];
};


export type QueryDocumentLinkArgs = {
  id: Scalars['String'];
};


export type QueryDocumentModelByIdArgs = {
  id: Scalars['String'];
};


export type QueryDocumentModelByTypeArgs = {
  type: Scalars['String'];
};


export type QueryDocumentModelPrivilegeArgs = {
  id: Scalars['String'];
};


export type QueryDocumentModelsArgs = {
  orderBy?: InputMaybe<Array<DocumentModelSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentModelWhere>;
};


export type QueryDocumentsV2Args = {
  orderBy?: InputMaybe<Array<DocumentV2Sort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentV2Where>;
};


export type QueryDriveFileArgs = {
  id: Scalars['String'];
};


export type QueryGetCustomReportArgs = {
  id: Scalars['String'];
};


export type QueryGetFieldNamesArgs = {
  type: Scalars['String'];
};


export type QueryGroupArgs = {
  id: Scalars['String'];
};


export type QueryGroupsArgs = {
  orderBy?: InputMaybe<Array<GroupSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhere>;
};


export type QueryLogsArgs = {
  documentId: Scalars['String'];
};


export type QueryMyGroupsArgs = {
  orderBy?: InputMaybe<Array<GroupSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhere>;
};


export type QueryQncCategoryReportArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type QueryQncCostsReportArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type QueryQncCountReportArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type QueryQncTagReportArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
};


export type QueryRiskCreateFormArgs = {
  id?: InputMaybe<Scalars['String']>;
};


export type QueryRiskEditFormArgs = {
  id: Scalars['String'];
};


export type QueryTagArgs = {
  id: Scalars['String'];
};


export type QueryTagsArgs = {
  orderBy?: InputMaybe<Array<TagSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagWhere>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  orderBy?: InputMaybe<Array<UserSort>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhere>;
};


export type QueryValidateInvitationTokenArgs = {
  body: InvitationTokenDto;
};


export type QueryValidateResetTokenArgs = {
  body: ValidateResetTokenDto;
};

export type RegisterDto = {
  company: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};

export type RejectDocumentDto = {
  reason: Scalars['String'];
};

export type ReportRiskMatrixType = {
  __typename?: 'ReportRiskMatrixType';
  documents: Array<MatrixDocs>;
  report: Scalars['String'];
};

export type ResetPasswordDto = {
  password: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  status: Scalars['String'];
};

export type ResourceAcldto = {
  name: Scalars['String'];
};

export type ResourceAclList = {
  __typename?: 'ResourceACLList';
  list: Array<ResourceAclType>;
  totalCount: Scalars['Int'];
};

export type ResourceAclRuleDto = {
  documentModelId: Scalars['String'];
  permissions: Array<Scalars['String']>;
  resourceACLId: Scalars['String'];
};

export type ResourceAclRuleType = {
  __typename?: 'ResourceACLRuleType';
  createdAt: Scalars['DateTime'];
  documentModel: DocumentModelType;
  id: Scalars['String'];
  name: Scalars['String'];
  permissions: Array<Scalars['String']>;
};

export type ResourceAclType = {
  __typename?: 'ResourceACLType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
  rules: Array<ResourceAclRuleType>;
  userCount: Scalars['Int'];
  users: Array<UserType>;
};

export type ResourceAClSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type ResourceAClWhere = {
  name?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilterV2 = {
  hasSome?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TagList = {
  __typename?: 'TagList';
  list: Array<TagType>;
  totalCount: Scalars['Int'];
};

export type TagSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type TagType = {
  __typename?: 'TagType';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TagWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCompanyDto = {
  name: Scalars['String'];
};

export type UpdateDocumentContentDto = {
  content: Scalars['String'];
};

export type UpdateDocumentDto = {
  category: Scalars['String'];
  costs?: InputMaybe<Scalars['Float']>;
  description: Scalars['String'];
  fields: Array<DocumentFieldDto>;
  groups: Array<Scalars['String']>;
  isApproveRequired: Scalars['Boolean'];
  name: Scalars['String'];
  tags: Array<Scalars['String']>;
};

export type UpdateDocumentLinkDto = {
  name: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateDocumentModelDto = {
  documentModelFields: Array<UpdateDocumentModelField>;
  privilege: Scalars['String'];
};

export type UpdateDocumentModelField = {
  id?: InputMaybe<Scalars['String']>;
  label: Scalars['String'];
  options: Array<Scalars['String']>;
  position: Scalars['Int'];
  type: Scalars['String'];
};

export type UpdateDriveFileDto = {
  name: Scalars['String'];
};

export type UpdateGroupDto = {
  name: Scalars['String'];
};

export type UpdateMsLinkDto = {
  msLink: Scalars['String'];
};

export type UpdateMiroLinkDto = {
  miroLink: Scalars['String'];
};

export type UpdateTagDto = {
  name: Scalars['String'];
};

export type UpdateTeamDto = {
  defaultApproverId?: InputMaybe<Scalars['String']>;
  defaultGroups: Array<Scalars['String']>;
  groups: Array<Scalars['String']>;
  isAnonymousHandler: Scalars['Boolean'];
  isApprover: Scalars['Boolean'];
  isCompanyAdmin: Scalars['Boolean'];
  name: Scalars['String'];
  resourceACLId?: InputMaybe<Scalars['String']>;
  wantWeeklyNotification: Scalars['Boolean'];
};

export type UpdateUserDto = {
  email: Scalars['String'];
  language: Scalars['String'];
  name: Scalars['String'];
  wantWeeklyNotification: Scalars['Boolean'];
};

export type UserList = {
  __typename?: 'UserList';
  list: Array<UserType>;
  totalCount: Scalars['Int'];
};

export type UserSort = {
  createdAt?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  acl?: Maybe<ResourceAclType>;
  company: CompanyType;
  createdAt: Scalars['DateTime'];
  defaultApproverId?: Maybe<Scalars['String']>;
  defaultGroups: Array<GroupType>;
  email: Scalars['String'];
  groups: Array<GroupType>;
  id: Scalars['String'];
  isAnonymousHandler: Scalars['Boolean'];
  isApprover: Scalars['Boolean'];
  isCompanyAdmin: Scalars['Boolean'];
  isCompanyOwner: Scalars['Boolean'];
  language: Scalars['String'];
  myCustomDocuments: MyCustomDocuments;
  name: Scalars['String'];
  resourceACLId?: Maybe<Scalars['String']>;
  wantWeeklyNotification: Scalars['Boolean'];
};

export type UserWhere = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isApprover?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  resourceACLId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ValidateResetTokenDto = {
  resetPasswordToken: Scalars['String'];
};

export type ViewPrivilegeSubType = {
  __typename?: 'ViewPrivilegeSubType';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  modelId: Scalars['String'];
  read: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type ViewPrivilegeType = {
  __typename?: 'ViewPrivilegeType';
  anonymous: ViewPrivilegeSubType;
  document: ViewPrivilegeSubType;
  qnc: ViewPrivilegeSubType;
  risk: ViewPrivilegeSubType;
};

export type ViewPrivilegeTypeV2 = {
  __typename?: 'ViewPrivilegeTypeV2';
  create: Scalars['Boolean'];
  delete: Scalars['Boolean'];
  isBuiltInModel: Scalars['Boolean'];
  modelId: Scalars['String'];
  name: Scalars['String'];
  read: Scalars['Boolean'];
  update: Scalars['Boolean'];
};

export type AclListQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<ResourceAClSort> | ResourceAClSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ResourceAClWhere>;
}>;


export type AclListQuery = { __typename?: 'Query', acls: { __typename?: 'ResourceACLList', totalCount: number, list: Array<{ __typename?: 'ResourceACLType', id: string, name: string, userCount: number, createdAt: any }> } };

export type AclQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type AclQuery = { __typename?: 'Query', acl: { __typename?: 'ResourceACLType', id: string, name: string, rules: Array<{ __typename?: 'ResourceACLRuleType', id: string, permissions: Array<string>, documentModel: { __typename?: 'DocumentModelType', id: string, type: string } }>, users: Array<{ __typename?: 'UserType', id: string, name: string }> } };

export type CreateDocumentApproverMutationVariables = Exact<{
  id: Scalars['String'];
  userId: Scalars['String'];
}>;


export type CreateDocumentApproverMutation = { __typename?: 'Mutation', createDocumentApprover: { __typename?: 'DocumentApproverType', id: string } };

export type AddApproverSceneQueryVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type AddApproverSceneQuery = { __typename?: 'Query', availableDocumentApprovers: Array<{ __typename?: 'UserType', id: string, name: string }> };

export type ApproveDocumentMutationVariables = Exact<{
  documentApproverId: Scalars['String'];
}>;


export type ApproveDocumentMutation = { __typename?: 'Mutation', approveDocument: { __typename?: 'DocumentApproverType', id: string } };

export type CompanyEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CompanyEditSceneQuery = { __typename?: 'Query', company: { __typename?: 'CompanyType', name: string } };

export type CompleteInvitationMutationVariables = Exact<{
  body: CompleteInvitationDto;
}>;


export type CompleteInvitationMutation = { __typename?: 'Mutation', completeInvitation: { __typename?: 'AuthStatusType', status: boolean } };

export type ConfirmMutationVariables = Exact<{
  body: ConfirmDto;
}>;


export type ConfirmMutation = { __typename?: 'Mutation', confirm: { __typename?: 'AuthStatusResponse', status: string } };

export type CreateAclMutationVariables = Exact<{
  body: ResourceAcldto;
}>;


export type CreateAclMutation = { __typename?: 'Mutation', createACL: { __typename?: 'ResourceACLType', id: string } };

export type CreateAclRuleMutationVariables = Exact<{
  body: ResourceAclRuleDto;
}>;


export type CreateAclRuleMutation = { __typename?: 'Mutation', createACLRule: { __typename?: 'ResourceACLRuleType', id: string } };

export type CreateDocumentMutationVariables = Exact<{
  body: CreateDocumentDto;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument: { __typename?: 'DocumentType', id: string } };

export type CreateDocumentLinkMutationVariables = Exact<{
  body: CreateDocumentLinkDto;
}>;


export type CreateDocumentLinkMutation = { __typename?: 'Mutation', createDocumentLink: { __typename?: 'DocumentLinkType', id: string } };

export type CreateDocumentModelMutationVariables = Exact<{
  body: CreateDocumentModelDto;
}>;


export type CreateDocumentModelMutation = { __typename?: 'Mutation', createDocumentModel: { __typename?: 'DocumentModelType', id: string } };

export type CreateGroupMutationVariables = Exact<{
  body: CreateGroupDto;
}>;


export type CreateGroupMutation = { __typename?: 'Mutation', createGroup: { __typename?: 'GroupType', id: string } };

export type CreateMessageMutationVariables = Exact<{
  body: MessageDto;
}>;


export type CreateMessageMutation = { __typename?: 'Mutation', createMessage: { __typename?: 'LogType', id: string } };

export type CreateTagMutationVariables = Exact<{
  body: CreateTagDto;
}>;


export type CreateTagMutation = { __typename?: 'Mutation', createTag: { __typename?: 'TagType', id: string } };

export type CustomDocumentCreateSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomDocumentCreateSceneQuery = { __typename?: 'Query', documentModelById: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, label: string, options: Array<string>, type: string }> }, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }>, myGroups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', id: string, name: string }> }, tags: { __typename?: 'TagList', list: Array<{ __typename?: 'TagType', id: string, name: string }> } };

export type CustomDocumentEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomDocumentEditSceneQuery = { __typename?: 'Query', document: { __typename?: 'DocumentType', name: string, isApproveRequired: boolean, costs: number, description: string, groups: Array<{ __typename?: 'GroupType', id: string, name: string }>, tags: Array<{ __typename?: 'TagType', id: string, name: string }>, documentFields: Array<{ __typename?: 'DocumentFieldType', id: string, value: string, key: string }>, documentModel: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, label: string, options: Array<string>, type: string }> } }, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }>, myGroups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', id: string, name: string }> }, tags: { __typename?: 'TagList', list: Array<{ __typename?: 'TagType', id: string, name: string }> } };

export type CustomDocumentModelsQueryVariables = Exact<{
  modelIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type CustomDocumentModelsQuery = { __typename?: 'Query', customDocumentModels: Array<{ __typename?: 'DocumentModelType', id: string, type: string }> };

export type GetModelTypeNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetModelTypeNamesQuery = { __typename?: 'Query', getModelTypeNames: Array<string> };

export type GetFieldNamesQueryVariables = Exact<{
  modelTypeName: Scalars['String'];
}>;


export type GetFieldNamesQuery = { __typename?: 'Query', getFieldNames: Array<{ __typename?: 'GetFieldNameType', key: string, label: string }> };

export type CreateCustomReportMutationVariables = Exact<{
  name: Scalars['String'];
  fieldKey: Scalars['String'];
}>;


export type CreateCustomReportMutation = { __typename?: 'Mutation', createCustomReport: { __typename?: 'CustomReportType', id: string } };

export type CustomReportShowQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomReportShowQuery = { __typename?: 'Query', getCustomReport: { __typename?: 'ChartType', dataKeys: Array<string>, reportName: string, data: Array<{ __typename?: 'Data', name: string, bars: Array<{ __typename?: 'Bars', value: string, label: string }> }> } };

export type DeleteCustomReportMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCustomReportMutation = { __typename?: 'Mutation', deleteCustomReport: { __typename?: 'CustomReportType', id: string } };

export type DataValidationSceneQueryVariables = Exact<{
  qncModelId: Scalars['String'];
  riskModelId: Scalars['String'];
  documentModelId: Scalars['String'];
}>;


export type DataValidationSceneQuery = { __typename?: 'Query', qncs: { __typename?: 'DocumentList', totalCount: number }, risks: { __typename?: 'DocumentList', totalCount: number }, documentsV2: { __typename?: 'DocumentList', totalCount: number }, users: { __typename?: 'UserList', totalCount: number }, tags: { __typename?: 'TagList', totalCount: number }, groups: { __typename?: 'GroupList', totalCount: number } };

export type DeleteAclMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAclMutation = { __typename?: 'Mutation', deleteACL: { __typename?: 'ResourceACLType', id: string } };

export type DeleteAclRuleMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteAclRuleMutation = { __typename?: 'Mutation', deleteACLRule: { __typename?: 'ResourceACLRuleType', id: string } };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: { __typename?: 'DocumentType', id: string } };

export type DeleteDocumentApproverMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDocumentApproverMutation = { __typename?: 'Mutation', deleteDocumentApprover: { __typename?: 'DocumentApproverType', id: string } };

export type DeleteDocumentLinkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDocumentLinkMutation = { __typename?: 'Mutation', deleteDocumentLink: { __typename?: 'DocumentLinkType', id: string } };

export type DeleteDocumentModelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDocumentModelMutation = { __typename?: 'Mutation', deleteDocumentModel: { __typename?: 'DocumentModelType', id: string } };

export type DeleteDriveFileMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDriveFileMutation = { __typename?: 'Mutation', deleteDriveFile: { __typename?: 'DriveFileType', id: string } };

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteGroupMutation = { __typename?: 'Mutation', deleteGroup: { __typename?: 'GroupType', id: string } };

export type DeleteMsLinkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMsLinkMutation = { __typename?: 'Mutation', deleteMSLink: { __typename?: 'DocumentType', id: string } };

export type DeleteMiroLinkMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteMiroLinkMutation = { __typename?: 'Mutation', deleteMiroLink: { __typename?: 'DocumentType', id: string } };

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteTagMutation = { __typename?: 'Mutation', deleteTag: { __typename?: 'TagType', id: string } };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'UserType', id: string } };

export type DocumentCreateSceneQueryVariables = Exact<{
  type: Scalars['String'];
}>;


export type DocumentCreateSceneQuery = { __typename?: 'Query', documentModelByType: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, label: string, options: Array<string>, type: string }> }, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }>, myGroups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', id: string, name: string }> }, tags: { __typename?: 'TagList', list: Array<{ __typename?: 'TagType', id: string, name: string }> } };

export type DocumentEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
  type: Scalars['String'];
}>;


export type DocumentEditSceneQuery = { __typename?: 'Query', documentModelByType: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, label: string, options: Array<string>, type: string }> }, document: { __typename?: 'DocumentType', name: string, isApproveRequired: boolean, costs: number, category: string, description: string, groups: Array<{ __typename?: 'GroupType', id: string, name: string }>, tags: Array<{ __typename?: 'TagType', id: string, name: string }>, documentFields: Array<{ __typename?: 'DocumentFieldType', id: string, value: string, key: string }> }, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }>, myGroups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', id: string, name: string }> }, tags: { __typename?: 'TagList', list: Array<{ __typename?: 'TagType', id: string, name: string }> } };

export type DocumentIndexMetaQueryVariables = Exact<{
  modelIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type DocumentIndexMetaQuery = { __typename?: 'Query', tags: { __typename?: 'TagList', list: Array<{ __typename?: 'TagType', id: string, name: string }> }, groups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', id: string, name: string }> }, customDocumentModels: Array<{ __typename?: 'DocumentModelType', id: string, type: string }>, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }>, users: { __typename?: 'UserList', list: Array<{ __typename?: 'UserType', id: string, name: string }> } };

export type DocumentIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DocumentV2Sort> | DocumentV2Sort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where: DocumentV2Where;
}>;


export type DocumentIndexSceneQuery = { __typename?: 'Query', documentsV2: { __typename?: 'DocumentList', totalCount: number, list: Array<{ __typename?: 'DocumentType', createdAt: any, type: string, documentIdentifier?: number | null, category: string, hasAttachment: boolean, id: string, costs: number, isApproveRequestSend: boolean, isApproveRequired: boolean, isApproved: boolean, name: string, groups: Array<{ __typename?: 'GroupType', name: string }>, documentProperties: Array<{ __typename?: 'DocumentPropertyType', label: string, value: string }> }> } };

export type DocumentLinkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocumentLinkQuery = { __typename?: 'Query', documentLink: { __typename?: 'DocumentLinkType', name: string, url: string } };

export type DocumentModelEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocumentModelEditSceneQuery = { __typename?: 'Query', documentModelById: { __typename?: 'DocumentModelType', type: string, privilege: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', id: string, label: string, options: Array<string>, position: number, type: string }> } };

export type DocumentModelIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<DocumentModelSort> | DocumentModelSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<DocumentModelWhere>;
}>;


export type DocumentModelIndexSceneQuery = { __typename?: 'Query', documentModels: { __typename?: 'DocumentModelList', totalCount: number, list: Array<{ __typename?: 'DocumentModelType', id: string, type: string, privilege: string }> } };

export type DocumentModelOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentModelOptionsQuery = { __typename?: 'Query', documentModels: { __typename?: 'DocumentModelList', list: Array<{ __typename?: 'DocumentModelType', id: string, type: string }> } };

export type DocumentModelPublicityQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocumentModelPublicityQuery = { __typename?: 'Query', documentModelPrivilege: { __typename?: 'DocumentModelPrivilege', privilege: string, model?: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, label: string, options: Array<string>, type: string }> } | null } };

export type DocumentShowSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DocumentShowSceneQuery = { __typename?: 'Query', logs: Array<{ __typename?: 'LogType', createdAt: any, type: string, id: string, message?: string | null, user?: { __typename?: 'UserType', id: string, name: string } | null }>, document: { __typename?: 'DocumentType', id: string, type: string, name: string, documentIdentifier?: number | null, costs: number, category: string, isApproved: boolean, isApproveRequired: boolean, isApproveRequestSend: boolean, createdAt: any, miroLink?: string | null, msLink?: string | null, approverId?: string | null, userId?: string | null, description: string, userStatus: string, documentContent: { __typename?: 'DocumentContentType', content: string }, tags: Array<{ __typename?: 'TagType', name: string }>, createdBy?: { __typename?: 'UserType', name: string } | null, documentApprovers: Array<{ __typename?: 'DocumentApproverType', id: string, status: string, user: { __typename?: 'UserType', id: string, name: string } }>, groups: Array<{ __typename?: 'GroupType', name: string }>, documentModel: { __typename?: 'DocumentModelType', id: string, type: string, documentModelFields: Array<{ __typename?: 'DocumentModelFieldType', key: string, id: string, label: string, type: string, options: Array<string> }> }, documentFields: Array<{ __typename?: 'DocumentFieldType', key: string, id: string, value: string }>, driveFiles: Array<{ __typename?: 'DriveFileType', id: string, mimeType: string, storagePath: string, name: string, createdAt: any }>, documentLinks: Array<{ __typename?: 'DocumentLinkType', id: string, name: string, url: string }>, documentProperties: Array<{ __typename?: 'DocumentPropertyType', label: string, value: string }> } };

export type DriveFileEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type DriveFileEditSceneQuery = { __typename?: 'Query', driveFile: { __typename?: 'DriveFileType', name: string } };

export type EditAclSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EditAclSceneQuery = { __typename?: 'Query', acl: { __typename?: 'ResourceACLType', name: string } };

export type EditorQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EditorQuery = { __typename?: 'Query', document: { __typename?: 'DocumentType', documentContent: { __typename?: 'DocumentContentType', id: string, content: string } } };

export type ExportSceneQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportSceneQuery = { __typename?: 'Query', documentCount: number };

export type ForgotPasswordMutationVariables = Exact<{
  body: ForgotPasswordDto;
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: { __typename?: 'ForgotPasswordResponse', status: string } };

export type GroupEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GroupEditSceneQuery = { __typename?: 'Query', group: { __typename?: 'GroupType', name: string } };

export type GroupIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<GroupSort> | GroupSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<GroupWhere>;
}>;


export type GroupIndexSceneQuery = { __typename?: 'Query', groups: { __typename?: 'GroupList', totalCount: number, list: Array<{ __typename?: 'GroupType', id: string, createdAt: any, name: string }> } };

export type GroupShowSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GroupShowSceneQuery = { __typename?: 'Query', group: { __typename?: 'GroupType', name: string, users: Array<{ __typename?: 'UserType', id: string, name: string }> } };

export type HomeSceneQncCountQueryVariables = Exact<{
  qncModelId: Scalars['String'];
}>;


export type HomeSceneQncCountQuery = { __typename?: 'Query', qnc: { __typename?: 'DocumentList', totalCount: number } };

export type HomeSceneDocCountQueryVariables = Exact<{
  documentModelId: Scalars['String'];
}>;


export type HomeSceneDocCountQuery = { __typename?: 'Query', document: { __typename?: 'DocumentList', totalCount: number } };

export type HomeSceneRiskCountQueryVariables = Exact<{
  riskModelId: Scalars['String'];
}>;


export type HomeSceneRiskCountQuery = { __typename?: 'Query', risk: { __typename?: 'DocumentList', totalCount: number } };

export type HomeSceneCustomCountQueryVariables = Exact<{
  where: DocumentV2Where;
}>;


export type HomeSceneCustomCountQuery = { __typename?: 'Query', documentsV2: { __typename?: 'DocumentList', totalCount: number } };

export type HomeSceneReportQueryVariables = Exact<{
  end: Scalars['String'];
  start: Scalars['String'];
}>;


export type HomeSceneReportQuery = { __typename?: 'Query', qncCostsReport: { __typename?: 'QNCCostsReportType', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCCostsReportTypeData', name: string, costs: number }> }, qncCountReport: { __typename?: 'QNCCountReportType', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCCountReportTypeData', count: number, name: string, approved: number }> }, qncCategoryReport: { __typename?: 'QNCTypeReport', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCTypeReportData', name: string, data: { __typename?: 'QNCTypeReportDataField', internalNonConformity?: number | null, customerReclamation?: number | null, supplierReclamation?: number | null, initiative?: number | null, safetyAccident?: number | null, safetyObservation?: number | null, environmentObservation?: number | null, internalAudit?: number | null, externalAudit?: number | null, other?: number | null } }> } };

export type InviteUserMutationVariables = Exact<{
  body: InviteUserDto;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'UserType', id: string } };

export type LoginMutationVariables = Exact<{
  body: LoginDto;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string } };

export type MsLinkSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MsLinkSceneQuery = { __typename?: 'Query', document: { __typename?: 'DocumentType', msLink?: string | null } };

export type MiroLinkQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type MiroLinkQuery = { __typename?: 'Query', document: { __typename?: 'DocumentType', miroLink?: string | null } };

export type PublicDocumentCreateMutationVariables = Exact<{
  body: CreateDocumentDto;
}>;


export type PublicDocumentCreateMutation = { __typename?: 'Mutation', createPublicDocument: { __typename?: 'DocumentType', id: string } };

export type RegisterMutationVariables = Exact<{
  body: RegisterDto;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResponse', token: string } };

export type RejectDocumentMutationVariables = Exact<{
  documentApproverId: Scalars['String'];
  body: RejectDocumentDto;
}>;


export type RejectDocumentMutation = { __typename?: 'Mutation', rejectDocument: { __typename?: 'DocumentApproverType', id: string } };

export type GetCustomReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCustomReportsQuery = { __typename?: 'Query', getCustomReports: Array<{ __typename?: 'CustomReportType', id: string, name: string }> };

export type ReportQncCostSceneQueryVariables = Exact<{
  end: Scalars['String'];
  start: Scalars['String'];
}>;


export type ReportQncCostSceneQuery = { __typename?: 'Query', qncCostsReport: { __typename?: 'QNCCostsReportType', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCCostsReportTypeData', name: string, costs: number }> } };

export type ReportQncCountSceneQueryVariables = Exact<{
  end: Scalars['String'];
  start: Scalars['String'];
}>;


export type ReportQncCountSceneQuery = { __typename?: 'Query', qncCountReport: { __typename?: 'QNCCountReportType', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCCountReportTypeData', count: number, name: string, approved: number }> } };

export type ReportQncTagSceneQueryVariables = Exact<{
  end: Scalars['String'];
  start: Scalars['String'];
}>;


export type ReportQncTagSceneQuery = { __typename?: 'Query', qncTagReport: { __typename?: 'QNCTagReport', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCTagReportData', name: string, data?: Array<{ __typename?: 'QNCTagReportDataField', name: string, count: number }> | null }> } };

export type ReportQncTypeSceneQueryVariables = Exact<{
  end: Scalars['String'];
  start: Scalars['String'];
}>;


export type ReportQncTypeSceneQuery = { __typename?: 'Query', qncCategoryReport: { __typename?: 'QNCTypeReport', total: number, start: any, end: any, result: Array<{ __typename?: 'QNCTypeReportData', name: string, data: { __typename?: 'QNCTypeReportDataField', internalNonConformity?: number | null, customerReclamation?: number | null, supplierReclamation?: number | null, initiative?: number | null, safetyAccident?: number | null, safetyObservation?: number | null, environmentObservation?: number | null, internalAudit?: number | null, externalAudit?: number | null, other?: number | null } }> } };

export type ResetPasswordMutationVariables = Exact<{
  body: ResetPasswordDto;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'ResetPasswordResponse', status: string } };

export type SendAllDocumentApproveRequestMutationVariables = Exact<{
  documentId: Scalars['String'];
}>;


export type SendAllDocumentApproveRequestMutation = { __typename?: 'Mutation', sendAllDocumentApproveRequest: Array<string> };

export type SendDocumentApproveRequestMutationVariables = Exact<{
  documentApproverId: Scalars['String'];
}>;


export type SendDocumentApproveRequestMutation = { __typename?: 'Mutation', sendDocumentApproveRequest: { __typename?: 'DocumentApproverType', id: string } };

export type SendReminderMutationVariables = Exact<{
  documentApproverId: Scalars['String'];
  message: Scalars['String'];
}>;


export type SendReminderMutation = { __typename?: 'Mutation', sendReminder?: { __typename?: 'DocumentApproverType', id: string } | null };

export type TagEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TagEditSceneQuery = { __typename?: 'Query', tag: { __typename?: 'TagType', name: string } };

export type TagIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<TagSort> | TagSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TagWhere>;
}>;


export type TagIndexSceneQuery = { __typename?: 'Query', tags: { __typename?: 'TagList', totalCount: number, list: Array<{ __typename?: 'TagType', id: string, name: string, createdAt: any }> } };

export type TeamEditSceneQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type TeamEditSceneQuery = { __typename?: 'Query', user: { __typename?: 'UserType', resourceACLId?: string | null, defaultApproverId?: string | null, isApprover: boolean, isCompanyAdmin: boolean, isAnonymousHandler: boolean, isCompanyOwner: boolean, wantWeeklyNotification: boolean, name: string, groups: Array<{ __typename?: 'GroupType', id: string, name: string }>, defaultGroups: Array<{ __typename?: 'GroupType', id: string, name: string }> }, acls: { __typename?: 'ResourceACLList', list: Array<{ __typename?: 'ResourceACLType', value: string, label: string }> }, groups: { __typename?: 'GroupList', list: Array<{ __typename?: 'GroupType', value: string, label: string }> }, allDocumentApprovers: Array<{ __typename?: 'UserType', value: string, label: string }> };

export type UpdateAclMutationVariables = Exact<{
  id: Scalars['String'];
  body: ResourceAcldto;
}>;


export type UpdateAclMutation = { __typename?: 'Mutation', updateACL: { __typename?: 'ResourceACLType', id: string } };

export type UpdateCompanyMutationVariables = Exact<{
  body: UpdateCompanyDto;
  id: Scalars['String'];
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', updateCompany: { __typename?: 'CompanyType', id: string } };

export type UpdateDocumentMutationVariables = Exact<{
  body: UpdateDocumentDto;
  id: Scalars['String'];
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'DocumentType', id: string } };

export type UpdateDocumentContentMutationVariables = Exact<{
  body: UpdateDocumentContentDto;
  documentContentId: Scalars['String'];
  id: Scalars['String'];
}>;


export type UpdateDocumentContentMutation = { __typename?: 'Mutation', updateDocumentContent: { __typename?: 'DocumentType', id: string } };

export type UpdateDocumentLinkMutationVariables = Exact<{
  id: Scalars['String'];
  body: UpdateDocumentLinkDto;
}>;


export type UpdateDocumentLinkMutation = { __typename?: 'Mutation', updateDocumentLink: { __typename?: 'DocumentLinkType', id: string } };

export type UpdateDocumentModelMutationVariables = Exact<{
  body: UpdateDocumentModelDto;
  id: Scalars['String'];
}>;


export type UpdateDocumentModelMutation = { __typename?: 'Mutation', updateDocumentModel: { __typename?: 'DocumentModelType', id: string } };

export type UpdateDriveFileMutationVariables = Exact<{
  body: UpdateDriveFileDto;
  id: Scalars['String'];
}>;


export type UpdateDriveFileMutation = { __typename?: 'Mutation', updateDriveFile: { __typename?: 'DriveFileType', id: string } };

export type UpdateGroupMutationVariables = Exact<{
  body: UpdateGroupDto;
  id: Scalars['String'];
}>;


export type UpdateGroupMutation = { __typename?: 'Mutation', updateGroup: { __typename?: 'GroupType', id: string } };

export type UpdateMsLinkMutationVariables = Exact<{
  body: UpdateMsLinkDto;
  id: Scalars['String'];
}>;


export type UpdateMsLinkMutation = { __typename?: 'Mutation', updateMSLink: { __typename?: 'DocumentType', id: string } };

export type UpdateMiroLinkMutationVariables = Exact<{
  body: UpdateMiroLinkDto;
  id: Scalars['String'];
}>;


export type UpdateMiroLinkMutation = { __typename?: 'Mutation', updateMiroLink: { __typename?: 'DocumentType', id: string } };

export type UpdateTagMutationVariables = Exact<{
  body: UpdateTagDto;
  id: Scalars['String'];
}>;


export type UpdateTagMutation = { __typename?: 'Mutation', updateTag: { __typename?: 'TagType', id: string } };

export type UpdateTeamMutationVariables = Exact<{
  body: UpdateTeamDto;
  id: Scalars['String'];
}>;


export type UpdateTeamMutation = { __typename?: 'Mutation', updateTeam: { __typename?: 'UserType', id: string } };

export type UpdateUserMutationVariables = Exact<{
  body: UpdateUserDto;
  id: Scalars['String'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserType', id: string } };

export type UserEditWhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEditWhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', email: string, name: string, wantWeeklyNotification: boolean, language: string } };

export type UserIndexSceneQueryVariables = Exact<{
  orderBy?: InputMaybe<Array<UserSort> | UserSort>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhere>;
}>;


export type UserIndexSceneQuery = { __typename?: 'Query', users: { __typename?: 'UserList', totalCount: number, list: Array<{ __typename?: 'UserType', email: string, id: string, createdAt: any, isApprover: boolean, name: string, acl?: { __typename?: 'ResourceACLType', name: string } | null, groups: Array<{ __typename?: 'GroupType', name: string }> }> } };

export type ValidateInvitationTokenQueryVariables = Exact<{
  body: InvitationTokenDto;
}>;


export type ValidateInvitationTokenQuery = { __typename?: 'Query', validateInvitationToken: { __typename?: 'AuthStatusType', status: boolean } };

export type ValidateResetTokenQueryVariables = Exact<{
  body: ValidateResetTokenDto;
}>;


export type ValidateResetTokenQuery = { __typename?: 'Query', validateResetToken: { __typename?: 'AuthStatusType', status: boolean } };

export type ViewPrivilegeQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewPrivilegeQuery = { __typename?: 'Query', viewPrivilege: { __typename?: 'ViewPrivilegeType', risk: { __typename?: 'ViewPrivilegeSubType', modelId: string, create: boolean, read: boolean, update: boolean, delete: boolean }, document: { __typename?: 'ViewPrivilegeSubType', modelId: string, create: boolean, read: boolean, update: boolean, delete: boolean }, qnc: { __typename?: 'ViewPrivilegeSubType', modelId: string, create: boolean, read: boolean, update: boolean, delete: boolean }, anonymous: { __typename?: 'ViewPrivilegeSubType', modelId: string, create: boolean, read: boolean, update: boolean, delete: boolean } } };

export type WhoAmIQueryVariables = Exact<{ [key: string]: never; }>;


export type WhoAmIQuery = { __typename?: 'Query', whoAmI: { __typename?: 'UserType', id: string, language: string, name: string, email: string, isCompanyAdmin: boolean, isAnonymousHandler: boolean, isCompanyOwner: boolean, defaultApproverId?: string | null, defaultGroups: Array<{ __typename?: 'GroupType', id: string, name: string }>, myCustomDocuments: { __typename?: 'MyCustomDocuments', create: Array<string>, edit: Array<string>, read: Array<string>, delete: Array<string> }, company: { __typename?: 'CompanyType', id: string, name: string, features: Array<{ __typename?: 'FeatureType', key: string, value: string }> } }, documentModelIdObject: { __typename?: 'DocumentModelIDObject', risk: string, qnc: string, document: string, anonymous?: string | null } };

export type AccountManagementQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountManagementQuery = { __typename?: 'Query', accountFeatures: Array<{ __typename?: 'FeatureType', id: string, key: string, value: string }> };

export type UpdateFeatureMutationVariables = Exact<{
  key: Scalars['String'];
  value: Scalars['String'];
}>;


export type UpdateFeatureMutation = { __typename?: 'Mutation', updateFeature: { __typename?: 'FeatureType', key: string, value: string } };

export type CreateRiskMutationVariables = Exact<{
  body: Scalars['String'];
}>;


export type CreateRiskMutation = { __typename?: 'Mutation', createRiskFromForm: { __typename?: 'DocumentType', id: string } };

export type RiskCreateFormQueryVariables = Exact<{ [key: string]: never; }>;


export type RiskCreateFormQuery = { __typename?: 'Query', riskCreateForm: { __typename?: 'DocumentCreateFormType', redirect: string, initialValues: string, fields: Array<{ __typename?: 'DocumentFormField', name: string, label: string, type: string, isRequired: boolean, options: Array<{ __typename?: 'DocumentFormOptions', label: string, value: string }> }> } };

export type RiskEditFormQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RiskEditFormQuery = { __typename?: 'Query', riskEditForm: { __typename?: 'DocumentEditFormType', initialValues: string, fields: Array<{ __typename?: 'DocumentFormField', name: string, label: string, type: string, isRequired: boolean, options: Array<{ __typename?: 'DocumentFormOptions', label: string, value: string }> }> } };

export type UpdateRiskMutationVariables = Exact<{
  id: Scalars['String'];
  body: Scalars['String'];
}>;


export type UpdateRiskMutation = { __typename?: 'Mutation', updateRiskFromForm: { __typename?: 'DocumentType', id: string } };

export type PaperipankkiRequestMutationVariables = Exact<{
  body: PaperipankkiRequestDto;
}>;


export type PaperipankkiRequestMutation = { __typename?: 'Mutation', requestPaperipankkiContact: string };

export type ReportRiskMatrixQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportRiskMatrixQuery = { __typename?: 'Query', reportRiskMatrix: { __typename?: 'ReportRiskMatrixType', report: string, documents: Array<{ __typename?: 'MatrixDocs', documentId: string, documentName: string, riskIndex: number }> } };


export const AclListDocument = gql`
    query ACLList($orderBy: [ResourceAClSort!], $skip: Int, $take: Int, $where: ResourceAClWhere) {
  acls(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      name
      userCount
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useAclListQuery__
 *
 * To run a query within a React component, call `useAclListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclListQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useAclListQuery(baseOptions?: Apollo.QueryHookOptions<AclListQuery, AclListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AclListQuery, AclListQueryVariables>(AclListDocument, options);
      }
export function useAclListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclListQuery, AclListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AclListQuery, AclListQueryVariables>(AclListDocument, options);
        }
export type AclListQueryHookResult = ReturnType<typeof useAclListQuery>;
export type AclListLazyQueryHookResult = ReturnType<typeof useAclListLazyQuery>;
export type AclListQueryResult = Apollo.QueryResult<AclListQuery, AclListQueryVariables>;
export const AclDocument = gql`
    query ACL($id: String!) {
  acl(id: $id) {
    id
    name
    rules {
      id
      permissions
      documentModel {
        id
        type
      }
    }
    users {
      id
      name
    }
  }
}
    `;

/**
 * __useAclQuery__
 *
 * To run a query within a React component, call `useAclQuery` and pass it any options that fit your needs.
 * When your component renders, `useAclQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAclQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAclQuery(baseOptions: Apollo.QueryHookOptions<AclQuery, AclQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AclQuery, AclQueryVariables>(AclDocument, options);
      }
export function useAclLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AclQuery, AclQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AclQuery, AclQueryVariables>(AclDocument, options);
        }
export type AclQueryHookResult = ReturnType<typeof useAclQuery>;
export type AclLazyQueryHookResult = ReturnType<typeof useAclLazyQuery>;
export type AclQueryResult = Apollo.QueryResult<AclQuery, AclQueryVariables>;
export const CreateDocumentApproverDocument = gql`
    mutation CreateDocumentApprover($id: String!, $userId: String!) {
  createDocumentApprover(id: $id, userId: $userId) {
    id
  }
}
    `;
export type CreateDocumentApproverMutationFn = Apollo.MutationFunction<CreateDocumentApproverMutation, CreateDocumentApproverMutationVariables>;

/**
 * __useCreateDocumentApproverMutation__
 *
 * To run a mutation, you first call `useCreateDocumentApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentApproverMutation, { data, loading, error }] = useCreateDocumentApproverMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateDocumentApproverMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentApproverMutation, CreateDocumentApproverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentApproverMutation, CreateDocumentApproverMutationVariables>(CreateDocumentApproverDocument, options);
      }
export type CreateDocumentApproverMutationHookResult = ReturnType<typeof useCreateDocumentApproverMutation>;
export type CreateDocumentApproverMutationResult = Apollo.MutationResult<CreateDocumentApproverMutation>;
export type CreateDocumentApproverMutationOptions = Apollo.BaseMutationOptions<CreateDocumentApproverMutation, CreateDocumentApproverMutationVariables>;
export const AddApproverSceneDocument = gql`
    query AddApproverScene($documentId: String!) {
  availableDocumentApprovers(documentId: $documentId) {
    id
    name
  }
}
    `;

/**
 * __useAddApproverSceneQuery__
 *
 * To run a query within a React component, call `useAddApproverSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddApproverSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddApproverSceneQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useAddApproverSceneQuery(baseOptions: Apollo.QueryHookOptions<AddApproverSceneQuery, AddApproverSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddApproverSceneQuery, AddApproverSceneQueryVariables>(AddApproverSceneDocument, options);
      }
export function useAddApproverSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddApproverSceneQuery, AddApproverSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddApproverSceneQuery, AddApproverSceneQueryVariables>(AddApproverSceneDocument, options);
        }
export type AddApproverSceneQueryHookResult = ReturnType<typeof useAddApproverSceneQuery>;
export type AddApproverSceneLazyQueryHookResult = ReturnType<typeof useAddApproverSceneLazyQuery>;
export type AddApproverSceneQueryResult = Apollo.QueryResult<AddApproverSceneQuery, AddApproverSceneQueryVariables>;
export const ApproveDocumentDocument = gql`
    mutation ApproveDocument($documentApproverId: String!) {
  approveDocument(documentApproverId: $documentApproverId) {
    id
  }
}
    `;
export type ApproveDocumentMutationFn = Apollo.MutationFunction<ApproveDocumentMutation, ApproveDocumentMutationVariables>;

/**
 * __useApproveDocumentMutation__
 *
 * To run a mutation, you first call `useApproveDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveDocumentMutation, { data, loading, error }] = useApproveDocumentMutation({
 *   variables: {
 *      documentApproverId: // value for 'documentApproverId'
 *   },
 * });
 */
export function useApproveDocumentMutation(baseOptions?: Apollo.MutationHookOptions<ApproveDocumentMutation, ApproveDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveDocumentMutation, ApproveDocumentMutationVariables>(ApproveDocumentDocument, options);
      }
export type ApproveDocumentMutationHookResult = ReturnType<typeof useApproveDocumentMutation>;
export type ApproveDocumentMutationResult = Apollo.MutationResult<ApproveDocumentMutation>;
export type ApproveDocumentMutationOptions = Apollo.BaseMutationOptions<ApproveDocumentMutation, ApproveDocumentMutationVariables>;
export const CompanyEditSceneDocument = gql`
    query CompanyEditScene($id: String!) {
  company(id: $id) {
    name
  }
}
    `;

/**
 * __useCompanyEditSceneQuery__
 *
 * To run a query within a React component, call `useCompanyEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyEditSceneQuery(baseOptions: Apollo.QueryHookOptions<CompanyEditSceneQuery, CompanyEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyEditSceneQuery, CompanyEditSceneQueryVariables>(CompanyEditSceneDocument, options);
      }
export function useCompanyEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyEditSceneQuery, CompanyEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyEditSceneQuery, CompanyEditSceneQueryVariables>(CompanyEditSceneDocument, options);
        }
export type CompanyEditSceneQueryHookResult = ReturnType<typeof useCompanyEditSceneQuery>;
export type CompanyEditSceneLazyQueryHookResult = ReturnType<typeof useCompanyEditSceneLazyQuery>;
export type CompanyEditSceneQueryResult = Apollo.QueryResult<CompanyEditSceneQuery, CompanyEditSceneQueryVariables>;
export const CompleteInvitationDocument = gql`
    mutation CompleteInvitation($body: CompleteInvitationDTO!) {
  completeInvitation(body: $body) {
    status
  }
}
    `;
export type CompleteInvitationMutationFn = Apollo.MutationFunction<CompleteInvitationMutation, CompleteInvitationMutationVariables>;

/**
 * __useCompleteInvitationMutation__
 *
 * To run a mutation, you first call `useCompleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeInvitationMutation, { data, loading, error }] = useCompleteInvitationMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCompleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CompleteInvitationMutation, CompleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteInvitationMutation, CompleteInvitationMutationVariables>(CompleteInvitationDocument, options);
      }
export type CompleteInvitationMutationHookResult = ReturnType<typeof useCompleteInvitationMutation>;
export type CompleteInvitationMutationResult = Apollo.MutationResult<CompleteInvitationMutation>;
export type CompleteInvitationMutationOptions = Apollo.BaseMutationOptions<CompleteInvitationMutation, CompleteInvitationMutationVariables>;
export const ConfirmDocument = gql`
    mutation Confirm($body: ConfirmDto!) {
  confirm(body: $body) {
    status
  }
}
    `;
export type ConfirmMutationFn = Apollo.MutationFunction<ConfirmMutation, ConfirmMutationVariables>;

/**
 * __useConfirmMutation__
 *
 * To run a mutation, you first call `useConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMutation, { data, loading, error }] = useConfirmMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMutation, ConfirmMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMutation, ConfirmMutationVariables>(ConfirmDocument, options);
      }
export type ConfirmMutationHookResult = ReturnType<typeof useConfirmMutation>;
export type ConfirmMutationResult = Apollo.MutationResult<ConfirmMutation>;
export type ConfirmMutationOptions = Apollo.BaseMutationOptions<ConfirmMutation, ConfirmMutationVariables>;
export const CreateAclDocument = gql`
    mutation CreateACL($body: ResourceACLDTO!) {
  createACL(body: $body) {
    id
  }
}
    `;
export type CreateAclMutationFn = Apollo.MutationFunction<CreateAclMutation, CreateAclMutationVariables>;

/**
 * __useCreateAclMutation__
 *
 * To run a mutation, you first call `useCreateAclMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAclMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAclMutation, { data, loading, error }] = useCreateAclMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateAclMutation(baseOptions?: Apollo.MutationHookOptions<CreateAclMutation, CreateAclMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAclMutation, CreateAclMutationVariables>(CreateAclDocument, options);
      }
export type CreateAclMutationHookResult = ReturnType<typeof useCreateAclMutation>;
export type CreateAclMutationResult = Apollo.MutationResult<CreateAclMutation>;
export type CreateAclMutationOptions = Apollo.BaseMutationOptions<CreateAclMutation, CreateAclMutationVariables>;
export const CreateAclRuleDocument = gql`
    mutation CreateACLRule($body: ResourceACLRuleDTO!) {
  createACLRule(body: $body) {
    id
  }
}
    `;
export type CreateAclRuleMutationFn = Apollo.MutationFunction<CreateAclRuleMutation, CreateAclRuleMutationVariables>;

/**
 * __useCreateAclRuleMutation__
 *
 * To run a mutation, you first call `useCreateAclRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAclRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAclRuleMutation, { data, loading, error }] = useCreateAclRuleMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateAclRuleMutation(baseOptions?: Apollo.MutationHookOptions<CreateAclRuleMutation, CreateAclRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAclRuleMutation, CreateAclRuleMutationVariables>(CreateAclRuleDocument, options);
      }
export type CreateAclRuleMutationHookResult = ReturnType<typeof useCreateAclRuleMutation>;
export type CreateAclRuleMutationResult = Apollo.MutationResult<CreateAclRuleMutation>;
export type CreateAclRuleMutationOptions = Apollo.BaseMutationOptions<CreateAclRuleMutation, CreateAclRuleMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($body: CreateDocumentDTO!) {
  createDocument(body: $body) {
    id
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const CreateDocumentLinkDocument = gql`
    mutation CreateDocumentLink($body: CreateDocumentLinkDTO!) {
  createDocumentLink(body: $body) {
    id
  }
}
    `;
export type CreateDocumentLinkMutationFn = Apollo.MutationFunction<CreateDocumentLinkMutation, CreateDocumentLinkMutationVariables>;

/**
 * __useCreateDocumentLinkMutation__
 *
 * To run a mutation, you first call `useCreateDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentLinkMutation, { data, loading, error }] = useCreateDocumentLinkMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDocumentLinkMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentLinkMutation, CreateDocumentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentLinkMutation, CreateDocumentLinkMutationVariables>(CreateDocumentLinkDocument, options);
      }
export type CreateDocumentLinkMutationHookResult = ReturnType<typeof useCreateDocumentLinkMutation>;
export type CreateDocumentLinkMutationResult = Apollo.MutationResult<CreateDocumentLinkMutation>;
export type CreateDocumentLinkMutationOptions = Apollo.BaseMutationOptions<CreateDocumentLinkMutation, CreateDocumentLinkMutationVariables>;
export const CreateDocumentModelDocument = gql`
    mutation CreateDocumentModel($body: CreateDocumentModelDTO!) {
  createDocumentModel(body: $body) {
    id
  }
}
    `;
export type CreateDocumentModelMutationFn = Apollo.MutationFunction<CreateDocumentModelMutation, CreateDocumentModelMutationVariables>;

/**
 * __useCreateDocumentModelMutation__
 *
 * To run a mutation, you first call `useCreateDocumentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentModelMutation, { data, loading, error }] = useCreateDocumentModelMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateDocumentModelMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentModelMutation, CreateDocumentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentModelMutation, CreateDocumentModelMutationVariables>(CreateDocumentModelDocument, options);
      }
export type CreateDocumentModelMutationHookResult = ReturnType<typeof useCreateDocumentModelMutation>;
export type CreateDocumentModelMutationResult = Apollo.MutationResult<CreateDocumentModelMutation>;
export type CreateDocumentModelMutationOptions = Apollo.BaseMutationOptions<CreateDocumentModelMutation, CreateDocumentModelMutationVariables>;
export const CreateGroupDocument = gql`
    mutation CreateGroup($body: CreateGroupDTO!) {
  createGroup(body: $body) {
    id
  }
}
    `;
export type CreateGroupMutationFn = Apollo.MutationFunction<CreateGroupMutation, CreateGroupMutationVariables>;

/**
 * __useCreateGroupMutation__
 *
 * To run a mutation, you first call `useCreateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGroupMutation, { data, loading, error }] = useCreateGroupMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateGroupMutation(baseOptions?: Apollo.MutationHookOptions<CreateGroupMutation, CreateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGroupMutation, CreateGroupMutationVariables>(CreateGroupDocument, options);
      }
export type CreateGroupMutationHookResult = ReturnType<typeof useCreateGroupMutation>;
export type CreateGroupMutationResult = Apollo.MutationResult<CreateGroupMutation>;
export type CreateGroupMutationOptions = Apollo.BaseMutationOptions<CreateGroupMutation, CreateGroupMutationVariables>;
export const CreateMessageDocument = gql`
    mutation CreateMessage($body: MessageDTO!) {
  createMessage(body: $body) {
    id
  }
}
    `;
export type CreateMessageMutationFn = Apollo.MutationFunction<CreateMessageMutation, CreateMessageMutationVariables>;

/**
 * __useCreateMessageMutation__
 *
 * To run a mutation, you first call `useCreateMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMessageMutation, { data, loading, error }] = useCreateMessageMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateMessageMutation(baseOptions?: Apollo.MutationHookOptions<CreateMessageMutation, CreateMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMessageMutation, CreateMessageMutationVariables>(CreateMessageDocument, options);
      }
export type CreateMessageMutationHookResult = ReturnType<typeof useCreateMessageMutation>;
export type CreateMessageMutationResult = Apollo.MutationResult<CreateMessageMutation>;
export type CreateMessageMutationOptions = Apollo.BaseMutationOptions<CreateMessageMutation, CreateMessageMutationVariables>;
export const CreateTagDocument = gql`
    mutation CreateTag($body: CreateTagDTO!) {
  createTag(body: $body) {
    id
  }
}
    `;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const CustomDocumentCreateSceneDocument = gql`
    query CustomDocumentCreateScene($id: String!) {
  documentModelById(id: $id) {
    id
    type
    documentModelFields {
      key
      label
      options
      type
    }
  }
  allDocumentApprovers {
    value: id
    label: name
  }
  myGroups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  tags(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useCustomDocumentCreateSceneQuery__
 *
 * To run a query within a React component, call `useCustomDocumentCreateSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDocumentCreateSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDocumentCreateSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomDocumentCreateSceneQuery(baseOptions: Apollo.QueryHookOptions<CustomDocumentCreateSceneQuery, CustomDocumentCreateSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomDocumentCreateSceneQuery, CustomDocumentCreateSceneQueryVariables>(CustomDocumentCreateSceneDocument, options);
      }
export function useCustomDocumentCreateSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomDocumentCreateSceneQuery, CustomDocumentCreateSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomDocumentCreateSceneQuery, CustomDocumentCreateSceneQueryVariables>(CustomDocumentCreateSceneDocument, options);
        }
export type CustomDocumentCreateSceneQueryHookResult = ReturnType<typeof useCustomDocumentCreateSceneQuery>;
export type CustomDocumentCreateSceneLazyQueryHookResult = ReturnType<typeof useCustomDocumentCreateSceneLazyQuery>;
export type CustomDocumentCreateSceneQueryResult = Apollo.QueryResult<CustomDocumentCreateSceneQuery, CustomDocumentCreateSceneQueryVariables>;
export const CustomDocumentEditSceneDocument = gql`
    query CustomDocumentEditScene($id: String!) {
  document(id: $id) {
    name
    groups {
      id
      name
    }
    isApproveRequired
    costs
    tags {
      id
      name
    }
    description
    documentFields {
      id
      value
      key
    }
    documentModel {
      id
      type
      documentModelFields {
        key
        label
        options
        type
      }
    }
  }
  allDocumentApprovers {
    value: id
    label: name
  }
  myGroups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  tags(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useCustomDocumentEditSceneQuery__
 *
 * To run a query within a React component, call `useCustomDocumentEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDocumentEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDocumentEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomDocumentEditSceneQuery(baseOptions: Apollo.QueryHookOptions<CustomDocumentEditSceneQuery, CustomDocumentEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomDocumentEditSceneQuery, CustomDocumentEditSceneQueryVariables>(CustomDocumentEditSceneDocument, options);
      }
export function useCustomDocumentEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomDocumentEditSceneQuery, CustomDocumentEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomDocumentEditSceneQuery, CustomDocumentEditSceneQueryVariables>(CustomDocumentEditSceneDocument, options);
        }
export type CustomDocumentEditSceneQueryHookResult = ReturnType<typeof useCustomDocumentEditSceneQuery>;
export type CustomDocumentEditSceneLazyQueryHookResult = ReturnType<typeof useCustomDocumentEditSceneLazyQuery>;
export type CustomDocumentEditSceneQueryResult = Apollo.QueryResult<CustomDocumentEditSceneQuery, CustomDocumentEditSceneQueryVariables>;
export const CustomDocumentModelsDocument = gql`
    query CustomDocumentModels($modelIds: [String!]!) {
  customDocumentModels(modelIds: $modelIds) {
    id
    type
  }
}
    `;

/**
 * __useCustomDocumentModelsQuery__
 *
 * To run a query within a React component, call `useCustomDocumentModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomDocumentModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomDocumentModelsQuery({
 *   variables: {
 *      modelIds: // value for 'modelIds'
 *   },
 * });
 */
export function useCustomDocumentModelsQuery(baseOptions: Apollo.QueryHookOptions<CustomDocumentModelsQuery, CustomDocumentModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomDocumentModelsQuery, CustomDocumentModelsQueryVariables>(CustomDocumentModelsDocument, options);
      }
export function useCustomDocumentModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomDocumentModelsQuery, CustomDocumentModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomDocumentModelsQuery, CustomDocumentModelsQueryVariables>(CustomDocumentModelsDocument, options);
        }
export type CustomDocumentModelsQueryHookResult = ReturnType<typeof useCustomDocumentModelsQuery>;
export type CustomDocumentModelsLazyQueryHookResult = ReturnType<typeof useCustomDocumentModelsLazyQuery>;
export type CustomDocumentModelsQueryResult = Apollo.QueryResult<CustomDocumentModelsQuery, CustomDocumentModelsQueryVariables>;
export const GetModelTypeNamesDocument = gql`
    query GetModelTypeNames {
  getModelTypeNames
}
    `;

/**
 * __useGetModelTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetModelTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetModelTypeNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetModelTypeNamesQuery, GetModelTypeNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelTypeNamesQuery, GetModelTypeNamesQueryVariables>(GetModelTypeNamesDocument, options);
      }
export function useGetModelTypeNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelTypeNamesQuery, GetModelTypeNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelTypeNamesQuery, GetModelTypeNamesQueryVariables>(GetModelTypeNamesDocument, options);
        }
export type GetModelTypeNamesQueryHookResult = ReturnType<typeof useGetModelTypeNamesQuery>;
export type GetModelTypeNamesLazyQueryHookResult = ReturnType<typeof useGetModelTypeNamesLazyQuery>;
export type GetModelTypeNamesQueryResult = Apollo.QueryResult<GetModelTypeNamesQuery, GetModelTypeNamesQueryVariables>;
export const GetFieldNamesDocument = gql`
    query GetFieldNames($modelTypeName: String!) {
  getFieldNames(type: $modelTypeName) {
    key
    label
  }
}
    `;

/**
 * __useGetFieldNamesQuery__
 *
 * To run a query within a React component, call `useGetFieldNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFieldNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFieldNamesQuery({
 *   variables: {
 *      modelTypeName: // value for 'modelTypeName'
 *   },
 * });
 */
export function useGetFieldNamesQuery(baseOptions: Apollo.QueryHookOptions<GetFieldNamesQuery, GetFieldNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFieldNamesQuery, GetFieldNamesQueryVariables>(GetFieldNamesDocument, options);
      }
export function useGetFieldNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFieldNamesQuery, GetFieldNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFieldNamesQuery, GetFieldNamesQueryVariables>(GetFieldNamesDocument, options);
        }
export type GetFieldNamesQueryHookResult = ReturnType<typeof useGetFieldNamesQuery>;
export type GetFieldNamesLazyQueryHookResult = ReturnType<typeof useGetFieldNamesLazyQuery>;
export type GetFieldNamesQueryResult = Apollo.QueryResult<GetFieldNamesQuery, GetFieldNamesQueryVariables>;
export const CreateCustomReportDocument = gql`
    mutation CreateCustomReport($name: String!, $fieldKey: String!) {
  createCustomReport(name: $name, fieldKey: $fieldKey) {
    id
  }
}
    `;
export type CreateCustomReportMutationFn = Apollo.MutationFunction<CreateCustomReportMutation, CreateCustomReportMutationVariables>;

/**
 * __useCreateCustomReportMutation__
 *
 * To run a mutation, you first call `useCreateCustomReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomReportMutation, { data, loading, error }] = useCreateCustomReportMutation({
 *   variables: {
 *      name: // value for 'name'
 *      fieldKey: // value for 'fieldKey'
 *   },
 * });
 */
export function useCreateCustomReportMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomReportMutation, CreateCustomReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomReportMutation, CreateCustomReportMutationVariables>(CreateCustomReportDocument, options);
      }
export type CreateCustomReportMutationHookResult = ReturnType<typeof useCreateCustomReportMutation>;
export type CreateCustomReportMutationResult = Apollo.MutationResult<CreateCustomReportMutation>;
export type CreateCustomReportMutationOptions = Apollo.BaseMutationOptions<CreateCustomReportMutation, CreateCustomReportMutationVariables>;
export const CustomReportShowDocument = gql`
    query CustomReportShow($id: String!) {
  getCustomReport(id: $id) {
    data {
      name
      bars {
        value
        label
      }
    }
    dataKeys
    reportName
  }
}
    `;

/**
 * __useCustomReportShowQuery__
 *
 * To run a query within a React component, call `useCustomReportShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomReportShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomReportShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomReportShowQuery(baseOptions: Apollo.QueryHookOptions<CustomReportShowQuery, CustomReportShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomReportShowQuery, CustomReportShowQueryVariables>(CustomReportShowDocument, options);
      }
export function useCustomReportShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomReportShowQuery, CustomReportShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomReportShowQuery, CustomReportShowQueryVariables>(CustomReportShowDocument, options);
        }
export type CustomReportShowQueryHookResult = ReturnType<typeof useCustomReportShowQuery>;
export type CustomReportShowLazyQueryHookResult = ReturnType<typeof useCustomReportShowLazyQuery>;
export type CustomReportShowQueryResult = Apollo.QueryResult<CustomReportShowQuery, CustomReportShowQueryVariables>;
export const DeleteCustomReportDocument = gql`
    mutation DeleteCustomReport($id: String!) {
  deleteCustomReport(id: $id) {
    id
  }
}
    `;
export type DeleteCustomReportMutationFn = Apollo.MutationFunction<DeleteCustomReportMutation, DeleteCustomReportMutationVariables>;

/**
 * __useDeleteCustomReportMutation__
 *
 * To run a mutation, you first call `useDeleteCustomReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomReportMutation, { data, loading, error }] = useDeleteCustomReportMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomReportMutation, DeleteCustomReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomReportMutation, DeleteCustomReportMutationVariables>(DeleteCustomReportDocument, options);
      }
export type DeleteCustomReportMutationHookResult = ReturnType<typeof useDeleteCustomReportMutation>;
export type DeleteCustomReportMutationResult = Apollo.MutationResult<DeleteCustomReportMutation>;
export type DeleteCustomReportMutationOptions = Apollo.BaseMutationOptions<DeleteCustomReportMutation, DeleteCustomReportMutationVariables>;
export const DataValidationSceneDocument = gql`
    query DataValidationScene($qncModelId: String!, $riskModelId: String!, $documentModelId: String!) {
  qncs: documentsV2(where: {includeModels: [$qncModelId]}) {
    totalCount
  }
  risks: documentsV2(where: {includeModels: [$riskModelId]}) {
    totalCount
  }
  documentsV2(where: {includeModels: [$documentModelId]}) {
    totalCount
  }
  users {
    totalCount
  }
  tags(take: 300, orderBy: [{name: "asc"}]) {
    totalCount
  }
  groups {
    totalCount
  }
}
    `;

/**
 * __useDataValidationSceneQuery__
 *
 * To run a query within a React component, call `useDataValidationSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataValidationSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataValidationSceneQuery({
 *   variables: {
 *      qncModelId: // value for 'qncModelId'
 *      riskModelId: // value for 'riskModelId'
 *      documentModelId: // value for 'documentModelId'
 *   },
 * });
 */
export function useDataValidationSceneQuery(baseOptions: Apollo.QueryHookOptions<DataValidationSceneQuery, DataValidationSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DataValidationSceneQuery, DataValidationSceneQueryVariables>(DataValidationSceneDocument, options);
      }
export function useDataValidationSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DataValidationSceneQuery, DataValidationSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DataValidationSceneQuery, DataValidationSceneQueryVariables>(DataValidationSceneDocument, options);
        }
export type DataValidationSceneQueryHookResult = ReturnType<typeof useDataValidationSceneQuery>;
export type DataValidationSceneLazyQueryHookResult = ReturnType<typeof useDataValidationSceneLazyQuery>;
export type DataValidationSceneQueryResult = Apollo.QueryResult<DataValidationSceneQuery, DataValidationSceneQueryVariables>;
export const DeleteAclDocument = gql`
    mutation DeleteACL($id: String!) {
  deleteACL(id: $id) {
    id
  }
}
    `;
export type DeleteAclMutationFn = Apollo.MutationFunction<DeleteAclMutation, DeleteAclMutationVariables>;

/**
 * __useDeleteAclMutation__
 *
 * To run a mutation, you first call `useDeleteAclMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAclMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAclMutation, { data, loading, error }] = useDeleteAclMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAclMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAclMutation, DeleteAclMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAclMutation, DeleteAclMutationVariables>(DeleteAclDocument, options);
      }
export type DeleteAclMutationHookResult = ReturnType<typeof useDeleteAclMutation>;
export type DeleteAclMutationResult = Apollo.MutationResult<DeleteAclMutation>;
export type DeleteAclMutationOptions = Apollo.BaseMutationOptions<DeleteAclMutation, DeleteAclMutationVariables>;
export const DeleteAclRuleDocument = gql`
    mutation DeleteACLRule($id: String!) {
  deleteACLRule(id: $id) {
    id
  }
}
    `;
export type DeleteAclRuleMutationFn = Apollo.MutationFunction<DeleteAclRuleMutation, DeleteAclRuleMutationVariables>;

/**
 * __useDeleteAclRuleMutation__
 *
 * To run a mutation, you first call `useDeleteAclRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAclRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAclRuleMutation, { data, loading, error }] = useDeleteAclRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAclRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAclRuleMutation, DeleteAclRuleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAclRuleMutation, DeleteAclRuleMutationVariables>(DeleteAclRuleDocument, options);
      }
export type DeleteAclRuleMutationHookResult = ReturnType<typeof useDeleteAclRuleMutation>;
export type DeleteAclRuleMutationResult = Apollo.MutationResult<DeleteAclRuleMutation>;
export type DeleteAclRuleMutationOptions = Apollo.BaseMutationOptions<DeleteAclRuleMutation, DeleteAclRuleMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: String!) {
  deleteDocument(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const DeleteDocumentApproverDocument = gql`
    mutation DeleteDocumentApprover($id: String!) {
  deleteDocumentApprover(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentApproverMutationFn = Apollo.MutationFunction<DeleteDocumentApproverMutation, DeleteDocumentApproverMutationVariables>;

/**
 * __useDeleteDocumentApproverMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentApproverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentApproverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentApproverMutation, { data, loading, error }] = useDeleteDocumentApproverMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentApproverMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentApproverMutation, DeleteDocumentApproverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentApproverMutation, DeleteDocumentApproverMutationVariables>(DeleteDocumentApproverDocument, options);
      }
export type DeleteDocumentApproverMutationHookResult = ReturnType<typeof useDeleteDocumentApproverMutation>;
export type DeleteDocumentApproverMutationResult = Apollo.MutationResult<DeleteDocumentApproverMutation>;
export type DeleteDocumentApproverMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentApproverMutation, DeleteDocumentApproverMutationVariables>;
export const DeleteDocumentLinkDocument = gql`
    mutation DeleteDocumentLink($id: String!) {
  deleteDocumentLink(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentLinkMutationFn = Apollo.MutationFunction<DeleteDocumentLinkMutation, DeleteDocumentLinkMutationVariables>;

/**
 * __useDeleteDocumentLinkMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentLinkMutation, { data, loading, error }] = useDeleteDocumentLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentLinkMutation, DeleteDocumentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentLinkMutation, DeleteDocumentLinkMutationVariables>(DeleteDocumentLinkDocument, options);
      }
export type DeleteDocumentLinkMutationHookResult = ReturnType<typeof useDeleteDocumentLinkMutation>;
export type DeleteDocumentLinkMutationResult = Apollo.MutationResult<DeleteDocumentLinkMutation>;
export type DeleteDocumentLinkMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentLinkMutation, DeleteDocumentLinkMutationVariables>;
export const DeleteDocumentModelDocument = gql`
    mutation DeleteDocumentModel($id: String!) {
  deleteDocumentModel(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentModelMutationFn = Apollo.MutationFunction<DeleteDocumentModelMutation, DeleteDocumentModelMutationVariables>;

/**
 * __useDeleteDocumentModelMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentModelMutation, { data, loading, error }] = useDeleteDocumentModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentModelMutation, DeleteDocumentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentModelMutation, DeleteDocumentModelMutationVariables>(DeleteDocumentModelDocument, options);
      }
export type DeleteDocumentModelMutationHookResult = ReturnType<typeof useDeleteDocumentModelMutation>;
export type DeleteDocumentModelMutationResult = Apollo.MutationResult<DeleteDocumentModelMutation>;
export type DeleteDocumentModelMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentModelMutation, DeleteDocumentModelMutationVariables>;
export const DeleteDriveFileDocument = gql`
    mutation DeleteDriveFile($id: String!) {
  deleteDriveFile(id: $id) {
    id
  }
}
    `;
export type DeleteDriveFileMutationFn = Apollo.MutationFunction<DeleteDriveFileMutation, DeleteDriveFileMutationVariables>;

/**
 * __useDeleteDriveFileMutation__
 *
 * To run a mutation, you first call `useDeleteDriveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDriveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDriveFileMutation, { data, loading, error }] = useDeleteDriveFileMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDriveFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDriveFileMutation, DeleteDriveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDriveFileMutation, DeleteDriveFileMutationVariables>(DeleteDriveFileDocument, options);
      }
export type DeleteDriveFileMutationHookResult = ReturnType<typeof useDeleteDriveFileMutation>;
export type DeleteDriveFileMutationResult = Apollo.MutationResult<DeleteDriveFileMutation>;
export type DeleteDriveFileMutationOptions = Apollo.BaseMutationOptions<DeleteDriveFileMutation, DeleteDriveFileMutationVariables>;
export const DeleteGroupDocument = gql`
    mutation DeleteGroup($id: String!) {
  deleteGroup(id: $id) {
    id
  }
}
    `;
export type DeleteGroupMutationFn = Apollo.MutationFunction<DeleteGroupMutation, DeleteGroupMutationVariables>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGroupMutation, DeleteGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(DeleteGroupDocument, options);
      }
export type DeleteGroupMutationHookResult = ReturnType<typeof useDeleteGroupMutation>;
export type DeleteGroupMutationResult = Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<DeleteGroupMutation, DeleteGroupMutationVariables>;
export const DeleteMsLinkDocument = gql`
    mutation DeleteMSLink($id: String!) {
  deleteMSLink(id: $id) {
    id
  }
}
    `;
export type DeleteMsLinkMutationFn = Apollo.MutationFunction<DeleteMsLinkMutation, DeleteMsLinkMutationVariables>;

/**
 * __useDeleteMsLinkMutation__
 *
 * To run a mutation, you first call `useDeleteMsLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMsLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMsLinkMutation, { data, loading, error }] = useDeleteMsLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMsLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMsLinkMutation, DeleteMsLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMsLinkMutation, DeleteMsLinkMutationVariables>(DeleteMsLinkDocument, options);
      }
export type DeleteMsLinkMutationHookResult = ReturnType<typeof useDeleteMsLinkMutation>;
export type DeleteMsLinkMutationResult = Apollo.MutationResult<DeleteMsLinkMutation>;
export type DeleteMsLinkMutationOptions = Apollo.BaseMutationOptions<DeleteMsLinkMutation, DeleteMsLinkMutationVariables>;
export const DeleteMiroLinkDocument = gql`
    mutation DeleteMiroLink($id: String!) {
  deleteMiroLink(id: $id) {
    id
  }
}
    `;
export type DeleteMiroLinkMutationFn = Apollo.MutationFunction<DeleteMiroLinkMutation, DeleteMiroLinkMutationVariables>;

/**
 * __useDeleteMiroLinkMutation__
 *
 * To run a mutation, you first call `useDeleteMiroLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMiroLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMiroLinkMutation, { data, loading, error }] = useDeleteMiroLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMiroLinkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMiroLinkMutation, DeleteMiroLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteMiroLinkMutation, DeleteMiroLinkMutationVariables>(DeleteMiroLinkDocument, options);
      }
export type DeleteMiroLinkMutationHookResult = ReturnType<typeof useDeleteMiroLinkMutation>;
export type DeleteMiroLinkMutationResult = Apollo.MutationResult<DeleteMiroLinkMutation>;
export type DeleteMiroLinkMutationOptions = Apollo.BaseMutationOptions<DeleteMiroLinkMutation, DeleteMiroLinkMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: String!) {
  deleteTag(id: $id) {
    id
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: String!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DocumentCreateSceneDocument = gql`
    query DocumentCreateScene($type: String!) {
  documentModelByType(type: $type) {
    id
    type
    documentModelFields {
      key
      label
      options
      type
    }
  }
  allDocumentApprovers {
    value: id
    label: name
  }
  myGroups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  tags(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useDocumentCreateSceneQuery__
 *
 * To run a query within a React component, call `useDocumentCreateSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentCreateSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentCreateSceneQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDocumentCreateSceneQuery(baseOptions: Apollo.QueryHookOptions<DocumentCreateSceneQuery, DocumentCreateSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentCreateSceneQuery, DocumentCreateSceneQueryVariables>(DocumentCreateSceneDocument, options);
      }
export function useDocumentCreateSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentCreateSceneQuery, DocumentCreateSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentCreateSceneQuery, DocumentCreateSceneQueryVariables>(DocumentCreateSceneDocument, options);
        }
export type DocumentCreateSceneQueryHookResult = ReturnType<typeof useDocumentCreateSceneQuery>;
export type DocumentCreateSceneLazyQueryHookResult = ReturnType<typeof useDocumentCreateSceneLazyQuery>;
export type DocumentCreateSceneQueryResult = Apollo.QueryResult<DocumentCreateSceneQuery, DocumentCreateSceneQueryVariables>;
export const DocumentEditSceneDocument = gql`
    query DocumentEditScene($id: String!, $type: String!) {
  documentModelByType(type: $type) {
    id
    type
    documentModelFields {
      key
      label
      options
      type
    }
  }
  document(id: $id) {
    name
    groups {
      id
      name
    }
    isApproveRequired
    costs
    category
    tags {
      id
      name
    }
    description
    documentFields {
      id
      value
      key
    }
  }
  allDocumentApprovers {
    value: id
    label: name
  }
  myGroups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  tags(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useDocumentEditSceneQuery__
 *
 * To run a query within a React component, call `useDocumentEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useDocumentEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DocumentEditSceneQuery, DocumentEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentEditSceneQuery, DocumentEditSceneQueryVariables>(DocumentEditSceneDocument, options);
      }
export function useDocumentEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentEditSceneQuery, DocumentEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentEditSceneQuery, DocumentEditSceneQueryVariables>(DocumentEditSceneDocument, options);
        }
export type DocumentEditSceneQueryHookResult = ReturnType<typeof useDocumentEditSceneQuery>;
export type DocumentEditSceneLazyQueryHookResult = ReturnType<typeof useDocumentEditSceneLazyQuery>;
export type DocumentEditSceneQueryResult = Apollo.QueryResult<DocumentEditSceneQuery, DocumentEditSceneQueryVariables>;
export const DocumentIndexMetaDocument = gql`
    query DocumentIndexMeta($modelIds: [String!]!) {
  tags(orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  groups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      id
      name
    }
  }
  customDocumentModels(modelIds: $modelIds) {
    id
    type
  }
  allDocumentApprovers {
    value: id
    label: name
  }
  users {
    list {
      id
      name
    }
  }
}
    `;

/**
 * __useDocumentIndexMetaQuery__
 *
 * To run a query within a React component, call `useDocumentIndexMetaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentIndexMetaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentIndexMetaQuery({
 *   variables: {
 *      modelIds: // value for 'modelIds'
 *   },
 * });
 */
export function useDocumentIndexMetaQuery(baseOptions: Apollo.QueryHookOptions<DocumentIndexMetaQuery, DocumentIndexMetaQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentIndexMetaQuery, DocumentIndexMetaQueryVariables>(DocumentIndexMetaDocument, options);
      }
export function useDocumentIndexMetaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentIndexMetaQuery, DocumentIndexMetaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentIndexMetaQuery, DocumentIndexMetaQueryVariables>(DocumentIndexMetaDocument, options);
        }
export type DocumentIndexMetaQueryHookResult = ReturnType<typeof useDocumentIndexMetaQuery>;
export type DocumentIndexMetaLazyQueryHookResult = ReturnType<typeof useDocumentIndexMetaLazyQuery>;
export type DocumentIndexMetaQueryResult = Apollo.QueryResult<DocumentIndexMetaQuery, DocumentIndexMetaQueryVariables>;
export const DocumentIndexSceneDocument = gql`
    query DocumentIndexScene($orderBy: [DocumentV2Sort!], $skip: Int, $take: Int, $where: DocumentV2Where!) {
  documentsV2(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      createdAt
      type
      documentIdentifier
      category
      hasAttachment
      groups {
        name
      }
      id
      costs
      isApproveRequestSend
      isApproveRequired
      isApproved
      documentProperties {
        label
        value
      }
      name
    }
    totalCount
  }
}
    `;

/**
 * __useDocumentIndexSceneQuery__
 *
 * To run a query within a React component, call `useDocumentIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDocumentIndexSceneQuery(baseOptions: Apollo.QueryHookOptions<DocumentIndexSceneQuery, DocumentIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentIndexSceneQuery, DocumentIndexSceneQueryVariables>(DocumentIndexSceneDocument, options);
      }
export function useDocumentIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentIndexSceneQuery, DocumentIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentIndexSceneQuery, DocumentIndexSceneQueryVariables>(DocumentIndexSceneDocument, options);
        }
export type DocumentIndexSceneQueryHookResult = ReturnType<typeof useDocumentIndexSceneQuery>;
export type DocumentIndexSceneLazyQueryHookResult = ReturnType<typeof useDocumentIndexSceneLazyQuery>;
export type DocumentIndexSceneQueryResult = Apollo.QueryResult<DocumentIndexSceneQuery, DocumentIndexSceneQueryVariables>;
export const DocumentLinkDocument = gql`
    query DocumentLink($id: String!) {
  documentLink(id: $id) {
    name
    url
  }
}
    `;

/**
 * __useDocumentLinkQuery__
 *
 * To run a query within a React component, call `useDocumentLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentLinkQuery(baseOptions: Apollo.QueryHookOptions<DocumentLinkQuery, DocumentLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentLinkQuery, DocumentLinkQueryVariables>(DocumentLinkDocument, options);
      }
export function useDocumentLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentLinkQuery, DocumentLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentLinkQuery, DocumentLinkQueryVariables>(DocumentLinkDocument, options);
        }
export type DocumentLinkQueryHookResult = ReturnType<typeof useDocumentLinkQuery>;
export type DocumentLinkLazyQueryHookResult = ReturnType<typeof useDocumentLinkLazyQuery>;
export type DocumentLinkQueryResult = Apollo.QueryResult<DocumentLinkQuery, DocumentLinkQueryVariables>;
export const DocumentModelEditSceneDocument = gql`
    query DocumentModelEditScene($id: String!) {
  documentModelById(id: $id) {
    type
    privilege
    documentModelFields {
      id
      label
      options
      position
      type
    }
  }
}
    `;

/**
 * __useDocumentModelEditSceneQuery__
 *
 * To run a query within a React component, call `useDocumentModelEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentModelEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentModelEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentModelEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DocumentModelEditSceneQuery, DocumentModelEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentModelEditSceneQuery, DocumentModelEditSceneQueryVariables>(DocumentModelEditSceneDocument, options);
      }
export function useDocumentModelEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentModelEditSceneQuery, DocumentModelEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentModelEditSceneQuery, DocumentModelEditSceneQueryVariables>(DocumentModelEditSceneDocument, options);
        }
export type DocumentModelEditSceneQueryHookResult = ReturnType<typeof useDocumentModelEditSceneQuery>;
export type DocumentModelEditSceneLazyQueryHookResult = ReturnType<typeof useDocumentModelEditSceneLazyQuery>;
export type DocumentModelEditSceneQueryResult = Apollo.QueryResult<DocumentModelEditSceneQuery, DocumentModelEditSceneQueryVariables>;
export const DocumentModelIndexSceneDocument = gql`
    query DocumentModelIndexScene($orderBy: [DocumentModelSort!], $skip: Int, $take: Int, $where: DocumentModelWhere) {
  documentModels(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      type
      privilege
    }
    totalCount
  }
}
    `;

/**
 * __useDocumentModelIndexSceneQuery__
 *
 * To run a query within a React component, call `useDocumentModelIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentModelIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentModelIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDocumentModelIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<DocumentModelIndexSceneQuery, DocumentModelIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentModelIndexSceneQuery, DocumentModelIndexSceneQueryVariables>(DocumentModelIndexSceneDocument, options);
      }
export function useDocumentModelIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentModelIndexSceneQuery, DocumentModelIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentModelIndexSceneQuery, DocumentModelIndexSceneQueryVariables>(DocumentModelIndexSceneDocument, options);
        }
export type DocumentModelIndexSceneQueryHookResult = ReturnType<typeof useDocumentModelIndexSceneQuery>;
export type DocumentModelIndexSceneLazyQueryHookResult = ReturnType<typeof useDocumentModelIndexSceneLazyQuery>;
export type DocumentModelIndexSceneQueryResult = Apollo.QueryResult<DocumentModelIndexSceneQuery, DocumentModelIndexSceneQueryVariables>;
export const DocumentModelOptionsDocument = gql`
    query DocumentModelOptions {
  documentModels {
    list {
      id
      type
    }
  }
}
    `;

/**
 * __useDocumentModelOptionsQuery__
 *
 * To run a query within a React component, call `useDocumentModelOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentModelOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentModelOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDocumentModelOptionsQuery(baseOptions?: Apollo.QueryHookOptions<DocumentModelOptionsQuery, DocumentModelOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentModelOptionsQuery, DocumentModelOptionsQueryVariables>(DocumentModelOptionsDocument, options);
      }
export function useDocumentModelOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentModelOptionsQuery, DocumentModelOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentModelOptionsQuery, DocumentModelOptionsQueryVariables>(DocumentModelOptionsDocument, options);
        }
export type DocumentModelOptionsQueryHookResult = ReturnType<typeof useDocumentModelOptionsQuery>;
export type DocumentModelOptionsLazyQueryHookResult = ReturnType<typeof useDocumentModelOptionsLazyQuery>;
export type DocumentModelOptionsQueryResult = Apollo.QueryResult<DocumentModelOptionsQuery, DocumentModelOptionsQueryVariables>;
export const DocumentModelPublicityDocument = gql`
    query DocumentModelPublicity($id: String!) {
  documentModelPrivilege(id: $id) {
    privilege
    model {
      id
      type
      documentModelFields {
        key
        label
        options
        type
      }
    }
  }
}
    `;

/**
 * __useDocumentModelPublicityQuery__
 *
 * To run a query within a React component, call `useDocumentModelPublicityQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentModelPublicityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentModelPublicityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentModelPublicityQuery(baseOptions: Apollo.QueryHookOptions<DocumentModelPublicityQuery, DocumentModelPublicityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentModelPublicityQuery, DocumentModelPublicityQueryVariables>(DocumentModelPublicityDocument, options);
      }
export function useDocumentModelPublicityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentModelPublicityQuery, DocumentModelPublicityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentModelPublicityQuery, DocumentModelPublicityQueryVariables>(DocumentModelPublicityDocument, options);
        }
export type DocumentModelPublicityQueryHookResult = ReturnType<typeof useDocumentModelPublicityQuery>;
export type DocumentModelPublicityLazyQueryHookResult = ReturnType<typeof useDocumentModelPublicityLazyQuery>;
export type DocumentModelPublicityQueryResult = Apollo.QueryResult<DocumentModelPublicityQuery, DocumentModelPublicityQueryVariables>;
export const DocumentShowSceneDocument = gql`
    query DocumentShowScene($id: String!) {
  logs(documentId: $id) {
    createdAt
    type
    id
    message
    user {
      id
      name
    }
  }
  document(id: $id) {
    id
    type
    name
    documentIdentifier
    costs
    category
    isApproved
    isApproveRequired
    isApproveRequestSend
    createdAt
    miroLink
    msLink
    approverId
    userId
    description
    documentContent {
      content
    }
    userStatus
    tags {
      name
    }
    createdBy {
      name
    }
    documentApprovers {
      id
      status
      user {
        id
        name
      }
    }
    groups {
      name
    }
    documentModel {
      id
      type
      documentModelFields {
        key
        id
        label
        type
        options
      }
    }
    documentFields {
      key
      id
      value
    }
    driveFiles {
      id
      mimeType
      storagePath
      name
      createdAt
    }
    documentLinks {
      id
      name
      url
    }
    documentProperties {
      label
      value
    }
  }
}
    `;

/**
 * __useDocumentShowSceneQuery__
 *
 * To run a query within a React component, call `useDocumentShowSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentShowSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentShowSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentShowSceneQuery(baseOptions: Apollo.QueryHookOptions<DocumentShowSceneQuery, DocumentShowSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentShowSceneQuery, DocumentShowSceneQueryVariables>(DocumentShowSceneDocument, options);
      }
export function useDocumentShowSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentShowSceneQuery, DocumentShowSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentShowSceneQuery, DocumentShowSceneQueryVariables>(DocumentShowSceneDocument, options);
        }
export type DocumentShowSceneQueryHookResult = ReturnType<typeof useDocumentShowSceneQuery>;
export type DocumentShowSceneLazyQueryHookResult = ReturnType<typeof useDocumentShowSceneLazyQuery>;
export type DocumentShowSceneQueryResult = Apollo.QueryResult<DocumentShowSceneQuery, DocumentShowSceneQueryVariables>;
export const DriveFileEditSceneDocument = gql`
    query DriveFileEditScene($id: String!) {
  driveFile(id: $id) {
    name
  }
}
    `;

/**
 * __useDriveFileEditSceneQuery__
 *
 * To run a query within a React component, call `useDriveFileEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useDriveFileEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDriveFileEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDriveFileEditSceneQuery(baseOptions: Apollo.QueryHookOptions<DriveFileEditSceneQuery, DriveFileEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DriveFileEditSceneQuery, DriveFileEditSceneQueryVariables>(DriveFileEditSceneDocument, options);
      }
export function useDriveFileEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DriveFileEditSceneQuery, DriveFileEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DriveFileEditSceneQuery, DriveFileEditSceneQueryVariables>(DriveFileEditSceneDocument, options);
        }
export type DriveFileEditSceneQueryHookResult = ReturnType<typeof useDriveFileEditSceneQuery>;
export type DriveFileEditSceneLazyQueryHookResult = ReturnType<typeof useDriveFileEditSceneLazyQuery>;
export type DriveFileEditSceneQueryResult = Apollo.QueryResult<DriveFileEditSceneQuery, DriveFileEditSceneQueryVariables>;
export const EditAclSceneDocument = gql`
    query EditACLScene($id: String!) {
  acl(id: $id) {
    name
  }
}
    `;

/**
 * __useEditAclSceneQuery__
 *
 * To run a query within a React component, call `useEditAclSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditAclSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditAclSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditAclSceneQuery(baseOptions: Apollo.QueryHookOptions<EditAclSceneQuery, EditAclSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditAclSceneQuery, EditAclSceneQueryVariables>(EditAclSceneDocument, options);
      }
export function useEditAclSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditAclSceneQuery, EditAclSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditAclSceneQuery, EditAclSceneQueryVariables>(EditAclSceneDocument, options);
        }
export type EditAclSceneQueryHookResult = ReturnType<typeof useEditAclSceneQuery>;
export type EditAclSceneLazyQueryHookResult = ReturnType<typeof useEditAclSceneLazyQuery>;
export type EditAclSceneQueryResult = Apollo.QueryResult<EditAclSceneQuery, EditAclSceneQueryVariables>;
export const EditorDocument = gql`
    query Editor($id: String!) {
  document(id: $id) {
    documentContent {
      id
      content
    }
  }
}
    `;

/**
 * __useEditorQuery__
 *
 * To run a query within a React component, call `useEditorQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditorQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEditorQuery(baseOptions: Apollo.QueryHookOptions<EditorQuery, EditorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditorQuery, EditorQueryVariables>(EditorDocument, options);
      }
export function useEditorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditorQuery, EditorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditorQuery, EditorQueryVariables>(EditorDocument, options);
        }
export type EditorQueryHookResult = ReturnType<typeof useEditorQuery>;
export type EditorLazyQueryHookResult = ReturnType<typeof useEditorLazyQuery>;
export type EditorQueryResult = Apollo.QueryResult<EditorQuery, EditorQueryVariables>;
export const ExportSceneDocument = gql`
    query ExportScene {
  documentCount
}
    `;

/**
 * __useExportSceneQuery__
 *
 * To run a query within a React component, call `useExportSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportSceneQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportSceneQuery(baseOptions?: Apollo.QueryHookOptions<ExportSceneQuery, ExportSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportSceneQuery, ExportSceneQueryVariables>(ExportSceneDocument, options);
      }
export function useExportSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportSceneQuery, ExportSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportSceneQuery, ExportSceneQueryVariables>(ExportSceneDocument, options);
        }
export type ExportSceneQueryHookResult = ReturnType<typeof useExportSceneQuery>;
export type ExportSceneLazyQueryHookResult = ReturnType<typeof useExportSceneLazyQuery>;
export type ExportSceneQueryResult = Apollo.QueryResult<ExportSceneQuery, ExportSceneQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($body: ForgotPasswordDto!) {
  forgotPassword(body: $body) {
    status
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const GroupEditSceneDocument = gql`
    query GroupEditScene($id: String!) {
  group(id: $id) {
    name
  }
}
    `;

/**
 * __useGroupEditSceneQuery__
 *
 * To run a query within a React component, call `useGroupEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupEditSceneQuery(baseOptions: Apollo.QueryHookOptions<GroupEditSceneQuery, GroupEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupEditSceneQuery, GroupEditSceneQueryVariables>(GroupEditSceneDocument, options);
      }
export function useGroupEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupEditSceneQuery, GroupEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupEditSceneQuery, GroupEditSceneQueryVariables>(GroupEditSceneDocument, options);
        }
export type GroupEditSceneQueryHookResult = ReturnType<typeof useGroupEditSceneQuery>;
export type GroupEditSceneLazyQueryHookResult = ReturnType<typeof useGroupEditSceneLazyQuery>;
export type GroupEditSceneQueryResult = Apollo.QueryResult<GroupEditSceneQuery, GroupEditSceneQueryVariables>;
export const GroupIndexSceneDocument = gql`
    query GroupIndexScene($orderBy: [GroupSort!], $skip: Int, $take: Int, $where: GroupWhere) {
  groups(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      createdAt
      name
    }
    totalCount
  }
}
    `;

/**
 * __useGroupIndexSceneQuery__
 *
 * To run a query within a React component, call `useGroupIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGroupIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<GroupIndexSceneQuery, GroupIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupIndexSceneQuery, GroupIndexSceneQueryVariables>(GroupIndexSceneDocument, options);
      }
export function useGroupIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupIndexSceneQuery, GroupIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupIndexSceneQuery, GroupIndexSceneQueryVariables>(GroupIndexSceneDocument, options);
        }
export type GroupIndexSceneQueryHookResult = ReturnType<typeof useGroupIndexSceneQuery>;
export type GroupIndexSceneLazyQueryHookResult = ReturnType<typeof useGroupIndexSceneLazyQuery>;
export type GroupIndexSceneQueryResult = Apollo.QueryResult<GroupIndexSceneQuery, GroupIndexSceneQueryVariables>;
export const GroupShowSceneDocument = gql`
    query GroupShowScene($id: String!) {
  group(id: $id) {
    users {
      id
      name
    }
    name
  }
}
    `;

/**
 * __useGroupShowSceneQuery__
 *
 * To run a query within a React component, call `useGroupShowSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupShowSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupShowSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGroupShowSceneQuery(baseOptions: Apollo.QueryHookOptions<GroupShowSceneQuery, GroupShowSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GroupShowSceneQuery, GroupShowSceneQueryVariables>(GroupShowSceneDocument, options);
      }
export function useGroupShowSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GroupShowSceneQuery, GroupShowSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GroupShowSceneQuery, GroupShowSceneQueryVariables>(GroupShowSceneDocument, options);
        }
export type GroupShowSceneQueryHookResult = ReturnType<typeof useGroupShowSceneQuery>;
export type GroupShowSceneLazyQueryHookResult = ReturnType<typeof useGroupShowSceneLazyQuery>;
export type GroupShowSceneQueryResult = Apollo.QueryResult<GroupShowSceneQuery, GroupShowSceneQueryVariables>;
export const HomeSceneQncCountDocument = gql`
    query HomeSceneQNCCount($qncModelId: String!) {
  qnc: documentsV2(where: {includeModels: [$qncModelId]}) {
    totalCount
  }
}
    `;

/**
 * __useHomeSceneQncCountQuery__
 *
 * To run a query within a React component, call `useHomeSceneQncCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneQncCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneQncCountQuery({
 *   variables: {
 *      qncModelId: // value for 'qncModelId'
 *   },
 * });
 */
export function useHomeSceneQncCountQuery(baseOptions: Apollo.QueryHookOptions<HomeSceneQncCountQuery, HomeSceneQncCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneQncCountQuery, HomeSceneQncCountQueryVariables>(HomeSceneQncCountDocument, options);
      }
export function useHomeSceneQncCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneQncCountQuery, HomeSceneQncCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneQncCountQuery, HomeSceneQncCountQueryVariables>(HomeSceneQncCountDocument, options);
        }
export type HomeSceneQncCountQueryHookResult = ReturnType<typeof useHomeSceneQncCountQuery>;
export type HomeSceneQncCountLazyQueryHookResult = ReturnType<typeof useHomeSceneQncCountLazyQuery>;
export type HomeSceneQncCountQueryResult = Apollo.QueryResult<HomeSceneQncCountQuery, HomeSceneQncCountQueryVariables>;
export const HomeSceneDocCountDocument = gql`
    query HomeSceneDocCount($documentModelId: String!) {
  document: documentsV2(where: {includeModels: [$documentModelId]}) {
    totalCount
  }
}
    `;

/**
 * __useHomeSceneDocCountQuery__
 *
 * To run a query within a React component, call `useHomeSceneDocCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneDocCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneDocCountQuery({
 *   variables: {
 *      documentModelId: // value for 'documentModelId'
 *   },
 * });
 */
export function useHomeSceneDocCountQuery(baseOptions: Apollo.QueryHookOptions<HomeSceneDocCountQuery, HomeSceneDocCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneDocCountQuery, HomeSceneDocCountQueryVariables>(HomeSceneDocCountDocument, options);
      }
export function useHomeSceneDocCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneDocCountQuery, HomeSceneDocCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneDocCountQuery, HomeSceneDocCountQueryVariables>(HomeSceneDocCountDocument, options);
        }
export type HomeSceneDocCountQueryHookResult = ReturnType<typeof useHomeSceneDocCountQuery>;
export type HomeSceneDocCountLazyQueryHookResult = ReturnType<typeof useHomeSceneDocCountLazyQuery>;
export type HomeSceneDocCountQueryResult = Apollo.QueryResult<HomeSceneDocCountQuery, HomeSceneDocCountQueryVariables>;
export const HomeSceneRiskCountDocument = gql`
    query HomeSceneRiskCount($riskModelId: String!) {
  risk: documentsV2(where: {includeModels: [$riskModelId]}) {
    totalCount
  }
}
    `;

/**
 * __useHomeSceneRiskCountQuery__
 *
 * To run a query within a React component, call `useHomeSceneRiskCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneRiskCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneRiskCountQuery({
 *   variables: {
 *      riskModelId: // value for 'riskModelId'
 *   },
 * });
 */
export function useHomeSceneRiskCountQuery(baseOptions: Apollo.QueryHookOptions<HomeSceneRiskCountQuery, HomeSceneRiskCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneRiskCountQuery, HomeSceneRiskCountQueryVariables>(HomeSceneRiskCountDocument, options);
      }
export function useHomeSceneRiskCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneRiskCountQuery, HomeSceneRiskCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneRiskCountQuery, HomeSceneRiskCountQueryVariables>(HomeSceneRiskCountDocument, options);
        }
export type HomeSceneRiskCountQueryHookResult = ReturnType<typeof useHomeSceneRiskCountQuery>;
export type HomeSceneRiskCountLazyQueryHookResult = ReturnType<typeof useHomeSceneRiskCountLazyQuery>;
export type HomeSceneRiskCountQueryResult = Apollo.QueryResult<HomeSceneRiskCountQuery, HomeSceneRiskCountQueryVariables>;
export const HomeSceneCustomCountDocument = gql`
    query HomeSceneCustomCount($where: DocumentV2Where!) {
  documentsV2(where: $where) {
    totalCount
  }
}
    `;

/**
 * __useHomeSceneCustomCountQuery__
 *
 * To run a query within a React component, call `useHomeSceneCustomCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneCustomCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneCustomCountQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useHomeSceneCustomCountQuery(baseOptions: Apollo.QueryHookOptions<HomeSceneCustomCountQuery, HomeSceneCustomCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneCustomCountQuery, HomeSceneCustomCountQueryVariables>(HomeSceneCustomCountDocument, options);
      }
export function useHomeSceneCustomCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneCustomCountQuery, HomeSceneCustomCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneCustomCountQuery, HomeSceneCustomCountQueryVariables>(HomeSceneCustomCountDocument, options);
        }
export type HomeSceneCustomCountQueryHookResult = ReturnType<typeof useHomeSceneCustomCountQuery>;
export type HomeSceneCustomCountLazyQueryHookResult = ReturnType<typeof useHomeSceneCustomCountLazyQuery>;
export type HomeSceneCustomCountQueryResult = Apollo.QueryResult<HomeSceneCustomCountQuery, HomeSceneCustomCountQueryVariables>;
export const HomeSceneReportDocument = gql`
    query HomeSceneReport($end: String!, $start: String!) {
  qncCostsReport(end: $end, start: $start) {
    total
    start
    end
    result {
      name
      costs
    }
  }
  qncCountReport(end: $end, start: $start) {
    total
    start
    end
    result {
      count
      name
      approved
    }
  }
  qncCategoryReport(end: $end, start: $start) {
    total
    start
    end
    result {
      name
      data {
        internalNonConformity
        customerReclamation
        supplierReclamation
        initiative
        safetyAccident
        safetyObservation
        environmentObservation
        internalAudit
        externalAudit
        other
      }
    }
  }
}
    `;

/**
 * __useHomeSceneReportQuery__
 *
 * To run a query within a React component, call `useHomeSceneReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeSceneReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeSceneReportQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useHomeSceneReportQuery(baseOptions: Apollo.QueryHookOptions<HomeSceneReportQuery, HomeSceneReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeSceneReportQuery, HomeSceneReportQueryVariables>(HomeSceneReportDocument, options);
      }
export function useHomeSceneReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeSceneReportQuery, HomeSceneReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeSceneReportQuery, HomeSceneReportQueryVariables>(HomeSceneReportDocument, options);
        }
export type HomeSceneReportQueryHookResult = ReturnType<typeof useHomeSceneReportQuery>;
export type HomeSceneReportLazyQueryHookResult = ReturnType<typeof useHomeSceneReportLazyQuery>;
export type HomeSceneReportQueryResult = Apollo.QueryResult<HomeSceneReportQuery, HomeSceneReportQueryVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($body: InviteUserDTO!) {
  inviteUser(body: $body) {
    id
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const LoginDocument = gql`
    mutation Login($body: LoginDto!) {
  login(body: $body) {
    token
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const MsLinkSceneDocument = gql`
    query MSLinkScene($id: String!) {
  document(id: $id) {
    msLink
  }
}
    `;

/**
 * __useMsLinkSceneQuery__
 *
 * To run a query within a React component, call `useMsLinkSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useMsLinkSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMsLinkSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMsLinkSceneQuery(baseOptions: Apollo.QueryHookOptions<MsLinkSceneQuery, MsLinkSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MsLinkSceneQuery, MsLinkSceneQueryVariables>(MsLinkSceneDocument, options);
      }
export function useMsLinkSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MsLinkSceneQuery, MsLinkSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MsLinkSceneQuery, MsLinkSceneQueryVariables>(MsLinkSceneDocument, options);
        }
export type MsLinkSceneQueryHookResult = ReturnType<typeof useMsLinkSceneQuery>;
export type MsLinkSceneLazyQueryHookResult = ReturnType<typeof useMsLinkSceneLazyQuery>;
export type MsLinkSceneQueryResult = Apollo.QueryResult<MsLinkSceneQuery, MsLinkSceneQueryVariables>;
export const MiroLinkDocument = gql`
    query MiroLink($id: String!) {
  document(id: $id) {
    miroLink
  }
}
    `;

/**
 * __useMiroLinkQuery__
 *
 * To run a query within a React component, call `useMiroLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiroLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMiroLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMiroLinkQuery(baseOptions: Apollo.QueryHookOptions<MiroLinkQuery, MiroLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MiroLinkQuery, MiroLinkQueryVariables>(MiroLinkDocument, options);
      }
export function useMiroLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MiroLinkQuery, MiroLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MiroLinkQuery, MiroLinkQueryVariables>(MiroLinkDocument, options);
        }
export type MiroLinkQueryHookResult = ReturnType<typeof useMiroLinkQuery>;
export type MiroLinkLazyQueryHookResult = ReturnType<typeof useMiroLinkLazyQuery>;
export type MiroLinkQueryResult = Apollo.QueryResult<MiroLinkQuery, MiroLinkQueryVariables>;
export const PublicDocumentCreateDocument = gql`
    mutation PublicDocumentCreate($body: CreateDocumentDTO!) {
  createPublicDocument(body: $body) {
    id
  }
}
    `;
export type PublicDocumentCreateMutationFn = Apollo.MutationFunction<PublicDocumentCreateMutation, PublicDocumentCreateMutationVariables>;

/**
 * __usePublicDocumentCreateMutation__
 *
 * To run a mutation, you first call `usePublicDocumentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublicDocumentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publicDocumentCreateMutation, { data, loading, error }] = usePublicDocumentCreateMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function usePublicDocumentCreateMutation(baseOptions?: Apollo.MutationHookOptions<PublicDocumentCreateMutation, PublicDocumentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublicDocumentCreateMutation, PublicDocumentCreateMutationVariables>(PublicDocumentCreateDocument, options);
      }
export type PublicDocumentCreateMutationHookResult = ReturnType<typeof usePublicDocumentCreateMutation>;
export type PublicDocumentCreateMutationResult = Apollo.MutationResult<PublicDocumentCreateMutation>;
export type PublicDocumentCreateMutationOptions = Apollo.BaseMutationOptions<PublicDocumentCreateMutation, PublicDocumentCreateMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($body: RegisterDto!) {
  register(body: $body) {
    token
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const RejectDocumentDocument = gql`
    mutation RejectDocument($documentApproverId: String!, $body: RejectDocumentDTO!) {
  rejectDocument(documentApproverId: $documentApproverId, body: $body) {
    id
  }
}
    `;
export type RejectDocumentMutationFn = Apollo.MutationFunction<RejectDocumentMutation, RejectDocumentMutationVariables>;

/**
 * __useRejectDocumentMutation__
 *
 * To run a mutation, you first call `useRejectDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDocumentMutation, { data, loading, error }] = useRejectDocumentMutation({
 *   variables: {
 *      documentApproverId: // value for 'documentApproverId'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRejectDocumentMutation(baseOptions?: Apollo.MutationHookOptions<RejectDocumentMutation, RejectDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectDocumentMutation, RejectDocumentMutationVariables>(RejectDocumentDocument, options);
      }
export type RejectDocumentMutationHookResult = ReturnType<typeof useRejectDocumentMutation>;
export type RejectDocumentMutationResult = Apollo.MutationResult<RejectDocumentMutation>;
export type RejectDocumentMutationOptions = Apollo.BaseMutationOptions<RejectDocumentMutation, RejectDocumentMutationVariables>;
export const GetCustomReportsDocument = gql`
    query GetCustomReports {
  getCustomReports {
    id
    name
  }
}
    `;

/**
 * __useGetCustomReportsQuery__
 *
 * To run a query within a React component, call `useGetCustomReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetCustomReportsQuery, GetCustomReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomReportsQuery, GetCustomReportsQueryVariables>(GetCustomReportsDocument, options);
      }
export function useGetCustomReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomReportsQuery, GetCustomReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomReportsQuery, GetCustomReportsQueryVariables>(GetCustomReportsDocument, options);
        }
export type GetCustomReportsQueryHookResult = ReturnType<typeof useGetCustomReportsQuery>;
export type GetCustomReportsLazyQueryHookResult = ReturnType<typeof useGetCustomReportsLazyQuery>;
export type GetCustomReportsQueryResult = Apollo.QueryResult<GetCustomReportsQuery, GetCustomReportsQueryVariables>;
export const ReportQncCostSceneDocument = gql`
    query ReportQncCostScene($end: String!, $start: String!) {
  qncCostsReport(end: $end, start: $start) {
    total
    start
    end
    result {
      name
      costs
    }
  }
}
    `;

/**
 * __useReportQncCostSceneQuery__
 *
 * To run a query within a React component, call `useReportQncCostSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQncCostSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQncCostSceneQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useReportQncCostSceneQuery(baseOptions: Apollo.QueryHookOptions<ReportQncCostSceneQuery, ReportQncCostSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQncCostSceneQuery, ReportQncCostSceneQueryVariables>(ReportQncCostSceneDocument, options);
      }
export function useReportQncCostSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQncCostSceneQuery, ReportQncCostSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQncCostSceneQuery, ReportQncCostSceneQueryVariables>(ReportQncCostSceneDocument, options);
        }
export type ReportQncCostSceneQueryHookResult = ReturnType<typeof useReportQncCostSceneQuery>;
export type ReportQncCostSceneLazyQueryHookResult = ReturnType<typeof useReportQncCostSceneLazyQuery>;
export type ReportQncCostSceneQueryResult = Apollo.QueryResult<ReportQncCostSceneQuery, ReportQncCostSceneQueryVariables>;
export const ReportQncCountSceneDocument = gql`
    query ReportQncCountScene($end: String!, $start: String!) {
  qncCountReport(end: $end, start: $start) {
    total
    start
    end
    result {
      count
      name
      approved
    }
  }
}
    `;

/**
 * __useReportQncCountSceneQuery__
 *
 * To run a query within a React component, call `useReportQncCountSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQncCountSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQncCountSceneQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useReportQncCountSceneQuery(baseOptions: Apollo.QueryHookOptions<ReportQncCountSceneQuery, ReportQncCountSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQncCountSceneQuery, ReportQncCountSceneQueryVariables>(ReportQncCountSceneDocument, options);
      }
export function useReportQncCountSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQncCountSceneQuery, ReportQncCountSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQncCountSceneQuery, ReportQncCountSceneQueryVariables>(ReportQncCountSceneDocument, options);
        }
export type ReportQncCountSceneQueryHookResult = ReturnType<typeof useReportQncCountSceneQuery>;
export type ReportQncCountSceneLazyQueryHookResult = ReturnType<typeof useReportQncCountSceneLazyQuery>;
export type ReportQncCountSceneQueryResult = Apollo.QueryResult<ReportQncCountSceneQuery, ReportQncCountSceneQueryVariables>;
export const ReportQncTagSceneDocument = gql`
    query ReportQncTagScene($end: String!, $start: String!) {
  qncTagReport(end: $end, start: $start) {
    total
    start
    end
    result {
      name
      data {
        name
        count
      }
    }
  }
}
    `;

/**
 * __useReportQncTagSceneQuery__
 *
 * To run a query within a React component, call `useReportQncTagSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQncTagSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQncTagSceneQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useReportQncTagSceneQuery(baseOptions: Apollo.QueryHookOptions<ReportQncTagSceneQuery, ReportQncTagSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQncTagSceneQuery, ReportQncTagSceneQueryVariables>(ReportQncTagSceneDocument, options);
      }
export function useReportQncTagSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQncTagSceneQuery, ReportQncTagSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQncTagSceneQuery, ReportQncTagSceneQueryVariables>(ReportQncTagSceneDocument, options);
        }
export type ReportQncTagSceneQueryHookResult = ReturnType<typeof useReportQncTagSceneQuery>;
export type ReportQncTagSceneLazyQueryHookResult = ReturnType<typeof useReportQncTagSceneLazyQuery>;
export type ReportQncTagSceneQueryResult = Apollo.QueryResult<ReportQncTagSceneQuery, ReportQncTagSceneQueryVariables>;
export const ReportQncTypeSceneDocument = gql`
    query ReportQncTypeScene($end: String!, $start: String!) {
  qncCategoryReport(end: $end, start: $start) {
    total
    start
    end
    result {
      name
      data {
        internalNonConformity
        customerReclamation
        supplierReclamation
        initiative
        safetyAccident
        safetyObservation
        environmentObservation
        internalAudit
        externalAudit
        other
      }
    }
  }
}
    `;

/**
 * __useReportQncTypeSceneQuery__
 *
 * To run a query within a React component, call `useReportQncTypeSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQncTypeSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQncTypeSceneQuery({
 *   variables: {
 *      end: // value for 'end'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useReportQncTypeSceneQuery(baseOptions: Apollo.QueryHookOptions<ReportQncTypeSceneQuery, ReportQncTypeSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQncTypeSceneQuery, ReportQncTypeSceneQueryVariables>(ReportQncTypeSceneDocument, options);
      }
export function useReportQncTypeSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQncTypeSceneQuery, ReportQncTypeSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQncTypeSceneQuery, ReportQncTypeSceneQueryVariables>(ReportQncTypeSceneDocument, options);
        }
export type ReportQncTypeSceneQueryHookResult = ReturnType<typeof useReportQncTypeSceneQuery>;
export type ReportQncTypeSceneLazyQueryHookResult = ReturnType<typeof useReportQncTypeSceneLazyQuery>;
export type ReportQncTypeSceneQueryResult = Apollo.QueryResult<ReportQncTypeSceneQuery, ReportQncTypeSceneQueryVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($body: ResetPasswordDto!) {
  resetPassword(body: $body) {
    status
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SendAllDocumentApproveRequestDocument = gql`
    mutation sendAllDocumentApproveRequest($documentId: String!) {
  sendAllDocumentApproveRequest(documentId: $documentId)
}
    `;
export type SendAllDocumentApproveRequestMutationFn = Apollo.MutationFunction<SendAllDocumentApproveRequestMutation, SendAllDocumentApproveRequestMutationVariables>;

/**
 * __useSendAllDocumentApproveRequestMutation__
 *
 * To run a mutation, you first call `useSendAllDocumentApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendAllDocumentApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendAllDocumentApproveRequestMutation, { data, loading, error }] = useSendAllDocumentApproveRequestMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useSendAllDocumentApproveRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendAllDocumentApproveRequestMutation, SendAllDocumentApproveRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAllDocumentApproveRequestMutation, SendAllDocumentApproveRequestMutationVariables>(SendAllDocumentApproveRequestDocument, options);
      }
export type SendAllDocumentApproveRequestMutationHookResult = ReturnType<typeof useSendAllDocumentApproveRequestMutation>;
export type SendAllDocumentApproveRequestMutationResult = Apollo.MutationResult<SendAllDocumentApproveRequestMutation>;
export type SendAllDocumentApproveRequestMutationOptions = Apollo.BaseMutationOptions<SendAllDocumentApproveRequestMutation, SendAllDocumentApproveRequestMutationVariables>;
export const SendDocumentApproveRequestDocument = gql`
    mutation SendDocumentApproveRequest($documentApproverId: String!) {
  sendDocumentApproveRequest(documentApproverId: $documentApproverId) {
    id
  }
}
    `;
export type SendDocumentApproveRequestMutationFn = Apollo.MutationFunction<SendDocumentApproveRequestMutation, SendDocumentApproveRequestMutationVariables>;

/**
 * __useSendDocumentApproveRequestMutation__
 *
 * To run a mutation, you first call `useSendDocumentApproveRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendDocumentApproveRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendDocumentApproveRequestMutation, { data, loading, error }] = useSendDocumentApproveRequestMutation({
 *   variables: {
 *      documentApproverId: // value for 'documentApproverId'
 *   },
 * });
 */
export function useSendDocumentApproveRequestMutation(baseOptions?: Apollo.MutationHookOptions<SendDocumentApproveRequestMutation, SendDocumentApproveRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendDocumentApproveRequestMutation, SendDocumentApproveRequestMutationVariables>(SendDocumentApproveRequestDocument, options);
      }
export type SendDocumentApproveRequestMutationHookResult = ReturnType<typeof useSendDocumentApproveRequestMutation>;
export type SendDocumentApproveRequestMutationResult = Apollo.MutationResult<SendDocumentApproveRequestMutation>;
export type SendDocumentApproveRequestMutationOptions = Apollo.BaseMutationOptions<SendDocumentApproveRequestMutation, SendDocumentApproveRequestMutationVariables>;
export const SendReminderDocument = gql`
    mutation SendReminder($documentApproverId: String!, $message: String!) {
  sendReminder(documentApproverId: $documentApproverId, message: $message) {
    id
  }
}
    `;
export type SendReminderMutationFn = Apollo.MutationFunction<SendReminderMutation, SendReminderMutationVariables>;

/**
 * __useSendReminderMutation__
 *
 * To run a mutation, you first call `useSendReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendReminderMutation, { data, loading, error }] = useSendReminderMutation({
 *   variables: {
 *      documentApproverId: // value for 'documentApproverId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useSendReminderMutation(baseOptions?: Apollo.MutationHookOptions<SendReminderMutation, SendReminderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendReminderMutation, SendReminderMutationVariables>(SendReminderDocument, options);
      }
export type SendReminderMutationHookResult = ReturnType<typeof useSendReminderMutation>;
export type SendReminderMutationResult = Apollo.MutationResult<SendReminderMutation>;
export type SendReminderMutationOptions = Apollo.BaseMutationOptions<SendReminderMutation, SendReminderMutationVariables>;
export const TagEditSceneDocument = gql`
    query TagEditScene($id: String!) {
  tag(id: $id) {
    name
  }
}
    `;

/**
 * __useTagEditSceneQuery__
 *
 * To run a query within a React component, call `useTagEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagEditSceneQuery(baseOptions: Apollo.QueryHookOptions<TagEditSceneQuery, TagEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagEditSceneQuery, TagEditSceneQueryVariables>(TagEditSceneDocument, options);
      }
export function useTagEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagEditSceneQuery, TagEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagEditSceneQuery, TagEditSceneQueryVariables>(TagEditSceneDocument, options);
        }
export type TagEditSceneQueryHookResult = ReturnType<typeof useTagEditSceneQuery>;
export type TagEditSceneLazyQueryHookResult = ReturnType<typeof useTagEditSceneLazyQuery>;
export type TagEditSceneQueryResult = Apollo.QueryResult<TagEditSceneQuery, TagEditSceneQueryVariables>;
export const TagIndexSceneDocument = gql`
    query TagIndexScene($orderBy: [TagSort!], $skip: Int, $take: Int, $where: TagWhere) {
  tags(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      id
      name
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __useTagIndexSceneQuery__
 *
 * To run a query within a React component, call `useTagIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useTagIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<TagIndexSceneQuery, TagIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagIndexSceneQuery, TagIndexSceneQueryVariables>(TagIndexSceneDocument, options);
      }
export function useTagIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagIndexSceneQuery, TagIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagIndexSceneQuery, TagIndexSceneQueryVariables>(TagIndexSceneDocument, options);
        }
export type TagIndexSceneQueryHookResult = ReturnType<typeof useTagIndexSceneQuery>;
export type TagIndexSceneLazyQueryHookResult = ReturnType<typeof useTagIndexSceneLazyQuery>;
export type TagIndexSceneQueryResult = Apollo.QueryResult<TagIndexSceneQuery, TagIndexSceneQueryVariables>;
export const TeamEditSceneDocument = gql`
    query TeamEditScene($id: String!) {
  user(id: $id) {
    groups {
      id
      name
    }
    defaultGroups {
      id
      name
    }
    resourceACLId
    defaultApproverId
    isApprover
    isCompanyAdmin
    isAnonymousHandler
    isCompanyOwner
    wantWeeklyNotification
    name
  }
  acls {
    list {
      value: id
      label: name
    }
  }
  groups(take: 300, orderBy: [{name: "asc"}]) {
    list {
      value: id
      label: name
    }
  }
  allDocumentApprovers {
    value: id
    label: name
  }
}
    `;

/**
 * __useTeamEditSceneQuery__
 *
 * To run a query within a React component, call `useTeamEditSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamEditSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamEditSceneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamEditSceneQuery(baseOptions: Apollo.QueryHookOptions<TeamEditSceneQuery, TeamEditSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamEditSceneQuery, TeamEditSceneQueryVariables>(TeamEditSceneDocument, options);
      }
export function useTeamEditSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamEditSceneQuery, TeamEditSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamEditSceneQuery, TeamEditSceneQueryVariables>(TeamEditSceneDocument, options);
        }
export type TeamEditSceneQueryHookResult = ReturnType<typeof useTeamEditSceneQuery>;
export type TeamEditSceneLazyQueryHookResult = ReturnType<typeof useTeamEditSceneLazyQuery>;
export type TeamEditSceneQueryResult = Apollo.QueryResult<TeamEditSceneQuery, TeamEditSceneQueryVariables>;
export const UpdateAclDocument = gql`
    mutation UpdateACL($id: String!, $body: ResourceACLDTO!) {
  updateACL(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateAclMutationFn = Apollo.MutationFunction<UpdateAclMutation, UpdateAclMutationVariables>;

/**
 * __useUpdateAclMutation__
 *
 * To run a mutation, you first call `useUpdateAclMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAclMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAclMutation, { data, loading, error }] = useUpdateAclMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateAclMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAclMutation, UpdateAclMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAclMutation, UpdateAclMutationVariables>(UpdateAclDocument, options);
      }
export type UpdateAclMutationHookResult = ReturnType<typeof useUpdateAclMutation>;
export type UpdateAclMutationResult = Apollo.MutationResult<UpdateAclMutation>;
export type UpdateAclMutationOptions = Apollo.BaseMutationOptions<UpdateAclMutation, UpdateAclMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($body: UpdateCompanyDTO!, $id: String!) {
  updateCompany(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($body: UpdateDocumentDTO!, $id: String!) {
  updateDocument(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const UpdateDocumentContentDocument = gql`
    mutation UpdateDocumentContent($body: UpdateDocumentContentDTO!, $documentContentId: String!, $id: String!) {
  updateDocumentContent(
    body: $body
    documentContentId: $documentContentId
    id: $id
  ) {
    id
  }
}
    `;
export type UpdateDocumentContentMutationFn = Apollo.MutationFunction<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>;

/**
 * __useUpdateDocumentContentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentContentMutation, { data, loading, error }] = useUpdateDocumentContentMutation({
 *   variables: {
 *      body: // value for 'body'
 *      documentContentId: // value for 'documentContentId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentContentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>(UpdateDocumentContentDocument, options);
      }
export type UpdateDocumentContentMutationHookResult = ReturnType<typeof useUpdateDocumentContentMutation>;
export type UpdateDocumentContentMutationResult = Apollo.MutationResult<UpdateDocumentContentMutation>;
export type UpdateDocumentContentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentContentMutation, UpdateDocumentContentMutationVariables>;
export const UpdateDocumentLinkDocument = gql`
    mutation UpdateDocumentLink($id: String!, $body: UpdateDocumentLinkDTO!) {
  updateDocumentLink(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateDocumentLinkMutationFn = Apollo.MutationFunction<UpdateDocumentLinkMutation, UpdateDocumentLinkMutationVariables>;

/**
 * __useUpdateDocumentLinkMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentLinkMutation, { data, loading, error }] = useUpdateDocumentLinkMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateDocumentLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentLinkMutation, UpdateDocumentLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentLinkMutation, UpdateDocumentLinkMutationVariables>(UpdateDocumentLinkDocument, options);
      }
export type UpdateDocumentLinkMutationHookResult = ReturnType<typeof useUpdateDocumentLinkMutation>;
export type UpdateDocumentLinkMutationResult = Apollo.MutationResult<UpdateDocumentLinkMutation>;
export type UpdateDocumentLinkMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentLinkMutation, UpdateDocumentLinkMutationVariables>;
export const UpdateDocumentModelDocument = gql`
    mutation UpdateDocumentModel($body: UpdateDocumentModelDTO!, $id: String!) {
  updateDocumentModel(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDocumentModelMutationFn = Apollo.MutationFunction<UpdateDocumentModelMutation, UpdateDocumentModelMutationVariables>;

/**
 * __useUpdateDocumentModelMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentModelMutation, { data, loading, error }] = useUpdateDocumentModelMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDocumentModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentModelMutation, UpdateDocumentModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentModelMutation, UpdateDocumentModelMutationVariables>(UpdateDocumentModelDocument, options);
      }
export type UpdateDocumentModelMutationHookResult = ReturnType<typeof useUpdateDocumentModelMutation>;
export type UpdateDocumentModelMutationResult = Apollo.MutationResult<UpdateDocumentModelMutation>;
export type UpdateDocumentModelMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentModelMutation, UpdateDocumentModelMutationVariables>;
export const UpdateDriveFileDocument = gql`
    mutation UpdateDriveFile($body: UpdateDriveFileDTO!, $id: String!) {
  updateDriveFile(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateDriveFileMutationFn = Apollo.MutationFunction<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>;

/**
 * __useUpdateDriveFileMutation__
 *
 * To run a mutation, you first call `useUpdateDriveFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDriveFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDriveFileMutation, { data, loading, error }] = useUpdateDriveFileMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateDriveFileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>(UpdateDriveFileDocument, options);
      }
export type UpdateDriveFileMutationHookResult = ReturnType<typeof useUpdateDriveFileMutation>;
export type UpdateDriveFileMutationResult = Apollo.MutationResult<UpdateDriveFileMutation>;
export type UpdateDriveFileMutationOptions = Apollo.BaseMutationOptions<UpdateDriveFileMutation, UpdateDriveFileMutationVariables>;
export const UpdateGroupDocument = gql`
    mutation UpdateGroup($body: UpdateGroupDTO!, $id: String!) {
  updateGroup(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateGroupMutationFn = Apollo.MutationFunction<UpdateGroupMutation, UpdateGroupMutationVariables>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGroupMutation, UpdateGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(UpdateGroupDocument, options);
      }
export type UpdateGroupMutationHookResult = ReturnType<typeof useUpdateGroupMutation>;
export type UpdateGroupMutationResult = Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<UpdateGroupMutation, UpdateGroupMutationVariables>;
export const UpdateMsLinkDocument = gql`
    mutation UpdateMSLink($body: UpdateMSLinkDTO!, $id: String!) {
  updateMSLink(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateMsLinkMutationFn = Apollo.MutationFunction<UpdateMsLinkMutation, UpdateMsLinkMutationVariables>;

/**
 * __useUpdateMsLinkMutation__
 *
 * To run a mutation, you first call `useUpdateMsLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMsLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMsLinkMutation, { data, loading, error }] = useUpdateMsLinkMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMsLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMsLinkMutation, UpdateMsLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMsLinkMutation, UpdateMsLinkMutationVariables>(UpdateMsLinkDocument, options);
      }
export type UpdateMsLinkMutationHookResult = ReturnType<typeof useUpdateMsLinkMutation>;
export type UpdateMsLinkMutationResult = Apollo.MutationResult<UpdateMsLinkMutation>;
export type UpdateMsLinkMutationOptions = Apollo.BaseMutationOptions<UpdateMsLinkMutation, UpdateMsLinkMutationVariables>;
export const UpdateMiroLinkDocument = gql`
    mutation UpdateMiroLink($body: UpdateMiroLinkDTO!, $id: String!) {
  updateMiroLink(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateMiroLinkMutationFn = Apollo.MutationFunction<UpdateMiroLinkMutation, UpdateMiroLinkMutationVariables>;

/**
 * __useUpdateMiroLinkMutation__
 *
 * To run a mutation, you first call `useUpdateMiroLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMiroLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMiroLinkMutation, { data, loading, error }] = useUpdateMiroLinkMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateMiroLinkMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMiroLinkMutation, UpdateMiroLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMiroLinkMutation, UpdateMiroLinkMutationVariables>(UpdateMiroLinkDocument, options);
      }
export type UpdateMiroLinkMutationHookResult = ReturnType<typeof useUpdateMiroLinkMutation>;
export type UpdateMiroLinkMutationResult = Apollo.MutationResult<UpdateMiroLinkMutation>;
export type UpdateMiroLinkMutationOptions = Apollo.BaseMutationOptions<UpdateMiroLinkMutation, UpdateMiroLinkMutationVariables>;
export const UpdateTagDocument = gql`
    mutation UpdateTag($body: UpdateTagDTO!, $id: String!) {
  updateTag(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
      }
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateTeamDocument = gql`
    mutation UpdateTeam($body: UpdateTeamDTO!, $id: String!) {
  updateTeam(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateTeamMutationFn = Apollo.MutationFunction<UpdateTeamMutation, UpdateTeamMutationVariables>;

/**
 * __useUpdateTeamMutation__
 *
 * To run a mutation, you first call `useUpdateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTeamMutation, { data, loading, error }] = useUpdateTeamMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateTeamMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTeamMutation, UpdateTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTeamMutation, UpdateTeamMutationVariables>(UpdateTeamDocument, options);
      }
export type UpdateTeamMutationHookResult = ReturnType<typeof useUpdateTeamMutation>;
export type UpdateTeamMutationResult = Apollo.MutationResult<UpdateTeamMutation>;
export type UpdateTeamMutationOptions = Apollo.BaseMutationOptions<UpdateTeamMutation, UpdateTeamMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($body: UpdateUserDTO!, $id: String!) {
  updateUser(body: $body, id: $id) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      body: // value for 'body'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UserEditWhoAmIDocument = gql`
    query UserEditWhoAmI {
  whoAmI {
    email
    name
    wantWeeklyNotification
    language
  }
}
    `;

/**
 * __useUserEditWhoAmIQuery__
 *
 * To run a query within a React component, call `useUserEditWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEditWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
      }
export function useUserEditWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>(UserEditWhoAmIDocument, options);
        }
export type UserEditWhoAmIQueryHookResult = ReturnType<typeof useUserEditWhoAmIQuery>;
export type UserEditWhoAmILazyQueryHookResult = ReturnType<typeof useUserEditWhoAmILazyQuery>;
export type UserEditWhoAmIQueryResult = Apollo.QueryResult<UserEditWhoAmIQuery, UserEditWhoAmIQueryVariables>;
export const UserIndexSceneDocument = gql`
    query UserIndexScene($orderBy: [UserSort!], $skip: Int, $take: Int, $where: UserWhere) {
  users(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    list {
      email
      id
      createdAt
      isApprover
      name
      acl {
        name
      }
      groups {
        name
      }
    }
    totalCount
  }
}
    `;

/**
 * __useUserIndexSceneQuery__
 *
 * To run a query within a React component, call `useUserIndexSceneQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserIndexSceneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserIndexSceneQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUserIndexSceneQuery(baseOptions?: Apollo.QueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
      }
export function useUserIndexSceneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserIndexSceneQuery, UserIndexSceneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserIndexSceneQuery, UserIndexSceneQueryVariables>(UserIndexSceneDocument, options);
        }
export type UserIndexSceneQueryHookResult = ReturnType<typeof useUserIndexSceneQuery>;
export type UserIndexSceneLazyQueryHookResult = ReturnType<typeof useUserIndexSceneLazyQuery>;
export type UserIndexSceneQueryResult = Apollo.QueryResult<UserIndexSceneQuery, UserIndexSceneQueryVariables>;
export const ValidateInvitationTokenDocument = gql`
    query ValidateInvitationToken($body: InvitationTokenDto!) {
  validateInvitationToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateInvitationTokenQuery__
 *
 * To run a query within a React component, call `useValidateInvitationTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateInvitationTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateInvitationTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateInvitationTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>(ValidateInvitationTokenDocument, options);
      }
export function useValidateInvitationTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>(ValidateInvitationTokenDocument, options);
        }
export type ValidateInvitationTokenQueryHookResult = ReturnType<typeof useValidateInvitationTokenQuery>;
export type ValidateInvitationTokenLazyQueryHookResult = ReturnType<typeof useValidateInvitationTokenLazyQuery>;
export type ValidateInvitationTokenQueryResult = Apollo.QueryResult<ValidateInvitationTokenQuery, ValidateInvitationTokenQueryVariables>;
export const ValidateResetTokenDocument = gql`
    query ValidateResetToken($body: ValidateResetTokenDto!) {
  validateResetToken(body: $body) {
    status
  }
}
    `;

/**
 * __useValidateResetTokenQuery__
 *
 * To run a query within a React component, call `useValidateResetTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateResetTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateResetTokenQuery({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useValidateResetTokenQuery(baseOptions: Apollo.QueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
      }
export function useValidateResetTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>(ValidateResetTokenDocument, options);
        }
export type ValidateResetTokenQueryHookResult = ReturnType<typeof useValidateResetTokenQuery>;
export type ValidateResetTokenLazyQueryHookResult = ReturnType<typeof useValidateResetTokenLazyQuery>;
export type ValidateResetTokenQueryResult = Apollo.QueryResult<ValidateResetTokenQuery, ValidateResetTokenQueryVariables>;
export const ViewPrivilegeDocument = gql`
    query ViewPrivilege {
  viewPrivilege {
    risk {
      modelId
      create
      read
      update
      delete
    }
    document {
      modelId
      create
      read
      update
      delete
    }
    qnc {
      modelId
      create
      read
      update
      delete
    }
    anonymous {
      modelId
      create
      read
      update
      delete
    }
  }
}
    `;

/**
 * __useViewPrivilegeQuery__
 *
 * To run a query within a React component, call `useViewPrivilegeQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPrivilegeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPrivilegeQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewPrivilegeQuery(baseOptions?: Apollo.QueryHookOptions<ViewPrivilegeQuery, ViewPrivilegeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ViewPrivilegeQuery, ViewPrivilegeQueryVariables>(ViewPrivilegeDocument, options);
      }
export function useViewPrivilegeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ViewPrivilegeQuery, ViewPrivilegeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ViewPrivilegeQuery, ViewPrivilegeQueryVariables>(ViewPrivilegeDocument, options);
        }
export type ViewPrivilegeQueryHookResult = ReturnType<typeof useViewPrivilegeQuery>;
export type ViewPrivilegeLazyQueryHookResult = ReturnType<typeof useViewPrivilegeLazyQuery>;
export type ViewPrivilegeQueryResult = Apollo.QueryResult<ViewPrivilegeQuery, ViewPrivilegeQueryVariables>;
export const WhoAmIDocument = gql`
    query WhoAmI {
  whoAmI {
    id
    language
    name
    email
    isCompanyAdmin
    isAnonymousHandler
    isCompanyOwner
    defaultGroups {
      id
      name
    }
    defaultApproverId
    myCustomDocuments {
      create
      edit
      read
      delete
    }
    company {
      id
      name
      features {
        key
        value
      }
    }
  }
  documentModelIdObject {
    risk
    qnc
    document
    anonymous
  }
}
    `;

/**
 * __useWhoAmIQuery__
 *
 * To run a query within a React component, call `useWhoAmIQuery` and pass it any options that fit your needs.
 * When your component renders, `useWhoAmIQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWhoAmIQuery({
 *   variables: {
 *   },
 * });
 */
export function useWhoAmIQuery(baseOptions?: Apollo.QueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
      }
export function useWhoAmILazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WhoAmIQuery, WhoAmIQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WhoAmIQuery, WhoAmIQueryVariables>(WhoAmIDocument, options);
        }
export type WhoAmIQueryHookResult = ReturnType<typeof useWhoAmIQuery>;
export type WhoAmILazyQueryHookResult = ReturnType<typeof useWhoAmILazyQuery>;
export type WhoAmIQueryResult = Apollo.QueryResult<WhoAmIQuery, WhoAmIQueryVariables>;
export const AccountManagementDocument = gql`
    query AccountManagement {
  accountFeatures {
    id
    key
    value
  }
}
    `;

/**
 * __useAccountManagementQuery__
 *
 * To run a query within a React component, call `useAccountManagementQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountManagementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountManagementQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountManagementQuery(baseOptions?: Apollo.QueryHookOptions<AccountManagementQuery, AccountManagementQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountManagementQuery, AccountManagementQueryVariables>(AccountManagementDocument, options);
      }
export function useAccountManagementLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountManagementQuery, AccountManagementQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountManagementQuery, AccountManagementQueryVariables>(AccountManagementDocument, options);
        }
export type AccountManagementQueryHookResult = ReturnType<typeof useAccountManagementQuery>;
export type AccountManagementLazyQueryHookResult = ReturnType<typeof useAccountManagementLazyQuery>;
export type AccountManagementQueryResult = Apollo.QueryResult<AccountManagementQuery, AccountManagementQueryVariables>;
export const UpdateFeatureDocument = gql`
    mutation UpdateFeature($key: String!, $value: String!) {
  updateFeature(key: $key, value: $value) {
    key
    value
  }
}
    `;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      key: // value for 'key'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const CreateRiskDocument = gql`
    mutation CreateRisk($body: String!) {
  createRiskFromForm(body: $body) {
    id
  }
}
    `;
export type CreateRiskMutationFn = Apollo.MutationFunction<CreateRiskMutation, CreateRiskMutationVariables>;

/**
 * __useCreateRiskMutation__
 *
 * To run a mutation, you first call `useCreateRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRiskMutation, { data, loading, error }] = useCreateRiskMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateRiskMutation(baseOptions?: Apollo.MutationHookOptions<CreateRiskMutation, CreateRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRiskMutation, CreateRiskMutationVariables>(CreateRiskDocument, options);
      }
export type CreateRiskMutationHookResult = ReturnType<typeof useCreateRiskMutation>;
export type CreateRiskMutationResult = Apollo.MutationResult<CreateRiskMutation>;
export type CreateRiskMutationOptions = Apollo.BaseMutationOptions<CreateRiskMutation, CreateRiskMutationVariables>;
export const RiskCreateFormDocument = gql`
    query RiskCreateForm {
  riskCreateForm {
    redirect
    initialValues
    fields {
      name
      label
      type
      isRequired
      options {
        label
        value
      }
    }
  }
}
    `;

/**
 * __useRiskCreateFormQuery__
 *
 * To run a query within a React component, call `useRiskCreateFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskCreateFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskCreateFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useRiskCreateFormQuery(baseOptions?: Apollo.QueryHookOptions<RiskCreateFormQuery, RiskCreateFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskCreateFormQuery, RiskCreateFormQueryVariables>(RiskCreateFormDocument, options);
      }
export function useRiskCreateFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskCreateFormQuery, RiskCreateFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskCreateFormQuery, RiskCreateFormQueryVariables>(RiskCreateFormDocument, options);
        }
export type RiskCreateFormQueryHookResult = ReturnType<typeof useRiskCreateFormQuery>;
export type RiskCreateFormLazyQueryHookResult = ReturnType<typeof useRiskCreateFormLazyQuery>;
export type RiskCreateFormQueryResult = Apollo.QueryResult<RiskCreateFormQuery, RiskCreateFormQueryVariables>;
export const RiskEditFormDocument = gql`
    query RiskEditForm($id: String!) {
  riskEditForm(id: $id) {
    initialValues
    fields {
      name
      label
      type
      isRequired
      options {
        label
        value
      }
    }
  }
}
    `;

/**
 * __useRiskEditFormQuery__
 *
 * To run a query within a React component, call `useRiskEditFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useRiskEditFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRiskEditFormQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRiskEditFormQuery(baseOptions: Apollo.QueryHookOptions<RiskEditFormQuery, RiskEditFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RiskEditFormQuery, RiskEditFormQueryVariables>(RiskEditFormDocument, options);
      }
export function useRiskEditFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RiskEditFormQuery, RiskEditFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RiskEditFormQuery, RiskEditFormQueryVariables>(RiskEditFormDocument, options);
        }
export type RiskEditFormQueryHookResult = ReturnType<typeof useRiskEditFormQuery>;
export type RiskEditFormLazyQueryHookResult = ReturnType<typeof useRiskEditFormLazyQuery>;
export type RiskEditFormQueryResult = Apollo.QueryResult<RiskEditFormQuery, RiskEditFormQueryVariables>;
export const UpdateRiskDocument = gql`
    mutation UpdateRisk($id: String!, $body: String!) {
  updateRiskFromForm(id: $id, body: $body) {
    id
  }
}
    `;
export type UpdateRiskMutationFn = Apollo.MutationFunction<UpdateRiskMutation, UpdateRiskMutationVariables>;

/**
 * __useUpdateRiskMutation__
 *
 * To run a mutation, you first call `useUpdateRiskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRiskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRiskMutation, { data, loading, error }] = useUpdateRiskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateRiskMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRiskMutation, UpdateRiskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRiskMutation, UpdateRiskMutationVariables>(UpdateRiskDocument, options);
      }
export type UpdateRiskMutationHookResult = ReturnType<typeof useUpdateRiskMutation>;
export type UpdateRiskMutationResult = Apollo.MutationResult<UpdateRiskMutation>;
export type UpdateRiskMutationOptions = Apollo.BaseMutationOptions<UpdateRiskMutation, UpdateRiskMutationVariables>;
export const PaperipankkiRequestDocument = gql`
    mutation PaperipankkiRequest($body: PaperipankkiRequestDTO!) {
  requestPaperipankkiContact(body: $body)
}
    `;
export type PaperipankkiRequestMutationFn = Apollo.MutationFunction<PaperipankkiRequestMutation, PaperipankkiRequestMutationVariables>;

/**
 * __usePaperipankkiRequestMutation__
 *
 * To run a mutation, you first call `usePaperipankkiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePaperipankkiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [paperipankkiRequestMutation, { data, loading, error }] = usePaperipankkiRequestMutation({
 *   variables: {
 *      body: // value for 'body'
 *   },
 * });
 */
export function usePaperipankkiRequestMutation(baseOptions?: Apollo.MutationHookOptions<PaperipankkiRequestMutation, PaperipankkiRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PaperipankkiRequestMutation, PaperipankkiRequestMutationVariables>(PaperipankkiRequestDocument, options);
      }
export type PaperipankkiRequestMutationHookResult = ReturnType<typeof usePaperipankkiRequestMutation>;
export type PaperipankkiRequestMutationResult = Apollo.MutationResult<PaperipankkiRequestMutation>;
export type PaperipankkiRequestMutationOptions = Apollo.BaseMutationOptions<PaperipankkiRequestMutation, PaperipankkiRequestMutationVariables>;
export const ReportRiskMatrixDocument = gql`
    query ReportRiskMatrix {
  reportRiskMatrix {
    report
    documents {
      documentId
      documentName
      riskIndex
    }
  }
}
    `;

/**
 * __useReportRiskMatrixQuery__
 *
 * To run a query within a React component, call `useReportRiskMatrixQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRiskMatrixQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRiskMatrixQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportRiskMatrixQuery(baseOptions?: Apollo.QueryHookOptions<ReportRiskMatrixQuery, ReportRiskMatrixQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportRiskMatrixQuery, ReportRiskMatrixQueryVariables>(ReportRiskMatrixDocument, options);
      }
export function useReportRiskMatrixLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRiskMatrixQuery, ReportRiskMatrixQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportRiskMatrixQuery, ReportRiskMatrixQueryVariables>(ReportRiskMatrixDocument, options);
        }
export type ReportRiskMatrixQueryHookResult = ReturnType<typeof useReportRiskMatrixQuery>;
export type ReportRiskMatrixLazyQueryHookResult = ReturnType<typeof useReportRiskMatrixLazyQuery>;
export type ReportRiskMatrixQueryResult = Apollo.QueryResult<ReportRiskMatrixQuery, ReportRiskMatrixQueryVariables>;