export const MiroSceneTranslation = {
  fi: {
    MiroScene: {
      updateMiroLink: 'Yhdistä Miro',
      instruction1: 'Luo tunnukset ',
      instruction2: ' osoitteessa ja luo prosessikuvaus tai muu visuaalinen dokumentti',
      instruction3: 'Klikkaa "Export this board" - linkkiä, josta valitaan Embed vaihtoehto',
      instruction4: 'Kopioi web-linkki',
      instruction5: 'Liitä linkki alla olevaan kenttään ja paina tallenna',
    },
  },
  en: {
    MiroScene: {
      updateMiroLink: 'Connect Miro',
      instruction1: 'Create account ',
      instruction2: ' and create a Miro document',
      instruction3: 'Click "Export this board" - link, and select Embed option',
      instruction4: 'Copy the web link',
      instruction5: 'Paste the link here and press save',
    },
  },
};
