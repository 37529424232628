import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { getRoute } from '../../config/routes.config';
import { viewPrivilegeAtom } from '../../contexes/view-privilege.atom';
import { useHomeSceneRiskCountQuery } from '../../generated/graphql';
import { DashButton } from '../DashButton';

export const RiskIndexDashButton = () => {
  const { t } = useTranslation('HomeScene');
  const viewPrivilege = useRecoilValue(viewPrivilegeAtom);
  const { data } = useHomeSceneRiskCountQuery({
    variables: {
      riskModelId: viewPrivilege.risk.modelId,
    },
  });

  return (
    <DashButton
      to={getRoute('RISK_INDEX')}
      icon="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      title={t('risks')}
      text={`${t('showAll')} ${data?.risk.totalCount}`}
    />
  );
};
