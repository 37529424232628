import { ErrorMessage as Error } from 'formik';
import * as _ from 'lodash';
import { isI18nString } from '../../utils/is-i18n-string';

type Props = {
  name: string;
};

// write function that replace {{ value }} and {{ field }} in string
const t = (str: string, params: { field: string; value: string }) => {
  return str.replace('{{ field }}', params.field).replace('{{ value }}', params.value);
};

export const ErrorMessage = ({ name }: Props) => {
  return (
    <Error
      render={(msg: string) => <RawErrorMessage name={name}>{msg}</RawErrorMessage>}
      name={name}
    />
  );
};

type RawProps = {
  children: string;
  name: string;
};

export const RawErrorMessage = ({ children, name }: RawProps) => {
  let value = '';

  if (isI18nString(children)) {
    const x = /\d+/.exec(children);
    const number = _.first(x);
    if (number) {
      value = number;
      children = children.split(' ')[0];
    }
  }

  return <span className="releox-input-error-message">{t(children, { field: name, value })}</span>;
};
