export default {
  fi: {
    AuthErrorView: {
      wentWrong: 'Jotain meni vikaan..',
      backLink: 'Takaisin kirjautumissivulle',
    },
  },
  en: {
    AuthErrorView: {
      wentWrong: 'Something went wrong..',
      backLink: 'Back to login',
    },
  },
};
