import { Fragment } from 'react';
import { DocumentShowSceneQuery } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { ApprovableByUser } from './ApprovableByUser';
import { Approved } from './Approved';
import { ApprovedByUser } from './ApprovedByUser';
import { NotApprovableCreatedByUser } from './NotApprovableCreatedByUser';

interface Props {
  type: DocumentTypeField;
  refetch(): void;
  data: DocumentShowSceneQuery;
}

type ApprovalActionStatus =
  | 'no-action'
  | 'not-approvable-by-user'
  | 'not-approvable-created-by-user'
  | 'approvable-by-user'
  | 'approved-by-user'
  | 'approved';

export const DocumentApprovalAction = ({ data, refetch, type }: Props) => {
  const status: ApprovalActionStatus = data.document.userStatus as ApprovalActionStatus;

  switch (status) {
    case 'approved':
      return <Approved />;

    case 'approved-by-user':
      return <ApprovedByUser />;

    case 'approvable-by-user':
      return <ApprovableByUser type={type} refetch={refetch} data={data} />;

    case 'not-approvable-created-by-user':
      return <NotApprovableCreatedByUser refetch={refetch} />;

    default:
      return <Fragment />;
  }
};
