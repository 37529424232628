import { useMemo } from 'react';
import { useDriveFileInput } from '../inputs/use-drive-file.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useDriveFileForm = () => {
  const inputs = useDriveFileInput();

  return useMemo(() => {
    return createForm([inputs.name]);
  }, [inputs.name]);
};

export type DriveFileFormBody = {
  name: string;
};
