import { first } from 'lodash';
import { FC, ReactNode, useState } from 'react';
import { classNames } from '../DashboardLayout';

type Prop = {
  tabs: {
    name: string;
    children: ReactNode;
  }[];
};

export const Tab: FC<Prop> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(first(tabs)?.name ?? '');

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            defaultValue={activeTab}
            onChange={(e) => setActiveTab(e.target.value)}
            className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary focus:outline-none focus:ring-primary sm:text-sm"
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav aria-label="Tabs" className="-mb-px flex space-x-8">
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => setActiveTab(tab.name)}
                  aria-current={activeTab ? 'page' : undefined}
                  className={classNames(
                    activeTab === tab.name
                      ? 'border-primary text-primary font-bold'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                  )}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="mt-5">{tabs.find((tab) => tab.name === activeTab)?.children}</div>
    </>
  );
};
