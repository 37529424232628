import { useReactMultiSelectValidationSchema } from '../components/ReactMultiSelectInput';
import { QNCType } from '../config/qnc-types';
import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useDocumentInput = () => {
  const Yup = useYup();
  const multiSelectValidation = useReactMultiSelectValidationSchema();

  const isApproveRequired: FormInput = {
    type: 'checkbox',
    initValue: true,
    validation: Yup.boolean().required(),
    props: {
      name: 'isApproveRequired',
      label: 'Document:isApproveRequired',
    },
  };

  const costs: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.number().required(),
    props: {
      name: 'costs',
      label: 'Document:costs',
      type: 'number',
    },
  };

  const qncCategories: FormInput = {
    type: 'select',
    initValue: '',
    validation: Yup.string(),
    props: {
      placeholder: 'Document:choose',
      name: 'category',
      label: 'Document:category',
      options: [
        {
          label: 'QNCType:customerReclamation',
          value: QNCType.CUSTOMER_RECLAMATION,
        },
        {
          label: 'QNCType:initiative',
          value: QNCType.INITIATIVE,
        },
        {
          label: 'QNCType:internalNonConformity',
          value: QNCType.INTERNAL_NON_CONFORMITY,
        },
        {
          label: 'QNCType:safetyAccident',
          value: QNCType.SAFETY_ACCIDENT,
        },
        {
          label: 'QNCType:safetyObservation',
          value: QNCType.SAFETY_OBSERVATION,
        },
        {
          label: 'QNCType:supplierReclamation',
          value: QNCType.SUPPLIER_RECLAMATION,
        },
        {
          label: 'QNCType:environmentObservation',
          value: QNCType.ENVIRONMENT_OBSERVATION,
        },
        {
          label: 'QNCType:internalAudit',
          value: QNCType.INTERNAL_AUDIT,
        },
        {
          label: 'QNCType:externalAudit',
          value: QNCType.EXTERNAL_AUDIT,
        },
        {
          label: 'QNCType:other',
          value: QNCType.OTHER,
        },
      ],
    },
  };

  const name: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'name',
      label: 'Document:name',
      type: 'text',
    },
  };

  const description: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'description',
      label: 'Document:description',
      type: 'text',
    },
  };

  const groups: FormInput = {
    type: 'react-multi-select',
    initValue: [],
    validation: multiSelectValidation,
    props: {
      name: 'groups',
      label: 'Document:groups',
      placeholder: 'Document:groups',
    },
  };

  const tags: FormInput = {
    type: 'react-multi-select',
    initValue: [],
    validation: multiSelectValidation,
    props: {
      name: 'tags',
      label: 'Document:tags',
      placeholder: 'Document:tags',
    },
  };

  const approverId: FormInput = {
    type: 'react-select',
    initValue: '',
    validation: Yup.string().when('isApproveRequired', ([value], schema) => {
      if (value) {
        return schema.required();
      }
      return schema.optional();
    }),
    props: {
      name: 'approverId',
      label: 'Document:approverId',
      placeholder: 'Document:approverId',
    },
  };

  return {
    isApproveRequired,
    name,
    tags,
    costs,
    qncCategories,
    approverId,
    groups,
    description,
  };
};
