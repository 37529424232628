import { useMemo } from 'react';
import { useTagInput } from '../inputs/use-tag.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useTagForm = () => {
  const inputs = useTagInput();

  const form = useMemo(() => {
    return createForm([inputs.name]);
  }, [inputs.name]);

  return form;
};

export type TagFormBody = {
  name: string;
};
