import { changeLanguage } from 'i18next';
import { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useSetRecoilState } from 'recoil';
import { getRoute } from '../../config/routes.config';
import { useSideBarConfig } from '../../config/sidebar.config';
import UserContext, { UserContextData, userInit } from '../../contexes/UserContext';
import { viewPrivilegeAtom } from '../../contexes/view-privilege.atom';
import { useViewPrivilegeQuery, useWhoAmIQuery } from '../../generated/graphql';
import { useAuth } from '../../hooks/auth/use-auth';
import { DashboardLayout } from '../DashboardLayout';
import { Loading } from '../Loading';

type Props = {
  children: React.ReactNode;
};

const Layout: FC<Props> = ({ children }) => {
  const { i18n } = useTranslation();
  const { update } = useIntercom();
  const setVPValue = useSetRecoilState(viewPrivilegeAtom);
  const [user, setUser] = useContext(UserContext);
  const nav = useSideBarConfig();

  useWhoAmIQuery({
    onCompleted: (data) => {
      if (data?.whoAmI) {
        setUser({
          ...data.whoAmI,
          documentModelIdObject: data.documentModelIdObject,
        });

        if (i18n.language !== data.whoAmI.language) {
          changeLanguage(data.whoAmI.language);
        }
        if ('Intercom' in window) {
          const ami = data.whoAmI;
          update({
            userId: ami.id,
            email: ami.email,
            name: ami.name,
            company: {
              companyId: ami.company.id,
              name: ami.company.name,
            },
          });
        }
      }
    },
  });

  useViewPrivilegeQuery({
    onCompleted: (d) => {
      if (d.viewPrivilege) {
        setVPValue(d.viewPrivilege);
      }
    },
  });

  if (!user.name) {
    return <Loading />;
  }

  return (
    <DashboardLayout user={user} nav={nav} homeUrl={getRoute('DASHBOARD')}>
      {children}
    </DashboardLayout>
  );
};

export const DashLayout = () => {
  const { token } = useAuth();
  const [user, setUser] = useState<UserContextData>(userInit);

  if (!token) {
    return <Navigate to={getRoute('LOGIN')} />;
  }

  return (
    <UserContext.Provider value={[user, setUser]}>
      <Layout>
        <Outlet />
      </Layout>
    </UserContext.Provider>
  );
};
