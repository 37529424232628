export const ACLCreateSceneTranslation = {
  fi: {
    ACLCreateScene: {
      title: 'Lisää uusi käyttöoikeus',
    },
  },
  en: {
    ACLCreateScene: {
      title: 'Create new access control list',
    },
  },
};
