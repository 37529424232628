import { useMemo } from 'react';
import { useSendReminderInput } from '../inputs/use-send-reminder.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useSendReminderForm = () => {
  const inputs = useSendReminderInput();

  const form = useMemo(() => {
    return createForm([inputs.message]);
  }, [inputs.message]);

  return form;
};

export type SendReminderFormBody = {
  message: string;
};
