import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { ReportMetaData } from '../../components/ReportMetaData';
import { ReportQncCountSceneQuery } from '../../generated/graphql';
import { Loading } from '../Loading';

type Props = {
  data?: ReportQncCountSceneQuery['qncCountReport'];
  loading: boolean;
  hideCount?: boolean;
};

export const ReportQncCountChart = ({ data, loading, hideCount }: Props) => {
  const { t } = useTranslation('ReportQncCountScene');

  const chartData = data?.result.map((r) => ({
    ...r,
    name: t(`Month:${r.name}`),
  }));

  if (loading || !data) return <Loading />;

  return (
    <>
      <ReportMetaData data={data} hideCount={hideCount} />
      <ResponsiveContainer width="100%" aspect={4.0 / 1.4}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="count" name={t<string>('count')} fill="#ff8c86" />
          <Bar dataKey="approved" name={t<string>('approved')} fill="#52c76f" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
