import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';

type Notifications = 'deleted' | 'saved';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Callback<T> = (data: T) => any;

export const useOnComplete = <T>(
  url?: Parameters<typeof getRoute>[0] | null,
  notification?: Notifications | null,
  callback?: Callback<T> | null,
  remapRouteData?: Callback<T> | null
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (data: T) => {
    if (data) {
      if (_.keys(data).length !== 1) throw new Error('[useOnComplete] there is more than one key');
      const key = _.keys(data)[0];
      if (callback) callback(data);
      if (notification) toast.success(t(`Common:${notification}`));
      if (url) {
        let d = _.get(data, key);
        if (remapRouteData) {
          d = _.map(d, remapRouteData);
        }
        navigate(getRoute(url, d, true));
      }
    }
  };
};
