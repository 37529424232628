export const ReportQncTagSceneTranslation = {
  fi: {
    ReportQncTagScene: {
      title: 'Poikkeamatagiraportti',
    },
  },
  en: {
    ReportQncTagScene: {
      title: 'QNC Tag Report',
    },
  },
};
