export default {
  fi: {
    DocumentFilterForm: {
      advanceQuery: 'Tarkennettu haku',
      query: 'Tiedustelu',
      chooseStatus: 'Valitse tila',
      chooseUser: 'Valitse käyttäjä',
      chooseGroup: 'Valitse ryhmä',
      chooseApprover: 'Valitse hyväksyjä',
      chooseCategory: 'Valitse kategoria',
      documentType: 'Pohja',
    },
  },
  en: {
    DocumentFilterForm: {
      advanceQuery: 'Advanced search',
      query: 'Query',
      chooseStatus: 'Choose status',
      chooseUser: 'Choose user',
      chooseGroup: 'Choose group',
      chooseApprover: 'Choose approver',
      chooseCategory: 'Choose category',
      documentType: 'Document type',
    },
  },
};
