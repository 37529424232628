export const ArrayInputTranslation = {
  fi: {
    ArrayInput: {
      addField: 'Lisää Kenttä',
      deleteField: 'Poista Kenttä',
    },
  },
  en: {
    ArrayInput: {
      addField: 'Add field',
      deleteField: 'Delete field',
    },
  },
};
