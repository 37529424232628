import { Link } from 'react-router-dom';

interface Props {
  children: string;
  className?: string;
  to: string;
}

export const buttonClassNames = '';

export const ButtonLink = (props: Props) => {
  const { children, className, to } = props;

  const text = children;

  return (
    <Link
      to={to}
      className={`inline-flex justify-center w-sm py-2 px-6 shadow-sm text-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-gray-300 ${className}`}
    >
      {text}
    </Link>
  );
};
