import i18next from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import ApolloClientProvider from './ApolloProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import translation from './translation';

const i18n = i18next;

if (!process.env.REACT_APP_API_URL) {
  throw new Error(
    `REACT_APP_API_URL is not defined. Please make sure you have .env.${process.env.NODE_ENV}`
  );
}

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fi',
    debug: process.env.REACT_APP_I18N_DEBUG === '1',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: translation,
  });

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<ApolloClientProvider />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
