import { useMemo } from 'react';
import { useGroupInput } from '../inputs/use-group.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useGroupForm = () => {
  const inputs = useGroupInput();

  return useMemo(() => {
    return createForm([inputs.name]);
  }, [inputs.name]);
};

export type GroupFormBody = {
  name: string;
};
