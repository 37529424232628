import { useMemo } from 'react';
import { TFunction } from 'react-i18next';

interface Props {
  type?: string;
  t: TFunction;
}

export const useDocumentTitle = ({ type, t }: Props) => {
  const title = useMemo(() => {
    if (!['qnc', 'risk', 'document', 'anonymous'].includes(type || '')) {
      return `${t('title', { type })}`;
    }

    return `${t('title', { type: t(`DocumentType:${type}`) })}`;
  }, [type, t]);

  return title;
};
