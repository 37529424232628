export const ACLEditSceneTranslation = {
  fi: {
    ACLEditScene: {
      title: 'Muokkaa käyttöoikeuksia',
      deleteConfirm: 'Haluatko varmasti poistaa käyttöoikeuden?',
    },
  },
  en: {
    ACLEditScene: {
      title: 'Edit ACL',
      deleteConfirm: 'Are you sure you want to delete this ACL?',
    },
  },
};
