export interface InputWrapperProps {
  info?: string;
  children: JSX.Element | JSX.Element[];
}

export const InputWrapper = ({ children, info }: InputWrapperProps) => {
  return (
    <div className="input-wrapper">
      {children}
      {info ? <p className="input-info">{info}</p> : ''}
    </div>
  );
};
