import { useState } from 'react';
import { authConfig } from '../../config/auth.config';
import { Token } from './Token';

type LSType = [Token, (token: Token) => void];

export const useLocalStorage = (token: Token): LSType => {
  const [storedValue, setStoredValue] = useState<Token>((): Token => {
    const localValue = window.localStorage.getItem(authConfig.tokenStorageKey);
    if (token) {
      if (localValue !== token) {
        window.localStorage.setItem(authConfig.tokenStorageKey, token);
      }
    } else {
      window.localStorage.removeItem(authConfig.tokenStorageKey);
    }

    return token;
  });

  const setValue = (_token: Token): void => {
    try {
      if (_token === null) {
        window.localStorage.removeItem(authConfig.tokenStorageKey);
      } else {
        window.localStorage.setItem(authConfig.tokenStorageKey, _token);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(`[LocalStorage] ${err}`);
    }
    setStoredValue(_token);
  };

  return [storedValue, setValue];
};
