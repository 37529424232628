import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Steps } from '../../components/Steps';
import { getRoute } from '../../config/routes.config';
import {
  InviteTeamFormBody,
  useInviteTeamStep1Form,
} from '../../forms/use-invite-team-step-1.form';
import { useInviteUserMutation } from '../../generated/graphql';
import { AppFormGenerator } from '../../releox-engine/react-components/app-form-generator/AppFormGenerator';

export const TeamInviteStep1Scene = (): JSX.Element => {
  const form = useInviteTeamStep1Form();
  const { t } = useTranslation('TeamInviteScene');
  const navigate = useNavigate();

  const [inviteUser, { loading }] = useInviteUserMutation({
    onCompleted: (data) => {
      if (data.inviteUser) {
        toast.success(t('Common:updated'));
        navigate(getRoute('TEAM_INVITE_STEP_2', { id: data.inviteUser.id }));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: InviteTeamFormBody) => {
      inviteUser({ variables: { body } });
    },
    [inviteUser]
  );

  return (
    <>
      <FormLayout>
        <Card>
          <div className="mb-5">
            <Steps
              steps={[
                { id: '1', name: t('basicInfo'), status: 'current' },
                { id: '2', name: t('preveledges'), status: 'upcoming' },
              ]}
            />
          </div>
          <AppFormGenerator
            wrapperClassNames="space-y-4"
            HeaderElement={<CardTitle>{t('basicInfo')}</CardTitle>}
            FooterElement={
              <div className="flex justify-between pt-4">
                <ButtonLink to={getRoute('TEAM_INDEX')}>{t('toTeam')}</ButtonLink>
                <Button type="submit" loading={loading}>
                  {t('inviteUser')}
                </Button>
              </div>
            }
            onSubmit={handleSubmit}
            form={form}
            initialValues={{}}
          />
        </Card>
      </FormLayout>
    </>
  );
};
