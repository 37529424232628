export default {
  fi: {
    ExportIndexScene: {
      title: 'Lataa dokumentit',
      documents: 'Dokumentit',
      qncs: 'Poikkeamat',
      risks: 'Riskit ja mahdollisuudet',
      anonymous: 'Anonyymi ilmoitukset',
    },
  },
  en: {
    ExportIndexScene: {
      title: 'Download the documents',
      documents: 'Documents',
      qncs: 'QNCs',
      risks: 'Risks',
      anonymous: 'Anonymous report',
    },
  },
};
