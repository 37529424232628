export const ApprovalStatusBadgeTranslation = {
  en: {
    Status: {
      noAction: 'No action required',
      approved: 'Approved',
      'request-sent': 'Request sent',
      waiting: 'Waiting',
      sent: 'Send',
      rejected: 'Rejected',
      unapproved: 'Unapproved',
    },
  },
  fi: {
    Status: {
      noAction: 'Ei vaadi hyväksyntää',
      approved: 'Hyväksytty',
      'request-sent': 'Pyyntö lähetetty',
      waiting: 'Odottaa',
      rejected: 'Hylätty',
      sent: 'Lähetetty',
      unapproved: 'Hyväksymättä',
    },
  },
};
