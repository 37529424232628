export const ReportQncCountSceneTranslation = {
  fi: {
    ReportQncCountScene: {
      title: 'Poikkeamaraportti',
      totalCount: 'Poikkeamat yhteensä: ',
      submit: 'Hae',
      approved: 'Hyväksytyt',
      count: 'Määrä',
    },
  },
  en: {
    ReportQncCountScene: {
      title: 'QNC Report',
      totalCount: 'Total QNC: ',
      submit: 'Submit',
      approved: 'Approved',
      count: 'Count',
    },
  },
};
