export const ServerErrorsTranslation = {
  fi: {
    LoginError: {
      invalidCredentials: 'Väärä käyttäjätunnus tai salasana',
    },
    UpdateDocumentModelError: {
      emptyOption: 'Vaihtoehto ei voi olla tyhjä',
    },
  },
  en: {
    LoginError: {
      invalidCredentials: 'Invalid username or password',
    },
    UpdateDocumentModelError: {
      emptyOption: 'Option cannot be empty',
    },
  },
};
