export const ACLShowSceneTranslation = {
  fi: {
    ACLShowScene: {
      edit: 'Muokkaa',
      delete: 'Poista',
      addRule: '{{ title }}',
      p: 'Tässä voit lisätä uusia sääntöjä.',
      qnc: 'Poikkeama',
      risk: 'Riskit ja mahdollisuudet',
      document: 'Dokumentti',
      anonymous: 'Anonyymi ilmoituskanava',
      create: 'Luonti',
      read: 'Luku',
      update: 'Päivitys',
      del: 'Poisto',
      all: 'Kaikki',
      '*': 'Kaikki',
    },
  },
  en: {
    ACLShowScene: {
      edit: 'Edit',
      delete: 'Delete',
      addRule: '{{ title }}',
      p: 'Here you can add new rules.',
      qnc: 'QNC',
      risk: 'Risks and opportunities',
      document: 'Document',
      anonymous: 'Anonymous documents',
      create: 'Create',
      read: 'Read',
      update: 'Update',
      del: 'Delete',
      all: 'All',
      '*': 'All',
    },
  },
};
