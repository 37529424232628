export const DocumentModelIndexSceneTranslation = {
  fi: {
    DocumentModelIndexScene: {
      title: 'Valitse pohja',
      create: 'Lisää uusi',
    },
  },
  en: {
    DocumentModelIndexScene: {
      title: 'Choose Document models',
      create: 'Create new',
    },
  },
};
