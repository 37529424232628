import {
  GeneratableDocumentFieldOptionInput,
  GeneratableDocumentFieldOptionInputOptions,
} from '../../components/DocumentFieldOptionInput';
import {
  GeneratableReactMultiSelectInput,
  GeneratableReactMultiSelectInputOptions,
} from '../../components/ReactMultiSelectInput';
import {
  GeneratableReactSelectInput,
  GeneratableReactSelectInputOptions,
} from '../../components/ReactSelectInput';
import {
  GeneratableCheckboxInput,
  GeneratableCheckboxInputOptions,
} from '../react-components/form-inputs/Checkbox';
import {
  GeneratableFileInput,
  GeneratableFileInputOptions,
} from '../react-components/form-inputs/FileInput';
import {
  GeneratableSelectInput,
  GeneratableSelectInputOptions,
} from '../react-components/form-inputs/SelectInput';
import {
  GeneratableTextareaInput,
  GeneratableTextareaInputOptions,
} from '../react-components/form-inputs/TextareaInput';
import {
  GeneratableTextInput,
  GeneratableTextInputOptions,
} from '../react-components/form-inputs/TextInput';
import { GeneratableArrayInput, GeneratableArrayInputOptions } from './ArrayInput';
import {
  GeneratableReleoxFormElement,
  GeneratableReleoxFormElementOptions,
} from './ReleoxFormElement';

export type FormInput =
  | GeneratableTextInputOptions
  | GeneratableTextareaInputOptions
  | GeneratableSelectInputOptions
  | GeneratableArrayInputOptions
  | GeneratableCheckboxInputOptions
  | GeneratableFileInputOptions
  | GeneratableReleoxFormElementOptions
  | GeneratableDocumentFieldOptionInputOptions
  | GeneratableReactMultiSelectInputOptions
  | GeneratableReactSelectInputOptions;

export const FormInputs = [
  GeneratableTextInput,
  GeneratableFileInput,
  GeneratableCheckboxInput,
  GeneratableSelectInput,
  GeneratableTextareaInput,
  GeneratableArrayInput,
  GeneratableReleoxFormElement,
  GeneratableDocumentFieldOptionInput,
  GeneratableReactMultiSelectInput,
  GeneratableReactSelectInput,
];
