export const DocumentLinkEditTranslation = {
  fi: {
    DocumentLinkEdit: {
      title: 'Muokkaa linkkiä',
    },
  },
  en: {
    DocumentLinkEdit: {
      title: 'Edit link',
    },
  },
};
