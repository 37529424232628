import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

type Props = {
  to: string;
};

export const BackButton = ({ to }: Props) => {
  const { t } = useTranslation('Common');
  return (
    <Link
      to={to}
      className="inline-flex justify-center py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
    >
      {t('back')}
    </Link>
  );
};
