import { atom } from 'recoil';
import { ViewPrivilegeQuery } from '../generated/graphql';

export type ViewPrivilege = ViewPrivilegeQuery['viewPrivilege'];

const init: ViewPrivilege = {
  qnc: {
    modelId: '',
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  risk: {
    modelId: '',
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  document: {
    modelId: '',
    create: false,
    read: false,
    update: false,
    delete: false,
  },
  anonymous: {
    modelId: '',
    create: false,
    read: false,
    update: false,
    delete: false,
  },
};

export const viewPrivilegeAtom = atom<ViewPrivilege>({
  key: 'viewPrivilege',
  default: init,
});
