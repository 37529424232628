import { ErrorMessage, Field, FieldArray, useField, useFormikContext } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../releox-engine/form/ReleoxBaseInputProps';
import { InputWrapper } from '../releox-engine/react-components/form-inputs/InputWrapper';
import { Label } from '../releox-engine/react-components/form-inputs/Label';
import { useInputClasses } from '../releox-engine/react-components/form-inputs/use-input-classes';
import { Button } from './Button';

export interface TextInputProps extends ReleoxBaseInputProps {
  label: string;
}

export const DocumentFieldOptionInput = (props: TextInputProps) => {
  const [field, meta] = useField(props);
  const formikContext = useFormikContext();
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta);

  const fieldIndex = name.split('.')[1];
  const fieldType = _.get(formikContext.values, `documentModelFields.${fieldIndex}.type`);

  if (!['select', 'inline-select'].includes(fieldType)) return <span />;

  return (
    <>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <FieldArray
        name={field.name}
        render={(helper) => {
          if (field.value > 1) {
            return (
              <Button type="button" onClick={() => helper.push('')}>
                +
              </Button>
            );
          }
          const fields = field.value.map((option: string, i: number) => (
            <div key={i} className="grid grid-cols-4">
              <div className="col-span-3">
                <InputWrapper>
                  <Field name={`${field.name}.${i}`} className={`${inputClasses} mb-1`} />
                  <ErrorMessage
                    component="span"
                    className="text-red-color text-sm"
                    name={field.name}
                  />
                </InputWrapper>
              </div>
              <div className="pl-2">
                <Button type="button" onClick={() => helper.remove(i)}>
                  -
                </Button>
              </div>
            </div>
          ));
          return (
            <>
              {fields}
              <Button type="button" onClick={() => helper.push('')}>
                +
              </Button>
            </>
          );
        }}
      />
    </>
  );
};

export interface GeneratableDocumentFieldOptionInputOptions {
  type: 'document-model-field-option-input';
  initValue: string[];
  validation: Yup.ArraySchema<(string | undefined)[] | undefined, Yup.AnyObject, '', ''>;
  props: TextInputProps;
}

export const GeneratableDocumentFieldOptionInput = {
  Element: DocumentFieldOptionInput,
  type: 'document-model-field-option-input',
};
