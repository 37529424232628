import { GroupIndexSceneQuery } from '../generated/graphql';
import { formatDate } from '../utils/format-date';
import { TableColumn } from './table-column';

type ColumnData = GroupIndexSceneQuery['groups']['list'][0];

const id: TableColumn = {
  field: 'id',
  text: 'Group:id',
};

const name: TableColumn = {
  field: 'name',
  sort: true,
  text: 'Group:name',
};

const createdAt: TableColumn = {
  field: 'createdAt',
  sort: true,
  text: 'Group:createdAt',
  formatter: (data: ColumnData) => {
    return formatDate(data.createdAt);
  },
};

export const groupColumn = {
  id,
  name,
  createdAt,
};
