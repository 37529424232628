import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { ReportQncTagChart } from '../../components/report/ReportQncTagChart';
import { ReportForm, ReportFormBody, useReportInitialValues } from '../../forms/use-report.form';
import { useReportQncTagSceneLazyQuery } from '../../generated/graphql';

export const ReportQncTagScene = () => {
  const initialValues = useReportInitialValues();
  const { t } = useTranslation('ReportQncTagScene');
  const [fetch, { data, loading }] = useReportQncTagSceneLazyQuery();

  useEffect(() => {
    fetch({ variables: initialValues });
  }, [fetch, initialValues]);

  const handleSubmit = useCallback(
    (body: ReportFormBody) => {
      fetch({ variables: body });
    },
    [fetch]
  );

  return (
    <Card>
      <CardTitle>{t('title')}</CardTitle>
      <ReportForm onSubmit={handleSubmit} />
      <ReportQncTagChart data={data?.qncTagReport} loading={loading} />
    </Card>
  );
};
