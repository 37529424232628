export const GroupCreateSceneTranslation = {
  fi: {
    GroupCreateScene: {
      createGroup: 'Luo ryhmä',
    },
  },

  en: {
    GroupCreateScene: {
      createGroup: 'Create Group',
    },
  },
};
