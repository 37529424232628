import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { usePageNumber } from '../../hooks/index/use-page-number';
import { usePerPage } from '../../hooks/index/use-per-page';

type Props = {
  total: number;
};

export const Pagination: FC<Props> = ({ total }) => {
  const { t } = useTranslation('Pagination');
  const perPage = usePerPage();
  const pageNumber = usePageNumber();
  const [searchParams, setSearchParams] = useSearchParams();

  // List of page numbers to display, 3 before and 3 after the current page
  const pageNumbers = [];
  for (let i = pageNumber - 3; i <= pageNumber + 3; i++) {
    if (i > 0 && i <= Math.ceil(total / perPage)) {
      pageNumbers.push(i);
    }
  }

  const onClick = (page: number) => () => {
    searchParams.set('page', `${page}`);

    setSearchParams(searchParams);
  };

  const currentClassses =
    'z-10 bg-primary text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary';
  const defaultClasses =
    'text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0';

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-sm text-gray-700">
          {t('page')} {pageNumber} {t('result')} {(pageNumber - 1) * perPage + 1} -{' '}
          {pageNumber * perPage} / {total}
        </div>

        <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            {pageNumber > 1 ? (
              <button
                onClick={onClick(pageNumber - 1)}
                className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {t('previous')}
              </button>
            ) : null}
            {pageNumber < Math.ceil(total / perPage) ? (
              <button
                onClick={onClick(pageNumber + 1)}
                className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                {t('next')}
              </button>
            ) : null}
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <nav
                className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                aria-label="Pagination"
              >
                {pageNumbers.map((page) => (
                  <button
                    key={page}
                    onClick={onClick(page)}
                    className={`${
                      page === pageNumber ? currentClassses : defaultClasses
                    } relative inline-flex items-center px-4 py-2 text-sm font-medium`}
                  >
                    {page}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
