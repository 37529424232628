export const GroupIndexSceneTranslation = {
  fi: {
    GroupIndexScene: {
      title: 'Ryhmät',
    },
  },
  en: {
    GroupIndexScene: {
      title: 'Groups',
    },
  },
};
