import { FC } from 'react';

type Props = {
  fields: string[];
};

export const SmallTableHeader: FC<Props> = ({ fields }) => {
  return (
    <thead>
      <tr>
        {fields.map((field) => (
          <th key={field}>{field}</th>
        ))}
      </tr>
    </thead>
  );
};
