export default {
  fi: {
    DocumentType: {
      document: 'Dokumentti',
      qnc: 'Poikkeama',
      risk: 'Riskit ja mahdollisuudet',
      anonymous: 'Anonyymi',
    },
  },
  en: {
    DocumentType: {
      document: 'Document',
      qnc: 'QNC',
      risk: 'Risk',
      anonymous: 'Anonymous',
    },
  },
};
