import { FieldMetaProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { isI18nString } from '../../../utils/is-i18n-string';

type Props = {
  htmlFor: string;
  children: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldMetaProps<any>;
};

export const Label = ({ htmlFor, children, meta }: Props) => {
  const { t } = useTranslation();
  let labelClasses = 'block text-sm font-medium text-gray-700';

  if (meta.error && meta.touched) {
    labelClasses += ' text-red-color';
  }
  return (
    <label htmlFor={htmlFor} className={labelClasses}>
      {isI18nString(children) ? t(children) : children}
    </label>
  );
};
