export const DocumentShowSceneTranslation = {
  fi: {
    DocumentShowScene: {
      approvers: 'Hyväksyjät',
      addApprover: 'Lisää hyväksyjä',
      request: 'Pyydä hyväksyntä',
      remove: 'Poista',
      requestSent: 'Pyyntö lähetetty',
      reminder: 'Muistuta',
      sending: 'Lähetetään...',
      status: 'Tila',
      edit: 'Muokkaa',
      name: 'Nimi',
      created: 'Luotu',
      actions: 'Toiminnot',
      confirmFileDelete: 'Haluatko varmasti poistaa tiedoston?',
      confirmLinkDelete: 'Haluatko varmasti poistaa linkin?',
      links: 'Linkit',
      addLink: 'Lisää linkki',
      emptyLinks: 'Dokumentilla ei ole vielä linkkejä. Lisää linkki painamalla "Lisää linkki".',
    },
  },
  en: {
    DocumentShowScene: {
      approvers: 'Approvers',
      addApprover: 'Add approver',
      status: 'Status',
      request: 'Request',
      remove: 'Remove',
      reminder: 'Reminder',
      requestSent: 'Request sent',
      sending: 'Sending...',
      edit: 'Edit',
      name: 'Name',
      created: 'Created',
      actions: 'Actions',
      confirmFileDelete: 'Are you sure you want to delete the file?',
      confirmLinkDelete: 'Are you sure you want to delete the link?',
      links: 'Links',
      addLink: 'Add link',
      emptyLinks: 'Document has no links yet. Add a link by pressing "Add link".',
    },
  },
};
