import { useMemo } from 'react';
import { useUserInput } from '../inputs/use-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useResetPasswordForm = () => {
  const inputs = useUserInput();

  const form = useMemo(() => {
    return createForm([inputs.password]);
  }, [inputs.password]);

  return form;
};

export type ResetPasswordFormBody = {
  password: string;
};
