export const ReportIndexSceneTranslation = {
  fi: {
    ReportIndexScene: {
      QncReport: 'Poikkeamaraportti',
      QncTypeReport: 'Poikkeamatyyppiraportti',
      QncCostReport: 'Poikkeamakustannusraportti',
      QncTagReport: 'Poikkeamatagiraportti',
      RiskMatrixReport: 'Riskimatriisiraportti',
      last12months: 'Viimeiseltä 12 kuukaudelta',
      createReport: 'Luo raportti',
      companyWide: 'Koko yrityksen laajuinen',
    },
  },
  en: {
    ReportIndexScene: {
      QncReport: 'QNC Report',
      QncTypeReport: 'QNC Type Report',
      QncCostReport: 'QNC Costs Report',
      QncTagReport: 'QNC Tag Report',
      RiskMatrixReport: 'Risk Matrix Report',
      last12months: 'For the last 12 months',
      createReport: 'Create report',
      companyWide: 'Company wide',
    },
  },
};
