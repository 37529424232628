import { useMemo } from 'react';
import { useUserInput } from '../inputs/use-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useLoginForm = () => {
  const inputs = useUserInput();

  const form = useMemo(() => {
    return createForm([inputs.email, inputs.password]);
  }, [inputs.email, inputs.password]);

  return form;
};

export type LoginFormBody = {
  email: string;
  password: string;
};
