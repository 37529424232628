export const PublicDocumentCreateTranslation = {
  fi: {
    PublicDocumentCreate: {
      title: 'Täytä lomake',
      isPrivate: 'Yksityinen',
    },
  },
  en: {
    PublicDocumentCreate: {
      title: 'Fill the form',
      isPrivate: 'Private',
    },
  },
};
