type Props = {
  text: string;
  tKey: string;
};

export const RiskBadge = ({ text, tKey }: Props): JSX.Element => {
  let color = '';

  if (
    tKey === 'RiskProperties:insignificantRisk' ||
    tKey === 'RiskProperties:minor' ||
    tKey === 'RiskProperties:unlikely'
  ) {
    color = 'bg-green-100 text-green-800';
  }

  if (tKey === 'RiskProperties:minorRisk') {
    color = 'bg-lime-100 text-lime-800';
  }

  if (
    tKey === 'RiskProperties:moderateRisk' ||
    tKey === 'RiskProperties:harmful' ||
    tKey === 'RiskProperties:possible'
  ) {
    color = 'bg-yellow-100 text-yellow-800';
  }

  if (tKey === 'RiskProperties:significantRisk') {
    color = 'bg-orange-100 text-orange-800';
  }

  if (
    tKey === 'RiskProperties:unacceptableRisk' ||
    tKey === 'RiskProperties:severe' ||
    tKey === 'RiskProperties:likely'
  ) {
    color = 'bg-red-100 text-red-800';
  }

  if (color === '') {
    return <>{text}</>;
  }

  return (
    <span className={`${color} text-xs font-semibold mr-2 px-2.5 py-0.5 rounded`}>{text}</span>
  );
};
