export const saveStringAsFile = (fileName: string, data: string) => {
  const element = document.createElement('a');
  const content = `data:text/plain;charset=utf-8,${encodeURIComponent(data)}`;
  element.setAttribute('href', content);
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
