import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useSendReminderInput = () => {
  const Yup = useYup();

  const message: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'message',
      label: 'SendReminderScene:message',
      type: 'text',
    },
  };

  return {
    message,
  };
};
