export const AcceptInvitationSceneTranslation = {
  fi: {
    AcceptInvitationScene: {
      newPassword: 'Määritä käyttäjätunnuksellesi salasana',
      fillNewPassword: 'Valitse turvallinen salasana ja kirjoita se tähän',
      save: 'Tallenna',
    },
  },
  en: {
    AcceptInvitationScene: {
      newPassword: 'Set a password for your account',
      fillNewPassword: 'Choose a secure password and type it here',
      save: 'Save',
    },
  },
};
