import { useMemo } from 'react';
import { useACLInput } from '../inputs/use-acl.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export type ACLFormBody = {
  name: string;
};

export const useACLForm = () => {
  const inputs = useACLInput();

  const form = useMemo(() => {
    return createForm([inputs.name]);
  }, [inputs.name]);

  return form;
};
