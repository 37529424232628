import { useReactMultiSelectValidationSchema } from '../components/ReactMultiSelectInput';
import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useUserInput = () => {
  const Yup = useYup();
  const schema = useReactMultiSelectValidationSchema();

  const email: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().email().required(),
    props: {
      name: 'email',
      label: 'User:email',
      type: 'email',
      autoComplete: 'email',
    },
  };

  const name: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'name',
      label: 'User:name',
      type: 'text',
      autoComplete: 'name',
    },
  };

  const resourceACLId: FormInput = {
    type: 'react-select',
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'resourceACLId',
      label: 'User:acl',
    },
  };

  const defaultApproverId: FormInput = {
    type: 'react-select',
    initValue: '',
    validation: Yup.string(),
    props: {
      name: 'defaultApproverId',
      label: 'User:defaultApproverId',
    },
  };

  const groups: FormInput = {
    type: 'react-multi-select',
    initValue: [],
    validation: schema,
    props: {
      name: 'groups',
      label: 'User:groups',
    },
  };

  const defaultGroups: FormInput = {
    type: 'react-multi-select',
    initValue: [],
    validation: schema,
    props: {
      name: 'defaultGroups',
      label: 'User:defaultGroups',
    },
  };

  const password: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().min(8).required(),
    props: {
      name: 'password',
      label: 'User:password',
      type: 'password',
      autoComplete: 'current-password',
    },
  };

  const company: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'company',
      label: 'User:company',
      type: 'text',
    },
  };

  const language: FormInput = {
    type: 'select',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'language',
      label: 'User:language',
      options: [
        {
          value: 'en',
          label: 'User:english',
        },
        {
          value: 'fi',
          label: 'User:finnish',
        },
      ],
    },
  };

  const wantWeeklyNotification: FormInput = {
    type: 'checkbox',
    initValue: false,
    validation: Yup.boolean().required(),
    props: {
      name: 'wantWeeklyNotification',
      label: 'User:wantWeeklyNotification',
    },
  };

  const isApprover: FormInput = {
    type: 'checkbox',
    initValue: false,
    validation: Yup.boolean().required(),
    props: {
      name: 'isApprover',
      label: 'User:isApprover',
    },
  };

  const isCompanyAdmin: FormInput = {
    type: 'checkbox',
    initValue: false,
    validation: Yup.boolean().required(),
    props: {
      name: 'isCompanyAdmin',
      label: 'User:isCompanyAdmin',
    },
  };

  const isAnonymousHandler: FormInput = {
    type: 'checkbox',
    initValue: false,
    validation: Yup.boolean().required(),
    props: {
      name: 'isAnonymousHandler',
      label: 'User:isAnonymousHandler',
    },
  };

  return {
    email,
    name,
    password,
    company,
    language,
    groups,
    isAnonymousHandler,
    defaultApproverId,
    isCompanyAdmin,
    defaultGroups,
    isApprover,
    wantWeeklyNotification,
    resourceACLId,
  };
};
