export const MSLinkSceneTranslation = {
  fi: {
    WordScene: {
      updateMSLink: 'Yhdistä Ms Office dokumentti (Word, PPT, Excel)',
      instruction1:
        'Varmista, että dokumentti on jaettu organisaatiolle jolle haluat dokumentin jaettavan',
      instruction2: 'Avaa dokumentti online officessa',
      instruction3: 'Valitse Tiedosto - Jaa - Upota',
      instruction4: 'Kopio upotuskoodi',
      instruction5: 'Liitä upotuskoodi alla olevaan kenttään ja paina tallenna',
    },
  },
  en: {
    WordScene: {
      updateMSLink: 'Connect MS Office document (Word, PPT, Excel)',
      instruction1:
        'Make sure the document is shared with the organization to which you want the document to be shared',
      instruction2: 'Open the document in online office',
      instruction3: 'Choose File - Share - Embed',
      instruction4: 'Copy Embedding Code',
      instruction5: 'Paste the Embed code here and press save',
    },
  },
};
