export const DocumentModelEditSceneTranslation = {
  fi: {
    DocumentModelEditScene: {
      title: 'Muokkaa {{type}} pohjaa',
      confirmDelete: 'Haluatko varmasti poistaa pohjan ja kaikki siihen liittyvät tietueet?',
    },
  },
  en: {
    DocumentModelEditScene: {
      title: 'Edit {{type}} document model',
      confirmDelete: 'Are you sure you want to delete the document model and all related records?',
    },
  },
};
