import { useMemo } from 'react';
import { useUserInput } from '../inputs/use-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useForgotForm = () => {
  const inputs = useUserInput();

  const form = useMemo(() => {
    return createForm([inputs.email]);
  }, [inputs.email]);

  return form;
};

export type ForgotFormBody = {
  email: string;
};
