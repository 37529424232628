export const ACLIndexSceneTranslation = {
  fi: {
    ACLIndexScene: {
      title: 'Käyttöoikeusryhmät',
    },
  },
  en: {
    ACLIndexScene: {
      title: 'Access Control Lists',
    },
  },
};
