import { FieldMetaProps } from 'formik';
import { mergeClasses } from '../../utils/merge-classes';

export type InputClassNames = {
  input?: string;
  error?: string;
};

export const useInputClasses = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldMetaProps<any>,
  classes?: InputClassNames
): string => {
  const inputClasses = classes?.input || 'input';

  let errorClasses: string | undefined;

  if (meta.error && meta.touched) {
    errorClasses = classes?.error || 'input-error';
  }

  return mergeClasses(inputClasses, errorClasses);
};
