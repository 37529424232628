export const DocumentApprovalActionTranslation = {
  fi: {
    DocumentApprovalAction: {
      approvedByUser: 'Olet hyväksynyt dokumentin. Odotetaan muiden hyväksyntää.',
      unapproved:
        'Dokumentti vaati hyväksynnän. Lähetä pyyntö hyväksyjälle, kun haluat hyväksyttää dokumentin.',
      approved: 'Dokumentti on hyväksytty.',
      approve: 'Voit hyväksyä dokumentin.',
      requestSent: 'Dokumentille on pyydetty hyväksyntää.',
      approveButton: 'Hyväksy',
      sendRequestButton: 'Lähetä pyyntö',
      rejectButton: 'Hylkää',
      missingApprover: 'Hyväksyjä puuttuu',
      sendReminder: 'Lähetä muistutus',
      allRequestSent: `{{ count  }} hyväksyntäpyyntöä lähetetty`,
    },
  },
  en: {
    DocumentApprovalAction: {
      approvedByUser: 'You have approved the document. Waiting for other approvals.',
      unapproved:
        'The document required approval. Send the request to the approver to approve the document.',
      approved: 'The document has been approved.',
      approve: 'You can accept the document.',
      requestSent: 'Approval has been requested for the document.',
      approveButton: 'Approve',
      sendRequestButton: 'Send request',
      rejectButton: 'Reject',
      missingApprover: 'Approver missing',
      sendReminder: 'Send reminder',
      allRequestSent: `{{ count  }} approval request sent`,
    },
  },
};
