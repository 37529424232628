import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CardTitle } from '../../components/CardTitle';
import { getDocEditRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import { MSLinkFormBody, useMSLinkForm } from '../../forms/use-ms-link.form';
import {
  useDeleteMsLinkMutation,
  useMsLinkSceneQuery,
  useUpdateMsLinkMutation,
} from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';
import { BackButton } from '../../releox-engine/react-components/app-form-generator/BackButton';
import { DeleteButton } from '../../releox-engine/react-components/app-form-generator/DeleteButton';
import { SubmitButton } from '../../releox-engine/react-components/app-form-generator/SubmitButton';
import { DocumentTypeField } from '../../utils/DocumentType';

type Props = {
  type: DocumentTypeField;
};

export const MSLinkScene = ({ type }: Props): JSX.Element => {
  const form = useMSLinkForm();
  const { t } = useTranslation('WordScene');
  const { id } = useParams();
  if (!id) throw new Error('[WordScene] Missing id');

  const SHOW = getDocShowRoute(type);
  const EDIT = getDocEditRoute(type);

  const { data } = useMsLinkSceneQuery({ variables: { id } });

  const [updateMsLink, { loading }] = useUpdateMsLinkMutation({
    onCompleted: useOnComplete(SHOW, 'saved'),
  });

  const [deleteMsLink, { loading: isDeleteLoading }] = useDeleteMsLinkMutation({
    variables: { id },
    onCompleted: useOnComplete(SHOW, 'saved'),
  });

  const handleSubmit = useCallback(
    (body: MSLinkFormBody) => {
      const msLink = body.iframe;
      if (!msLink?.includes('sharepoint.com')) return toast.error(t('Invalid MS link url'));
      // Take src from iframe
      const part1 = msLink.split('src="')[1];
      // Take everything before the first "
      const url = part1.split('"')[0];

      updateMsLink({ variables: { id, body: { msLink: url.replaceAll('&amp;', '&') } } });
    },
    [id, updateMsLink, t]
  );
  const Footer = (
    <>
      <SubmitButton text={t('Common:save')} loading={loading || isDeleteLoading} />
      <BackButton to={getRoute(EDIT, { id })} />
      {data?.document.msLink ? (
        <DeleteButton onClick={() => deleteMsLink()} loading={loading || isDeleteLoading} />
      ) : null}
      <div className="clear-both" />
    </>
  );

  const Instructions = (
    <>
      <CardTitle>{t('updateMSLink')}</CardTitle>
      <ul className="list-decimal text-sm text-gray-800 ml-5 space-y-2">
        <li>{t('instruction1')}</li>
        <li> {t('instruction2')}</li>
        <li>{t('instruction3')}</li>
        <li>{t('instruction4')}</li>
        <li>{t('instruction5')}</li>
      </ul>
    </>
  );

  return (
    <AppFormScene
      type="create"
      title={t('updateMSLink')}
      form={form}
      FooterElement={Footer}
      onSubmit={handleSubmit}
      isSubmitLoading={loading}
      backLink={getRoute(EDIT, { id })}
      HeaderElement={Instructions}
    />
  );
};
