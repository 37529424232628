import { useMemo } from 'react';
import { useUserInput } from '../inputs/use-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useInviteTeamStep1Form = () => {
  const inputs = useUserInput();

  const form = useMemo(() => {
    return createForm([inputs.email, inputs.name]);
  }, [inputs.email, inputs.name]);

  return form;
};

export type InviteTeamFormBody = {
  name: string;
  email: string;
};
