export const ReportRiskMatrixSceneTranslation = {
  fi: {
    ReportRiskMatrixScene: {
      title: 'Riskimatriisi',
      documents: 'Dokumentit',
    },
  },
  en: {
    ReportRiskMatrixScene: {
      title: 'Risk Matrix',
      documents: 'Documents',
    },
  },
};
