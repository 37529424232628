import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Loading } from '../../components/Loading';
import { ReportQncTagSceneQuery } from '../../generated/graphql';
import { LegendOnClick } from '../../utils/LegendOnClick';
import { chartColors } from '../../utils/chart-colors';
import { ReportMetaData } from '../ReportMetaData';

type Props = {
  data?: ReportQncTagSceneQuery['qncTagReport'];
  loading: boolean;
  hideCount?: boolean;
};

export const ReportQncTagChart = ({ data, loading, hideCount }: Props) => {
  const [ignore, setIgnore] = useState<string[]>([]);
  const { t } = useTranslation();

  const chartData = data?.result.map((r) => {
    const o = {};
    _.set(o, 'name', t(`Month:${r.name}`));
    if (r.data) {
      r.data.forEach((d) => {
        _.setWith(o, [`${d.name}`], d.count);
      });
    }
    return o;
  });

  const bars = Object.keys(_.get(chartData, '0', {}));

  const handleLegendClick = useCallback(
    ({ dataKey }: LegendOnClick) => {
      if (typeof dataKey === 'string') {
        setIgnore((prev) => {
          if (prev.includes(dataKey)) return prev.filter((p) => p !== dataKey);
          return [...prev, dataKey];
        });
      }
    },
    [setIgnore]
  );

  if (loading || !chartData || !data) return <Loading />;

  return (
    <>
      <ReportMetaData data={data} hideCount={hideCount} />
      <ResponsiveContainer width="100%" aspect={4.0 / 1.4}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend onClick={handleLegendClick} />
          {bars
            .filter((key) => key !== 'name')
            .map((chart, i) => (
              <Bar
                dataKey={chart}
                hide={ignore.includes(chart)}
                key={chart}
                name={chart}
                fill={chartColors[i % chartColors.length]}
              />
            ))}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
