import { useTranslation } from 'react-i18next';
import { documentModelColumn } from '../../columns/document-model.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { EmptyTable } from '../../components/table/EmptyTable';
import { LoadingTable } from '../../components/table/LoadingTable';
import { Table } from '../../components/table/Table';
import { TableBody } from '../../components/table/TableBody';
import { TableCol } from '../../components/table/TableCol';
import { TableHead } from '../../components/table/TableHead';
import { TableRow } from '../../components/table/TableRow';
import { TableTH } from '../../components/table/TableTH';
import { getRoute } from '../../config/routes.config';
import { useDocumentModelIndexSceneQuery } from '../../generated/graphql';

export const DocumentModelIndexScene = (): JSX.Element => {
  const { t } = useTranslation('DocumentModelIndexScene');
  const { data, loading } = useDocumentModelIndexSceneQuery();

  const columns = [documentModelColumn.type, documentModelColumn.link, documentModelColumn.edit];

  return (
    <Card>
      <ButtonLink to={getRoute('DOCUMENT_MODEL_CREATE')} className="float-right">
        {t('Common:addNew')}
      </ButtonLink>
      <CardTitle>{t('title')}</CardTitle>
      <Table>
        <TableHead>
          <tr>
            {columns.map((column) => (
              <TableTH key={`${column.field}-${column.text}`} column={column} />
            ))}
          </tr>
        </TableHead>
        <TableBody>
          <LoadingTable columns={columns} loading={loading} />
          <EmptyTable columns={columns} data={data?.documentModels.list} loading={loading} />
          {data?.documentModels.list.map((documentModel) => (
            <TableRow key={documentModel.id}>
              {columns.map((column) => (
                <TableCol
                  key={`${column.field}-${column.text}`}
                  data={documentModel}
                  column={column}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};
