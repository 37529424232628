export const ReportMetaDataTranslation = {
  fi: {
    ReportMetaData: {
      totalCount: 'Poikkeamat yhteensä:',
      dateRange: 'Ajanjakso:',
    },
  },
  en: {
    ReportMetaData: {
      totalCount: 'Total count:',
      dateRange: 'Period:',
    },
  },
};
