import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { useHomeSceneCustomCountQuery } from '../../generated/graphql';
import { DashButton } from '../DashButton';

export const CustomDocIndexDashButton = () => {
  const { t } = useTranslation('HomeScene');
  const [user] = useContext(UserContext);

  const { data } = useHomeSceneCustomCountQuery({
    variables: {
      where: {
        includeModels: user.myCustomDocuments.read,
      },
    },
  });

  return (
    <DashButton
      to={getRoute('CUSTOM_DOCUMENT_INDEX')}
      icon="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
      title={t('customDocuments')}
      text={`${t('showAll')} ${data?.documentsV2.totalCount}`}
    />
  );
};
