import { FC } from 'react';
import { FormInput } from './form-input';
import { InputElementType } from './input-element-type';

type RenderFieldProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  input: FormInput<any, any, unknown, string> | InputElementType;
};

/**
 * @example
 * <FieldRenderer input={input} />
 * // Renders as
 * <input type="text" name="name" id="name" />
 */
export const FieldRenderer: FC<RenderFieldProps> = ({ input }) => {
  if (!('type' in input)) return <input.element key={input.name} />;

  return <input.element {...input.props} id={input.props.id || input.props.name} />;
};
