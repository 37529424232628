export const QNCTypeTranslation = {
  fi: {
    QNCType: {
      internalNonConformity: 'Sisäiset poikkeamat', // 'Internal non conformity',
      customerReclamation: 'Asiakasreklamaatiot', // 'Customer reclamation',
      supplierReclamation: 'Toimittaja reklamaatiot', // 'Supplier reclamation',
      initiative: 'Aloitteet', // 'Initiative',
      safetyAccident: 'Työtapaturmat', // 'Safety accident',
      safetyObservation: 'Turvallisuushavainnot', // 'Safety observation',
      environmentObservation: 'Ympäristöhavainnot',
      internalAudit: 'Sisäinen auditointi',
      externalAudit: 'Ulkoinen auditointi',
      other: 'Muu',
    },
  },
  en: {
    QNCType: {
      internalNonConformity: 'Internal non conformity', // 'Internal non conformity',
      customerReclamation: 'Customer reclamation', // 'Customer reclamation',
      supplierReclamation: 'Supplier reclamation', // 'Supplier reclamation',
      initiative: 'Initiative', // 'Initiative',
      safetyAccident: 'Safety accident', // 'Safety accident',
      safetyObservation: 'Safety observation', // 'Safety observation',
      environmentObservation: 'Environment observation',
      internalAudit: 'Internal audit',
      externalAudit: 'External audit',
      other: 'Other',
    },
  },
};
