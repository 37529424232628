export enum QNCType {
  INTERNAL_NON_CONFORMITY = 'internalNonConformity', // 'Internal non conformity',
  CUSTOMER_RECLAMATION = 'customerReclamation', // 'Customer reclamation',
  SUPPLIER_RECLAMATION = 'supplierReclamation', // 'Supplier reclamation',
  INITIATIVE = 'initiative', // 'Initiative',
  SAFETY_ACCIDENT = 'safetyAccident', // 'Safety accident',
  SAFETY_OBSERVATION = 'safetyObservation', // 'Safety observation',
  ENVIRONMENT_OBSERVATION = 'environmentObservation',
  INTERNAL_AUDIT = 'internalAudit', // sisäinen auditointi
  EXTERNAL_AUDIT = 'externalAudit', // ulkoinen
  OTHER = 'other', // muu havainto
}
