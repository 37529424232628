export const TeamIndexSceneTranslation = {
  fi: {
    TeamIndexScene: {
      title: 'Tiimi',
      filter: 'Haku',
      submit: 'Hae',
    },
  },
  en: {
    TeamIndexScene: {
      title: 'Team',
      filter: 'Search',
      submit: 'Search',
    },
  },
};
