import { MutationHookOptions } from '@apollo/client';
import _ from 'lodash';
import { useCallback } from 'react';
import type { PartialDeep } from 'type-fest';

/**
 * Transform form data to `{ body }`
 */
export const useSubmitHandler = <T, C>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: (opt: MutationHookOptions<C, T>) => void,
  variables?: PartialDeep<T>
) => {
  return useCallback(
    (body: PartialDeep<T>) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      mutation({ variables: _.merge<any, any>(variables, { body }) });
    },
    [mutation, variables]
  );
};
