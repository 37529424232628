import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ApprovalActionLayout } from './ApprovalActionLayout';

export const ApprovedByUser: FC = () => {
  const { t } = useTranslation('DocumentApprovalAction');
  return (
    <ApprovalActionLayout>
      <p className="text-sm text-gray-900">{t('approvedByUser')}</p>
    </ApprovalActionLayout>
  );
};
