import { createContext, FC, useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRoute } from '../../config/routes.config';
import { useLocalStorage } from './use-localstorage';

const AuthContext = createContext<Context>({
  token: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  login: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  logout: () => {},
});

type Props = {
  children: React.ReactNode;
  token: string | null;
};

type Context = {
  token: string | null;
  login: (token: string) => void;
  logout: () => void;
};

export const AuthProvider: FC<Props> = ({ children, token: propToken }) => {
  const [token, setToken] = useLocalStorage(propToken);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    async (_token: string) => {
      setToken(_token);
      navigate(getRoute('DASHBOARD'));
    },
    [navigate, setToken]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setToken(null);
    navigate(getRoute('LOGIN'), { replace: true });
  }, [setToken, navigate]);

  const value = useMemo(
    (): Context => ({
      token,
      login,
      logout,
    }),
    [login, logout, token]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
