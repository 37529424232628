import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useCustomReportInput = () => {
  const Yup = useYup();

  const name: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'name',
      label: 'CustomReport:name',
      type: 'text',
    },
  };

  const modelType: FormInput = {
    type: 'select',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'modelType',
      label: 'CustomReport:modelType',
      options: [],
    },
  };

  const fieldKey: FormInput = {
    type: 'select',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'fieldKey',
      label: 'CustomReport:fieldKey',
      options: [],
    },
  };

  return {
    name,
    fieldKey,
    modelType,
  };
};
