import { Navigate, Outlet } from 'react-router-dom';
import { getRoute } from '../../config/routes.config';
import { useAuth } from '../../hooks/auth/use-auth';
import { BackgroundClass } from '../BackgroundClass';

interface Props {
  className?: string;
}

export const AuthLayout = (props: Props) => {
  const { className } = props;
  const { token } = useAuth();

  if (token) {
    return <Navigate to={getRoute('DASHBOARD')} />;
  }

  return (
    <div className={`w-full h-screen justify-center py-64 px-6 lg:px-8 items-center ${className}`}>
      <BackgroundClass className="BlueBackground" />
      <Outlet />
    </div>
  );
};
