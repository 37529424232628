import { PaperClipIcon } from '@heroicons/react/outline';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { ApprovalStatusBadge } from '../components/ApprovalStatusBadge';
import { RiskBadge } from '../components/RiskBadge';
import { DocumentIndexSceneQuery, DocumentShowSceneQuery } from '../generated/graphql';
import { formatDate } from '../utils/format-date';
import { TableColumn } from './table-column';

type ColumnData = DocumentIndexSceneQuery['documentsV2']['list'][0];

export const useDocumentColumn = () => {
  const { t } = useTranslation('Document');

  const documentIdentifier: TableColumn = {
    field: 'documentIdentifier',
    text: 'Document:documentIdentifier',
    formatter: (data: ColumnData) => <>{data.documentIdentifier}</>,
  };

  const name: TableColumn = {
    field: 'name',
    text: 'Document:name',
    sort: true,
    formatter: (data: ColumnData) => {
      let icon: null | JSX.Element = null;
      if (data.hasAttachment) icon = <PaperClipIcon className="w-4 h-4 inline-block ml-2" />;
      return (
        <>
          {data.name}
          {icon}
        </>
      );
    },
  };

  const type: TableColumn = {
    field: 'type',
    text: 'Document:type',
  };

  const createdBy: TableColumn = {
    field: 'createdBy.name',
    text: 'Document:createdBy',
  };

  const riskProbability: TableColumn = {
    field: 'documentProperties.0.value',
    text: 'Document:riskProbability',
    formatter: (data: DocumentShowSceneQuery['document']) => {
      const val = get(data, `documentProperties[0].value`, null);

      if (!val || val === 'Label not found') {
        return '-';
      }

      return <RiskBadge tKey={val} text={t(val)} />;
    },
  };

  const riskConsequence: TableColumn = {
    field: 'documentProperties.1.value',
    text: 'Document:riskConsequence',
    formatter: (data: DocumentShowSceneQuery['document']) => {
      const val = get(data, `documentProperties[1].value`, null);

      if (!val || val === 'Label not found') {
        return '-';
      }

      return <RiskBadge tKey={val} text={t(val)} />;
    },
  };

  const riskIndex: TableColumn = {
    field: 'documentProperties.2.value',
    text: 'Document:riskIndex',
    formatter: (data: DocumentShowSceneQuery['document']) => {
      const val = get(data, `documentProperties[2].value`, null);

      if (!val || val === 'Label not found') {
        return '-';
      }

      return <RiskBadge tKey={val} text={t(val)} />;
    },
  };

  const costs: TableColumn = {
    field: 'costs',
    text: 'Document:costs',
  };

  const description: TableColumn = {
    field: 'description',
    text: 'Document:description',
  };

  const category: TableColumn = {
    field: 'category',
    text: 'Document:category',
    formatter: (data: DocumentShowSceneQuery['document']) => {
      return data.category ? t(`QNCType:${data.category}`) : '-';
    },
  };

  const groupNames: TableColumn = {
    field: 'groups',
    text: 'Document:groupNames',
    formatter: (data: ColumnData) => {
      return data.groups.map((group) => group.name).join(', ') || t('public');
    },
  };

  const createdAt: TableColumn = {
    field: 'createdAt',
    text: 'Document:createdAt',
    sort: true,
    formatter: (data: ColumnData) => {
      return formatDate(data.createdAt);
    },
  };

  const status: TableColumn = {
    field: 'isApproved',
    text: 'Document:status',
    formatter: (data: ColumnData) => <ApprovalStatusBadge data={data} />,
  };

  return {
    documentIdentifier,
    name,
    createdBy,
    groupNames,
    createdAt,
    status,
    type,
    description,
    costs,
    category,
    riskConsequence,
    riskIndex,
    riskProbability,
  };
};
