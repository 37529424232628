export default {
  fi: {
    Tag: {
      name: 'Nimi',
      createdAt: 'Luotu',
    },
  },
  en: {
    Tag: {
      name: 'Name',
      createdAt: 'Created',
    },
  },
};
