import Axios, { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { authConfig } from '../config/auth.config';
import { DriveFileResourceType, uploadConfig } from '../config/upload.config';

export const uploadFileToServer = async (
  file: File,
  imageType: DriveFileResourceType,
  documentId: string
) => {
  const token = localStorage.getItem(authConfig.tokenStorageKey);

  if (!token) throw new Error('[uploadFile] Token is undefined');

  const opts: AxiosRequestConfig = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const renamedFile = new File([file], encodeURIComponent(file.name), { type: file.type });

  const body = new FormData();
  body.append('file', renamedFile);
  body.append('documentId', documentId);
  body.append('fileType', imageType);

  try {
    const { data } = await Axios.post(uploadConfig.api, body, opts);
    return data;
  } catch (e) {
    if (Array.isArray(_.get(e, 'response.data.message'))) {
      toast.error(_.get(e, 'response.data.message.0'));
    } else if (_.has(e, 'message')) {
      toast.error(_.get(e, 'message'));
    }
  }
};
