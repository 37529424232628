export const PrivacyScene = () => {
  return (
    <div className="bg-white p-6 rounded text-gray-600">
      <h1 className="my-6 font-bold text-4xl">Rekisteriseloste</h1>

      <h2 className="my-6 font-bold text-3xl">Rekisterinpitäjä</h2>
      <p className="mb-4 text-xl">QMClouds Oy, c/o Tamkotek Oy, Töyrytie 1-5, 33530 TAMPERE</p>

      <h2 className="my-6 font-bold text-3xl">Rekisterin ylläpitäjä</h2>
      <p className="mb-4 text-xl">Vivecho Oy, c/o Tamkotek Oy, Töyrytie 1-5, 33530 TAMPERE</p>

      <h2 className="my-6 font-bold text-3xl">Rekisterin nimi</h2>
      <p className="mb-4 text-xl">QMClouds Oy:n asiakas- ja ohjelmistorekisteri</p>

      <h2 className="my-6 font-bold text-3xl">Henkilötietojen käsittelyn tarkoitus</h2>
      <p className="mb-4 text-xl">
        Henkilötietoja käsitellään asiakassuhteen hoitamiseen, laskutukseen, tilastollisiin
        tarkoituksiin sekä markkinointitarkoituksiin.
      </p>

      <h2 className="my-6 font-bold text-3xl">Rekisterin tietosisältö</h2>
      <p className="mb-4 text-xl">Rekisteriin tallennetaan seuraavat tiedot:</p>
      <ul>
        <li>Asiakkaan nimi ja sähköpostiosoite</li>
        <li>Asiakkaan tilaustiedot</li>
        <li>EU velvoittamat asiakkaantunnistamiseen tarvittavat- ja maksu-tiedot</li>
        <li>Käyttäjätunnus ja salasana (sähköpostiosoite ja salattu salasana)</li>
      </ul>
      <p></p>

      <h2 className="my-6 font-bold text-3xl">Säännönmukaiset tietolähteet</h2>
      <p className="mb-4 text-xl">
        Henkilötiedot kerätään asiakkaalta itseltään tilauksen yhteydessä tai asiakassuhteen aikana.
      </p>

      <h2 className="my-6 font-bold text-3xl">Tietojen luovutus</h2>
      <p className="mb-4 text-xl">
        Henkilötietoja ei luovuteta kolmansille osapuolille ilman asiakkaan suostumusta, ellei se
        ole tarpeen lakisääteisten velvoitteiden täyttämiseksi.
      </p>

      <h2 className="my-6 font-bold text-3xl">Henkilötietojen siirto EU:n ulkopuolelle</h2>
      <p className="mb-4 text-xl">
        Henkilötietoja voidaan siirtää EU:n ulkopuolelle esimerkiksi Google Analyticsin tai Amazon
        S3-palvelun käytön vuoksi. Tietojen siirrossa noudatetaan EU:n tietosuoja-asetuksen
        vaatimuksia.
      </p>

      <h2 className="my-6 font-bold text-3xl">Rekisterin suojauksen periaatteet</h2>
      <p className="mb-4 text-xl">
        Asiakasrekisteri sijaitsee saksassa MongoDB Atlas -palvelun turvallisessa
        tietokanta-alustassa ja tiedostot säilytetään Amazon S3:ssa. Rekisterin tietojen käyttöön
        ovat oikeutettuja ainoastaan ne henkilöt, jotka tarvitsevat tietoja työtehtäviensä
        hoitamiseen. Rekisteri on suojattu asianmukaisin teknisin ja organisatorisin toimenpitein.
      </p>

      <h2 className="my-6 font-bold text-3xl">Tietojen säilytysaika</h2>
      <p className="mb-4 text-xl">
        Asiakkaan henkilötiedot säilytetään niin kauan kuin asiakassuhde on voimassa. Tilauksen
        päätyttyä henkilötiedot poistetaan rekisteristä kohtuullisen ajan kuluessa. Tietoja
        säilytetään kuitenkin pidempään, jos se on tarpeen lakisääteisten velvoitteiden
        noudattamiseksi.
      </p>

      <h2 className="my-6 font-bold text-3xl">Rekisteröidyn oikeudet</h2>
      <p className="mb-4 text-xl">
        Rekisteröidyllä on oikeus saada tietoa häntä koskevien henkilötietojen käsittelystä, pyytää
        virheellisten tietojen korjaamista ja poistamista, rajoittaa tietojen käsittelyä sekä
        vastustaa tietojen käsittelyä markkinointitarkoituksiin. Rekisteröidyllä on myös oikeus
        siirtää tiedot järjestelmästä toiseen.
      </p>

      <h2 className="my-6 font-bold text-3xl">Evästeet</h2>
      <p className="mb-4 text-xl">
        QMClouds Oy käyttää evästeitä palvelunsa tarjoamiseen ja parantamiseen sekä
        markkinointitarkoituksiin. Evästeet tallentavat tietoja käyttäjän tietokoneelle, jotta
        käyttäjän ei tarvitse syöttää tietoja uudelleen. Evästeitä käytetään myös tilastollisiin
        tarkoituksiin ja käyttäjien käyttäytymisen analysointiin. Käyttäjä voi halutessaan estää
        evästeiden käytön selaimen asetuksista.
      </p>
    </div>
  );
};
