export default {
  fi: {
    Filter: {
      submit: 'Etsi',
      reset: 'Tyhjennä',
      name: 'Nimi',
      email: 'Sähköposti',
      isApprover: 'Hyväksyjä',
      acl: 'Käyttöoikeusryhmä',
      groups: 'Ryhmä',
      noSelection: 'Ei valintaa',
      noOptions: 'Ei vaihtoehtoja',
      yes: 'Kyllä',
      no: 'Ei',
    },
  },
  en: {
    Filter: {
      submit: 'Find',
      reset: 'Reset',
      name: 'Name',
      email: 'Email',
      isApprover: 'Approver',
      acl: 'Resource ACL',
      groups: 'Group',
      noSelection: 'No selection',
      noOptions: 'No options',
      yes: 'Yes',
      no: 'No',
    },
  },
};
