/* eslint-disable @typescript-eslint/no-explicit-any */
import _ from 'lodash';
import * as Yup from 'yup';
import { FormInput, FormInputs } from './FormInput';

interface FormGeneratorProps {
  formConfig: FormInput[];
}

type Shape = {
  [key: string]: any;
};

export const createFormMeta = (formInputs: FormInput[]) => {
  const initValues: any = {};
  const yupShape: Shape = {};

  formInputs.forEach((input) => {
    if (input.type !== 'element') {
      if (input.type === 'array') {
        const childShape: any = {};
        input.props.elements.forEach((childInput) => {
          if (childInput.type !== 'array' && childInput.type !== 'element') {
            childShape[childInput.props.name] = childInput.validation;
          }
        });
        _.set(initValues, input.props.name, []);
        yupShape[input.props.name] = Yup.array().of(Yup.object().shape(childShape));
      } else {
        _.set(initValues, input.props.name, input.initValue);
        yupShape[input.props.name] = input.validation;
      }
    }
  });

  return {
    initValues,
    validateSchema: Yup.object().shape(yupShape),
  };
};

export const createForm = (formConfig: FormInput[]) => ({
  Element: () => <FormGenerator formConfig={formConfig} />,
  meta: createFormMeta(formConfig),
});

export type ReleoxFormObject = ReturnType<typeof createForm>;
export type ReleoxFormMeta = ReturnType<typeof createFormMeta>;

export const FormGenerator = ({ formConfig }: FormGeneratorProps) => {
  const elements = formConfig.map((form) => {
    const input: any = FormInputs.find((i) => i.type === form.type);
    if (!input) throw new Error('ReleoxFormGenerator error: Cannot find input');
    return (
      <input.Element {...form.props} key={form.props.name} id={form.props.id || form.props.name} />
    );
  });

  return <>{elements}</>;
};
