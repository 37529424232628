import { QueryResult } from '@apollo/client';
import { Map } from 'immutable';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../components/Card';
import { CardTitle } from '../../../components/CardTitle';
import { FormLayout } from '../../../components/FormLayout';
import { Loading } from '../../../components/Loading';
import { ReleoxFormObject } from '../../form/FormGenerator';
import { AppFormGenerator, AppFormGeneratorProps } from './AppFormGenerator';
import { BackButton } from './BackButton';
import { DeleteButton } from './DeleteButton';
import { SubmitButton } from './SubmitButton';

type BaseProps = {
  title: string;
  form: ReleoxFormObject;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit(data: any): void;

  isSubmitLoading: boolean;
  backLink?: string;
  FooterElement?: AppFormGeneratorProps['FooterElement'];
  HeaderElement?: AppFormGeneratorProps['HeaderElement'];
};

interface CreateProps extends BaseProps {
  type: 'create';
}

interface EditProps extends BaseProps {
  type: 'edit';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  query: QueryResult<any, any>;
  queryDataPath: string;
  onDelete?(): void;
}

/**
 *  reformat some value that they fit to formik initial values
 *  like reformat date and remove undefined and null values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const format = (data: any) => {
  let values = Map(data);

  Object.keys(values.toObject()).forEach((key) => {
    const value = [null, undefined].includes(data[key]) ? '' : data[key];
    values = values.set(key, value);

    // Refactor date format from YYYY-MM-DDTmm:ss to YYYY-MM-DD what is requirements of html date input
    const isDateFormat = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
    if (typeof data[key] === 'string' && isDateFormat.test(data[key])) {
      values = values.set(key, data[key].substring(0, 10));
    }
  });

  // Remove graphql ___typename
  values = values.delete('__typename');
  return values.toObject();
};

export const AppFormScene = (props: EditProps | CreateProps) => {
  const { t } = useTranslation('Common');
  const { form, onSubmit, isSubmitLoading, title, backLink } = props;

  // Handle on submit event
  const submitMiddleware = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (baseValues: any) => {
      const validatedValues = form.meta.validateSchema.validateSync(baseValues);

      onSubmit(validatedValues);
    },
    [form, onSubmit]
  );

  if (props.type === 'edit' && props.query?.loading) return <Loading />;

  let key: string | undefined = undefined;

  if (props.type === 'edit') key = JSON.stringify(props.query?.data);

  const Header = props.HeaderElement || <CardTitle>{title}</CardTitle>;

  const Buttons = props.FooterElement || (
    <>
      <SubmitButton text={t('save')} loading={isSubmitLoading} />
      {backLink ? <BackButton to={backLink} /> : null}
      {props.type === 'edit' && props.onDelete ? (
        <DeleteButton onClick={props.onDelete} loading={isSubmitLoading} />
      ) : undefined}
      <div className="clear-both" />
    </>
  );

  return (
    <FormLayout key={key}>
      <Card>
        <AppFormGenerator
          wrapperClassNames="space-y-4"
          HeaderElement={Header}
          FooterElement={Buttons}
          onSubmit={submitMiddleware}
          form={form}
          initialValues={
            props.type === 'edit' && props.query?.data && props.queryDataPath
              ? format(props.query.data[props.queryDataPath])
              : {}
          }
        />
      </Card>
    </FormLayout>
  );
};
