export default {
  fi: {
    ResetPasswordScene: {
      save: 'Tallenna',
      newPassword: 'Anna uusi salasana',
      fillNewPassword: 'Anna uusi salasana ja paina tallenna.',
    },
  },
  en: {
    ResetPasswordScene: {
      save: 'Save',
      newPassword: 'Enter a new password',
      fillNewPassword: 'Enter your new password and press save.',
    },
  },
};
