export default {
  fi: {
    Miro: {
      iframe: 'Linkki',
    },
  },
  en: {
    Miro: {
      iframe: 'Link',
    },
  },
};
