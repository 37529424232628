export const TagCreateSceneTranslation = {
  fi: {
    TagCreateScene: {
      title: 'Luo Tagi',
    },
  },
  en: {
    TagCreateScene: {
      title: 'Create Tag',
    },
  },
};
