import { DocumentEditSceneQuery } from '../generated/graphql';

type Option = {
  label: string;
  value: string;
};

type Data = {
  id: string;
  name: string;
};

export const remapOptions = (data: Data): Option => {
  return {
    label: data.name,
    value: data.id,
  };
};

export const remapModelFieldToDocumentFormField =
  (documentFields: DocumentEditSceneQuery['document']['documentFields']) =>
  (modelField: DocumentEditSceneQuery['documentModelByType']['documentModelFields'][0]) => {
    const docField = documentFields.find((df) => df.key === modelField.key);
    return {
      id: docField?.id,
      key: docField?.key || modelField.key,
      value: docField?.value || '',
      label: modelField.label,
      options: modelField.options,
      type: modelField.type,
    };
  };

export const remapModelFieldToEmptyDocumentFormField = (
  modelField: DocumentEditSceneQuery['documentModelByType']['documentModelFields'][0]
) => {
  return {
    key: modelField?.key || modelField.key,
    value: '',
    label: modelField.label,
    options: modelField.options,
    type: modelField.type,
  };
};

export type DocumentFormFields = {
  value: string;
  id?: string;
  key: string;
  label: string;
  options: string[];
  type: string;
};

export const remapDocumentFormFieldToDocumentField = (f: DocumentFormFields) => ({
  value: `${f.value}`,
  id: f.id,
  key: f.key,
});
