export const GroupEditSceneTranslation = {
  fi: {
    GroupEditScene: {
      title: 'Muokkaa ryhmää',
    },
  },
  en: {
    GroupEditScene: {
      title: 'Edit Group',
    },
  },
};
