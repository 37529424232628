import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Button } from '../../components/Button';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import {
  CustomReportShowQuery,
  useCustomReportShowQuery,
  useDeleteCustomReportMutation,
} from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { chartColors } from '../../utils/chart-colors';

type ChartData = {
  [key: string]: string;
};

const restructureData = (
  t: TFunction,
  data: CustomReportShowQuery['getCustomReport']['data']
): ChartData[] => {
  return data.map((row) => {
    const newRow: ChartData = {
      name: t(row.name),
    };
    row.bars.forEach((cell) => {
      newRow[cell.label] = cell.value;
    });
    return newRow;
  });
};

export const CustomReportShow = () => {
  const { t } = useTranslation('CustomReportShow');
  const { id } = useParams();
  if (!id) throw new Error('No id provided');

  const onComplete = useOnComplete('REPORT_INDEX', 'deleted');

  const { data, loading: queryLoading } = useCustomReportShowQuery({ variables: { id } });
  const [deleteReport, { loading }] = useDeleteCustomReportMutation({
    variables: { id },
    onCompleted: onComplete,
  });

  const handleDelete = () => {
    // Prompt user to confirm deletion
    if (!window.confirm(t('confirm'))) return;
    deleteReport();
  };

  if (queryLoading) return <span>loading</span>;

  if (!data?.getCustomReport)
    return (
      <Card>
        <Button type="button" onClick={handleDelete} loading={loading} className="float-right">
          {t('Common:deleteText')}
        </Button>
        <CardTitle>{t('errorMessage')}</CardTitle>
      </Card>
    );

  return (
    <Card>
      <Button type="button" onClick={handleDelete} loading={loading} className="float-right">
        {t('Common:deleteText')}
      </Button>

      <CardTitle>
        {t('title', {
          name: data.getCustomReport.reportName,
        })}
      </CardTitle>
      <>
        <ResponsiveContainer width="100%" aspect={4.0 / 1.4}>
          <BarChart data={restructureData(t, data.getCustomReport.data)}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            {data.getCustomReport.dataKeys
              .filter((key) => key !== 'name')
              .map((chart, i) => (
                <Bar
                  dataKey={chart}
                  key={chart}
                  name={chart}
                  fill={chartColors[i % chartColors.length]}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
      </>
    </Card>
  );
};
