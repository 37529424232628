import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const miroRegex = /https:\/\/miro\.com\/[^"]*/;

export const useMiroInput = () => {
  const Yup = useYup();

  // https://miro.com/app/live-embed/uXjVO3aJeTA=/?moveToViewport=-2834,-1668,5578,2981
  // <iframe width="768" height="432" src="https://miro.com/app/live-embed/uXjVPrdsvJ0=/?moveToViewport=-1842,-765,3523,1704&embedId=615539444810" frameborder="0" scrolling="no" allow="fullscreen; clipboard-read; clipboard-write" allowfullscreen></iframe>
  const iframe: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().matches(miroRegex).required(),
    props: {
      name: 'iframe',
      label: 'Miro:iframe',
      type: 'text',
    },
  };

  return {
    iframe,
  };
};
