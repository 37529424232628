import { Field, Form, Formik, FormikConfig } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateMessageMutation } from '../generated/graphql';

type MessageFormProps = {
  refetch(): unknown;
  documentId: string;
};

type FormBody = {
  message: string;
};

type HandleSubmit = FormikConfig<FormBody>['onSubmit'];

export const MessageForm: FC<MessageFormProps> = ({ refetch, documentId }) => {
  const { t } = useTranslation('MessageForm');
  const [createMessage] = useCreateMessageMutation({
    onCompleted: (d) => {
      if (d.createMessage) {
        refetch();
      }
    },
  });

  const handleSubmit: HandleSubmit = (values, helpers) => {
    createMessage({
      variables: {
        body: {
          message: values.message,
          documentId,
        },
      },
    });
    helpers.resetForm();
  };

  return (
    <Formik onSubmit={handleSubmit} initialValues={{ message: '' }}>
      <Form>
        <Field
          type="textarea"
          name="message"
          placeholder={t('message')}
          className="mt-4 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm"
        />
      </Form>
    </Formik>
  );
};
