import htmlReactParser from 'html-react-parser';
import { useTranslation } from 'react-i18next';
import { getDocEditorRoute, getRoute } from '../config/routes.config';
import { DocumentShowSceneQuery } from '../generated/graphql';
import { DocumentTypeField } from '../utils/DocumentType';
import { ButtonLink } from './ButtonLink';

interface Props {
  documentContent: DocumentShowSceneQuery['document']['documentContent'];
  type: DocumentTypeField;
  hasEditPermission: boolean;
  id: string;
}

const ContentEditor = ({ documentContent, type, id, hasEditPermission }: Props) => {
  const { t } = useTranslation('ContentEditor');

  const EDITOR_URL = getDocEditorRoute(type);

  return (
    <>
      <div className="mb-12">
        {hasEditPermission && (
          <ButtonLink to={getRoute(EDITOR_URL, { id })} className="float-right">
            {t('edit')}
          </ButtonLink>
        )}
      </div>
      <hr />
      <div className="mt-4 editor-preview">
        {documentContent.content ? (
          htmlReactParser(documentContent.content)
        ) : (
          <p className="text-gray-500 text-center">{t('noContent')}</p>
        )}
      </div>
    </>
  );
};

export default ContentEditor;
