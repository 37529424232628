import axios from 'axios';
import { createBrowserRouter, createRoutesFromElements, defer, Route } from 'react-router-dom';
import { AuthMiddleware } from './AuthMiddleware';
import { AuthLayout } from './components/auth/AuthLayout';
import { DashLayout } from './components/auth/DashLayout2';
import { authConfig } from './config/auth.config';
import { getDashboardRouteConfig } from './config/routes.config';
import { AccountManagementScene } from './scenes/account/AccountManagementScene';
import { AccountDeleteScene } from './scenes/AccountDelete';
import { ACLCreateScene } from './scenes/acl/ACLCreateScene';
import { ACLEditScene } from './scenes/acl/ACLEditScene';
import { ACLIndexScene } from './scenes/acl/ACLIndexScene';
import { ACLShowScene } from './scenes/acl/ACLShowScene';
import { AcceptInvitationScene } from './scenes/auth/accept-invitation/AcceptInvitationScene';
import { ConfirmScene } from './scenes/auth/confirm/ConfirmScene';
import { ForgotScene } from './scenes/auth/forgot/ForgotScene';
import { ForgotSuccessScene } from './scenes/auth/forgot/ForgotSuccessScene';
import { LoginScene } from './scenes/auth/login/LoginScene';
import { LogoutScene } from './scenes/auth/logout/Logout';
import { RegisterScene } from './scenes/auth/register/RegisterScene';
import { ResetPasswordScene } from './scenes/auth/reset/ResetPasswordScene';
import { ResetPasswordSuccessScene } from './scenes/auth/reset/ResetPasswordSuccessScene';
import { CompanyEditScene } from './scenes/company/CompanyEditScene';
import { ChooseCustomDocumentModelScene } from './scenes/custom-document/ChooseCustomDocumentModelScene';
import { CustomDocumentEditScene } from './scenes/custom-document/CustomDocumentEditScene';
import { CustomDocumentCreateScene } from './scenes/custom-document/CustomDocumentModelCreateScene';
import { CustomReportCreateScene } from './scenes/custom-reports/CustomReportCreate';
import { CustomReportShow } from './scenes/custom-reports/CustomReportShow';
import { HomeScene } from './scenes/dashbaord/HomeScene';
import { DataValidationScene } from './scenes/data-validation/DataValidationScene';
import { DocumentLinkCreate } from './scenes/document-link/DocumentLinkCreate';
import { DocumentLinkEdit } from './scenes/document-link/DocumentLinkEdit';
import { DocumentModelCreateScene } from './scenes/document-model/DocumentModelCreateScene';
import { DocumentModelEditScene } from './scenes/document-model/DocumentModelEditScene';
import { DocumentModelIndexScene } from './scenes/document-model/DocumentModelIndexScene';
import { DocumentAddApproverScene } from './scenes/document/DocumentAddApproverScene';
import { DocumentCreateScene } from './scenes/document/DocumentCreateScene';
import { DocumentDriveFileEditScene } from './scenes/document/DocumentDriveFileEditScene';
import { DocumentEditScene } from './scenes/document/DocumentEditScene';
import { DocumentIndexScene } from './scenes/document/DocumentIndexScene';
import { DocumentShowScene } from './scenes/document/DocumentShowScene';
import { RejectScene } from './scenes/document/RejectScene';
import { RiskCreateScene } from './scenes/document/RiskCreateScene';
import { RiskEditScene } from './scenes/document/RiskEditScene';
import { SendReminderScene } from './scenes/document/SendReminderScene';
import { EditorScene } from './scenes/editor/EditorScene';
import { ExportIndexScene } from './scenes/export/ExportIndexScene';
import { GroupCreateScene } from './scenes/group/GroupCreateScene';
import { GroupEditScene } from './scenes/group/GroupEditScene';
import { GroupIndexScene } from './scenes/group/GroupIndexScene';
import { GroupShowScene } from './scenes/group/GroupShowScene';
import { MiroScene } from './scenes/miro/MiroScene';
import { MSLinkScene } from './scenes/ms/MSLinkScene';
import { NoMatch } from './scenes/NoMatch';
import { PaperipankkiScene } from './scenes/paperipankki/PaperipankkiScene';
import { PrivacyScene } from './scenes/privacy';
import { PublicDocumentCreate } from './scenes/public-document-create/PublicDocumentCreate';
import { PublicThanksScene } from './scenes/public-document-create/PublicThanksScene';
import { ReportIndexScene } from './scenes/report/ReportIndexScene';
import { ReportQncCostScene } from './scenes/report/ReportQncCostScene';
import { ReportQncCountScene } from './scenes/report/ReportQncCountScene';
import { ReportQncTagScene } from './scenes/report/ReportQncTagScene';
import { ReportQncTypeScene } from './scenes/report/ReportQncTypeScene';
import { ReportRiskMatrixScene } from './scenes/report/ReportRiskMatrixScene';
import { UserEditScene } from './scenes/settings/UserEditScene';
import { TagCreateScene } from './scenes/tag/TagCreateScene';
import { TagEditScene } from './scenes/tag/TagEditScene';
import { TagIndexScene } from './scenes/tag/TagIndexScene';
import { TeamEditScene } from './scenes/team/TeamEditScene';
import { TeamIndexScene } from './scenes/team/TeamIndexScene';
import { TeamInviteStep1Scene } from './scenes/team/TeamInviteStep1Scene';
import { TeamInviteStep2Scene } from './scenes/team/TeamInviteStep2Scene';
import { createLogger } from './utils/create-logger';

const routes = getDashboardRouteConfig();
const logger = createLogger('UseRoutes');

const getUserData = async () => {
  const token = window.localStorage.getItem(authConfig.tokenStorageKey);

  if (token) {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/validate-token`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return token;
    } catch (error) {
      logger.debug('Token is invalid');
      window.localStorage.removeItem(authConfig.tokenStorageKey);
      return null;
    }
  } else {
    return null;
  }
};

export const appRoutes = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthMiddleware />} loader={() => defer({ userPromise: getUserData() })}>
      <Route element={<AuthLayout />}>
        <Route path={routes.PRIVACY} element={<PrivacyScene />} />
        <Route path={routes.ACCOUNT_DELETE} element={<AccountDeleteScene />} />
        <Route path={routes.ACCEPT_INVITATION} element={<AcceptInvitationScene />} />
        <Route path={routes.LOGIN} element={<LoginScene />} />
        <Route path={routes.FORGOT} element={<ForgotScene />} />
        <Route path={routes.FORGOT_SUCCESS} element={<ForgotSuccessScene />} />
        <Route path={routes.RESET_PASSWORD} element={<ResetPasswordScene />} />
        <Route path={routes.RESET_PASSWORD_SUCCESS} element={<ResetPasswordSuccessScene />} />
        <Route path={routes.CONFIRM} element={<ConfirmScene />} />
        <Route path={routes.REGISTER} element={<RegisterScene />} />
      </Route>

      <Route element={<DashLayout />}>
        <Route path={routes.DASHBOARD} element={<HomeScene />} />
        {/* Document */}
        <Route path={routes.DOCUMENT_CREATE} element={<DocumentCreateScene type="document" />} />
        <Route path={routes.DOCUMENT_INDEX} element={<DocumentIndexScene type="document" />} />
        <Route path={routes.DOCUMENT_EDIT} element={<DocumentEditScene type="document" />} />
        <Route path={routes.DOCUMENT_EDITOR} element={<EditorScene type="document" />} />
        <Route path={routes.DOCUMENT_SHOW} element={<DocumentShowScene type="document" />} />
        <Route
          path={routes.DOCUMENT_DRIVE_FILE_EDIT}
          element={<DocumentDriveFileEditScene type="document" />}
        />
        <Route path={routes.DOCUMENT_REJECT} element={<RejectScene type="document" />} />
        <Route
          path={routes.DOCUMENT_SEND_REMINDER}
          element={<SendReminderScene type="document" />}
        />
        <Route path={routes.DOCUMENT_MIRO} element={<MiroScene type="document" />} />
        <Route path={routes.DOCUMENT_MS} element={<MSLinkScene type="document" />} />
        <Route
          path={routes.DOCUMENT_LINKS_CREATE}
          element={<DocumentLinkCreate type="document" />}
        />
        <Route path={routes.DOCUMENT_LINKS_EDIT} element={<DocumentLinkEdit type="document" />} />
        <Route
          path={routes.DOCUMENT_ADD_APPROVER}
          element={<DocumentAddApproverScene type="document" />}
        />

        {/* QNC */}
        <Route path={routes.QNC_CREATE} element={<DocumentCreateScene type="qnc" />} />
        <Route path={routes.QNC_INDEX} element={<DocumentIndexScene type="qnc" />} />
        <Route path={routes.QNC_EDIT} element={<DocumentEditScene type="qnc" />} />
        <Route path={routes.QNC_EDITOR} element={<EditorScene type="qnc" />} />
        <Route path={routes.QNC_SHOW} element={<DocumentShowScene type="qnc" />} />
        <Route
          path={routes.QNC_DRIVE_FILE_EDIT}
          element={<DocumentDriveFileEditScene type="qnc" />}
        />
        <Route path={routes.QNC_REJECT} element={<RejectScene type="qnc" />} />
        <Route path={routes.QNC_SEND_REMINDER} element={<SendReminderScene type="qnc" />} />
        <Route path={routes.QNC_MIRO} element={<MiroScene type="qnc" />} />
        <Route path={routes.QNC_MS} element={<MSLinkScene type="qnc" />} />
        <Route path={routes.QNC_LINKS_CREATE} element={<DocumentLinkCreate type="qnc" />} />
        <Route path={routes.QNC_LINKS_EDIT} element={<DocumentLinkEdit type="qnc" />} />
        <Route path={routes.QNC_ADD_APPROVER} element={<DocumentAddApproverScene type="qnc" />} />

        {/* Risk */}
        <Route path={routes.RISK_EDITOR} element={<EditorScene type="risk" />} />
        <Route path={routes.RISK_CREATE} element={<RiskCreateScene />} />
        <Route path={routes.RISK_INDEX} element={<DocumentIndexScene type="risk" />} />
        <Route path={routes.RISK_EDIT} element={<RiskEditScene />} />
        <Route path={routes.RISK_SHOW} element={<DocumentShowScene type="risk" />} />
        <Route
          path={routes.RISK_DRIVE_FILE_EDIT}
          element={<DocumentDriveFileEditScene type="risk" />}
        />
        <Route path={routes.RISK_REJECT} element={<RejectScene type="risk" />} />
        <Route path={routes.RISK_SEND_REMINDER} element={<SendReminderScene type="risk" />} />
        <Route path={routes.RISK_MIRO} element={<MiroScene type="risk" />} />
        <Route path={routes.RISK_MS} element={<MSLinkScene type="risk" />} />
        <Route path={routes.RISK_LINKS_CREATE} element={<DocumentLinkCreate type="risk" />} />
        <Route path={routes.RISK_LINKS_EDIT} element={<DocumentLinkEdit type="risk" />} />
        <Route path={routes.RISK_ADD_APPROVER} element={<DocumentAddApproverScene type="risk" />} />

        {/* Anonymous */}
        <Route path={routes.ANONYMOUS_INDEX} element={<DocumentIndexScene type="anonymous" />} />
        <Route path={routes.ANONYMOUS_CREATE} element={<DocumentCreateScene type="anonymous" />} />
        <Route path={routes.ANONYMOUS_SHOW} element={<DocumentShowScene type="anonymous" />} />
        <Route path={routes.ANONYMOUS_EDITOR} element={<EditorScene type="anonymous" />} />
        <Route path={routes.ANONYMOUS_EDIT} element={<DocumentEditScene type="anonymous" />} />
        <Route path={routes.ANONYMOUS_REJECT} element={<RejectScene type="anonymous" />} />
        <Route
          path={routes.ANONYMOUS_SEND_REMINDER}
          element={<SendReminderScene type="anonymous" />}
        />
        <Route
          path={routes.ANONYMOUS_DRIVE_FILE_EDIT}
          element={<DocumentDriveFileEditScene type="anonymous" />}
        />
        <Route path={routes.ANONYMOUS_MIRO} element={<MiroScene type="anonymous" />} />
        <Route
          path={routes.ANONYMOUS_LINKS_CREATE}
          element={<DocumentLinkCreate type="anonymous" />}
        />
        <Route path={routes.ANONYMOUS_LINKS_EDIT} element={<DocumentLinkEdit type="anonymous" />} />
        <Route path={routes.ANONYMOUS_MS} element={<MSLinkScene type="anonymous" />} />
        <Route
          path={routes.ANONYMOUS_ADD_APPROVER}
          element={<DocumentAddApproverScene type="anonymous" />}
        />

        {/* UserEdit */}
        <Route path={routes.USER_EDIT} element={<UserEditScene />} />
        {/* Team */}
        <Route path={routes.TEAM_INDEX} element={<TeamIndexScene />} />
        <Route path={routes.TEAM_EDIT} element={<TeamEditScene />} />
        <Route path={routes.TEAM_INVITE_STEP_1} element={<TeamInviteStep1Scene />} />
        <Route path={routes.TEAM_INVITE_STEP_2} element={<TeamInviteStep2Scene />} />
        {/* Company */}
        <Route path={routes.COMPANY_EDIT} element={<CompanyEditScene />} />
        {/* Document Model */}
        <Route path={routes.DOCUMENT_MODEL_INDEX} element={<DocumentModelIndexScene />} />
        <Route path={routes.DOCUMENT_MODEL_EDIT} element={<DocumentModelEditScene />} />
        <Route path={routes.DOCUMENT_MODEL_CREATE} element={<DocumentModelCreateScene />} />
        {/* Group */}
        <Route path={routes.GROUP_INDEX} element={<GroupIndexScene />} />
        <Route path={routes.GROUP_CREATE} element={<GroupCreateScene />} />
        <Route path={routes.GROUP_EDIT} element={<GroupEditScene />} />
        <Route path={routes.GROUP_SHOW} element={<GroupShowScene />} />
        {/* Tag */}
        <Route path={routes.TAG_INDEX} element={<TagIndexScene />} />
        <Route path={routes.TAG_CREATE} element={<TagCreateScene />} />
        <Route path={routes.TAG_EDIT} element={<TagEditScene />} />
        {/* Report */}
        <Route path={routes.REPORT_INDEX} element={<ReportIndexScene />} />
        <Route path={routes.REPORT_RISK_MATRIX} element={<ReportRiskMatrixScene />} />
        <Route path={routes.REPORT_QNC_COUNT} element={<ReportQncCountScene />} />
        <Route path={routes.REPORT_QNC_TYPE} element={<ReportQncTypeScene />} />
        <Route path={routes.REPORT_QNC_COST} element={<ReportQncCostScene />} />
        <Route path={routes.REPORT_QNC_TAG} element={<ReportQncTagScene />} />
        {/* Export */}
        <Route path={routes.EXPORT} element={<ExportIndexScene />} />
        <Route path={routes.DATA_VALIDATION} element={<DataValidationScene />} />

        {/* Custom reports */}
        <Route path={routes.CUSTOM_REPORT_CREATE} element={<CustomReportCreateScene />} />
        <Route path={routes.CUSTOM_REPORT_SHOW} element={<CustomReportShow />} />

        {/* Custom documents */}
        <Route path={routes.CUSTOM_DOCUMENT_INDEX} element={<DocumentIndexScene type="custom" />} />
        <Route path={routes.CUSTOM_DOCUMENT_CHOOSE} element={<ChooseCustomDocumentModelScene />} />
        <Route path={routes.CUSTOM_DOCUMENT_CREATE} element={<CustomDocumentCreateScene />} />
        <Route path={routes.CUSTOM_DOCUMENT_SHOW} element={<DocumentShowScene type="custom" />} />
        <Route
          path={routes.CUSTOM_DOCUMENT_LINKS_CREATE}
          element={<DocumentLinkCreate type="custom" />}
        />
        <Route
          path={routes.CUSTOM_DOCUMENT_LINKS_EDIT}
          element={<DocumentLinkEdit type="custom" />}
        />
        <Route path={routes.CUSTOM_DOCUMENT_EDITOR} element={<EditorScene type="custom" />} />
        <Route path={routes.CUSTOM_DOCUMENT_REJECT} element={<RejectScene type="custom" />} />
        <Route
          path={routes.CUSTOM_DOCUMENT_SEND_REMINDER}
          element={<SendReminderScene type="custom" />}
        />
        <Route
          path={routes.CUSTOM_DOCUMENT_EDIT}
          element={<CustomDocumentEditScene type="custom" />}
        />
        <Route
          path={routes.CUSTOM_DOCUMENT_ADD_APPROVER}
          element={<DocumentAddApproverScene type="custom" />}
        />

        <Route
          path={routes.CUSTOM_DOCUMENT_DRIVE_FILE_EDIT}
          element={<DocumentDriveFileEditScene type="custom" />}
        />

        <Route path={routes.ACL_INDEX} element={<ACLIndexScene />} />
        <Route path={routes.ACL_CREATE} element={<ACLCreateScene />} />
        <Route path={routes.ACL_SHOW} element={<ACLShowScene />} />
        <Route path={routes.ACL_EDIT} element={<ACLEditScene />} />

        <Route path={routes.ACCOUNT_MANAGEMENT} element={<AccountManagementScene />} />
        <Route path={routes.PAPERIPANKKI} element={<PaperipankkiScene />} />
      </Route>

      <Route path={routes.LOGOUT} element={<LogoutScene />} />
      <Route path={routes.PUBLIC_PAGE} element={<PublicDocumentCreate />} />
      <Route path={routes.PUBLIC_THANKS} element={<PublicThanksScene />} />

      <Route path="*" element={<NoMatch />} />
    </Route>
  )
);
