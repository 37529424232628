export const DocumentEditSceneTranslation = {
  fi: {
    DocumentEditScene: {
      qncTitle: 'Muokkaa poikkeamaa',
      documentTitle: 'Muokkaa dokumenttia',
      riskTitle: 'Muokkaa riskiä tai mahdollisuutta',
      confirm: 'Haluatko varmasti poistaa tiedoston?',
      createMiroLink: 'Yhdistä Miro',
      createWordLink: 'Yhdistä Ms Office dokumentti',
      anonymousTitle: 'Muokkaa ilmoitusta',
      customTitle: 'Muokkaa dokumenttia',
    },
  },
  en: {
    DocumentEditScene: {
      qncTitle: 'Edit the Qnc',
      documentTitle: 'Edit the document',
      riskTitle: 'Edit the risk',
      confirm: 'Are you sure you want to delete the file?',
      createMiroLink: 'Connect Miro',
      createWordLink: 'Connect MS Office document',
      anonymousTitle: 'Edit',
      customTitle: 'Edit',
    },
  },
};
