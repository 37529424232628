export const CompanyEditSceneTranslation = {
  fi: {
    CompanyEditScene: {
      editInformation: 'Muokkaa yrityksen tietoja',
    },
  },
  en: {
    CompanyEditScene: {
      editInformation: 'Edit company information',
    },
  },
};
