const logColor = 'color: #5fb34c';
const debugColor = 'color: #38afc7';
const errorColor = 'color: #c63947';

const logMessage = (ns: string, message: string, color: string, metaData?: object) => {
  // eslint-disable-next-line no-console
  if (metaData) return console.log(`%c[${ns}] ${message}`, color, metaData);
  // eslint-disable-next-line no-console
  console.log(`%c[${ns}] ${message}`, color);
};

export const createLogger = (ns: string) => ({
  log: (message: string, metaData?: object) => logMessage(ns, message, logColor, metaData),
  debug: (message: string, metaData?: object) => logMessage(ns, message, debugColor, metaData),
  error: (message: string, metaData?: object) => logMessage(ns, message, errorColor, metaData),
});
