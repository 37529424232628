import { useMemo } from 'react';
import { useUserInput } from '../inputs/use-user.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useUserForm = () => {
  const inputs = useUserInput();

  const form = useMemo(() => {
    return createForm([inputs.name, inputs.email, inputs.language, inputs.wantWeeklyNotification]);
  }, [inputs.email, inputs.language, inputs.name, inputs.wantWeeklyNotification]);

  return form;
};

export type UserFormBody = {
  name: string;
  email: string;
  language: string;
  wantWeeklyNotification: boolean;
};
