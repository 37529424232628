export default {
  fi: {
    LoginScene: {
      forgotPassword: 'Unohditko salasanasi?',
      toRegister: 'Oletko uusi? Luo tunnus!',
      login: 'Kirjaudu',
      fillInformation: 'Täytä tiedot ja kirjaudu sisään',
    },
  },
  en: {
    LoginScene: {
      forgotPassword: 'Forgot your password?',
      toRegister: 'Are you new? Create an account!',
      login: 'Log in',
      fillInformation: 'Fill in the information and login',
    },
  },
};
