import { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

type Props = {
  tag: {
    id: string;
    name: string;
  };
};

export const Tag: FC<Props> = ({ tag }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isActive = searchParams.get('tag') === tag.id;

  const onClick = () => {
    if (isActive) {
      searchParams.delete('tag');
    } else {
      searchParams.set('tag', tag.id);
    }

    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const baseClasses = 'mr-2 text-sm py-1 px-2 rounded-md mb-1';
  const activeClasses = 'border border-primary text-white bg-primary';
  const inactiveClasses = 'border border-primary text-primary';

  return (
    <button
      type="button"
      className={[baseClasses, isActive ? activeClasses : inactiveClasses].join(' ')}
      onClick={onClick}
      key={tag.id}
    >
      {tag.name}
    </button>
  );
};
