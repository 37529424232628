import { useMemo } from 'react';
import { useDocumentLinkInput } from '../inputs/use-document-link.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useDocumentLinkForm = () => {
  const inputs = useDocumentLinkInput();

  return useMemo(() => {
    return createForm([inputs.name, inputs.url]);
  }, [inputs.name, inputs.url]);
};

export type DocumentLinkFormBody = {
  name: string;
  url: string;
};
