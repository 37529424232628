import { get } from 'lodash';
import { useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import UserContext from '../../contexes/UserContext';

export const useDocumentSearchParams = (type: string) => {
  const [searchParams] = useSearchParams();
  const [user] = useContext(UserContext);

  let models: string[] = [get(user.documentModelIdObject, type, 'UNKNOWN')];

  if (type === 'custom') {
    if (searchParams.get('documentModelId')) {
      models = [searchParams.get('documentModelId') as string];
    } else {
      models = user.myCustomDocuments.read;
    }
  }

  return {
    includeModels: models,
    documentIdentifier: searchParams.get('documentIdentifier')
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        parseInt(searchParams.get('documentIdentifier') as any, 10)
      : undefined,
    name: searchParams.get('name'),
    status: searchParams.get('status'),
    category: searchParams.get('category'),
    userId: searchParams.get('userId'),
    groups: {
      hasSome: [searchParams.get('groups')],
    },
    tags: {
      hasSome: [searchParams.get('tag')],
    },
    approverId: searchParams.get('approverId'),
    createdAtGte: searchParams.get('createdAtGte'),
    createdAtLte: searchParams.get('createdAtLte'),
  };
};
