import { useMemo } from 'react';
import { useCompanyInput } from '../inputs/use-company.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useCompanyForm = () => {
  const inputs = useCompanyInput();

  const form = useMemo(() => {
    return createForm([inputs.name]);
  }, [inputs.name]);

  return form;
};

export type CompanyFormBody = {
  name: string;
};
