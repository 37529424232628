export const EmptyTableTranslation = {
  fi: {
    EmptyTable: {
      noData: 'Ei näytettäviä tietoja',
    },
  },
  en: {
    EmptyTable: {
      noData: 'No data to display',
    },
  },
};
