import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { getDocAddApproverRoute, getRoute } from '../config/routes.config';
import { DocumentShowSceneQuery } from '../generated/graphql';
import { DocumentTypeField } from '../utils/DocumentType';
import { ButtonLink } from './ButtonLink';
import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { ReminderButton, RemoveButton, RequestButton } from './ReminderButton';

type Props = {
  data: DocumentShowSceneQuery;
  type: DocumentTypeField;
  isAllowed: boolean;
  refetch: () => void;
  id: string;
};

export const DocumentApprovers: FC<Props> = ({ data, type, id, refetch, isAllowed }) => {
  const { t } = useTranslation('DocumentShowScene');

  const url = getRoute(getDocAddApproverRoute(type), { id });

  if (!data.document.isApproveRequired) return <Fragment />;

  return (
    <Card className="mt-3">
      {isAllowed ? (
        <ButtonLink className="float-right" to={url}>
          {t('addApprover')}
        </ButtonLink>
      ) : (
        <> </>
      )}
      <CardTitle>{t('approvers')}</CardTitle>
      <table className="min-w-full divide-y divide-gray-300 text-left">
        <thead className="border-none">
          <tr>
            <th>{t('name')}</th>
            <th>{t('status')}</th>
            {isAllowed ? <th>{t('actions')}</th> : <> </>}
          </tr>
        </thead>
        <tbody>
          {data.document.documentApprovers.map((da) => {
            return (
              <tr key={da.id}>
                <td>{da.user.name}</td>
                <td>{t(`Status:${da.status}`)}</td>
                {isAllowed ? (
                  <td>
                    <span className="isolate inline-flex rounded-md shadow-sm">
                      <ReminderButton
                        refetch={refetch}
                        type={type}
                        id={id}
                        daId={da.id}
                        status={da.status}
                      />
                      <RequestButton
                        refetch={refetch}
                        type={type}
                        id={id}
                        daId={da.id}
                        status={da.status}
                      />
                      <RemoveButton refetch={refetch} daId={da.id} />
                    </span>
                  </td>
                ) : (
                  <> </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Card>
  );
};
