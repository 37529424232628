export default {
  fi: {
    ConfirmScene: {
      emailConfirmed: 'Sähköpostisi on vahvistettu',
      backToLogin: 'Takaisin kirjautumissivulle',
    },
  },
  en: {
    ConfirmScene: {
      emailConfirmed: 'Your email is confirmed',
      backToLogin: 'Back to the login page',
    },
  },
};
