import { Form, Formik, FormikConfig } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { ReactSelectInput } from '../../../components/ReactSelectInput';
import { QNCType } from '../../../config/qnc-types';
import { DocumentIndexMetaQuery } from '../../../generated/graphql';
import { SelectInput } from '../../../releox-engine/react-components/form-inputs/SelectInput';
import { TextInput } from '../../../releox-engine/react-components/form-inputs/TextInput';
import { DocumentTypeField } from '../../../utils/DocumentType';

type Props = {
  meta: DocumentIndexMetaQuery;
  type: DocumentTypeField;
};

type FilterFormBody = {
  name: string;
  status: string;
  userId: string;
  documentModelId: string;
  approverId: string;
  category: string;
  groups: string;
  createdAtGte: string;
  createdAtLte: string;
  documentIdentifier: string;
};

const allowedFields = [
  'documentIdentifier',
  'name',
  'status',
  'category',
  'userId',
  'documentModelId',
  'groups',
  'approverId',
  'createdAtGte',
  'createdAtLte',
];

export const DocumentFilterForm = ({ meta, type }: Props) => {
  const { t } = useTranslation('DocumentFilterForm');
  const [resetCount, setResetCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFormSubmit: FormikConfig<FilterFormBody>['onSubmit'] = (body) => {
    for (const key of allowedFields) {
      searchParams.delete(key);
    }

    if (body.name) searchParams.set(`name`, `${body.name.replaceAll(' ', '%20')}`);
    if (body.status) searchParams.set(`status`, `${body.status}`);
    if (body.userId) searchParams.set(`userId`, `${body.userId}`);
    if (body.documentModelId) searchParams.set(`documentModelId`, `${body.documentModelId}`);
    if (body.approverId) searchParams.set(`approverId`, `${body.approverId}`);
    if (body.category) searchParams.set(`category`, `${body.category}`);
    if (body.groups) searchParams.set(`groups`, `${body.groups}`);
    if (body.createdAtLte) searchParams.set(`createdAtLte`, `${body.createdAtLte}`);
    if (body.createdAtGte) searchParams.set(`createdAtGte`, `${body.createdAtGte}`);
    if (body.documentIdentifier)
      searchParams.set(`documentIdentifier`, `${body.documentIdentifier}`);

    searchParams.set('page', '1');

    setSearchParams(searchParams);
  };

  const initialValues = {
    name: searchParams.get('name') || '',
    status: searchParams.get('status') || '',
    userId: searchParams.get('userId') || '',
    documentModelId: searchParams.get('documentModelId') || '',
    approverId: searchParams.get('approverId') || '',
    category: searchParams.get('category') || '',
    groups: searchParams.get('groups') || '',
    createdAtLte: searchParams.get('createdAtLte') || '',
    createdAtGte: searchParams.get('createdAtGte') || '',
    documentIdentifier: searchParams.get('documentIdentifier') || '',
  };

  const handleReset: FormikConfig<FilterFormBody>['onReset'] = () => {
    setResetCount((prev) => prev + 1);
    for (const key of allowedFields) {
      searchParams.delete(key);
    }
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  const statuses = [
    {
      value: 'approved',
      label: 'Status:approved',
    },
    {
      value: 'unapproved',
      label: 'Status:unapproved',
    },
    {
      value: 'noAction',
      label: 'Status:noAction',
    },
  ];

  const qncCategories = [
    {
      value: QNCType.INTERNAL_NON_CONFORMITY,
      label: 'QNCType:internalNonConformity',
    },
    {
      value: QNCType.CUSTOMER_RECLAMATION,
      label: 'QNCType:customerReclamation',
    },
    {
      value: QNCType.SUPPLIER_RECLAMATION,
      label: 'QNCType:supplierReclamation',
    },
    {
      value: QNCType.INITIATIVE,
      label: 'QNCType:initiative',
    },
    {
      value: QNCType.SAFETY_ACCIDENT,
      label: 'QNCType:safetyAccident',
    },
    {
      value: QNCType.SAFETY_OBSERVATION,
      label: 'QNCType:safetyObservation',
    },
    {
      value: QNCType.ENVIRONMENT_OBSERVATION,
      label: 'QNCType:environmentObservation',
    },

    {
      value: QNCType.INTERNAL_AUDIT,
      label: 'QNCType:internalAudit',
    },
    {
      value: QNCType.EXTERNAL_AUDIT,
      label: 'QNCType:externalAudit',
    },
    {
      value: QNCType.OTHER,
      label: 'QNCType:other',
    },
  ];

  return (
    <Card key={resetCount}>
      <Formik initialValues={initialValues} onSubmit={handleFormSubmit} onReset={handleReset}>
        <Form className="space-y-3">
          <TextInput
            label={'Document:documentIdentifier'}
            name="documentIdentifier"
            type="number"
          />
          <TextInput label={'Document:name'} name="name" type="text" />
          <SelectInput
            label={'Document:status'}
            name="status"
            placeholder={'DocumentFilterForm:chooseStatus'}
            options={statuses}
          />
          {type === 'qnc' ? (
            <SelectInput
              label={'Document:category'}
              name="category"
              placeholder={'DocumentFilterForm:chooseCategory'}
              options={qncCategories}
            />
          ) : undefined}

          {type === 'custom' ? (
            <SelectInput
              label={'Document:documentType'}
              name="documentModelId"
              placeholder={'DocumentFilterForm:documentType'}
              options={meta.customDocumentModels.map((g) => ({ value: g.id, label: g.type }))}
            />
          ) : undefined}
          <ReactSelectInput
            label={'Document:userId'}
            name="userId"
            placeholder={'DocumentFilterForm:chooseUser'}
            options={meta.users.list.map((g) => ({ value: g.id, label: g.name }))}
          />
          <ReactSelectInput
            label={'Document:groups'}
            name="groups"
            placeholder={'DocumentFilterForm:chooseGroup'}
            options={meta.groups.list.map((g) => ({ value: g.id, label: g.name }))}
          />
          <ReactSelectInput
            label={'Document:approverId'}
            name="approverId"
            placeholder={'DocumentFilterForm:chooseApprover'}
            options={meta.allDocumentApprovers}
          />
          <TextInput label="Document:createdAtGte" name="createdAtGte" type="date" />
          <TextInput label="Document:createdAtLte" name="createdAtLte" type="date" />
          <Button className="float-right" type="submit">
            {t('Filter:submit')}
          </Button>
          <Button type="reset">{t('Filter:reset')}</Button>
        </Form>
      </Formik>
    </Card>
  );
};
