export const EditorSceneTranslation = {
  fi: {
    EditorScene: {
      save: 'Tallenna',
    },
  },
  en: {
    EditorScene: {
      save: 'Save',
    },
  },
};
