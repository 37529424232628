import { FC, ReactNode } from 'react';

type Props = {
  Filter: ReactNode;
  Table: ReactNode;
};

export const TableLayout: FC<Props> = ({ Filter, Table }) => {
  return (
    <div className="lg:grid lg:grid-cols-7 xl:grid-cols-5 lg:space-x-2">
      <div className="lg:col-span-2 xl:col-span-1 hidden lg:block">{Filter}</div>
      <div className="xl:col-span-4 lg:col-span-5 flex-1">{Table}</div>
    </div>
  );
};
