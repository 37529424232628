export const TeamInviteSceneTranslation = {
  fi: {
    TeamInviteScene: {
      inviteUser: 'Kutsu käyttäjä',
      toTeam: 'Käyttäjiin',
      basicInfo: 'Kutsu käyttäjä: Perustiedot',
      preveledges: 'Kutsu käyttäjä: Käyttöoikeudet',
    },
  },
  en: {
    TeamInviteScene: {
      inviteUser: 'Invite user',
      toTeam: 'To users',
      basicInfo: 'Invite user: Basic info',
      preveledges: 'Invite user: Preveledges',
    },
  },
};
