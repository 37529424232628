import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';
import './App.css';
import { appRoutes } from './router';

const AppRoutes: React.FC = () => {
  return (
    <RecoilRoot>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM || ''} autoBoot>
        <RouterProvider router={appRoutes} />
      </IntercomProvider>
      <ToastContainer />
    </RecoilRoot>
  );
};

export default AppRoutes;
