import moment from 'moment';
import { useTranslation } from 'react-i18next';

type Props = {
  data: {
    total: number;
    start: string;
    end: string;
  };
  hideCount?: boolean;
};

export const ReportMetaData = ({ data, hideCount }: Props) => {
  const { t } = useTranslation('ReportMetaData');
  return (
    <>
      {hideCount ? null : (
        <p className="text-sm text-gray-600 mt-4 mb-2">
          {t('totalCount')} {data.total}
        </p>
      )}
      <p className="text-sm text-gray-600 mb-2">
        {t('dateRange')} {moment(data.start).format('YYYY-MM-DD HH:mm')} -{' '}
        {moment(data.end).format('YYYY-MM-DD HH:mm')}
      </p>
    </>
  );
};
