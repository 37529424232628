export const DocumentDriveFileEditSceneTranslation = {
  fi: {
    DocumentDriveFileEdit: {
      title: 'Muokkaa tiedostoa',
    },
  },
  en: {
    DocumentDriveFileEdit: {
      title: 'Edit file',
    },
  },
};
