import { ReactMultiSelectInput } from '../components/ReactMultiSelectInput';
import { ReactSelectInput, ReactSelectOption } from '../components/ReactSelectInput';
import { useYup } from '../config/use-yup.config';
import { useUserInput } from '../inputs/use-user.input';
import { CheckboxInput } from '../releox-engine/react-components/form-inputs/Checkbox';
import { TextInput } from '../releox-engine/react-components/form-inputs/TextInput';

type Group = {
  label: string;
  value: string;
};

export type TeamFormBody = {
  name: string;
  isApprover: boolean;
  isCompanyAdmin: boolean;
  isAnonymousHandler: boolean;
  wantWeeklyNotification: boolean;
  groups: Group[];
  defaultGroups: Group[];
};

export const useTeamValidationSchema = () => {
  const inputs = useUserInput();
  const Yup = useYup();

  return Yup.object().shape({
    name: inputs.name.validation,
    isApprover: inputs.isApprover.validation,
    isCompanyAdmin: inputs.isCompanyAdmin.validation,
    wantWeeklyNotification: inputs.wantWeeklyNotification.validation,
    groups: inputs.groups.validation,
    defaultGroups: inputs.defaultGroups.validation,
  });
};

export const useTeamInitialValues = () => {
  const inputs = useUserInput();

  return {
    name: inputs.name.initValue,
    isApprover: inputs.isApprover.initValue,
    isCompanyAdmin: inputs.isCompanyAdmin.initValue,
    groups: inputs.groups.initValue,
    defaultGroups: inputs.defaultGroups.initValue,
  };
};

type Props = {
  groups: ReactSelectOption[];
  acls: ReactSelectOption[];
  approvers: ReactSelectOption[];
};

export const TeamForm = ({ groups, acls, approvers }: Props) => {
  const inputs = useUserInput();

  return (
    <div className="space-y-3 mb-4">
      <TextInput {...inputs.name.props} />
      <ReactMultiSelectInput {...inputs.groups.props} options={groups} />
      <ReactMultiSelectInput {...inputs.defaultGroups.props} options={groups} />
      <ReactSelectInput {...inputs.resourceACLId.props} options={acls} />
      <ReactSelectInput {...inputs.defaultApproverId.props} options={approvers} />
      <CheckboxInput {...inputs.isApprover.props} />
      <CheckboxInput {...inputs.isCompanyAdmin.props} />
      <CheckboxInput {...inputs.isAnonymousHandler.props} />
      <CheckboxInput {...inputs.wantWeeklyNotification.props} />
    </div>
  );
};
