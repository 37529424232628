import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getDocDriveFileEditRoute, getRoute } from '../../config/routes.config';
import { DocumentShowSceneQuery } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { formatDate } from '../../utils/format-date';
import { Card } from '../Card';
import { FileDropzone } from '../FileDropzone';
import { CogIcon } from '../icons/CogIcon';
import { DownloadIcon } from '../icons/DownloadIcon';
import { TrashIcon } from '../icons/TrashIcon';
import { SmallTable } from '../small-table/SmallTable';
import { SmallTableHeader } from '../small-table/SmallTableHeader';

type Props = {
  hasCreateOrEditPrivilege: boolean;
  data: DocumentShowSceneQuery;
  refetch: () => void;
  handleDelete: (id: string) => void;
  type: DocumentTypeField;
};

export const DSFiles: FC<Props> = ({
  data,
  hasCreateOrEditPrivilege,
  refetch,
  handleDelete,
  type,
}) => {
  const { t } = useTranslation('DocumentShowScene');
  const { id } = data.document;

  const DRIVE_FILE_EDIT_KEY = getDocDriveFileEditRoute(type);

  return (
    <>
      {hasCreateOrEditPrivilege ? (
        <Card className="my-3">
          <FileDropzone refetch={refetch} />
        </Card>
      ) : (
        <></>
      )}
      {data.document.driveFiles.length ? (
        <>
          <Card className="my-3 rounded">
            <SmallTable>
              <SmallTableHeader fields={[t('name'), t('created'), t('actions')]} />
              <tbody className="divide-y divide-gray-200 bg-white">
                {data.document.driveFiles.map((df) => (
                  <tr key={df.id}>
                    <td>
                      <a
                        target="_blank"
                        className="text-primary underline"
                        href={df.storagePath}
                        rel="noreferrer"
                      >
                        {df.name}
                      </a>
                    </td>
                    <td>{formatDate(df.createdAt)}</td>
                    <td className="px-2 py-2">
                      <div className="flex">
                        {hasCreateOrEditPrivilege && (
                          <button onClick={() => handleDelete(df.id)}>
                            <TrashIcon />
                          </button>
                        )}
                        {hasCreateOrEditPrivilege && (
                          <Link to={getRoute(DRIVE_FILE_EDIT_KEY, { id, driveFileId: df.id })}>
                            <CogIcon />
                          </Link>
                        )}
                        <a
                          href={`${df.storagePath}?disposition=download`}
                          download={`filedownload-${df.name}`}
                          className="btn btn-link btn-lg"
                        >
                          <DownloadIcon />
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </SmallTable>
          </Card>

          {data.document.driveFiles.filter((df) => df.mimeType.includes('image')).length ? (
            <Card className="mb-3">
              {data.document.driveFiles
                .filter((df) => df.mimeType.includes('image'))
                .map((df) => (
                  <img key={df.id} alt={df.name} className="m-auto mb-6" src={df.storagePath} />
                ))}
            </Card>
          ) : null}
        </>
      ) : null}
    </>
  );
};
