import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/outline';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { TableColumn } from '../../columns/table-column';
import { useOrderBy } from '../../hooks/index/use-order-by';

type Props = {
  children?: React.ReactNode;
  column?: TableColumn;
};

export const TableTH: FC<Props> = ({ children, column }) => {
  const { orderBy, orderDir } = useOrderBy();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const text = useMemo(() => {
    if (column) {
      return t(column.text);
    }

    return children;
  }, [children, column, t]);

  const isActive = orderBy === column?.field;
  const isAsc = orderDir === 'asc';
  const isSortable = column?.sort ?? false;

  const activeClasses = 'font-bold text-primary';
  const normalClasses = 'font-medium';

  let Sort = <></>;

  const onClick = () => {
    if (!isSortable) return;

    if (isActive) {
      if (isAsc) {
        searchParams.set('orderDir', 'desc');
      } else {
        searchParams.set('orderDir', 'asc');
      }
    } else {
      searchParams.set('orderBy', column?.field ?? 'UNKNOWN');
      searchParams.set('orderDir', 'asc');
    }

    setSearchParams(searchParams);
  };

  if (isSortable) {
    Sort = <SortAscendingIcon className="h-4 w-4 inline-block ml-1" />;
  }

  if (isActive) {
    if (isAsc) {
      Sort = <SortAscendingIcon className="h-4 w-4 inline-block ml-1" />;
    } else {
      Sort = <SortDescendingIcon className="h-4 w-4 inline-block ml-1" />;
    }
  }

  return (
    <th
      onClick={onClick}
      className={[
        'px-6 py-3 text-left text-xs text-gray-color uppercase tracking-wider',
        isActive ? activeClasses : normalClasses,
        isSortable ? 'cursor-pointer' : '',
      ].join(' ')}
    >
      {text}
      {Sort}
    </th>
  );
};
