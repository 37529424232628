import { useMemo } from 'react';
import { useDocumentModelInput } from '../inputs/use-document-model.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useDocumentModelEditForm = () => {
  const input = useDocumentModelInput();

  return useMemo(() => {
    return createForm([input.privilege, input.fields]);
  }, [input.fields, input.privilege]);
};

type DocumentModelField = {
  type: string;
  label: string;
  position: number;
  options: string[];
};

export type DocumentModelEditFormBody = {
  privilege: string;
  documentModelFields: DocumentModelField[];
};
