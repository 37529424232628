import { useSearchParams } from 'react-router-dom';

export const useOrderBy = () => {
  const [searchParams] = useSearchParams();

  return {
    orderBy: searchParams.get('orderBy') ?? 'createdAt',
    orderDir: searchParams.get('orderDir') ?? 'desc',
  };
};
