export default {
  fi: {
    User: {
      email: 'Sähköposti',
      name: 'Nimi',
      password: 'Salasana',
      company: 'Yritys',
      createdAt: 'Luotu',
      language: 'Kieli',
      wantWeeklyNotification: 'Haluan viikkoraportin sähköpostiin',
      english: 'English',
      finnish: 'Suomeksi',
      isApprover: 'Hyväksyjä',
      isCompanyAdmin: 'Järjestelmänvalvoja',
      groups: 'Ryhmät',
      isAnonymousHandler: 'Anonyymi ilmoituskanavan hoitaja',
      acl: 'Käyttöoikeudet',
      all: 'Kaikki',
      defaultGroups: 'Oletusryhmät',
      defaultApproverId: 'Oletushyväksyjä',
      yes: 'Kyllä',
      no: 'Ei',
      noSelection: 'Ei valintaa',
      noOptions: 'Ei vaihtoehtoja',
    },
  },
  en: {
    User: {
      email: 'Email',
      name: 'Name',
      password: 'Password',
      company: 'Company',
      createdAt: 'Created',
      language: 'Language',
      wantWeeklyNotification: 'I want a weekly email',
      english: 'English',
      finnish: 'Suomeksi',
      isApprover: 'Approver',
      isCompanyAdmin: 'Admin',
      groups: 'Groups',
      isAnonymousHandler: 'Anonymous handler',
      acl: 'Access control',
      all: 'All',
      defaultGroups: 'Default groups',
      defaultApproverId: 'Default approver',
      yes: 'Yes',
      no: 'No',
      noSelection: 'No selection',
      noOptions: 'No options',
    },
  },
};
