import { FC, MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: () => any;
  href?: string;
};

export const TableRow: FC<Props> = ({ children, onClick, href }) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (href) {
      navigate(href);
    } else if (onClick) {
      onClick();
    }
  };

  const handleMouseDown: MouseEventHandler<HTMLTableRowElement> | undefined = (ev) => {
    if (ev.button === 1 && href) {
      ev.preventDefault();
      window.open(href, '_blank', 'noreferrer');
    }
  };

  let cls = '';

  if (onClick || href) {
    cls = 'cursor-pointer';
  }

  return (
    <tr
      className={`even:bg-gray-50 hover:bg-gray-100 ${cls}`}
      onClick={handleOnClick}
      onMouseDown={handleMouseDown}
    >
      {children}
    </tr>
  );
};
