import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useRejectDocumentInput = () => {
  const Yup = useYup();

  const reason: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'reason',
      label: 'Reject:reason',
      type: 'text',
    },
  };

  return {
    reason,
  };
};
