import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { BackgroundClass } from '../../components/BackgroundClass';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { getRoute } from '../../config/routes.config';
import Logo from '../../images/logo.png';

export const PublicThanksScene = () => {
  const { t } = useTranslation('PublicThanksScene');
  const { id } = useParams();

  return (
    <div className="pt-6">
      <BackgroundClass className="BlueBackground" />

      <div className="py-10">
        <img className="mx-auto h-16 w-auto" src={Logo} alt="Laatupankki logo" />
      </div>
      <div className="mx-auto w-full max-w-3xl sm:px-2">
        <Card>
          <CardTitle>{t(`title`)}</CardTitle>
          <p className="mb-3">{t('thanks')}</p>
          <ButtonLink to={getRoute('PUBLIC_PAGE', { id })}>{t('createNew')}</ButtonLink>
        </Card>
      </div>
    </div>
  );
};
