export const DocumentModelCreateSceneTranslation = {
  fi: {
    DocumentModelCreateScene: {
      title: 'Lisää uusi lomake',
    },
  },
  en: {
    DocumentModelCreateScene: {
      title: 'Create a new form',
    },
  },
};
