import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from './Button';

type ArrayInputLayoutProps = {
  children: JSX.Element;
};

export const ArrayInputLayout = ({ children }: ArrayInputLayoutProps) => (
  <div className="space-y-4">{children}</div>
);

type ArrayAddButtonProps = {
  onClick: () => void;
};

export const ArrayAddButton = ({ onClick }: ArrayAddButtonProps) => {
  const { t } = useTranslation('ArrayInput');
  return (
    <button
      type="button"
      onClick={onClick}
      className="w-full py-2 px-4 border transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary disabled:bg-gray-300"
    >
      {t('addField')}
    </button>
  );
};

export const ArrayRemoveButton = ({ onClick }: ArrayAddButtonProps) => {
  const { t } = useTranslation('ArrayInput');

  return (
    <Button type="button" onClick={onClick} className="bg-red-color">
      {t('deleteField')}
    </Button>
  );
};
