export const LogEventTranslations = {
  fi: {
    LogEvent: {
      title: 'Tapahtumat',
      create: 'Dokumentti luotu',
      edit: 'Dokumenttia muokattu',
      reject: 'Dokumentti hylätty',
      approve: 'Käyttäjä hyväksyi dokumentin',
      'update-content': 'Dokumentin sisältöä muokattu',
      'file-edit': 'Tiedostoa muokattu',
      'file-delete': 'Tiedosto poistettu',
      'file-upload': 'Tiedosto lisätty',
      'send-approve-request': 'Hyväksyntäpyyntö lähetetty',
      'edit-ms-link': 'MS-linkkiä muokattu',
      'delete-ms-link': 'MS-linkki poistettu',
      'edit-miro-link': 'Miro-linkkiä muokattu',
      'delete-miro-link': 'Miro-linkki poistettu',
    },
  },
  en: {
    LogEvent: {
      title: 'Events',
      create: 'Document created',
      edit: 'Document edited',
      reject: 'Document rejected',
      approve: 'User approved document',
      'update-content': 'Document content edited',
      'file-edit': 'File edited',
      'file-delete': 'File deleted',
      'file-upload': 'File uploaded',
      'send-approve-request': 'Approval request sent',
      'edit-ms-link': 'MS-link edited',
      'delete-ms-link': 'MS-link deleted',
      'edit-miro-link': 'Miro-link edited',
      'delete-miro-link': 'Miro-link deleted',
    },
  },
};
