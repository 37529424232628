export const CustomReportTranslation = {
  fi: {
    CustomReport: {
      modelType: 'Dokumenttityyppi',
      name: 'Raportin nimi',
      fieldKey: 'Kentän nimi',
    },
  },
  en: {
    CustomReport: {
      modelType: 'Document type',
      name: 'Report name',
      fieldKey: 'Field name',
    },
  },
};
