import { useContext } from 'react';
import UserContext from '../../contexes/UserContext';
import { useDataValidationSceneQuery } from '../../generated/graphql';

export const DataValidationScene = (): JSX.Element => {
  const [user] = useContext(UserContext);
  const { data } = useDataValidationSceneQuery({
    variables: {
      qncModelId: user.documentModelIdObject.qnc,
      riskModelId: user.documentModelIdObject.risk,
      documentModelId: user.documentModelIdObject.document,
    },
  });

  return <pre>{JSON.stringify(data, null, 2)}</pre>;
};
