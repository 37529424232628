interface Props {
  children: JSX.Element | JSX.Element[];
}

export const AuthCard = (props: Props) => {
  const { children } = props;
  return (
    <div className="mx-auto w-full sm:max-w-2xl md:max-w-xl">
      <div className="bg-white py-4 px-5 sm:px-5 shadow sm:rounded-md">{children}</div>
    </div>
  );
};
