import { FieldMetaProps } from 'formik';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useInputClasses = (meta: FieldMetaProps<any>) => {
  let inputClasses =
    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm';

  if (meta.error && meta.touched) {
    inputClasses += ' border-red-color';
  }

  return inputClasses;
};
