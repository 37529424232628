export const chartColors = [
  '#ff8c86',
  '#190e8f',
  '#4c4ff9',
  '#ffc5c2',
  '#8c86c7',
  '#9395fb',
  '#199e2f',
  '#f2a2e8',
  '#a2f2d6',
  '#f2b2a2',
];
