export default {
  fi: {
    RiskProperties: {
      minor: 'Vähäinen',
      harmful: 'Haitallinen',
      severe: 'Vakava',
      unlikely: 'Epätodennäköinen',
      possible: 'Mahdollinen',
      likely: 'Todennäköinen',
      insignificantRisk: 'Merkityksetön riski',
      minorRisk: 'Vähäinen riski',
      moderateRisk: 'Kohtalainen riski',
      significantRisk: 'Merkittävä riski',
      unacceptableRisk: 'Sietämätön riski',
    },
  },
  en: {
    RiskProperties: {
      minor: 'Minor',
      harmful: 'Harmful',
      severe: 'Severe',
      unlikely: 'Unlikely',
      possible: 'Possible',
      likely: 'Likely',
      insignificantRisk: 'Insignificant risk',
      minorRisk: 'Minor risk',
      moderateRisk: 'Moderate risk',
      significantRisk: 'Significant risk',
      unacceptableRisk: 'Unacceptable risk',
    },
  },
};
