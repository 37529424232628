import React from 'react';

export interface InputWrapperProps {
  info?: string;
  children: React.ReactNode;
}

export const InputWrapper = ({ children, info }: InputWrapperProps) => {
  return (
    <div className="input-wrapper">
      {children}
      {info ? <span>{info}</span> : ''}
    </div>
  );
};
