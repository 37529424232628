import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useDocumentLinkInput = () => {
  const Yup = useYup();

  const name: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'name',
      label: 'DocumentLink:name',
      type: 'text',
    },
  };

  const url: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'url',
      label: 'DocumentLink:url',
      type: 'text',
    },
  };

  return {
    name,
    url,
  };
};
