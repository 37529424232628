import { GraphQLErrors } from '@apollo/client/errors';
import _ from 'lodash';

export const parseGraphqlErrors = (graphQLErrors?: GraphQLErrors): string => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const e = graphQLErrors as any;
  const catchError = _.get(e, 'networkError.result.errors.0.message');
  const inputError = _.get(e, '0.extensions.exception.response.message.0');
  let error;
  if (Array.isArray(_.get(e, '0.extensions.response.message.0'))) {
    error = _.get(e, '0.extensions.response.message.0');
  }
  const message = _.get(e, 'message');
  const bagError = _.get(e, '0.message');
  return catchError || inputError || error || bagError || message;
};
