export const DocumentCreateSceneTranslation = {
  fi: {
    DocumentCreateScene: {
      qncTitle: 'Luo poikkeama',
      documentTitle: 'Luo dokumentti',
      riskTitle: 'Luo riski tai mahdollisuus',
      anonymousTitle: 'Luo anonyymi ilmoitus',
      create: 'Luo',
    },
  },
  en: {
    DocumentCreateScene: {
      qncTitle: 'Create the qnc',
      documentTitle: 'Create the document',
      riskTitle: 'Create the risk',
      anonymousTitle: 'Create an anonymous report',
      create: 'Create',
    },
  },
};
