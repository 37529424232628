import { useMemo } from 'react';
import { useMiroInput } from '../inputs/use-miro.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useMiroForm = () => {
  const inputs = useMiroInput();

  return useMemo(() => {
    return createForm([inputs.iframe]);
  }, [inputs.iframe]);
};

export type MiroFormBody = {
  iframe: string;
};
