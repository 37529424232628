import { Form, Formik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Divider } from '../../components/Divider';
import { getRoute } from '../../config/routes.config';
import {
  useCreateCustomReportMutation,
  useGetFieldNamesLazyQuery,
  useGetModelTypeNamesQuery,
} from '../../generated/graphql';
import { useCustomReportInput } from '../../inputs/use-custom-report.input';
import { createForm } from '../../releox-engine/form/FormGenerator';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { SelectInput } from '../../releox-engine/react-components/form-inputs/SelectInput';
import { TextInput } from '../../releox-engine/react-components/form-inputs/TextInput';

type ModelForm = {
  modelType: string;
};

type FieldForm = {
  fieldKey: string;
  name: string;
};

export const CustomReportCreateScene: FC = () => {
  const inputs = useCustomReportInput();

  const typeForm = useMemo(() => {
    return createForm([inputs.modelType]);
  }, [inputs.modelType]);

  const reportForm = useMemo(() => {
    return createForm([inputs.name, inputs.fieldKey]);
  }, [inputs.fieldKey, inputs.name]);

  const { t } = useTranslation('CustomReportCreateScene');
  const [selectedModelName, setSelectedModelName] = useState('');
  const { data: typeData } = useGetModelTypeNamesQuery();

  const INDEX = 'REPORT_INDEX';

  const onCompleteHook = useOnComplete('CUSTOM_REPORT_SHOW');

  const [getFieldNames, { data: fieldData }] = useGetFieldNamesLazyQuery();
  const [createCustomReport, { loading: isLoading }] = useCreateCustomReportMutation({
    onCompleted: onCompleteHook,
  });

  useEffect(() => {
    if (selectedModelName) {
      getFieldNames({
        variables: {
          modelTypeName: selectedModelName,
        },
      });
    }
  }, [selectedModelName, getFieldNames]);

  const handleModelSubmit = (form: ModelForm) => {
    setSelectedModelName(form.modelType);
  };
  const handleFieldSubmit = (form: FieldForm) => {
    createCustomReport({
      variables: {
        name: form.name,
        fieldKey: form.fieldKey,
      },
    });
  };

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <CardTitle>{t('title')}</CardTitle>
        <Divider>{t('chooseType')}</Divider>

        {/* Choose model */}
        <Formik
          onSubmit={handleModelSubmit}
          initialValues={typeForm.meta.initValues}
          validationSchema={typeForm.meta.validateSchema}
        >
          <Form className="space-y-4">
            <SelectInput
              {...inputs.modelType.props}
              options={[
                {
                  value: '',
                  label: t('chooseModelType'),
                },
                ...(typeData?.getModelTypeNames.map((r) => ({
                  value: r,
                  label: r,
                })) || []),
              ]}
            />
            <Button type="submit" loading={isLoading}>
              {t('chooseModelTypeButton')}
            </Button>
          </Form>
        </Formik>

        <>
          {/* Model input */}
          {fieldData?.getFieldNames && selectedModelName && (
            <>
              <Divider>{t('chooseField')}</Divider>
              <Formik
                onSubmit={handleFieldSubmit}
                initialValues={reportForm.meta.initValues}
                validationSchema={reportForm.meta.validateSchema}
              >
                <Form className="space-y-4">
                  <TextInput {...inputs.name.props} />
                  <SelectInput
                    {...inputs.fieldKey.props}
                    options={[
                      {
                        value: '',
                        label: t('chooseFieldName'),
                      },
                      ...(fieldData?.getFieldNames.map((r) => ({
                        value: r.key,
                        label: r.label,
                      })) || []),
                    ]}
                  />
                  <Button loading={isLoading} type="submit">
                    {t('chooseModelTypeButton')}
                  </Button>
                </Form>
              </Formik>
            </>
          )}
        </>
        <ButtonLink to={getRoute(INDEX)} className="mt-5">
          {t('Common:back')}
        </ButtonLink>
      </Card>
    </div>
  );
};
