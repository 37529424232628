import { useTranslation } from 'react-i18next';
import { DashButton } from '../../components/DashButton';
import { getRoute } from '../../config/routes.config';
import { useGetCustomReportsQuery } from '../../generated/graphql';

export const ReportIndexScene = () => {
  const { t } = useTranslation('ReportIndexScene');
  const { data } = useGetCustomReportsQuery();
  return (
    <div className="grid xl:grid-cols-3 xl:space-y-0">
      <div className="xl:col-span-1">
        <DashButton
          secondaryColor
          to={getRoute('CUSTOM_REPORT_CREATE')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('createReport')}
          text={t('last12months')}
        />
        <DashButton
          to={getRoute('REPORT_QNC_COUNT')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('QncReport')}
          text={t('last12months')}
        />
        <DashButton
          to={getRoute('REPORT_QNC_TYPE')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('QncTypeReport')}
          text={t('last12months')}
        />
        <DashButton
          to={getRoute('REPORT_QNC_COST')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('QncCostReport')}
          text={t('last12months')}
        />
        <DashButton
          to={getRoute('REPORT_QNC_TAG')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('QncTagReport')}
          text={t('last12months')}
        />
        <DashButton
          to={getRoute('REPORT_RISK_MATRIX')}
          icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
          title={t('RiskMatrixReport')}
          text={t('companyWide')}
        />
        {data?.getCustomReports?.map((report) => (
          <DashButton
            key={report.id}
            to={getRoute('CUSTOM_REPORT_SHOW', { id: report.id })}
            icon="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
            title={report.name}
            text={t('last12months')}
          />
        ))}
      </div>
    </div>
  );
};
