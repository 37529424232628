import { get } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactMultiSelectInput } from '../components/ReactMultiSelectInput';
import { ReactSelectInput } from '../components/ReactSelectInput';
import { useYup } from '../config/use-yup.config';
import { DocumentModelOptionsQuery, useDocumentModelOptionsQuery } from '../generated/graphql';
import { useACLRuleInput } from '../inputs/use-acl-rule.input';

export const useACLRuleInitialValues = () => {
  const inputs = useACLRuleInput();

  return useMemo(() => {
    return {
      rules: inputs.rules.initValue,
      model: inputs.model.initValue,
    };
  }, [inputs.model.initValue, inputs.rules.initValue]);
};

export const useACLRuleSchema = () => {
  const inputs = useACLRuleInput();
  const Yup = useYup();

  return useMemo(() => {
    return Yup.object().shape({
      rules: inputs.rules.validation,
      model: inputs.model.validation,
    });
  }, [Yup, inputs.model.validation, inputs.rules.validation]);
};

export type ACLRuleFormBody = {
  rules: { label: string; value: string }[];
  model: string;
};

export const ACLForm = () => {
  const inputs = useACLRuleInput();
  const { t, i18n } = useTranslation('ACLShowScene');
  const { data } = useDocumentModelOptionsQuery();

  return (
    <div className="space-y-3 mb-4">
      <ReactSelectInput
        {...inputs.model.props}
        options={get(data, 'documentModels.list', []).map(
          (doc: DocumentModelOptionsQuery['documentModels']['list'][0]) => ({
            value: doc.id,
            label: i18n.exists(`ACLShowScene:${doc.type}`) ? t(doc.type) : doc.type,
          })
        )}
      />
      <ReactMultiSelectInput
        {...inputs.rules.props}
        options={[
          {
            value: '*',
            label: t('all'),
          },
          {
            value: 'read',
            label: t('read'),
          },
          {
            value: 'create',
            label: t('create'),
          },
          {
            value: 'update',
            label: t('update'),
          },
          {
            value: 'delete',
            label: t('del'),
          },
        ]}
      />
    </div>
  );
};
