export default {
  fi: {
    MSLink: {
      iframe: 'Linkki',
    },
  },
  en: {
    MSLink: {
      iframe: 'Link',
    },
  },
};
