export const TeamEditSceneTranslation = {
  fi: {
    TeamEditScene: {
      title: 'Tiimi',
      areYouSure: 'Haluatko varmasti poistaa käyttäjän?',
    },
  },
  en: {
    TeamEditScene: {
      title: 'Team',
      areYouSure: 'Are you sure you want to delete the user?',
    },
  },
};
