import { Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { InlineSelectInput } from '../../components/InlineSelectInput';
import { SelectInput } from '../../components/inputs/select-input';
import { TextInput } from '../../components/inputs/text-input';
import { Loading } from '../../components/Loading';
import { ReactMultiSelectInput } from '../../components/ReactMultiSelectInput';
import { ReactSelectInput } from '../../components/ReactSelectInput';
import { getDocIndexRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import {
  useCreateRiskMutation,
  useDocumentCreateSceneQuery,
  useRiskCreateFormQuery,
} from '../../generated/graphql';
import { useUserHavePermission } from '../../hooks/use-user-have-permission';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import { CheckboxInput } from '../../releox-engine/react-components/form-inputs/Checkbox';
import { isI18nString } from '../../utils/is-i18n-string';
import { useSchemaFromForm } from './use-schema-from-form';

export const RiskCreateScene = (): JSX.Element => {
  const { t } = useTranslation('DocumentCreateScene');
  const haveReadPrivilege = useUserHavePermission(`risk.read`);

  const { data: _D } = useRiskCreateFormQuery();

  const formSchema = useSchemaFromForm('create', _D?.riskCreateForm.fields || []);

  const INDEX = getDocIndexRoute('risk');
  const SHOW = getDocShowRoute('risk');

  const onCompleted = useOnComplete(haveReadPrivilege ? SHOW : 'DASHBOARD');

  const { data, loading: isQueryLoading } = useDocumentCreateSceneQuery({
    variables: { type: 'risk' },
  });

  const [createRisk, { loading }] = useCreateRiskMutation({
    onCompleted,
  });

  const handleSubmit = useCallback(
    (body: Record<string, string | number>) => {
      createRisk({
        variables: {
          body: JSON.stringify(body),
        },
      });
    },
    [createRisk]
  );

  if (isQueryLoading || !data || !_D) return <Loading />;

  const initialValues = JSON.parse(_D.riskCreateForm.initialValues);

  return (
    <FormLayout key={_D.riskCreateForm.initialValues}>
      <Card>
        <CardTitle>{t(`riskTitle`)}</CardTitle>
        <Formik validationSchema={formSchema} onSubmit={handleSubmit} initialValues={initialValues}>
          {({ submitForm, values }) => (
            <div className="space-y-4">
              {_D.riskCreateForm.fields.map((field) => {
                let label = field.label;

                if (isI18nString(field.label)) {
                  label = t(field.label);
                }

                if (field.type === 'text') {
                  return <TextInput name={field.name} type="text" label={label} key={field.name} />;
                }

                if (field.type === 'date') {
                  return <TextInput type="date" key={field.name} name={field.name} label={label} />;
                }

                if (field.type === 'number') {
                  return (
                    <TextInput type="number" key={field.name} name={field.name} label={label} />
                  );
                }

                if (field.type === 'select') {
                  return (
                    <SelectInput
                      name={field.name}
                      options={field.options}
                      label={label}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'react-select') {
                  if (field.name === 'approverId' && !values.isApproveRequired) {
                    return <span key={field.name} />;
                  }
                  return (
                    <ReactSelectInput
                      options={field.options}
                      label={label}
                      name={field.name}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'multi-select') {
                  return (
                    <ReactMultiSelectInput
                      options={field.options}
                      label={label}
                      name={field.name}
                      key={field.name}
                    />
                  );
                }

                if (field.type === 'checkbox') {
                  return <CheckboxInput name={field.name} label={label} key={field.name} />;
                }

                if (field.type === 'inline-select') {
                  return (
                    <InlineSelectInput
                      key={field.name}
                      name={field.name}
                      options={field.options}
                      label={label}
                    />
                  );
                }

                return <div key={field.name}>{field.name}</div>;
              })}

              <Button type="submit" className="float-right" loading={loading} onClick={submitForm}>
                {t('Common:save')}
              </Button>
              <ButtonLink to={haveReadPrivilege ? getRoute(INDEX) : getRoute('DASHBOARD')}>
                {t('Common:back')}
              </ButtonLink>
            </div>
          )}
        </Formik>
      </Card>
    </FormLayout>
  );
};
