export const ReportTranslation = {
  fi: {
    Report: {
      start: 'Alkamispäivä',
      end: 'Loppumispäivä',
    },
  },
  en: {
    Report: {
      start: 'Start date',
      end: 'End date',
    },
  },
};
