import { useField } from 'formik';
import { omit } from 'lodash';
import { FC } from 'react';
import * as Yup from 'yup';
import { FormInput, FormInputProps } from '../form-tools/form-input';
import { ErrorMessage } from './error-message';
import { InputWrapper } from './input-wrapper';
import { Label } from './label';
import { InputClassNames, useInputClasses } from './use-input-classes';

type Autocomplete = 'on' | 'off' | 'name' | 'email' | 'current-password';
type Type = 'text' | 'email' | 'password' | 'number' | 'date';

export interface TextInputProps extends FormInputProps {
  type: Type;
  autoComplete?: Autocomplete;
  classNames?: InputClassNames;
}

export type StringSchema = Yup.StringSchema<string | undefined, Yup.AnyObject, undefined, ''>;
export type NumberSchema = Yup.NumberSchema<number | undefined, Yup.AnyObject, undefined, ''>;

export type TextInputType = FormInput<TextInputProps, StringSchema, string, 'text'>;
export type NumberInputType = FormInput<TextInputProps, NumberSchema, number, 'text'>;

export const TextInput: FC<TextInputProps> = (props) => {
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta, props.classNames);

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <input
          {...omit(props, ['label', 'classNames'])}
          {...field}
          id={id}
          placeholder={label}
          className={inputClasses}
        />
        <ErrorMessage name={field.name} />
      </InputWrapper>
    </div>
  );
};
