import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { getDocIndexRoute, getDocShowRoute, getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import {
  DocumentEditForm,
  DocumentEditFormBody,
  DocumentEditInitialValues,
  useDocumentEditInitialValues,
  useDocumentEditValidationSchema,
} from '../../forms/use-document-edit.form';
import {
  useCustomDocumentEditSceneQuery,
  useDeleteDocumentMutation,
  useUpdateDocumentMutation,
} from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import {
  remapDocumentFormFieldToDocumentField,
  remapModelFieldToDocumentFormField,
  remapOptions,
} from '../../utils/document-remap-helpers';

type Props = {
  type: DocumentTypeField;
};

export const CustomDocumentEditScene = ({ type }: Props): JSX.Element => {
  const values = useDocumentEditInitialValues();
  const schema = useDocumentEditValidationSchema();
  const { t } = useTranslation('DocumentEditScene');
  const { id } = useParams();
  if (!id) throw new Error('[DocumentEditScene] Missing id');
  const navigate = useNavigate();
  const [user] = useContext(UserContext);
  const [initValues, setInitialValues] = useState<DocumentEditInitialValues | null>(null);

  const { data, loading: isQueryLoading } = useCustomDocumentEditSceneQuery({ variables: { id } });

  const SHOW = getDocShowRoute(type);
  const INDEX = getDocIndexRoute(type);

  const [deleteDocument, { loading: isDeleteLoading }] = useDeleteDocumentMutation({
    variables: { id },
    onCompleted: (d) => {
      if (d?.deleteDocument) {
        toast.success(t('Common:deleted'));
        navigate(getRoute(INDEX));
      }
    },
  });

  const [updateDocument, { loading }] = useUpdateDocumentMutation({
    onCompleted: (d) => {
      if (d.updateDocument) {
        toast.success(t('Common:updated'));
        navigate(getRoute(SHOW, { id: d.updateDocument.id }));
      }
    },
  });

  useEffect(() => {
    if (data) {
      const fields = _.map(
        data.document.documentModel.documentModelFields,
        remapModelFieldToDocumentFormField(data.document.documentFields)
      );

      setInitialValues({
        ...values,
        ..._.omit(data.document, 'documentFields'),
        groups: _.map(data.document.groups, remapOptions),
        tags: _.map(data.document.tags, remapOptions),
        fields,
      });
    }
  }, [data, values]);

  const handleSubmit = useCallback(
    (body: DocumentEditFormBody) => {
      updateDocument({
        variables: {
          id,
          body: {
            ..._.omit(body, '__typename', 'documentModel'),
            tags: _.map(body.tags, 'value'),
            groups: _.map(body.groups, 'value'),
            fields: _.map(body.fields, remapDocumentFormFieldToDocumentField),
          },
        },
      });
    },
    [updateDocument, id]
  );

  const handleDelete = useCallback(() => {
    if (confirm(t('confirm'))) deleteDocument();
  }, [deleteDocument, t]);

  if (isQueryLoading || !data || !initValues) return <Loading />;

  return (
    <FormLayout key={JSON.stringify(initValues)}>
      <Card>
        <CardTitle>{t(`${type}Title`)}</CardTitle>
        <Formik validationSchema={schema} onSubmit={handleSubmit} initialValues={initValues}>
          <Form>
            <DocumentEditForm
              groups={_.map(data.myGroups.list, remapOptions)}
              tags={_.map(data.tags.list, remapOptions)}
              users={data.allDocumentApprovers}
              type={type}
            />
            <div className="mb-4">
              <Button type="submit" className="float-right" loading={loading || isDeleteLoading}>
                {t('Common:save')}
              </Button>
              <ButtonLink to={getRoute(SHOW, { id })}>{t('Common:back')}</ButtonLink>
            </div>

            {user.myCustomDocuments.delete.includes(data.document.documentModel.id) && (
              <>
                <hr />
                <Button
                  className="mt-4"
                  type="button"
                  onClick={handleDelete}
                  loading={loading || isDeleteLoading}
                >
                  {t('Common:delete')}
                </Button>
              </>
            )}
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
