export default {
  fi: {
    UserEditScene: {
      editInformation: 'Muokkaa käyttäjää',
    },
  },
  en: {
    UserEditScene: {
      editInformation: 'Edit user',
    },
  },
};
