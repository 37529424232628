import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  errorReason: string;
  loginRoute: string;
}

export const AuthError = ({ errorReason, loginRoute }: Props) => {
  const { t } = useTranslation('AuthErrorView');

  return (
    <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div className="bg-white py-8 px-4 shadow rounded-lg sm:px-10">
        <h1 className="text-xl font-bold text-gray-900 text-center">{t('wentWrong')}</h1>
        <div className="mt-2 text-center text-sm text-gray-600 mb-6">{errorReason}</div>
        <Link
          to={loginRoute}
          className={`inline-flex w-full justify-center w-sm py-2 px-6 shadow-sm text-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-gray-300`}
        >
          {t('backLink')}
        </Link>
      </div>
    </div>
  );
};
