import { find } from 'lodash';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { getDocRejectRoute, getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { DocumentShowSceneQuery, useApproveDocumentMutation } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';
import { Button } from '../Button';
import { ApprovalActionLayout } from './ApprovalActionLayout';

interface Props {
  type: DocumentTypeField;
  refetch(): void;
  data: DocumentShowSceneQuery;
}

export const ApprovableByUser: FC<Props> = ({ refetch, type, data }) => {
  const { t } = useTranslation('DocumentApprovalAction');
  const { id } = useParams();
  const [user] = useContext(UserContext);

  if (!id) throw new Error('[ApproveElement] missing id');

  const da = find(data.document.documentApprovers, { user: { id: user.id } });

  if (!da?.id) throw new Error('[ApproveElement] missing `documentApprover` id');

  const [approveDocument, { loading }] = useApproveDocumentMutation({
    variables: {
      documentApproverId: da?.id,
    },
    onCompleted: (d) => {
      if (d.approveDocument) {
        refetch();
      }
    },
  });

  const REJECT = getDocRejectRoute(type);

  return (
    <ApprovalActionLayout>
      <div className="grid sm:grid-cols-3">
        <div className="sm:col-span-2">
          <p className="mt-1 text-sm text-gray-900">{t('approve')}</p>
        </div>
        <div className="mt-2 sm:mt-0 sm:my-auto">
          <Button
            className="sm:float-right"
            onClick={() => approveDocument()}
            loading={loading}
            size="xs"
            type="button"
          >
            {t('approveButton')}
          </Button>
          {data.document.userId !== user.id ? (
            <Link
              className="mr-2 float-right inline-flex justify-center py-1 px-2 shadow-sm text-sm font-sm rounded-md text-white bg-secondary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary disabled:bg-gray-300"
              to={getRoute(REJECT, { id, documentApproverId: da?.id })}
            >
              {t('rejectButton')}
            </Link>
          ) : (
            ''
          )}
        </div>
      </div>
    </ApprovalActionLayout>
  );
};
