import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useACLRuleInput = () => {
  const Yup = useYup();

  const rules: FormInput = {
    type: 'react-multi-select',
    initValue: [],
    validation: Yup.array().of(Yup.object().required()).min(1).required(),
    props: {
      name: 'rules',
      label: 'ACL:rules',
      placeholder: 'ACL:rulesPlaceholder',
    },
  };

  const model: FormInput = {
    type: 'react-select',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'model',
      label: 'ACL:model',
      placeholder: 'ACL:modelPlaceholder',
    },
  };

  return {
    rules,
    model,
  };
};
