import { get } from 'lodash';
import React from 'react';
import { TableColumn } from '../../columns/table-column';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderColumn = (column: TableColumn, data: any): React.ReactNode => {
  if (column.formatter) {
    return column.formatter(data);
  }

  return get(data, column.field, '-');
};
