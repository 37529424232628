import { useField } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Select, { MenuPlacement, MenuPosition } from 'react-select';
import * as Yup from 'yup';
import { FormInput, FormInputProps } from '../form-tools/form-input';
import { ErrorMessage } from './error-message';
import { InputWrapper } from './input-wrapper';
import { Label } from './label';

/**
 * classNames: https://github.com/JedWatson/react-select/blob/a465818786afd4857c9155bef1e9aa2fe3356097/cypress/fixtures/selectors.json#L13
 */

export type ReactMultiSelectOption = {
  value: string;
  label: string;
};

export interface ReactSelectInputProps extends FormInputProps {
  placeholder?: string;
  useOptions?: () => ReactMultiSelectOption[] | null;
  options?: ReactMultiSelectOption[];
  noOptionsMessage: string;
  reactSelectProps?: {
    menuPlacement?: MenuPlacement;
    menuPosition?: MenuPosition;
  };
}

export type ReactSelectInputType = FormInput<
  ReactSelectInputProps,
  Yup.StringSchema,
  string,
  'react-select'
>;

export const ReactSelectInput: FC<ReactSelectInputProps> = (props) => {
  const options = props.options || props.useOptions?.() || null;

  const [defaultValue, setDefaultValue] = useState<ReactSelectInputProps['options'] | undefined>();

  const [field, meta, helpers] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const handleDefaultValue = useCallback(() => {
    setDefaultValue(meta.initialValue);
  }, [meta.initialValue]);

  useEffect(() => {
    handleDefaultValue();
  }, [handleDefaultValue]);

  const placeholder = useMemo(() => {
    let text = '';
    if (props.label) text = props.label;
    if (props.placeholder) text = props.placeholder;
    return <>{text}</>;
  }, [props.placeholder, props.label]);

  return (
    <div key={JSON.stringify(defaultValue)}>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      {options !== null ? (
        <InputWrapper>
          <Select
            placeholder={placeholder}
            classNamePrefix="releox-react-select"
            defaultValue={options.find((o) => o.value === field.value)}
            isClearable
            isSearchable
            name={field.name}
            menuPlacement={props.reactSelectProps?.menuPlacement}
            menuPosition={props.reactSelectProps?.menuPosition}
            noOptionsMessage={props.noOptionsMessage ? () => props.noOptionsMessage : undefined}
            options={options}
            onChange={(value) => helpers.setValue(value ? value.value : '')}
          />

          <ErrorMessage name={field.name} />
        </InputWrapper>
      ) : (
        <div style={{ height: '38px' }} />
      )}
    </div>
  );
};
