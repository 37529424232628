import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthError } from '../../../components/auth/AuthError';
import { Button } from '../../../components/Button';
import { Loading } from '../../../components/Loading';
import { getRoute } from '../../../config/routes.config';
import { useResetPasswordForm } from '../../../forms/use-reset-password.form';
import {
  ResetPasswordDto,
  useCompleteInvitationMutation,
  useValidateInvitationTokenLazyQuery,
} from '../../../generated/graphql';
import { AppFormGenerator } from '../../../releox-engine/react-components/app-form-generator/AppFormGenerator';
import { ParseUrl } from '../../../utils/parser-url';

export const AcceptInvitationScene = () => {
  const form = useResetPasswordForm();
  const { t } = useTranslation('AcceptInvitationScene');
  const [token, setToken] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [validateResetToken, { loading: queryLoading, error }] =
    useValidateInvitationTokenLazyQuery();

  useEffect(() => {
    (async () => {
      try {
        const validateToken = ParseUrl.parseLocation(location, 'inviteToken');

        const response = await validateResetToken({
          variables: { body: { invitationToken: validateToken } },
        });

        if (response.data?.validateInvitationToken) {
          setToken(validateToken);
        } else {
          setHasError(true);
        }
      } catch (e) {
        setHasError(true);
      }
    })();
  }, [location, setHasError, setToken, validateResetToken]);

  const [reset, { loading }] = useCompleteInvitationMutation({
    onCompleted: (data) => {
      if (data.completeInvitation) {
        navigate(getRoute('LOGIN'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: ResetPasswordDto) => {
      reset({
        variables: {
          body: {
            password: body.password,
            invitationToken: token,
          },
        },
      });
    },
    [reset, token]
  );

  useEffect(() => {
    if (error) {
      setHasError(true);
    }
  }, [error, hasError]);

  const FooterElement = (
    <div className="text-right pt-3">
      <Button
        type="submit"
        loading={loading}
        className="inline-flex justify-center w-sm py-2 px-6 shadow-sm text-sm font-md rounded-md text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
      >
        {t('save')}
      </Button>
    </div>
  );

  if (hasError) {
    return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Invalid token" />;
  }

  if (queryLoading || !token) return <Loading />;

  return (
    <AuthCard>
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-900">{t('newPassword')}</h2>
        <p className="mt-1 text-sm text-gray-600">{t('fillNewPassword')}</p>
      </div>
      <AppFormGenerator
        form={form}
        onSubmit={handleSubmit}
        wrapperClassNames="space-y-3"
        FooterElement={FooterElement}
      />
    </AuthCard>
  );
};
