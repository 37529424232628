import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { AuthCard } from '../../../components/auth/AuthCard';
import { AuthError } from '../../../components/auth/AuthError';
import { Loading } from '../../../components/Loading';
import { getRoute } from '../../../config/routes.config';
import { useConfirmMutation } from '../../../generated/graphql';
import { ParseUrl } from '../../../utils/parser-url';

export const ConfirmScene = () => {
  const { t } = useTranslation('ConfirmScene');
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const [validateToken, { error }] = useConfirmMutation();

  useEffect(() => {
    (async () => {
      try {
        const confirmToken = ParseUrl.parseLocation(location, 'confirmToken');
        const data = await validateToken({
          variables: { body: { verificationToken: confirmToken } },
        });
        if (data.data?.confirm) {
          setIsLoading(false);
        } else {
          setShowError(true);
        }
        setIsLoading(false);
      } catch (e) {
        setShowError(true);
      }
    })();
  }, [location, validateToken]);

  useEffect(() => {
    if (error) setShowError(true);
  }, [error]);

  if (showError) {
    return <AuthError loginRoute={getRoute('LOGIN')} errorReason="Invalid token" />;
  }

  if (isLoading) return <Loading />;

  return (
    <AuthCard>
      <div className="mb-5">
        <h2 className="text-xl font-bold text-gray-900">{t('emailConfirmed')}</h2>
      </div>
      <Link to={getRoute('LOGIN')} className="text-primary hover:text-secondary block text-sm ">
        {t('backToLogin')}
      </Link>
    </AuthCard>
  );
};
