export const LoadingTableTranslation = {
  fi: {
    LoadingTable: {
      loading: 'Ladataan...',
    },
  },
  en: {
    LoadingTable: {
      loading: 'Loading...',
    },
  },
};
