import { FieldMetaProps } from 'formik';

type Props = {
  htmlFor: string;
  children: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta: FieldMetaProps<any>;
};

export const Label = ({ htmlFor, children, meta }: Props) => {
  let labelClasses = 'label';

  if (meta.error && meta.touched) {
    labelClasses += ' label-error';
  }

  return (
    <label htmlFor={htmlFor} className={labelClasses}>
      {children}
    </label>
  );
};
