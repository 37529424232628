import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DriveFileResourceType } from '../config/upload.config';
import { uploadFileToServer } from '../utils/upload-file';

type Props = {
  refetch(): void;
};

export const FileDropzone = ({ refetch }: Props) => {
  const { t } = useTranslation('FileDropzone');
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  if (!id) throw new Error('[FileDropzone] Missing iD');

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setIsLoading(true);
      if (!acceptedFiles.length) {
        return toast.error('[FileDropzone] No accepted files. You file size might be too large.');
      }
      const data = await uploadFileToServer(
        acceptedFiles[0],
        DriveFileResourceType.DOCUMENT_FILE,
        id
      );
      if (data) refetch();
      setIsLoading(false);
    },
    [id, refetch]
  );

  const onError = useCallback((error: Error) => {
    toast.error(error.message);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onError,
    maxFiles: 1,
    disabled: isLoading,
    maxSize: 12000000,
    multiple: false,
  });

  let text = <p className="text-sm text-gray-900 text-center py-2 cursor-pointer">{t('info')}</p>;

  if (isDragActive) {
    text = <p className="text-sm text-gray-900 text-center py-2">{t('dropFiles')}</p>;
  }

  if (isLoading) {
    text = <p className="text-sm text-gray-900 text-center py-2">{t('loading')}</p>;
  }

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {text}
    </div>
  );
};
