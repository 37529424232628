import { useMemo } from 'react';
import { ReactSelectInput } from '../components/ReactSelectInput';
import { useYup } from '../config/use-yup.config';
import { useDocumentInput } from '../inputs/use-document.input';

type Option = {
  label: string;
  value: string;
};

export type AddApproverFormBody = {
  approverId: string;
};

export const useAddApproverValidationSchema = () => {
  const inputs = useDocumentInput();
  const Yup = useYup();

  return useMemo(() => {
    return Yup.object().shape({
      approverId: inputs.approverId.validation,
    });
  }, [Yup, inputs.approverId.validation]);
};

export const useAddApproverInitialValues = () => {
  const inputs = useDocumentInput();

  return useMemo(() => {
    return {
      approverId: inputs.approverId.initValue,
    };
  }, [inputs.approverId.initValue]);
};

type Init = ReturnType<typeof useAddApproverInitialValues>;

export type AddApproverInitialValues = Init;

type Props = {
  users?: Option[];
};

export const AddApproverForm = ({ users }: Props) => {
  const inputs = useDocumentInput();

  return (
    <div className="space-y-3 mb-4">
      {users ? <ReactSelectInput {...inputs.approverId.props} options={users} /> : null}
    </div>
  );
};
