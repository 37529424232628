import { ErrorMessage, useField } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { ReleoxBaseInputProps } from '../../form/ReleoxBaseInputProps';
import { InputWrapper } from './InputWrapper';
import { Label } from './Label';
import { useInputClasses } from './use-input-classes';

export interface SelectInputProps extends ReleoxBaseInputProps {
  label: string;
  options: {
    value: string;
    label: string;
  }[];
}

export const SelectInput = (props: SelectInputProps) => {
  const { t, i18n } = useTranslation();
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta);

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <select {...props} {...field} placeholder={props.label} className={inputClasses}>
          {props.placeholder ? <option value="">{t(props.placeholder)}</option> : undefined}
          {props.options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {i18n.exists(opt.label) ? t(opt.label) : opt.label}
            </option>
          ))}
        </select>
        <ErrorMessage component="span" className="text-red-color text-sm" name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableSelectInputOptions {
  type: 'select';
  initValue: string;
  validation: Yup.StringSchema | Yup.NumberSchema;
  props: SelectInputProps;
}

export const GeneratableSelectInput = {
  Element: SelectInput,
  type: 'select',
};
