import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { getRoute } from '../../config/routes.config';
import {
  ACLForm,
  ACLRuleFormBody,
  useACLRuleInitialValues,
  useACLRuleSchema,
} from '../../forms/use-acl-rule.form';
import {
  useAclQuery,
  useCreateAclRuleMutation,
  useDeleteAclRuleMutation,
} from '../../generated/graphql';

export const ACLShowScene = () => {
  const initailValues = useACLRuleInitialValues();
  const schema = useACLRuleSchema();
  const { t, i18n } = useTranslation('ACLShowScene');
  const [resetKey, setResetKey] = useState(0);
  const params = useParams();

  const { data, refetch } = useAclQuery({
    variables: {
      id: params?.id || 'UNKNOWN',
    },
  });

  const [createRule] = useCreateAclRuleMutation();
  const [deleteRule] = useDeleteAclRuleMutation();

  const handleSubmit = (body: ACLRuleFormBody) => {
    createRule({
      variables: {
        body: {
          resourceACLId: params?.id || 'UNKNOWN',
          permissions: body.rules.map((rule) => rule.value),
          documentModelId: body.model,
        },
      },
      onCompleted: (d) => {
        if (d.createACLRule) {
          refetch();
          setResetKey((prev) => prev + 1);
        }
      },
    });
  };

  const handleDelete = (id: string) => {
    deleteRule({
      variables: {
        id,
      },
      onCompleted: (d) => {
        if (d.deleteACLRule) {
          refetch();
        }
      },
    });
  };

  return (
    <div>
      <div className="grid md:grid-cols-2 space-x-3" key={resetKey}>
        <div>
          <Card>
            <ButtonLink className="float-right" to={getRoute('ACL_EDIT', { id: params.id })}>
              {t('edit')}
            </ButtonLink>
            <CardTitle>
              {t('addRule', {
                title: data?.acl?.name,
              })}
            </CardTitle>
            <p>{t('p')}</p>
            <br />
            <Formik onSubmit={handleSubmit} initialValues={initailValues} validationSchema={schema}>
              <Form>
                <ACLForm />
                <Button type="submit" className="float-right">
                  {t('Common:save')}
                </Button>
                <div className="clear-both" />
              </Form>
            </Formik>
          </Card>
        </div>

        <div className="space-y-3">
          <Card>
            <CardTitle>{t('ACL:rules')}</CardTitle>
            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="text-left">{t('ACL:model')}</th>
                  <th className="text-left">{t('ACL:permissions')}</th>
                  <th className="text-left">{t('ACL:actions')}</th>
                </tr>
              </thead>
              <tbody>
                {data?.acl?.rules.map((rule) => (
                  <tr key={rule.id}>
                    <td>
                      {i18n.exists(`ACLShowScene:${rule.documentModel.type}`)
                        ? t(rule.documentModel.type)
                        : rule.documentModel.type}
                    </td>
                    <td>
                      {rule.permissions
                        .map((v) => (i18n.exists(`ACLShowScene:${v}`) ? t(v) : v))
                        .join(', ')}
                    </td>
                    <td>
                      <Button type="button" onClick={() => handleDelete(rule.id)}>
                        {t('delete')}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>

          <Card>
            <CardTitle>{t('ACL:users')}</CardTitle>

            <table className="min-w-full">
              <thead>
                <tr>
                  <th className="text-left">{t('ACL:name')}</th>
                  <th className="text-left">{t('ACL:actions')}</th>
                </tr>
              </thead>
              <tbody>
                {data?.acl?.users.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>
                      <ButtonLink to={getRoute('TEAM_EDIT', { id: user.id })}>
                        {t('edit')}
                      </ButtonLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Card>
        </div>
      </div>
    </div>
  );
};
