import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { aclColumn } from '../../columns/acl.column';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { EmptyTable } from '../../components/table/EmptyTable';
import { LoadingTable } from '../../components/table/LoadingTable';
import { Pagination } from '../../components/table/Pagination';
import { Table } from '../../components/table/Table';
import { TableBody } from '../../components/table/TableBody';
import { TableCol } from '../../components/table/TableCol';
import { TableHead } from '../../components/table/TableHead';
import { TableLayout } from '../../components/table/TableLayout';
import { TableRow } from '../../components/table/TableRow';
import { TableTH } from '../../components/table/TableTH';
import { getRoute } from '../../config/routes.config';
import { NameOnlyFilterForm } from '../../forms/filters/name-only-filter/name-only-filter.form';
import { useAclListQuery } from '../../generated/graphql';
import { useOrderBy } from '../../hooks/index/use-order-by';
import { usePerPage } from '../../hooks/index/use-per-page';
import { useSkip } from '../../hooks/index/use-skip';

export const ACLIndexScene = (): JSX.Element => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation('ACLIndexScene');
  const perPage = usePerPage();
  const { orderBy, orderDir } = useOrderBy();
  const skip = useSkip();

  const columns = [aclColumn.name, aclColumn.userCount, aclColumn.createdAt];

  const { data, loading } = useAclListQuery({
    variables: {
      where: {
        name: searchParams.get('name'),
      },
      orderBy: [
        {
          [orderBy]: orderDir,
        },
      ],
      take: perPage,
      skip: skip,
    },
  });

  return (
    <TableLayout
      Filter={<NameOnlyFilterForm />}
      Table={
        <Card>
          <ButtonLink to={getRoute('ACL_CREATE')} className="float-right">
            {t('Common:addNew')}
          </ButtonLink>
          <CardTitle>{t('title')}</CardTitle>
          <Table>
            <TableHead>
              <tr>
                {columns.map((column) => (
                  <TableTH key={`${column.field}-${column.text}`} column={column} />
                ))}
              </tr>
            </TableHead>
            <TableBody>
              <EmptyTable columns={columns} data={data?.acls.list} loading={loading} />
              <LoadingTable columns={columns} loading={loading} />
              {data?.acls.list.map((acl) => (
                <TableRow key={acl.id} href={getRoute('ACL_SHOW', { id: acl.id })}>
                  {columns.map((column) => (
                    <TableCol key={`${column.field}-${column.text}`} data={acl} column={column} />
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Pagination total={data?.acls.totalCount || 0} />
        </Card>
      }
    />
  );
};
