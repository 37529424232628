import { useYup } from '../config/use-yup.config';
import { FormInput } from '../releox-engine/form/FormInput';

export const useACLInput = () => {
  const Yup = useYup();

  const name: FormInput = {
    type: 'text',
    initValue: '',
    validation: Yup.string().required(),
    props: {
      name: 'name',
      label: 'ACL:name',
      type: 'text',
    },
  };

  return { name };
};
