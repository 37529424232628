import { useMemo } from 'react';
import { useRejectDocumentInput } from '../inputs/use-reject-document.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useRejectDocumentForm = () => {
  const inputs = useRejectDocumentInput();

  const form = useMemo(() => {
    return createForm([inputs.reason]);
  }, [inputs.reason]);

  return form;
};

export type RejectDocumentFormBody = {
  reason: string;
};
