import { formatDate } from '../utils/format-date';
import { TableColumn } from './table-column';

const id: TableColumn = {
  field: 'id',
  text: 'ACL:id',
};

const name: TableColumn = {
  field: 'name',
  text: 'ACL:name',
};

const userCount: TableColumn = {
  field: 'userCount',
  text: 'ACL:userCount',
};

const createdAt: TableColumn = {
  field: 'createdAt',
  text: 'ACL:createdAt',
  formatter: (data) => formatDate(data.createdAt),
};

export const aclColumn = {
  id,
  name,
  userCount,
  createdAt,
};
