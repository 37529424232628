import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getRoute } from '../../config/routes.config';
import { GroupFormBody, useGroupForm } from '../../forms/use-group.form';
import {
  useDeleteGroupMutation,
  useGroupEditSceneQuery,
  useUpdateGroupMutation,
} from '../../generated/graphql';
import { AppFormScene } from '../../releox-engine/react-components/app-form-generator/AppFormScene';

export const GroupEditScene = (): JSX.Element => {
  const form = useGroupForm();
  const { t } = useTranslation('GroupEditScene');
  const navigate = useNavigate();
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');

  const query = useGroupEditSceneQuery({ variables: { id } });

  const [updateGroup, { loading }] = useUpdateGroupMutation({
    onCompleted: (data) => {
      if (data.updateGroup) {
        toast.success(t('Common:updated'));
        navigate(getRoute('GROUP_INDEX'));
      }
    },
  });

  const [deleteGroup] = useDeleteGroupMutation({
    onCompleted: (data) => {
      if (data.deleteGroup) {
        toast.success(t('Common:deleted'));
        navigate(getRoute('GROUP_INDEX'));
      }
    },
  });

  const handleSubmit = useCallback(
    (body: GroupFormBody) => {
      updateGroup({ variables: { id, body } });
    },
    [updateGroup, id]
  );

  const handleDelete = useCallback(() => {
    deleteGroup({ variables: { id } });
  }, [deleteGroup, id]);

  return (
    <AppFormScene
      type="edit"
      query={query}
      queryDataPath="group"
      title={t('title')}
      form={form}
      isSubmitLoading={loading}
      onSubmit={handleSubmit}
      backLink={getRoute('GROUP_INDEX')}
      onDelete={handleDelete}
    />
  );
};
