export const RejectTranslation = {
  fi: {
    Reject: {
      reason: 'Selite',
    },
  },
  en: {
    Reject: {
      reason: 'Reason',
    },
  },
};
