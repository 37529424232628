export const CustomReportCreateSceneTranslation = {
  fi: {
    CustomReportCreateScene: {
      title: 'Lisää uusi raportti',
      chooseType: 'Valitse dokumenttityyppi',
      chooseModelType: 'Valitse mallityyppi',
      chooseModelTypeButton: 'Tallenna',
      chooseField: 'Valitse kenttä',
      chooseFieldName: 'Valitse kentän nimi',
    },
  },
  en: {
    CustomReportCreateScene: {
      title: 'Create new report',
      chooseType: 'Choose document type',
      chooseModelType: 'Choose model type',
      chooseModelTypeButton: 'Save',
      chooseField: 'Choose field',
      chooseFieldName: 'Choose field name',
    },
  },
};
