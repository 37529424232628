import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { getRoute } from '../../config/routes.config';
import { useGroupShowSceneQuery } from '../../generated/graphql';

export const GroupShowScene = (): JSX.Element => {
  const { t } = useTranslation('GroupShowScene');
  const { id } = useParams();
  if (!id) throw new Error('Missing ID');

  const { data } = useGroupShowSceneQuery({ variables: { id } });

  return (
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <ButtonLink
          to={getRoute('GROUP_EDIT', {
            id,
          })}
          className="float-right"
        >
          {t('edit')}
        </ButtonLink>
        <CardTitle>{data?.group.name ?? ''}</CardTitle>
        <table className="min-w-full">
          <thead>
            <tr>
              <td>{t('name')}</td>
              <td>{t('actions')}</td>
            </tr>
          </thead>
          <tbody>
            {data?.group.users.map((user) => (
              <tr key={user.id}>
                <td>{user.name}</td>
                <td>
                  <ButtonLink
                    to={getRoute('TEAM_EDIT', {
                      id: user.id,
                    })}
                  >
                    {t('edit')}
                  </ButtonLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
    </div>
  );
};
