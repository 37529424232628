export const ContentEditorTranslation = {
  fi: {
    ContentEditor: {
      noContent: 'Ei sisältöä',
      edit: 'Muokkaa',
    },
  },
  en: {
    ContentEditor: {
      noContent: 'No content',
      edit: 'Edit',
    },
  },
};
