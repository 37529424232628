import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getRoute } from '../config/routes.config';
import { DocumentModelIndexSceneQuery } from '../generated/graphql';
import { TableColumn } from './table-column';

const type: TableColumn = {
  field: 'type',
  text: 'DocumentModel:type',
  formatter: (data: DocumentModelIndexSceneQuery['documentModels']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('DocumentModel');
    return t(`${data.type}`);
  },
};

const link: TableColumn = {
  field: 'privilege',
  text: 'DocumentModel:link',
  formatter: (data: DocumentModelIndexSceneQuery['documentModels']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('DocumentModel');

    if (data.privilege !== 'public') return '-';

    return (
      <Link className="text-primary underline" to={getRoute('PUBLIC_PAGE', { id: data.id })}>
        {t('link')}
      </Link>
    );
  },
};

const edit: TableColumn = {
  field: 'id',
  text: 'DocumentModel:actions',
  formatter: (data: DocumentModelIndexSceneQuery['documentModels']['list'][0]) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('DocumentModel');

    return (
      <Link
        className="text-primary underline"
        to={getRoute('DOCUMENT_MODEL_EDIT', { id: data.id })}
      >
        {t('edit')}
      </Link>
    );
  },
};

export const documentModelColumn = {
  type,
  edit,
  link,
};
