import { useEffect } from 'react';

type Props = {
  className: string;
};

export const BackgroundClass = (props: Props) => {
  useEffect(() => {
    document.getElementsByTagName('html').item(0)?.classList.add(props.className);
    return () => {
      document.getElementsByTagName('html').item(0)?.classList.remove(props.className);
    };
  }, [props]);
  return <></>;
};
