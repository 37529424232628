import { useSearchParams } from 'react-router-dom';

export const usePageNumber = (): number => {
  const [searchParams] = useSearchParams();

  if (!searchParams.has('page')) return 1;

  try {
    const number = parseInt(searchParams.get('page') ?? '1', 10);

    if (number < 1) return 1;

    return number;
  } catch {
    return 1;
  }
};
