import { ErrorMessage, useField } from 'formik';
import { List } from 'immutable';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import * as _Yup from 'yup';
import { useYup } from '../config/use-yup.config';
import { ReleoxBaseInputProps } from '../releox-engine/form/ReleoxBaseInputProps';
import { InputWrapper } from '../releox-engine/react-components/form-inputs/InputWrapper';
import { Label } from '../releox-engine/react-components/form-inputs/Label';

export interface ReactMultiSelectInputProps extends ReleoxBaseInputProps {
  label: string;
}

type Option = {
  value: string;
  label: string;
};

interface Props extends ReactMultiSelectInputProps {
  options: Option[];
}

export const useReactMultiSelectValidationSchema = () => {
  const Yup = useYup();
  return Yup.array()
    .of(
      Yup.object()
        .shape({
          label: Yup.string().required(),
          value: Yup.string().required(),
        })
        .required()
    )
    .required();
};

export const ReactMultiSelectInput = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState<Props['options'] | null>(null);
  const [defaultValue, setDefaultValue] = useState<Props['options'] | undefined>();
  const [field, meta, helpers] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const handleDefaultValue = useCallback(() => {
    setDefaultValue(meta.initialValue);
  }, [meta.initialValue]);

  useEffect(() => {
    let list = List<Props['options'][0]>([]);
    props.options.forEach((opt) => {
      list = list.push({
        value: opt.value,
        label: i18n.exists(opt.label) ? t(opt.label) : opt.label,
      });
    });
    setOptions(list.toArray());
    handleDefaultValue();
  }, [props.options, t, i18n, handleDefaultValue]);

  if (!options) return <span />;

  return (
    <div key={JSON.stringify(defaultValue)}>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <Select
          placeholder={<>{props.placeholder ? t(props.placeholder) : undefined}</>}
          classNamePrefix="select"
          defaultValue={defaultValue}
          isClearable
          isMulti
          isSearchable
          noOptionsMessage={() => t('ReactSelectInput:noOptionsMessage')}
          name={field.name}
          options={options}
          onChange={(value) => helpers.setValue(value)}
        />
        <ErrorMessage component="span" className="text-red-color text-sm" name={field.name} />
      </InputWrapper>
    </div>
  );
};

export interface GeneratableReactMultiSelectInputOptions {
  type: 'react-multi-select';
  initValue: Option[];
  validation: _Yup.AnySchema;
  props: ReactMultiSelectInputProps;
}

export const GeneratableReactMultiSelectInput = {
  Element: ReactMultiSelectInput,
  type: 'react-multi-select',
};
