/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldArray, FieldArrayRenderProps } from 'formik';
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput, FormInputs } from './FormInput';
import { ReleoxBaseInputProps } from './ReleoxBaseInputProps';

type ArrayElementProps = {
  children: JSX.Element;
};

type AddElementProps = {
  onClick(): void;
};

interface Props extends ReleoxBaseInputProps {
  elements: FormInput[];
  label: string;
  initialValues: any;
  ArrayElement(props: ArrayElementProps): JSX.Element;
  AddElement(props: AddElementProps): JSX.Element;
  RemoveElement(props: AddElementProps): JSX.Element;
}

export const ArrayInput = (props: Props) => {
  const { t } = useTranslation();
  return (
    <FieldArray name={props.name}>
      {({ remove, push, form }: FieldArrayRenderProps) => (
        <>
          {_.get(form.values, props.name, { default: [] }).map((__: any, valueIndex: number) => (
            <Fragment key={valueIndex}>
              <h2>
                {t(props.label)} {valueIndex + 1}
              </h2>

              <props.ArrayElement>
                <>
                  {props.elements.map((ele) => {
                    const input: any = FormInputs.find((i) => i.type === ele.type);
                    const name = `${props.name}.${valueIndex}.${ele.props.name}`;

                    return (
                      <input.Element
                        {...ele.props}
                        name={name}
                        key={name}
                        id={ele.props.id || ele.props.name}
                      />
                    );
                  })}
                </>
              </props.ArrayElement>

              <props.RemoveElement
                key={`remove-${valueIndex}`}
                onClick={() => remove(valueIndex)}
              />
            </Fragment>
          ))}
          <props.AddElement onClick={() => push(props.initialValues)} />
        </>
      )}
    </FieldArray>
  );
};

export interface GeneratableArrayInputOptions {
  type: 'array';
  props: Props;
}

export const GeneratableArrayInput = {
  type: 'array',
  Element: ArrayInput,
};
