import { useState } from 'react';
import { ReactMultiSelectOption } from '../../components/inputs/react-select-input';
import { useGroupIndexSceneQuery } from '../../generated/graphql';

export const useGroupSelectValues = () => {
  const [value, setValue] = useState<null | ReactMultiSelectOption[]>(null);

  useGroupIndexSceneQuery({
    onCompleted: (data) => {
      setValue(
        data.groups.list.map((acl) => ({
          value: acl.id,
          label: acl.name,
        }))
      );
    },
  });

  return value;
};
