export const MonthTranslation = {
  fi: {
    Month: {
      January: 'tammikuu',
      February: 'helmikuu',
      March: 'maaliskuu',
      April: 'huhtikuu',
      May: 'toukokuu',
      June: 'kesäkuu',
      July: 'heinäkuu',
      August: 'elokuu',
      September: 'syyskuu',
      October: 'lokakuu',
      November: 'marraskuu',
      December: 'joulukuu',
    },
  },
  en: {
    Month: {
      January: 'Janyary',
      February: 'February',
      March: 'March',
      April: 'April',
      May: 'May',
      June: 'June',
      July: 'July',
      August: 'August',
      September: 'September',
      October: 'October',
      November: 'November',
      December: 'December',
    },
  },
};
