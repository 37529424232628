import { Form, Formik } from 'formik';
import _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { FormLayout } from '../../components/FormLayout';
import { Loading } from '../../components/Loading';
import { getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import {
  DocumentCreateForm,
  DocumentCreateFormBody,
  DocumentCreateInitialValues,
  useDocumentCreateInitialValues,
  useDocumentCreateValidationSchema,
} from '../../forms/use-document-create.form';
import {
  useCreateDocumentMutation,
  useCustomDocumentCreateSceneQuery,
} from '../../generated/graphql';
import { useOnComplete } from '../../releox-engine/on-complete/use-on-complete';
import {
  remapDocumentFormFieldToDocumentField,
  remapModelFieldToEmptyDocumentFormField,
  remapOptions,
} from '../../utils/document-remap-helpers';

type Params = { documentModelId: string };

export const CustomDocumentCreateScene = (): JSX.Element => {
  const schema = useDocumentCreateValidationSchema();
  const values = useDocumentCreateInitialValues();

  const { t } = useTranslation('DocumentCreateScene');
  const [user] = useContext(UserContext);
  const [initValues, setInitialValues] = useState<DocumentCreateInitialValues | null>(null);

  const { documentModelId } = useParams<Params>();

  // Throw error if documentModelId is not provided
  if (!documentModelId) {
    throw new Error('[CustomDocumentCreateScene] documentModelId is null');
  }

  const INDEX = 'CUSTOM_DOCUMENT_INDEX';
  const SHOW = 'CUSTOM_DOCUMENT_SHOW';
  const HOME = 'DASHBOARD';

  const hasReadPermission = useMemo(
    () => user.myCustomDocuments.read.includes(documentModelId),
    [documentModelId, user.myCustomDocuments.read]
  );

  const onComplete = useOnComplete(hasReadPermission ? SHOW : HOME, 'saved');

  const { data, loading: isQueryLoading } = useCustomDocumentCreateSceneQuery({
    variables: { id: documentModelId },
  });

  const [createDocument, { loading }] = useCreateDocumentMutation({
    onCompleted: onComplete,
  });

  useEffect(() => {
    const fields = _.map(
      data?.documentModelById.documentModelFields,
      remapModelFieldToEmptyDocumentFormField
    );

    setInitialValues({
      ...values,
      fields,
    });
  }, [data?.documentModelById.documentModelFields, values]);

  const handleSubmit = useCallback(
    (body: DocumentCreateFormBody) => {
      if (!data?.documentModelById) {
        throw new Error('[DocumentCreateScene] documentModelById is null');
      }

      createDocument({
        variables: {
          body: {
            ...body,
            tags: _.map(body.tags, 'value'),
            groups: _.map(body.groups, 'value'),
            fields: _.map(body.fields, remapDocumentFormFieldToDocumentField),
            type: data?.documentModelById.type,
            documentModelId: data?.documentModelById.id,
          },
        },
      });
    },
    [createDocument, data?.documentModelById]
  );

  if (isQueryLoading || !data || !initValues) return <Loading />;

  return (
    <FormLayout key={JSON.stringify(initValues)}>
      <Card>
        <CardTitle>
          {t('create')}: {data?.documentModelById.type}
        </CardTitle>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            ...initValues,
            groups: user.defaultGroups.map(remapOptions),
            approverId: user.defaultApproverId ?? initValues.approverId,
          }}
        >
          <Form>
            <DocumentCreateForm
              groups={_.map(data.myGroups.list, remapOptions)}
              tags={_.map(data.tags.list, remapOptions)}
              users={data.allDocumentApprovers}
              type={data.documentModelById.type}
            />
            <Button type="submit" className="float-right" loading={loading}>
              {t('Common:save')}
            </Button>
            <ButtonLink to={getRoute(hasReadPermission ? INDEX : HOME)}>
              {t('Common:back')}
            </ButtonLink>
          </Form>
        </Formik>
      </Card>
    </FormLayout>
  );
};
