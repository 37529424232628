export default {
  fi: {
    RegisterScene: {
      termsOfUse: 'Käyttöehdot',
      back: 'Takaisin',
      register: 'Rekisteröidy',
      fillInInformations: 'Täytä tiedot ja rekisteröidy',
    },
  },
  en: {
    RegisterScene: {
      forgotPassword: 'Terms of use',
      back: 'Back',
      register: 'Register',
      fillInInformations: 'Fill in the information and register',
    },
  },
};
