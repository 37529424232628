import { useMemo } from 'react';
import { useMSLinkInput } from '../inputs/use-ms-link.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useMSLinkForm = () => {
  const inputs = useMSLinkInput();

  return useMemo(() => {
    return createForm([inputs.iframe]);
  }, [inputs.iframe]);
};

export type MSLinkFormBody = {
  iframe: string;
};
