import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Loading } from '../../components/Loading';
import { ReportMetaData } from '../../components/ReportMetaData';
import { QNCType } from '../../config/qnc-types';
import { ReportQncTypeSceneQuery } from '../../generated/graphql';
import { LegendOnClick } from '../../utils/LegendOnClick';
import { chartColors } from '../../utils/chart-colors';

type Props = {
  data?: ReportQncTypeSceneQuery['qncCategoryReport'];
  loading: boolean;
  hideCount?: boolean;
};

export const ReportQncTypeChart = ({ data, loading, hideCount }: Props) => {
  const { t } = useTranslation('ReportQncTypeScene');
  const [displayData, setDisplayData] = useState({
    [QNCType.INTERNAL_NON_CONFORMITY]: true,
    [QNCType.CUSTOMER_RECLAMATION]: true,
    [QNCType.SUPPLIER_RECLAMATION]: true,
    [QNCType.INITIATIVE]: true,
    [QNCType.SAFETY_ACCIDENT]: true,
    [QNCType.SAFETY_OBSERVATION]: true,
    [QNCType.ENVIRONMENT_OBSERVATION]: true,
    [QNCType.EXTERNAL_AUDIT]: true,
    [QNCType.INTERNAL_AUDIT]: true,
    [QNCType.OTHER]: true,
  });

  const bars = Object.keys(_.get(data?.result, '0.data', {}));

  const barChartData = data?.result.map((d) => ({
    name: t(`Month:${d.name}`),
    ...d.data,
  }));

  const handleLegendClick = useCallback(
    ({ dataKey }: LegendOnClick) => {
      if (typeof dataKey === 'string') {
        setDisplayData({
          ...displayData,
          [dataKey]: !_.get(displayData, dataKey),
        });
      }
    },
    [displayData, setDisplayData]
  );

  if (loading || !data) return <Loading />;

  return (
    <>
      <ReportMetaData data={data} hideCount={hideCount} />
      <ResponsiveContainer width="100%" aspect={4.0 / 1.4}>
        <BarChart data={barChartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend onClick={handleLegendClick} />
          {bars
            .filter((key) => key !== '__typename')
            .map((key, i) => {
              return (
                <Bar
                  key={key}
                  hide={!_.get(displayData, key)}
                  dataKey={key}
                  name={t<string>(key)}
                  fill={chartColors[i]}
                />
              );
            })}
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};
