export const DocumentLinkCreateTranslation = {
  fi: {
    DocumentLinkCreate: {
      title: 'Lisää linkki',
    },
  },
  en: {
    DocumentLinkCreate: {
      title: 'Add link',
    },
  },
};
