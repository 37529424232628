import { useField } from 'formik';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { isI18nString } from '../../utils/is-i18n-string';
import { FormInput, FormInputProps } from '../form-tools/form-input';
import { ErrorMessage } from './error-message';
import { InputWrapper } from './input-wrapper';
import { Label } from './label';
import { useInputClasses } from './use-input-classes';

export interface SelectInputProps extends FormInputProps {
  placeholder?: string;
  options: {
    value: string;
    label: string;
  }[];
}

type Validation = Yup.StringSchema;

export type SelectInputType = FormInput<SelectInputProps, Validation, string, 'select'>;

export const SelectInput: FC<SelectInputProps> = (props) => {
  const { t } = useTranslation();
  const [field, meta] = useField(props);
  const { name, label } = props;
  const id = `${name}-input`;

  const inputClasses = useInputClasses(meta);

  return (
    <div>
      <Label htmlFor={id} meta={meta}>
        {label}
      </Label>
      <InputWrapper>
        <select {...props} {...field} id={id} placeholder={props.label} className={inputClasses}>
          {props.placeholder ? <option value="">{props.placeholder}</option> : undefined}
          {props.options.map((opt) => (
            <option key={opt.value} value={opt.value}>
              {isI18nString(opt.label) ? t(opt.label) : opt.label}
            </option>
          ))}
        </select>

        <ErrorMessage name={field.name} />
      </InputWrapper>
    </div>
  );
};
