export const TagEditSceneTranslation = {
  fi: {
    TagEditScene: {
      title: 'Muokkaa Tagia',
    },
  },
  en: {
    TagEditScene: {
      title: 'Edit Tag',
    },
  },
};
