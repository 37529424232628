import ImageCompress from 'quill-image-compress';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { getDocShowRoute, getRoute } from '../../config/routes.config';
import { useEditorQuery, useUpdateDocumentContentMutation } from '../../generated/graphql';
import { DocumentTypeField } from '../../utils/DocumentType';

Quill.debug('error');
Quill.register('modules/imageCompress', ImageCompress);

const modules = {
  toolbar: [
    [{ font: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'super' }, { script: 'sub' }],
    [{ header: '1' }, { header: '2' }, 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    [{ align: [] }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
  imageCompress: {
    quality: 0.5, // default
    maxWidth: 800, // default
    maxHeight: 800, // default
    debug: false, // default
    suppressErrorLogging: false, // default
  },
};

type Props = {
  type: DocumentTypeField;
};

export const EditorScene = ({ type }: Props): JSX.Element => {
  const { t } = useTranslation('EditorScene');
  const [text, setText] = useState('');
  const [isMounted, setIsMounted] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  if (!id) throw new Error(`[EditorScene] missing id`);
  const SHOW_URL = getDocShowRoute(type);

  const { data, loading } = useEditorQuery({ variables: { id } });
  const [updateContent, { loading: isMutationLoading }] = useUpdateDocumentContentMutation({
    onCompleted: (d) => {
      if (d) {
        navigate(getRoute(SHOW_URL, { id }));
      }
    },
  });

  const handleOnClick = useCallback(() => {
    if (!data) throw new Error(`[EditorScene:handleOnClick] missing data`);
    updateContent({
      variables: {
        id,
        documentContentId: data.document.documentContent.id,
        body: {
          content: text,
        },
      },
    });
  }, [text, id, updateContent, data]);

  useEffect(() => {
    if (data) {
      setText(data.document.documentContent.content);
      setIsMounted(true);
    }
  }, [data]);

  if (!data || loading || !isMounted) return <Loading />;

  return (
    <>
      <div className="editor-height">
        <ReactQuill defaultValue={text} theme="snow" modules={modules} onChange={setText} />
        <div>
          <Button
            loading={isMutationLoading}
            onClick={handleOnClick}
            type="button"
            className="md:mx-auto my-3 block"
          >
            {t('save')}
          </Button>
        </div>
      </div>
    </>
  );
};
