export const RejectSceneTranslation = {
  fi: {
    RejectScene: {
      title: 'Hylkää dokumentti',
    },
  },
  en: {
    RejectScene: {
      title: 'Discard the document',
    },
  },
};
