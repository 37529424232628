import { WatchQueryFetchPolicy } from '@apollo/client';
import _ from 'lodash';

type CacheOptions = {
  fetchPolicy: WatchQueryFetchPolicy;
};

export const setupQueryConfig = <T, O>(variables?: T, options?: O) => {
  const cacheOptions: CacheOptions = {
    fetchPolicy: 'no-cache',
  };

  return _.merge(cacheOptions, { variables }, options);
};
