type LayoutProps = {
  children: JSX.Element;
};

export const ApprovalActionLayout = ({ children }: LayoutProps) => (
  <>
    <hr className="my-3" />
    {children}
  </>
);
