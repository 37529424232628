export default {
  fi: {
    DocumentModel: {
      document: 'Dokumentti',
      risk: 'Riskit ja mahdollisuudet',
      qnc: 'Poikkeama',
      type: 'Tyyppi',
      label: 'Kentän nimi',
      options: 'Vaihtoehdot',
      documentModelFields: 'Pohjan kenttä',
      select: 'Valinta',
      text: 'Teksti',
      number: 'Numero',
      date: 'Päivämäärä',
      undefined: 'Määrittelemätön',
      modelType: 'Lomakkeen nimi',
      privilege: 'Käyttöoikeus',
      private: 'Yksityinen',
      public: 'Julkinen',
      actions: 'Toiminnot',
      edit: 'Muokkaa',
      inlineSelect: 'Pikavalinta',
      link: 'Linkki',
      anonymous: 'Anonyymi',
    },
  },
  en: {
    DocumentModel: {
      document: 'Document',
      risk: 'Risk',
      qnc: 'QNC',
      type: 'Type',
      label: 'Label',
      options: 'Options',
      documentModelFields: 'Document model field',
      select: 'Select',
      text: 'Text',
      number: 'Number',
      date: 'Date',
      undefined: 'Undifined',
      modelType: 'Document name',
      privilege: 'Publicity',
      actions: 'Actions',
      edit: 'Edit',
      inlineSelect: 'Quick select',
      anonymous: 'Anonymous',
      private: 'Private',
      public: 'Public',
    },
  },
};
