import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonLink } from '../../components/ButtonLink';
import { Card } from '../../components/Card';
import { CardTitle } from '../../components/CardTitle';
import { Loading } from '../../components/Loading';
import { getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { useCustomDocumentModelsQuery } from '../../generated/graphql';

export const ChooseCustomDocumentModelScene = () => {
  const { t } = useTranslation('ChooseCustomDocumentModelScene');
  const [user] = useContext(UserContext);

  const { data, loading } = useCustomDocumentModelsQuery({
    variables: {
      modelIds: user.myCustomDocuments.create,
    },
  });

  if (loading) {
    return <Loading />;
  }

  return (
    // TODO: Check and test responsiveness
    <div className="mx-auto w-full max-w-3xl sm:px-2">
      <Card>
        <>
          <CardTitle>{t('title')}</CardTitle>
          {/* Show test if custom documents is empty */}
          {data?.customDocumentModels.length === 0 && (
            <p className="text-center">{t('noCustomDocuments')}</p>
          )}

          {/* List all model names as buttons */}
          {data?.customDocumentModels.map((model) => (
            <ButtonLink
              key={model.id}
              className="!block text-center my-2"
              to={getRoute('CUSTOM_DOCUMENT_CREATE', { documentModelId: model.id })}
            >
              {model.type}
            </ButtonLink>
          ))}
        </>
      </Card>
    </div>
  );
};
