import { ExclamationIcon } from '@heroicons/react/outline';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useSendAllDocumentApproveRequestMutation } from '../../generated/graphql';
import { Button } from '../Button';
import { ApprovalActionLayout } from './ApprovalActionLayout';

type Props = {
  refetch(): void;
};

export const NotApprovableCreatedByUser: FC<Props> = ({ refetch }) => {
  const { t } = useTranslation('DocumentApprovalAction');
  const { id } = useParams();
  if (!id) throw new Error('[ApproveElement] missing id');

  const [sendAllDocumentApproveRequest, { loading }] = useSendAllDocumentApproveRequestMutation({
    variables: {
      documentId: id,
    },
    onCompleted: (d) => {
      if (d.sendAllDocumentApproveRequest) {
        toast.success(
          t('allRequestSent', {
            count: d.sendAllDocumentApproveRequest.length,
          })
        );
        refetch();
      }
    },
  });

  return (
    <ApprovalActionLayout>
      {/* <div className="grid sm:grid-cols-3"> */}
      <div className="sm:col-span-2 text-red-500">
        <div className="float-right">
          <Button type="button" loading={loading} onClick={() => sendAllDocumentApproveRequest()}>
            {t('DocumentShowScene:request')}
          </Button>
        </div>
        <ExclamationIcon className="h-6 w-6 float-left mt-2 mr-2" aria-hidden="true" />
        <p className="text-sm pt-2">{t('unapproved')}</p>
      </div>
      {/* </div> */}
    </ApprovalActionLayout>
  );
};
