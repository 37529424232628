import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  columns: unknown[];
  loading: boolean;
};

export const LoadingTable: FC<Props> = ({ columns, loading }) => {
  const { t } = useTranslation();

  if (!loading) return <></>;

  return (
    <tr>
      <td colSpan={columns.length} className="text-center py-4">
        {t('LoadingTable:loading')}
      </td>
    </tr>
  );
};
