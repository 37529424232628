import { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { DashButton } from '../../components/DashButton';
import { HomeSceneReport } from '../../components/HomeSceneReport';
import { CustomDocIndexDashButton } from '../../components/home-scene/CustomDocIndexDashButton';
import { DocIndexDashButton } from '../../components/home-scene/DocIndexDashButton';
import { QNCIndexDashButton } from '../../components/home-scene/QNCIndexDashButton';
import { RiskIndexDashButton } from '../../components/home-scene/RiskIndexDashButton';
import { getRoute } from '../../config/routes.config';
import UserContext from '../../contexes/UserContext';
import { viewPrivilegeAtom } from '../../contexes/view-privilege.atom';

export const HomeScene = () => {
  const { t } = useTranslation('HomeScene');
  const [user] = useContext(UserContext);

  const viewPrivilege = useRecoilValue(viewPrivilegeAtom);

  return (
    <div className="grid xl:grid-cols-4 xl:space-x-6 xl:space-y-0">
      <div className="xl:col-span-1">
        {viewPrivilege.qnc.create && (
          <DashButton
            to={getRoute('QNC_CREATE')}
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
            title={t('addNewQnc')}
            text={t('addNewQnc')}
          />
        )}

        {viewPrivilege.document.create && (
          <DashButton
            to={getRoute('DOCUMENT_CREATE')}
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
            title={t('addNewDocument')}
            text={t('addNewDocument')}
          />
        )}

        {viewPrivilege.risk.create && (
          <DashButton
            to={getRoute('RISK_CREATE')}
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
            title={t('addNewRisk')}
            text={t('addNewRisk')}
          />
        )}

        {user.myCustomDocuments.create.length ? (
          <DashButton
            to={getRoute('CUSTOM_DOCUMENT_CHOOSE')}
            icon="M12 6v6m0 0v6m0-6h6m-6 0H6"
            title={t('addNewCustomDocument')}
            text={t('addNewCustomDocument')}
          />
        ) : (
          <Fragment />
        )}

        {viewPrivilege.qnc.read && <QNCIndexDashButton />}

        {viewPrivilege.document.read && <DocIndexDashButton />}

        {viewPrivilege.risk.read && <RiskIndexDashButton />}

        {user.myCustomDocuments.read.length > 0 ? <CustomDocIndexDashButton /> : <Fragment />}
      </div>

      {user.isCompanyAdmin || user.isCompanyOwner ? <HomeSceneReport /> : null}
    </div>
  );
};
