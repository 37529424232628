import { FC } from 'react';

type Props = {
  children: React.ReactNode;
};

export const Table: FC<Props> = ({ children }) => {
  return (
    <div className="overflow-x-auto">
      <table className="bg-white min-w-full divide-y divide-gray-200 overflow-hidden border-b border-gray-200 rounded-lg">
        {children}
      </table>
    </div>
  );
};
