export const ReportQncCostSceneTranslation = {
  fi: {
    ReportQncCostScene: {
      title: 'Poikkeamakustannusraportti',
      sum: 'Summa',
    },
  },
  en: {
    ReportQncCostScene: {
      title: 'QNC Costs Report',
      sum: 'Sum',
    },
  },
};
