import { useMemo } from 'react';
import { useDocumentModelInput } from '../inputs/use-document-model.input';
import { createForm } from '../releox-engine/form/FormGenerator';

export const useDocumentModelCreateForm = () => {
  const inputs = useDocumentModelInput();

  return useMemo(() => {
    return createForm([inputs.modelType, inputs.privilege, inputs.fields]);
  }, [inputs.modelType, inputs.privilege, inputs.fields]);
};

type DocumentModelField = {
  type: string;
  label: string;
  position: number;
  options: string[];
};

export type DocumentModelCreateFormBody = {
  type: string;
  privilege: string;
  documentModelFields: DocumentModelField[];
};
